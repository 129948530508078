<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <!-- <h2>{{firstName}} {{lastName}}</h2> -->
      <h2>{{studentName}}</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngIf="IsStudentOrPreant!=true"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li> -->
          <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage
              Students</a></li>
          <li class="breadcrumb-item active" aria-current="page">Attendance</li>
        </ol>
      </div>
    
    </div>
    <div class="">
      <div class="add_button">
        <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
      </div>
    </div>
  </div>
  <div class="cardWidget">
    <app-profile-tabs></app-profile-tabs>
    <div class="cardBody">
      <div>
        <form [formGroup]="stuAttendanceForm" (ngSubmit)="getData()">
          <!-- <div class="row"> -->
          <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Class</mat-label>
                        <mat-select placeholder="Select Class" matTooltip="{{selectedClass}}"
                          matTooltipClass="my-custom-tooltip" (selectionChange)="selectSectionByClassID($event)"
                          formControlName="ClassID">
                          <mat-option *ngIf="!classListDropdown">No Classes</mat-option>
                          <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
                            {{classSelect.className}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="stuAttendanceForm.controls['ClassID'].invalid">
                          Class is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Section</mat-label>
                        <mat-select placeholder="Select Section" matTooltip="{{selectedSection}}"
                          matTooltipClass="my-custom-tooltip" (selectionChange)="GetSubjectBySectionId($event)"
                          formControlName="SectionID">
                          <mat-option *ngIf="!sectionListDropdown">No Sections</mat-option>
                          <mat-option [value]="sec.sectionID" *ngFor="let sec of sectionListDropdown">
                            {{sec.sectionName}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="stuAttendanceForm.controls['SectionID'].invalid">
                          Section is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Subject</mat-label>
                        <mat-select placeholder="Select Subject" formControlName="SubjetID">
                          <mat-option *ngIf="!subjectList">No Subjects</mat-option>
                          <mat-option [value]="sub.subjectID" *ngFor="let sub of subjectList">{{sub.subjectName}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="stuAttendanceForm.controls['SubjetID'].invalid">
                          Subject is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="col-lg-2 col-sm-12">
              <button type="submit" class="btn btn-primary btn-block smallerBtn mt-1" matRipple>
                Find <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                  <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                    <path id="Path"
                      d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                      transform="translate(-3 -5.95)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>


           
          </div>
        </form>
      </div>
      <div class="row mb-3">
        <div class="col-12 onerows">
          
          <div role="group" class="btn-group mr-2">
            <button class="btn btn-outline-primary" matRipple (click)="previousYear()">
              <i class="fas fa-chevron-left"></i> Previous</button>
            <button class=" btn btn-outline-primary" matRipple (click)="nextYear()">Next <i
                class="fas fa-chevron-right"></i>
            </button>
            <div *ngIf="colData1 && colData1.length>0 && dataSource1 && dataSource1.length>0" class="downloadTable tableActions" matTooltip="Download Attendence" style="
              margin-left: 39px !important;
          ">
            <a  (click)="exporter.exportTable('csv', {
              fileName: ExcelName()
            })"><i title="Export Students" class="fas fa-download mt-3"></i></a>
          </div>
          </div>
          
         
          <div class="attendancetabs">
            <p class="mr-2" *ngIf="attendanceCountModel.length == 0">No Attendence Marked</p>
            <div class="attendencestatus mb-3" *ngFor="let count of attendanceCountModel">
              <div class="attend-block mr-1" [ngStyle]="{'background-color': count.colorCode }">{{count.statusCount}}
                {{count.statusName}} </div>
            </div>

          </div>

        </div>
      </div>

      <div class="mat-elevation-z8 py-4">
        <table style="display: none;" matTableExporter #exporter="matTableExporter" mat-table [dataSource]="studentAttendanceModel">
          
  
              <!-- ID Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef > date </th>
                <td  mat-cell *matCellDef="let element"> {{element.startDate | date}} </td>
              </ng-container>
  
              <!-- ID Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> status </th>
                <td mat-cell *matCellDef="let element">
                  {{element.statusName}}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef> comment </th>
                <td mat-cell *matCellDef="let element">
                  {{element.firstName}}
                </td>
              </ng-container> -->


          <!-- <ng-container [matColumnDef]="column" *ngFor="let column of colData">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="colData1"></tr>
            <tr mat-row *matRowDef="let row; columns: colData1">
            </tr>
          </table>
      </div>

      <div class="text-center year_Of_caledar"><i class="fas fa-calendar-alt mr-1"></i> {{year}}</div>
      <div class="text-center">
        <div id="calendar"></div>
      </div>
    </div>
  </div>
</div>