<div class="container container1580">
    <div class="headingcontent">
      <div class="pageTitle mt-0">
        <h2>Grade Setup</h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="!isTeacherUser"><a routerLink="/admin"><i
                  class="fas fa-home"></i> Home</a></li>
                  <li class="breadcrumb-item"><a routerLink="../manage-grade">Grade Book</a></li>
            <li class="breadcrumb-item active" aria-current="page">Grade Setup</li>
          </ol>
        </div>
      </div>
 
    </div>
  
      <div class="cardWidget">
          <div class="assesmentTabs">
            <a *ngIf="checkScreenPermission('Grade Book')" routerLink="../manage-grade" routerLinkActive="active"><i class="far fa-bookmark"></i> Grade Book</a>
            <a *ngIf="checkScreenPermission('Report Card')" routerLink="../report-cards" routerLinkActive="active"><i class="far fa-list-alt"></i> Report Cards</a>
            <a *ngIf="checkScreenPermission('Assessment')" routerLink="../manage-assessment" routerLinkActive="active"><i class="far fa-file-alt"></i> Assessment</a>
            <a *ngIf="checkScreenPermission('Grade Setup')" routerLink="../grade-setup" routerLinkActive="active"><i class="fas fa-chalkboard-teacher"></i> Grade Setup</a>
          </div>
        <div class="cardBody" *ngIf="checkActionPermission('View Grade Setup Master')">           
            <div class="gradeSettingsWidget" *ngIf="checkActionPermission('Assessment Category List')">
               <app-assessment-categories></app-assessment-categories>               
            </div><!--/Assessment Categories-->
            <div class="gradeSettingsWidget">
               <app-wights-setting></app-wights-setting>
            </div><!--/Weighted Subject &amp; Category Settings-->
            <div class="gradeSettingsWidget">
                <app-grading-periods></app-grading-periods>
            </div><!--/Grading Periods-->
            <div class="gradeSettingsWidget">
                <app-grading-scales></app-grading-scales>
            </div><!--/Grading Scales-->
            <div class="gradeSettingsWidget">
                <app-reportgrade-display></app-reportgrade-display>
            </div><!--/Report cards & Grade Book Display-->
            <div class="gradeSettingsWidget">
                <app-reportcard-format></app-reportcard-format>
            </div><!--/Report Cards Format-->
        </div>
    </div>
</div>

