import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { ImageCroppedEvent } from 'src/app/app-layout/content/Interface/image-cropped-event';
import { LoadedImage } from 'src/app/app-layout/content/Interface/loaded-image';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';
import { FormioOptions } from 'angular-formio';
import { SectionService } from '../../../courses-and-subjects/services/sectionServices/section.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { F } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {
  form: FormGroup;
  countries: any;
  // public jsonFormData: Object = {
  //   components: []
  // };
  // initialFormValues: any = null;
  // formioOptions: FormioOptions = {
  //   disableAlerts: true
  // }
  roles: any;
  bloodGroups: any;
  genders: any;
  isDuplicateParent : boolean=false;
  //skills:any;
  @ViewChild('phone') phone: any;
  @ViewChild('parentphone') parentphone: any;
  studentForm: any;
  getFieldAndGroupDto: any;
  fieldTypeDto: any;
  enrolmentGrp: any;
  contactNumberCountryCode: any;
  studentID: number = 0;
  previousAcademicVal: any = [];
  previousClassVal: any = [];
  previousCountryVal: any = [];
  previousStateVal: any = [];
  previousSectionVal: any = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private masterService: MasterService,
    private sectionService: SectionService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
  ) {

    this.GetCountiresList();
  }
  extraformField: boolean
  ngOnInit(): void {

    this.extraformField = false;
    this.randomNumber = 'PI' + (Math.floor(100000 + Math.random() * 900000));
    console.log('there is', this.randomNumber);
    this.extraformField = false;
    //this.adminUserID = this.activatedRoute.snapshot.params.Id 
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? 0 : params.id;
    });


    this.form = this.formBuilder.group({
      studentID: this.studentID,
      admissionNumber: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      genderID: ['', Validators.required],
      bloodGroupID: [''],
      dateOfBirth: ['', Validators.required],
      address: [''],
      zipCode: [''],
      countryID: [0],
      stateID: [0],
      city: [''],
      motherName: ['', Validators.required],
      fatherName: ['', Validators.required],
      guardianName: [''],
      parentContact: ['', Validators.required],
      parentEmail: ['', Validators.required],
      guardianContact: [''],
      guardianEmail: ['', [Validators.email]],
      profilePhoto: [''],
      studentSkillID: [''],
      profileSummary: [''],
      facebookLink: [''],
      linkedInlink: [''],
      academicSessionID: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      departmentID: [1],
      parentsNumber: [''],
      aspNetUserID: [1],
      hasExcess: [true],
      password: ['123456'],
      userName: ['alia10'],
      roleID: [1],
      parentID: [4, Validators.required],
      block: [false],
      alumni: [false],
      studentExtraInfoInJson: ['']
    });
    // this.masterService.GetCustomFormTemplate(1).subscribe((res:any)=>{
    //   if(res.responseData.formInJson != ""){
    //     this.extraformField = true
    //   console.log("FORM",res.responseData)
    //   this.jsonFormData = JSON.parse(res.responseData.formInJson || '');
    //   }
    // })
    if (this.studentID > 0)
      this.GetStudentByID();
    else
      this.GetStudentForm();
    this.cdr.detectChanges();

    this.GetBloodGroupList();
    this.GetGenderList();
    this.GetClassList();
    this.GetSectionList();
    this.GetAcademicSessionList();
    // this.GetStudentSkillsList();

  }


  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'class') {
      if (serachTxt != '') {
        this.classes = this.previousClassVal.filter((x: any) => x.className.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.classes = this.previousClassVal;
      }
    } else if (type == 'academic') {
      if (serachTxt != '') {
        this.academicSessions = this.previousAcademicVal.filter((x: any) => x.academicSessionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.academicSessions = this.previousAcademicVal;
      }
    } else if (type == 'section') {
      if (serachTxt != '') {
        this.sections = this.previousSectionVal.filter((x: any) => x.sectionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.sections = this.previousSectionVal;
      }
    } else if (type == 'state') {
      if (serachTxt != '') {
        this.states = this.previousStateVal.filter((x: any) => x.stateName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.states = this.previousStateVal;
      }
    } else if (type == 'country') {
      if (serachTxt != '') {
        this.countries = this.previousCountryVal.filter((x: any) => x.countryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.countries = this.previousCountryVal;
      }
    }
  }


  ddlChange(value: any, grp: any, fieldName: any) {
    debugger
    this.form.valid;
    this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue = value;
  }
  chkChange(value: any, grp: any, chk: any, fieldName: any) {
    let val = this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue;
    if (val == undefined || val == null || val == '') {
      val = [];
    }
    else {
      val = this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue.split(',');
    }
    if (value.checked) {
      val.push(chk);
      this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue = val.join(',');
    }
    else {
      val.pop(chk);
      this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue = val.join(',');
    }
  }

  onBlurPhone(value: any, grp: any, fieldName: any) {
    if (this.phone !== undefined) {
      if (this.phone.numberInstance !== undefined) {
        this.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
        this.getFieldAndGroupDto
          .find((g: any) => g.groupID == grp.groupID)
          .fields.find(
            (f: any) => f.fieldName == fieldName
          ).fieldValue = this.phone.numberInstance.nationalNumber;
      }
    }
  }
  onBlurParentPhone(value: any, grp: any, fieldName: any) {
    if (this.parentphone !== undefined) {
      if (this.parentphone.numberInstance !== undefined) {
        //this.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
        this.getFieldAndGroupDto
          .find((g: any) => g.groupID == grp.groupID)
          .fields.find(
            (f: any) => f.fieldName == fieldName
          ).fieldValue = this.parentphone.numberInstance.number;
      }
    }
  }
  onBlurMethodtxt(value: any, grp: any, fieldName: any) {
    let dd = value.target.value;
    if (grp.fields.find((f: any) => f.fieldName == fieldName).fieldTypeName == "Date" || fieldName == "Date of Birth") {
      dd  = this.datePipe.transform(value.target.value, 'yyyy-MM-dd');
    }
    this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue = dd;
  }
  splitValues(values: any) {
    if (values !== undefined && values !== null && values !== '')
      return values.split(',');
    else
      return "";
  }
  genderID: any;
  setchkValue(fieldValue: any, chk: any) {
    if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
      let vals = fieldValue.split(',');

      return vals.includes(chk);

    }
    return false
  }
  GetStudentByID() {
    this.studentService.GetStudentByID(this.studentID).subscribe((res: any) => {
      let studentdetail = res.responseData.coreStudentDetails;
      this.getFieldAndGroupDto = studentdetail.groups;
      //this.getFieldAndGroupDto=this.getFieldAndGroupDto.filter((g: any) => g.groupName !== 'ENROLLMENT');
      let profilePhoto: any, admissionNumber: any, lastName: any,
        firstName: any, email: any, dateOfBirth: any, bloodGroupID: any, genderID: any,
        motherName: any, fatherName: any, studentSkillID: any, profileSummary: any,
        facebookLink: any, linkedInlink: any, address: any, city: any, contactNumber: any, countryID: any, stateID: any, zipCode: any,
        academicSessionID: any, classID: any, sectionID: any, parentContact: any, parentEmail: any, parentID: any, parentsNumber: any;
      profilePhoto = studentdetail.profilePhoto;
      if (studentdetail.profilePhoto !== undefined && studentdetail.profilePhoto !== null && studentdetail.profilePhoto !== '') {
        this.croppedImage = studentdetail.profilePhoto;
        console.log("image", this.croppedImage);

      }
      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {

          if (f.isDynamicField == true) {
            if (f.fieldTypeName !== 'Checkbox') {
              if (f.email == true && f.isRequried == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required, Validators.email]));
              else if (f.isRequried == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required]));
              else if (f.email == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.email]));
              else
                this.form.addControl(f.fieldName, this.formBuilder.control(''));


              this.form.controls[f.fieldName].setValue(f.fieldValue)
            }
          }

          if (f.fieldName == 'Student Email') {
            email = f.fieldValue;
          }
          else if (f.fieldName == 'Date of Birth') {
            dateOfBirth = f.fieldValue;
          }
          else if (f.fieldName == 'Blood Group') {
            bloodGroupID = f.fieldValue;
            if (f.isRequried == true) {
              this.form.controls["bloodGroupID"].setValidators(Validators.required);;
            }
          }
          else if (f.fieldName == 'Gender') {
            genderID = f.fieldValue;
            //this.genderID=f.fieldValue;
          }
          else if (f.fieldName == 'Academic Sessions') {
            academicSessionID = f.fieldValue;
          }
          else if (f.fieldName == 'Class') {
            classID = f.fieldValue;
          }
          else if (f.fieldName == 'Section') {
            sectionID = f.fieldValue;
          }
          else if (f.fieldName == 'Contact') {
            if (f.fieldValue !== null && f.fieldValue !== '' && f.fieldValue !== undefined)
              contactNumber = studentdetail.contactNumberCountryCode + f.fieldValue;
            else
              contactNumber = "";
            //  this.phone.numberInstance.nationalNumber=contactNumber;
            //this.phone.numberInstance.countryCallingCode= studentdetail.contactNumberCountryCode;
          }
          else if (f.fieldName == 'First Name') {
            firstName = f.fieldValue;
          }
          else if (f.fieldName == 'Last Name') {
            lastName = f.fieldValue;
          }
          else if (f.fieldName == 'Admission Number') {
            admissionNumber = f.fieldValue;
          }
          else if (f.fieldName == 'Profile Summary') {
            profileSummary = f.fieldValue;
          }
          else if (f.fieldName == 'Skills') {
            studentSkillID = f.fieldValue;
          }
          else if (f.fieldName == 'Facebook Profile Link') {
            facebookLink = f.fieldValue;
          }
          else if (f.fieldName == 'LinkedIn Profile Link') {
            linkedInlink = f.fieldValue;
          }
          else if (f.fieldName == 'Address') {
            address = f.fieldValue;
          }
          else if (f.fieldName == 'Country') {
            countryID = f.fieldValue;

            if (countryID !== undefined && countryID !== null && countryID !== '') {
              this.form.patchValue({
                countryID: countryID > 0 ? Number(countryID) : ''
              })

            }
          }
          else if (f.fieldName == 'State') {
            stateID = f.fieldValue;

          }
          else if (f.fieldName == 'City') {
            city = f.fieldValue;
          }
          else if (f.fieldName == 'Zip Code') {
            zipCode = f.fieldValue;
          }
          else if (f.fieldName == 'Parents ID') {
            parentsNumber = f.fieldValue;
          }
          else if (f.fieldName == 'FathersName') {
            fatherName = f.fieldValue;
          }
          else if (f.fieldName == 'MothersName') {
            motherName = f.fieldValue;
          }
          else if (f.fieldName == 'Parents Contact') {
            parentContact = f.fieldValue;
            console.log("PARENTCONT", parentContact)
          }
          else if (f.fieldName == 'Parents Email') {
            parentEmail = f.fieldValue;
          }
        });

      });
      this.GetStateList(countryID, stateID, true);
      this.form.patchValue({
        studentID: this.studentID,
        admissionNumber: admissionNumber,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        genderID: genderID > 0 ? Number(genderID) : '',
        email: email,
        motherName: motherName,
        fatherName: fatherName,
        studentSkillID: studentSkillID,
        profileSummary: profileSummary,
        facebookLink: facebookLink,
        linkedInlink: linkedInlink,
        bloodGroupID: bloodGroupID > 0 ? Number(bloodGroupID) : '',
        address: address,
        city: city,
        contactNumber: contactNumber,
        countryID: countryID > 0 ? Number(countryID) : '',
        stateID: stateID > 0 ? Number(stateID) : '',
        zipCode: zipCode,
        academicSessionID: academicSessionID > 0 ? Number(academicSessionID) : '',
        classID: classID > 0 ? Number(classID) : '',
        sectionID: sectionID > 0 ? Number(sectionID) : '',
        parentContact: parentContact,
        parentEmail: parentEmail,
        parentsNumber: parentsNumber,
        profilePhoto: profilePhoto
      });
      // this.getFieldAndGroupDto=this.getFieldAndGroupDto.filter((g: any) => g.groupName !== 'ENROLLMENT');

      //this.phone.numberInstance.nationalNumber='34567893';
      //this.phone.numberInstance.countryCallingCode= '+971';
      //this.form.controls['genderID'].setValue(1);
    });
  }
  GetStudentForm() {
    this.studentService.GetCustomFormById(1).subscribe((res: any) => {

      this.fieldTypeDto = res.responseData.fieldTypeDto
      //this.enrolmentGrp= res.responseData.getFieldAndGroupDto.find((g: any) => g.groupName == 'ENROLLMENT');
      this.getFieldAndGroupDto = res.responseData.getFieldAndGroupDto;//.filter((g: any) => g.groupName !== 'ENROLLMENT');;

      this.getFieldAndGroupDto.forEach((grp: any) => {
        //if(grp.groupName!=='ENROLLMENT' && grp.groupName!=='ADDITIONAL DETAILS' && grp.groupName!=='SKILLS & SOCIAL DETAILS' && grp.groupName!=='ACADEMIC DETAILS' && grp.groupName!=='PERSONAL INFORMATION')
        // {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if (f.isDynamicField == true) {
            if (f.fieldTypeName !== 'Checkbox') {
              if (f.email == true && f.isRequried == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required, Validators.email]));
              else if (f.isRequried == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required]));
              else if (f.email == true)
                this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.email]));
              else
                this.form.addControl(f.fieldName, this.formBuilder.control(''));
            }
          }
          if (f.fieldName == 'Blood Group') {
            if (f.isRequried == true) {
              this.form.controls["bloodGroupID"].setValidators(Validators.required);;
            }
          }
        });
        // }
      });
      //"ENROLLMENT"
      //getFieldAndGroupDto
      //fieldTypeDto
    })
  }
  country: any = [];
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      this.country = [...this.countries];
      this.previousCountryVal = Object.assign([], this.countries);
    })
  }
  states: any;
  stateValue: any = [];
  GetStateList(countryId: any, stateID?: any, defaults?: boolean) {
    this.form.controls['stateID'].setValue('');
    countryId = this.form.controls['countryID'].value;
    this.masterService.GetStateDropdown(countryId).subscribe((res: any) => {
      this.states = res.responseData;
      this.stateValue = [...this.states];
      this.previousStateVal = Object.assign([], this.states);
      console.log(res);
      if (defaults) {
        this.form.patchValue({
          stateID: stateID > 0 ? Number(stateID) : ''

        })
      }
    })
  }


  GetBloodGroupList() {
    this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }
  countryName: any = [];
  dispalyFns(countryId: any) {
    this.countryName = this.country.filter((a: any) => a.countryID == countryId);
    return this.countryName[0]?.countryName
  }

  academicDisplayWith: any = [];
  dispalyAcademicSession(academicSessionId: any) {
    this.academicDisplayWith = this.academicsesionlist.filter((a: any) => a.academicSessionID == academicSessionId);
    return this.academicDisplayWith[0]?.academicSessionName;
  }

  displayClass(classId: any) {
    return this.class.filter((a: any) => a.classID == classId)[0]?.className;
  }

  displaySectionFns(sectionId: any) {
    return this.sectionList.filter((a: any) => a.sectionID == sectionId)[0]?.sectionName;
  }



  GetGenderList() {
    this.masterService.GetGenderDropdown().subscribe((res: any) => {
      this.genders = res.responseData;
    })
  }
  classes: any
  class: any[];
  GetClassList() {
    this.masterService.GetClassDropdown().subscribe((res: any) => {
      this.classes = res.responseData;
      this.class = [...this.classes];
      this.previousClassVal = Object.assign([], this.classes);
    })
  }
  sections: any
  GetSectionList() {
    // this.masterService.GetSectionDropdown().subscribe((res: any) => {
    //   this.sections = res.responseData;
    // })
  }
  academicSessions: any
  academicsesionlist: any = [];
  GetAcademicSessionList() {
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
      this.academicsesionlist = [...this.academicSessions];
      this.previousAcademicVal = Object.assign([], this.academicSessions);
      console.log("sessions", this.academicSessions);
    })
  }

  // GetStudentSkillsList() {
  //   this.masterService.GetStudentSkillsDropdown().subscribe((res: any) => {
  //     this.skills = res.responseData;

  //   })
  // }


  profileImage: string = '';
  AddProfile(file: any) {
    this.studentService.postData(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log("imgefront", this.profileImage);
      }
    })
  }
  formData: any;
  onChange(event: any) {
    if (event.data != undefined || event.data != null) {
      this.formData = {}
      this.formData = {
        data: event.data
      }
      this.form.value.studentExtraInfoInJson = JSON.stringify(this.formData)
      console.log("EVENT", this.form.value.studentExtraInfoInJson)
    }
  }

  stateName: any = [];
  dispalyStateAuto(stateID: any) {
    this.stateName = this.stateValue.filter((a: any) => a.stateID == stateID)
    return this.stateName[0]?.stateName;
  }

  onSubmit() {
    let chkerror = "";
    this.getFieldAndGroupDto.forEach((grp: any) => {
      grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
      grp.fields.forEach((f: any) => {
        if (f.fieldTypeName == 'Checkbox' && f.isRequried == true) {
          if (f.fieldValue == undefined || f.fieldValue == null || f.fieldValue == '') {
            chkerror = chkerror + " " + f.fieldName + " field is required";
          }
        }
      })
    });
    if (chkerror !== "") {
      this.toaster.warning(chkerror);
      return;
    }
    if (this.form.invalid) {
      return;
    }
    if (this.studentID > 0) {
      let data = {
        StudentModel: {
          StudentID: this.studentID,
          ContactNumberCountryCode: '+' + (this.phone.numberInstance.countryCallingCode),
          Groups: this.getFieldAndGroupDto
        }
      }
      this.studentService.UpdateCoreStudent(data).subscribe((res: any) => {
        console.log(res);
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');

          if (this.imageFile) {
            this.studentService.UpdateStudentProfilePhoto(this.studentID, this.imageFile).subscribe((res: any) => {
              this.router.navigate(['/admin/academicslearning/manage-students']);
            }, err => this.router.navigate(['/admin/academicslearning/manage-students']));
          } else {
            this.router.navigate(['/admin/academicslearning/manage-students']);
          }

        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
    else {
      let data = {
        StudentModel: {
          StudentID: this.studentID,
          ContactNumberCountryCode: '+' + (this.phone.numberInstance.countryCallingCode),
          Groups: this.getFieldAndGroupDto,
          IsDuplicateParent : this.isDuplicateParent
        }
      }
      //date of birth convert into a same formate to get student's bday in dashboard
      data.StudentModel.Groups[2].fields[1].fieldValue = (moment(data.StudentModel.Groups[2].fields[1].fieldValue).format('YYYY-MM-DD[T]00:00:00.000') + "Z");
      this.studentService.AddStudent(data).subscribe((res: any) => {
        console.log(res);
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');

          if (this.imageFile) {
            this.studentService.UpdateStudentProfilePhoto(res.responseData, this.imageFile).subscribe((res: any) => {
              this.router.navigate(['/admin/academicslearning/manage-students']);
            }, err => this.router.navigate(['/admin/academicslearning/manage-students']));
          } else {
            this.router.navigate(['/admin/academicslearning/manage-students']);
          }

        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
    return;
    console.log("formdata", this.form.value);

    if (this.profileImage != '') {
      this.form.controls.profilePhoto.setValue(this.profileImage);
    }


    console.log("PHONENO", this.phone)
    this.form.controls.dateOfBirth.setValue(moment(this.form.value.dateOfBirth).format('YYYY-MM-DD'));
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);

    this.studentService.AddStudent(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');

        if (this.imageFile) {
          this.studentService.UpdateStudentProfilePhoto(res.responseData, this.imageFile).subscribe((res: any) => {
            this.router.navigate(['/admin/academicslearning/manage-students']);
          }, err => this.router.navigate(['/admin/academicslearning/manage-students']));
        } else {
          this.router.navigate(['/admin/academicslearning/manage-students']);
        }
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  sectionList: any = [];
  filterSectionListByClassId(id: any, grp: any, fieldName: any) {
    this.form.controls['sectionID'].setValue('');
    // let classId=this.form.controls['classID'].value;
    //let ids=id?.option?.value;
    this.ddlChange(id, grp, fieldName);
    console.log("filterSectionListByClassId", id)
    this.sections = [];
    this.sectionService.GetSectionByClassId(id).subscribe((res: any) => {
      this.sections = res.responseData;
      this.sectionList = [...this.sections];
      this.previousSectionVal = Object.assign([], this.sections);
    })
  }
  imageUrl: any;
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    this.AddProfile(formData);
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);
    //   // When file uploads set it to file formcontrol
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //     // this.registrationForm.patchValue({
    //     //   file: reader.result
    //     // });
    //     // this.editFile = false;
    //     // this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   //this.cd.markForCheck();        
    // }

  }

  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/display.jpg';
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    this.imageFile = this.imageChangedEvent.target.files[0];
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  allowAlphaNumber(event: any) {
    this.commonService.allowAlphaNumber(event)
  }

  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
  randomNumber: any;

  CheckParent(event: any) {
    this.studentService.getParentsDetails(event).subscribe((res: any) => {
      if (res.status > 0) {
        this.dialogService
        .confirm(`A parent is already associated with another student. Would you like to proceed?`)
        .subscribe((result: any) => {
          this.isDuplicateParent=  result;
        });
        this.form.patchValue({
          motherName: res.responseData['motherName'],
          fatherName: res.responseData['fatherName'],
          parentContact: res.responseData['parentContact'],
          parentEmail: res.responseData['parentEmail']
        });
      }
      else {
        this.form.patchValue({
          motherName: '',
          fatherName: '',
          parentContact: '',
          parentEmail: ''
        });
        this.parentphone.reset();
      }
    });
  }

  onBlurMethod() {
    this.CheckParent(this.form.controls.parentsNumber.value)
  }
  duplicateParent(IsduplicateParent:boolean){
    this.isDuplicateParent = IsduplicateParent;
  }
}

//   val:any;
//  val = Math.floor(1000 + Math.random() * 9000);
// console.log(val);

