<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage Teacher</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngIf="!isTeacherUser"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item active" aria-current="page">Manage Teacher</li>
        </ol>
      </div>
    </div>
    <div class="" *ngIf="!isTeacherUser">
      <div class="add_button">
        <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers/add-teachers"> <i
            class="fas fa-plus-circle"></i> Add New Teacher</button>
      </div>
    </div>
  </div>



  <!--/Page Title-->

  <div class="cardWidget">
    <!-- <div class="cardTitle centerVTItems justify-content-between">
      <h3>Teachers</h3>
      <button class="btn btn-primary btnsmall" routerLink="../manage-teachers/add-teachers"><i class="fas fa-plus"></i>
        Add New</button>
    </div> -->

    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <div class="tableActionTop">
          <div class="tableFilter">
            <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="dynamicFilterform" (ngSubmit)="onSubmit()">
                <div class="row">
                  <ng-container *ngFor="let field of dynamicFilterFields">
                    <div *ngIf="field.fieldTypeName!='Dropdown'" class="col-lg-4 col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{field.fieldName}}</mat-label>
                        <input matInput type="text" formControlName="{{field.fieldName}}">
                      </mat-form-field>
                    </div>
                    <div *ngIf="field.fieldName=='Employee Type'" class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Employee Type</mat-label>
                        <mat-select placeholder="Employee Type" name="employeeTypeID"
                          formControlName="{{field.fieldName}}">
                          <mat-option value="" disabled>Select Employee Type</mat-option>
                          <mat-option *ngFor="let employee of employeeTypes " [value]="employee.employeeTypeID">
                            {{employee.employeeTypeName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="field.fieldName=='Blood Group'" class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Blood Group</mat-label>
                        <mat-select placeholder="Blood Group" name="bloodGroup" formControlName="{{field.fieldName}}">
                          <mat-option value="" disabled>Select Blood Group
                          </mat-option>
                          <mat-option *ngFor="let bloodGroup of bloodGroups" [value]="bloodGroup.bloodGroupID">
                            {{bloodGroup.bloodGroupName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
                <div class="btnsWidget form-group text-right">
                  <button type="submit" class="btn btn-primary btn-sm" (click)="dynamicFilter()">Search</button>
                  <button type="button" (click)="resetFilter()" class="btn btn-reset btn-sm">Reset</button>

                </div>
              </form>
            </div>
          </div>
          <div *ngIf="!isTeacherUser" class="rightTableActions centerItemvert">
            <div class="iconsset d-flex align-items-center">
              <div class="viewColumns tableActions" matTooltip="Filter">
                <i class="fas fa-filter cursor-pointer" (click)="toggleField()"></i>
              </div>
              <div class="viewColumns tableActions" matTooltip="Hide/Show Columns">
                <a (click)="actionDialogPopup(hideShowColPopup,$event)"><i title="Hide/Show Column"
                    class="fas fa-columns"></i></a>
              </div>
              <!-- <div class="downloadTable tableActions" matTooltip="Upload Data">
                <a (click)="importDialogPopup(importPopup)"><i title="Import Teachers" class="fas fa-upload"></i></a>
              </div> -->



              <div class="downloadTable tableActions" matTooltip="Download Data">
                <a (click)="actionDialogPopup1(exportPopup)"><i title="Export Teachers" class="fas fa-download"></i></a>
              </div>
            </div>
            <div class="selectedColTable tableActions">
              <button mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType" [disabled]="!printFlag" matRipple
                matTooltip="You need to select atleast one record"><span
                  class="leftItem conterColumns">{{selectedData}}</span> <span class="">Selected</span> <i
                  class="fa fa-caret-down rightItem"></i></button>
              <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                <h4 class="actionSelected">Subjects</h4>
                <button mat-menu-item (click)="actionDialogPopup(subjectAllocation,$event)"><i
                    class="fas fa-vote-yea"></i> Subjects Allocation</button>
                <h4 class="actionSelected">Communications</h4>
                <button mat-menu-item (click)="teacherEmailSent(teacherEmail)"><i class="fas fa-envelope-open-text"></i>
                  Send Email</button>
                <button mat-menu-item (click)="teacherSmsSent(teacherSms)"><i class="fas fa-comment-alt"></i> Send
                  SMS</button>
                <button mat-menu-item (click)="teacherEmailSentInvite()"><i class="fas fa-exchange-alt"></i> Invite to
                  Login (Email)</button>
                <button mat-menu-item (click)="teacherSmsSentInvite()"><i class="fas fa-exchange-alt"></i> Invite to
                  Login (SMS)</button>



                <h4 class="actionSelected">Other Actions</h4>
                <button mat-menu-item (click)="DeleteMultipleStudent()"><i class="fas fa-trash"></i> Delete</button>

              </mat-menu>
            </div>
          </div>

        </div>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop">
          <div class="tableFilter">
            <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
            <!-- <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>First Name</mat-label>
                      <input matInput type="text" formControlName="firstName">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Last Name</mat-label>
                      <input matInput type="text" formControlName="lastName">
                    </mat-form-field>
                  </div>


                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Admission Number</mat-label>
                      <input matInput type="text" formControlName="admissionNumber">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput type="text" formControlName="contactNumber">
                    </mat-form-field>
                  </div>

                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>

                </div>
              </form>
            </div> -->
          </div>
          <div class="rightTableActions centerItemVert">


          </div>

        </div>
        <div class="mat-elevation-z8" *ngIf="checkActionPermission('Teacher List')">
          <!-- <mat-spinner *ngIf="spinnerFlag==true"></mat-spinner> -->
          <table mat-table class="w-100" [dataSource]="dataSource" matSort matSortDisableClear matSortDirection="asc"
            (matSortChange)="SortChange($event)">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  (change)="changee($event,row)" [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="columnName" *ngFor="let columnName of displayedColumnsList">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ columnName }}</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="columnName != 'profilePhoto' && columnName != 'First Name' && columnName != 'Last Name'"
                  [innerHTML]="row[columnName]"></span>
                <span *ngIf="columnName == 'profilePhoto'"><img [src]="row[columnName]" alt=""
                    onerror="this.src='../../../../../assets/img/avtaradmin.jpg';"></span>
                <span *ngIf="columnName == 'Last Name'"><a
                    (click)="infoCall(row['teacherID'])">{{row[columnName]}}</a></span>
                <span *ngIf="columnName == 'First Name'"><a
                    (click)="infoCall(row['teacherID'])">{{row[columnName]}}</a></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <Button *ngIf="checkActionPermission('View Teacher')" title="Details"
                  (click)="infoCall(element.teacherID)" class="tbactionbtn"><i class="fas fa-eye"></i></Button>
                <Button title="Attachment" *ngIf="checkActionPermission('Add Teacher Attachment') && !isTeacherUser"
                  (click)="singleattachment(element.teacherID)" class="tbactionbtn"><i
                    class="fas fa-paperclip"></i></Button>
                <Button title="Edit" *ngIf="checkActionPermission('Update Teacher') && !isTeacherUser"
                  (click)="editCall(element.teacherID)" class="tbactionbtn"><i class="fas fa-edit"></i></Button>
                <Button *ngIf="checkActionPermission('Reset Teacher Password')" title="Reset"
                  (click)="resetCall(element.teacherID)" class="tbactionbtn"><i class="fas fa-lock"></i></Button>
                <!-- <Button title="Reset" class="tbactionbtn"><i class="fas fa-lock"></i></Button> -->
                <Button title="Delete" *ngIf="checkActionPermission('Delete Teacher') && !isTeacherUser"
                  (click)="DeleteTeacher(element.teacherID)" class="tbactionbtn"><i class="fas fa-trash"></i></Button>
              </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="changeColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: changeColumnsToDisplay"></tr>
          </table>
          <div *ngIf="metaData.totalcount==0">
            <p>No Record Found</p>
          </div>
        </div>
        <mat-paginator [length]="metaData['totalRecords']" [pageSizeOptions]="itemperPage"
          [pageIndex]="metaData['currentPage'] - 1" [pageSize]="metaData['pageSize']" (page)="handlePage($event)"
          showFirstLastButtons></mat-paginator>
      </div>

      <!-- <app-checkboxdata-table [inputColumns]="displayedColumns" [inputSource]="usersData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)" (onselect)="onselect($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-checkboxdata-table> -->
    </div>
  </div>
  <!-- </div> -->

  <!-- <ng-template #loginPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Reset Password</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
              <input matInput value="Sharma">
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17 center">
            <button type="button" class="btn btn-primary" mat-dialog-close>Send</button>
          </div>
        </form>

      </mat-dialog-content>
    </div>
  </ng-template> -->

  <!-- //////// -->

  <ng-template #exportPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Export Teacher Data</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllExport()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAllExport()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex">
          <div *ngFor="let field of exportField" class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [(ngModel)]="field.checkbox">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
        <div class="form-group17 text-right">
          <button (click)="export()" class="btn btn-primary btn-sm" mat-dialog-close>Export</button>
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>





  <!-- ///// -->

  <ng-template #hideShowColPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Hide/Show Colums</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllField()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAllField()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="col s12 m12 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" [(ngModel)]="checked">
              Profile Picture
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group" *ngFor="let field of hideshowGridColumnList" cdkDrag>
            <mat-checkbox class="example-margin" [(ngModel)]="field.isShowInGrid" (change)="onCheck()">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>

  <ng-template #deletePopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title> Do you want to delete ?</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
        <div class="btnsWidget form-group center">
          <!-- <button (click)=confirmDelete() class="btn btn-primary btn-sm">Yes</button> -->
          <button mat-dialog-close class="btn btn-reset btn-sm">NO</button>

        </div>
      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #teacherEmail>
    <div class="">
      <i class="material-icons popupClose" (click)="resetEmailD()" mat-dialog-close>clear</i>
      <div class="dialogTitle  loginTtile">
        <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Send Teacher Email</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title>Send Email To Teacher</h2>
        </ng-template>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="teacherEmailForm" (ngSubmit)="onSubmitEmail()">
          <div>
          </div>
          <div class="row-flex">
            <div class="col-12 form-group">
              <mat-label>Send As</mat-label>
              <br>
              <br>
              <!-- <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
              info@creocms.com.au
            </mat-checkbox> -->
              <mat-radio-group aria-label="Select an option" formControlName="senderEmail" required>
                <mat-radio-button value=true [checked]="true" (change)="instituteMail()">info@creocms.com.au(Institute's
                  email)</mat-radio-button>
                <mat-radio-button value=false (change)="userMail()">{{userEmail}}(Your Email)</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col s12 m12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Subject </mat-label>
                <input required maxlength="100" formControlName="subject" matInput placeholder="subject">
                <mat-error
                  *ngIf="teacherEmailForm.get('subject')?.touched  && teacherEmailForm.get('subject')?.errors?.required">
                  Subject is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col s12 m12 form-group">
              <mat-label>Receivers</mat-label><br>
              <!-- <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
              Teachers Email
            </mat-checkbox> -->
              <!-- <mat-form-field class="w-100" appearance="outline">
              <textarea  matInput  matInput readonly  *ngFor="let teach of teacherData.email?.split(',')"
              placeholder="Enter custom email addresses in comma separated format">
                {{teach}}
              </textarea>
            </mat-form-field> -->
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box" cdkDrag *ngFor="let teacher of teacherData">
                  {{teacher.email}}
                </mat-chip>
              </mat-chip-list>

            </div>
            <div class="col-12 form-group">
              <mat-checkbox class="ml-0" [checked]='false' class="example-margin" (change)="oncustomChangeEmail()"
                formControlName="customCheck">
                Custom Email
              </mat-checkbox>
              <ng-container>
                <br>
                <div class="form-group">
                  <mat-form-field class="w-100" appearance="outline">
                    <!-- <textarea class="textareaForEmail" matInput  [readonly]="!customboxhideEmail" formControlName="customEmail" placeholder="Enter custom email addresses in comma separated format"></textarea> -->
                    <textarea class="textareaForSMS" matInput maxlength="100" matInput [readonly]="!customboxhideEmail"
                      formControlName="customEmail"
                      placeholder="Enter custom email addresses in comma separated format"></textarea>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <!-- <div class="col s12 m12 form-group"> -->
            <div class="px-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select Template</mat-label>
                <mat-select formControlName="template" (selectionChange)="selectChangeTemplate($event)" name="template">
                  <mat-option *ngFor="let temp of customtemplate" [value]="temp.emailTemplateID">
                    {{temp.templateName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- </div> -->
            <div class="col s12 m12 form-group">
              <ejs-richtexteditor #textEditorValue required formControlName="body" [maxLength]='256'
                [toolbarSettings]='tools'>
              </ejs-richtexteditor>
            </div>
            <div class="form-group col-12 px-4">
              <input formControlName="attachment" (change)="uploadFile($event)" id="file" type="file" multiple>
            </div>
          </div>

          <div class="btnsWidget form-group17 text-right">
            <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close [disabled]="teacherEmailForm.invalid"
              matRipple>Send</button>
            <button typetype="reset" (click)="resetEmailD()" class="btn btn-reset btn-sm" mat-dialog-close
              matRipple>Close</button>
          </div>

          <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>



  <ng-template #teacherSms>
    <div>
      <i class="material-icons popupClose" (click)="resetSMSForm()" mat-dialog-close>clear</i>
      <div class="dialogTitle ">
        <h2 mat-dialog-title>Send SMS To Teacher</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="teacherSmsForm" (ngSubmit)="onSubmitSms()">
          <div>


          </div>
          <div class="row-flex">
            <div class="col s12 m12 form-group">
              <mat-label>Receivers</mat-label><br>
              <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
                Teachers Contact Number
              </mat-checkbox>
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box" cdkDrag *ngFor="let teacher of teacherData">
                  {{teacher.contactNumber}}
                </mat-chip>
              </mat-chip-list>
            </div>


            <!-- <div class="col s12 m12 form-group">
            <mat-checkbox [checked]='false' class="example-margin" (change)="onparentSmsChange()"
              formControlName="parentCheckSms">
              Parents Contact Number
            </mat-checkbox>
            <ng-container *ngIf="!boxhidesms">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Parent Contact Number </mat-label>
                <input maxlength="14" formControlName="parentContactNo" matInput placeholder="Parent Contact">

              </mat-form-field>
            </ng-container>

          </div> -->
            <div class="col-12 form-group">
              <mat-checkbox [checked]='false' class="example-margin" (change)="oncustomChangeSMS()"
                formControlName="customCheck">
                Custom Numbers
              </mat-checkbox>
              <ng-container>
                <br>
                <div class="col s12 m12 form-group mb-0 ">
                  <mat-form-field class="w-100" appearance="outline">
                    <textarea class="textareaForSMS" matInput [readonly]="!customboxhideSMS" formControlName="customSMS"
                      placeholder="Enter Custom Numbers in comma separated format"></textarea>
                  </mat-form-field>
                </div>
              </ng-container>

            </div>


            <div class="col s12 m12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Message</mat-label>
                <textarea required maxlength="256" formControlName="message" matInput placeholder="Message"></textarea>
                <mat-error
                  *ngIf="teacherSmsForm.get('message')?.touched  && teacherSmsForm.get('message')?.errors?.required">
                  Message is required
                </mat-error>
              </mat-form-field>
            </div>


          </div>

          <div class="btnsWidget form-group17 text text-right">
            <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close [disabled]="teacherSmsForm.invalid"
              matRipple>Send</button>
            <button typetype="reset" (click)="resetSMSForm()" class="btn btn-reset btn-sm" mat-dialog-close
              matRipple>Close</button>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #importPopup>
    <form>
      <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle">
          <h5 mat-dialog-title> Import Teachers</h5>
        </div>
        <mat-dialog-content class="mat-typography">
          <div class="btnsWidget form-group center">
            <div class="form-group">
              <p class="chngePicupload">
                <input type="file" accept=".csv">
                <img src="../../../../../../assets/img/upload_icon.svg" alt="">
              </p>
              <p class="note"><b>Note: </b>Please upload only CSV file</p>
            </div>
          </div>
          <!-- </div> -->
        </mat-dialog-content>
        <div class="form-group17 text-right mt-2">
          <button (click)="addFile()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Import</button>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #subjectAllocation>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle">
        <h2 mat-dialog-title>Subject Allocation</h2>
      </div>
      <form [formGroup]="subjectAllocationForm" (ngSubmit)="onSubmitSubjectAllocation()">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Academic Session</mat-label>
          <mat-select formControlName="academicSessionID">
            <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
              {{session.academicSessionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="subjectAllocationForm.get('academicSessionID')?.touched  && subjectAllocationForm.get('academicSessionID')?.errors?.required">
            academicSession is required
          </mat-error>
        </mat-form-field>

        <!-- <ng-multiselect-dropdown name="subject" [placeholder]="'Select Subject for Primary Teachers'"
          [settings]="dropdownSettings" [data]="SubjectListDropdown" formControlName="primarysubjectID"
          [disabled]="disabled" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown> -->
        <div class="form-group multiselected">
          <div class="w-100" appearance="outline">
            <p-multiSelect [options]="SubjectListDropdown" formControlName="primarysubjectID"
              defaultLabel="Select Subject for Primary Teachers" optionLabel="subjectClassSection" display="chip" #elm>
            </p-multiSelect>
          </div>
        </div>
        <!-- <ng-multiselect-dropdown name="subject" [placeholder]="'Select Subject for Secondary Teachers'"
          [settings]="dropdownSettings" [data]="SubjectListDropdown" formControlName="secondarysubjectID"
          [disabled]="disabled" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown> -->
        <div class="form-group multiselected">
          <div class="w-100" appearance="outline">
            <p-multiSelect [options]="SubjectListDropdown" formControlName="secondarysubjectID"
              defaultLabel="Select Subject for Secondary Teachers" optionLabel="subjectClassSection" display="chip"
              #elm>
            </p-multiSelect>
          </div>
        </div>
        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" [disabled]="subjectAllocationForm.invalid"
            matRipple>Save</button>
          <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close
            maRipple>Cancel</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #loginPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Edit Teachers Credentials</h2>
      </div>

      <mat-dialog-content class="mat-typography">
        <form [formGroup]="updateCreditialForm">
          <div class="">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-user"></i></span> -->
              <input [readonly]="true" matInput required name="UserName" formControlName="userName">
              <!-- <mat-error
                  *ngIf="updateCreditialForm.get('userName')?.touched && updateCreditialForm.get('UserName')?.errors?.required">
                  Username is required
                </mat-error> -->
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" required [type]="hide ? 'password' : 'text'"
                formControlName="password" type="password" name="password">
              <a mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error
                *ngIf="updateCreditialForm.get('password')?.touched && updateCreditialForm.get('password')?.errors?.required">
                Please enter your password
              </mat-error>
              <mat-error *ngIf="updateCreditialForm.get('password')?.errors?.pattern">
                Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number
                and one special characters<strong>(!@#$%_^&*~)</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput placeholder="Verify Password" required [type]="hide1 ? 'password' : 'text'"
                (keypress)="onMatch()" type="password" formControlName='confirmPassword'>
              <a mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error
                *ngIf="updateCreditialForm.get('confirmPassword')?.touched && updateCreditialForm.get('confirmPassword')?.errors?.required">
                Please enter your Confirm password
              </mat-error>

            </mat-form-field>
          </div>
          <div
            *ngIf="this.updateCreditialForm.errors?.match && !updateCreditialForm.get('confirmPassword')?.errors?.required"
            class="state"><span style="color: red">
              Password & Confirm Password are not matching</span></div>
          <div class="form-group17">
            <p>
              <mat-checkbox formControlName="logoutFromAll">Logout from all active login sessions in mob app
              </mat-checkbox>
            </p>
            <p>
              <mat-checkbox formControlName="sendUpdateNotification">Send update notification email to teachers
              </mat-checkbox>
            </p>
          </div>
          <div class="form-group17 text-right">
            <button [disabled]="updateCreditialForm.invalid" (click)="updateCreditial()"
              class="btn btn-primary btn-sm">Update</button>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>