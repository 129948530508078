import { Component, ComponentFactoryResolver, ComponentRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ClassService } from '../../courses-and-subjects/services/classServices/class.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { ClassApiResponse, Criteria, FormInputBase, GradeType, Rubic, RubricDefaults, SectionApiResponse, SubjectApiReponse } from 'src/app/common/MasterModel/masterModel';
import { ManageAssessmentAndGradingsService } from '../services/manage-assessment-and-gradings.service';
import { AcademicSessionService } from '../../courses-and-subjects/services/academicSessionServices/academic-session.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { ImportDialogComponent } from 'src/app/common/components/import-dialog/import-dialog.component';
import { DropdownInput, ModuleDefinition } from 'src/app/common/MasterModel/masterModel';
import { RubicScaleComponent } from '../grade-setup/grading-scales/rubic-scale/rubic-scale.component';
import { RubicDialogComponent } from 'src/app/common/components/rubic-grading-scale/rubic-grading-scale.component';
import { RubicGradingScaleService } from 'src/app/services/rubic-grading-scale.service';
import { T } from '@angular/cdk/keycodes';
import { MappingContainerComponent } from 'src/app/common/components/mapping-container/mapping-container.component';
import { MappingContainerStudentGradeComponent } from 'src/app/common/components/mapping-container-student-grade/mapping-container-student-grade.component';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MasterService } from 'src/app/services/master.service';
import { UserRole } from 'src/environments/environment';
export interface PeriodicElement {

  sno: string;
  firstname: string;
  lastname: string;
  enrollmentstatus: any;
  overall: string;
  Manuall: string;
  Actuall: string;
  demo: string;
  prerubic: string;
  presem: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    sno: '1', firstname: 'Amit', lastname: 'Sharma', enrollmentstatus: '', overall: '60', Manuall: '', Actuall: '', demo: '', prerubic: '',
    presem: ''
  },
];
const elemental: any[] = [];
@Component({
  selector: 'app-manage-grade',
  templateUrl: './manage-grade.component.html',
  styleUrls: ['./manage-grade.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ManageGradeComponent implements OnInit {
  SelectedClass: number;
  SelectedSection: number;
  SelectedSubject: number;
  SelectedStudent: number;
  SelectedAcademicSession: number;
  SelectedCategory: number;
  ClassDropdownData: ClassApiResponse[] = [];
  classList: any = [];
  SectionDropdownData: SectionApiResponse[] = [];
  SubjectDropdownData: SubjectApiReponse[] = [];
  GradeBookData: any = [];
  GradeBookColumnHeader: any = []
  formGroup: FormGroup;
  GradingSystem: any = GradeType;
  openedCommentsDialog: any;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  hideUploadTab = false;
  isFileSelected = false;
  selectedFile: File;
  selectedName: string;
  @ViewChild("fileInput") fileInput: any;
  dynamicInputControls: FormInputBase<string>[] = [];
  isUploadPressed: boolean = false;
  caller: string;
  private componentRef: ComponentRef<MappingContainerStudentGradeComponent>;
  @ViewChild('mappingContainer', { read: ViewContainerRef }) mappingContainer: ViewContainerRef;
  child_unique_key: number = 0;
  unique_key_child: number = 0;
  componentsReferences = Array<ComponentRef<MappingContainerStudentGradeComponent>>();
  fileExtension = ".xlsx";
  defaultPlaceHolder: {}[] = RubricDefaults.defaultSelection;
  uploadedFile: File;
  userID: any;
  AssetmentHeader: { Name: string, Type: string, Identifier: number }[] = [];
  defaultCriteria: Criteria = RubricDefaults.Criteria;
  RubicObject: Rubic = RubricDefaults.Rubic;
  dialogRef: any;
  distinctAssessmentCategories: any[] = [];
  enrollmentFilter: string[] = [];
  selectedCategory: any[] = [];
  selectedEnrollment: string[] = [];
  eventValue: any;
  masterGradeBookData: any[] = [];
  filterType: {
    category: 'Category',
    enrollment: 'Enrollment'
  }
  @ViewChild('richComments') rteObj: RichTextEditorComponent;
  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  displayedColumns: string[] = ['sno', 'firstname', 'lastname', 'enrollmentstatus', 'overall', 'Manuall', 'Actuall', 'demo', 'prerubic', 'presem'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  users: any;
  user: any;
  permissions: any;
  classToggled = false;
  dateFormat: any;
  timeFormat: any;
  dateTimeFormat: any;
  dataSourcelogs: any;
  toppings = new FormControl();
  previousClassVal: any = [];
  previousSectionVal: any = [];
  previousSubjectVal: any = [];

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  displayedColumnstwo: string[] = ['changes', 'date', 'details'];
  isTeacherUser: boolean = false;
  constructor(
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private dialog: MatDialog,
    private classService: ClassService,
    private subjectService: SubjectService,
    private sectionService: SectionService,
    private gradeBookService: ManageAssessmentAndGradingsService,
    private academicSessionService: AcademicSessionService,
    private toaster: ToastrService,
    private readonly formBuilder: FormBuilder,
    private rubicService: RubicGradingScaleService,
    private resolver: ComponentFactoryResolver,
    private masterService: MasterService

  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
      this.dateFormat = currentUser.dateFormat;
      this.timeFormat = currentUser.timeFormat;
      this.dateTimeFormat = this.dateFormat + ', ' + this.timeFormat
    }

    //this.dataSourcelogs = elemental;
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID')
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    this.getAcademicSession();
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Grade Book');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }

    this.classService.GetClassDropdown().subscribe((response: any) => {
      if (response.responseData && response.responseData.length > 0) {
        this.ClassDropdownData = response.responseData;
        this.classList = [...this.ClassDropdownData];
        this.previousClassVal = Object.assign([], this.ClassDropdownData);
      }
    });
  }
  dispalyFns(classId: any) {
    return this.classList.filter((a: any) => a.classID == classId)[0]?.className;
  }
  sectionReturn: any = [];
  dispalySectionAuto(sectionId: any) {
    this.sectionReturn = '';
    this.sectionReturn = this.sectionDropdown.filter((a: any) => a.sectionID == sectionId)[0]?.sectionName;
    return this.sectionReturn;
  }

  dispalySubjectAuto(subjectId: any) {
    return this.subjectData.filter((a: any) => a.subjectID == subjectId)[0]?.subjectName;
  }

  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'class') {
      if (serachTxt != '') {
        this.ClassDropdownData = this.previousClassVal.filter((x: any) => x.className.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.ClassDropdownData = this.previousClassVal;
      }
    } else if (type == 'section') {
      if (serachTxt != '') {
        this.SectionDropdownData = this.previousSectionVal.filter((x: any) => x.sectionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.SectionDropdownData = this.previousSectionVal;
      }
    } else if (type == 'subject') {
      if (serachTxt != '') {
        this.SubjectDropdownData = this.previousSubjectVal.filter((x: any) => x.subjectName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.SubjectDropdownData = this.previousSubjectVal;
      }
    }


  }

  getDropDownValuesAsFormData() {
    const formData: FormData = new FormData();
    formData.append('SubjectId', String(this.SelectedSubject));
    formData.append('ClassId', String(this.SelectedClass));
    formData.append('SectionId', String(this.SelectedSection));
    formData.append('AcademicSessionId', String(this.SelectedAcademicSession));
    formData.append('CategoryId', String(0));
    return formData;
  }


  exportGradeBook() {
    let result = this.validateAllSelections();
    if (!result) {
      this.toaster.error("Please select all options Class, Section and Subject.")
      return;
    }
    const formData = this.getDropDownValuesAsFormData();
    const fileName = 'StudentGradeBook'
    this.gradeBookService.exportGradeBook(formData).subscribe((data: any) => {
      let workBook: any = null;
      let parsedExcelRowArray = null;
      let rawExcel: File;
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        const blob = new File([new Blob([rawExcel], { type: this.fileType })], `${fileName}.xlsx`);
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.xlsx`;
        link.click();
      };

      reader.readAsBinaryString(data);
    })
  }

  sectionDropdown: any = [];
  selectedData = new FormControl();
  selectedData1 = new FormControl();
  classId: any;
  onSelectClassChange(selectedClass: any) {
    this.sectionReturn = [];
    this.selectedData.setValue(null);
    this.selectedData1.setValue(null);
    this.SelectedClass = selectedClass;
    this.sectionService.GetSectionByClassId(this.SelectedClass).subscribe((response: any) => {
      if (response.responseData && response.responseData.length > 0) {
        this.SectionDropdownData = response.responseData;
        this.sectionDropdown = [...this.SectionDropdownData];
        this.previousSectionVal = Object.assign([], this.SectionDropdownData);
      }
    })
  }
  subjectData: any = [];
  sectionId: any;
  onSelectSectionChange(selectedSection: any) {
    this.selectedData1.setValue(null);
    this.SelectedSection = selectedSection;
    this.subjectService.GetSubjectBySectionId(this.SelectedSection).subscribe((response: any) => {
      if (response.responseData && response.responseData.length > 0) {
        this.SubjectDropdownData = response.responseData;
        this.subjectData = [...this.SubjectDropdownData]
        this.previousSubjectVal = Object.assign([], this.SubjectDropdownData);
      }
    })

  }
  subjectId: any;
  onSelectSubjectChange(selectedSubject: any) {
    this.SelectedSubject = selectedSubject;
  }

  getGradeBookLog() {

    const formData = this.getDropDownValuesAsFormData();
    this.dataSourcelogs = [];
    this.gradeBookService.getGradeBookLogs(formData).subscribe((data: any) => {
      this.dataSourcelogs = data;

    })
  }




  getAcademicSession() {
    this.SelectedAcademicSession = this.getGobalUser()?.academicSessionID;
  }


  activityLog: any;
  ActivityLogTrack() {

    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Asessment & Gradings',
        ActivityTypeId: 10,
        Description: 'Grade list record viewed by',
        ScreenName: 'Grade Book',
        RecordTypeId: 54,
        RecordId: 0,
        Type: 0
      }
    }
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  getGobalUser() {
    const currentUser = localStorage.getItem('currentUser');
    return JSON.parse(this.users);
  }

  buildDynamicFormGroup(gradeBook: any) {
    return this.formBuilder.group({
      AcademicSessionId: [this.SelectedAcademicSession],
      ClassId: [this.SelectedClass],
      SectionId: [this.SelectedSection],
      SubjectId: [this.SelectedSubject],
      StudentMarks: this.formBuilder.array(
        this.GradeBookData?.map((studentData: any) => this.createStudent(studentData))
      ),
    });
  }
  createStudent(student: any): FormGroup {
    return this.formBuilder.group({
      ...student,
      Assessments: this.formBuilder.array(
        student.Assessments?.map((marks: any) =>
          this.createMarks(marks)
        )
      ),
    });
  }

  createMarks(marks: any): FormGroup {
    return this.formBuilder.group({
      ...marks,
    });
  }
  @ViewChild('select')
  select!: MatSelect;

  allSelected = false;
  allSelected1 = false;
  Status: any[] = [
    { value: 'upcoming', viewValue: 'Upcoming' },
    { value: 'inprogress', viewValue: 'In Progress' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'incomplete', viewValue: 'Incomplete' },
    { value: 'withdrawn', viewValue: 'Withdrawn' },
    { value: 'notset', viewValue: 'Not Set' },
  ];
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  getGradeBook() {
    if (this.formGroup?.value.StudentMarks.length > 0) {
      this.Students().clear();
    }
    this.getAcademicSession();
    this.GradeBookColumnHeader = [];
    this.dataSourcelogs = [];
    this.classToggled = false;
    this.ActivityLogTrack();
    this.gradeBookService.getGradeBook(this.SelectedAcademicSession, this.SelectedClass, this.SelectedSection, this.SelectedSubject).subscribe((responseData: any) => {
      const response = JSON.parse(JSON.stringify(responseData));
      if (response && response.length > 0) {
        let sort = 1;
        this.GradeBookData = response.reduce((acc: any, data: any) => {
          let renderGrading = '';
          let { assessmentCategoryId, weightage, assessmentCategoryName, assessmentID, assessmentName, comment, enrollmentStatus, firstName, lastName, marks, points, recordId, studentId, typeName, passingPoints, createdDate, gradeDetail, calculatedField, overallScoreComment } = data;
          let prevCat = acc.find((x: any) => x.StudentId === studentId);
          const typeNameToUpper = new String(typeName).toUpperCase();
          if (assessmentCategoryId > 0) {
            const existingCategpry = this.distinctAssessmentCategories.find((cat: any) => cat.AssessmentCategoryId === assessmentCategoryId);
            if (!existingCategpry) {
              this.distinctAssessmentCategories.push({ AssessmentCategoryId: assessmentCategoryId, AssessmentCategoryName: assessmentCategoryName });
            }
          }

          const existingEnrollment = this.enrollmentFilter.find((enrol: any) => enrol === enrollmentStatus);
          if (!existingEnrollment) {
            debugger
            if (enrollmentStatus != '' && enrollmentStatus != undefined)
              this.enrollmentFilter.push(enrollmentStatus);
          }

          if (typeNameToUpper === this.GradingSystem.Grade) {
            if (gradeDetail) {
              const grades = JSON.parse(gradeDetail);
              if (grades) {
                grades.forEach((grade: any) => {
                  if (Number(marks) >= grade.MinValue && Number(marks) <= grade.MaxValue) {
                    renderGrading = grade.DenotedAlpha;
                  }
                });
              }
            }
          }
          if (!prevCat) {
            sort = 1;
            acc.push({ StudentId: studentId, 'First Name': firstName, 'Last Name': lastName, 'Enrollment Status': enrollmentStatus, Assessments: [{ AssessmentID: assessmentID, AssessmentName: assessmentName, Comment: comment, AssessmentCategoryId: assessmentCategoryId, AssessmentCategoryName: assessmentCategoryName, Marks: marks, Points: points, RecordId: recordId, TypeName: typeNameToUpper, Sort: sort, PassingPoints: passingPoints, CreatedDate: createdDate, CalculatedField: calculatedField, GradeDetail: gradeDetail, OverallScoreComment: overallScoreComment, RenderGrade: renderGrading, Placeholder: this.defaultPlaceHolder, Weightage: weightage }] });
          } else {
            sort = sort + 1;

            let prevSubCat = prevCat.Assessments.find((x: any) => x.AssessmentID === assessmentID);
            if (!prevSubCat) {
              prevCat.Assessments.push({ AssessmentID: assessmentID, AssessmentName: assessmentName, Comment: comment, AssessmentCategoryId: assessmentCategoryId, AssessmentCategoryName: assessmentCategoryName, Marks: marks, Points: points, RecordId: recordId, TypeName: typeNameToUpper, Sort: sort, PassingPoints: passingPoints, CreatedDate: createdDate, CalculatedField: calculatedField, GradeDetail: gradeDetail, OverallScoreComment: overallScoreComment, RenderGrade: renderGrading, Placeholder: this.defaultPlaceHolder, Weightage: weightage });
            }
          }
          return acc;
        }, []);
        this.masterGradeBookData = cloneDeep(this.GradeBookData);
        this.formGroup = this.buildDynamicFormGroup(this.GradeBookData);
        this.createTableHeaders();
      }
      else {
        this.toaster.info('We cannot find the grade book details in the database. Please contact administrator.');
      }
    }, (error: any) => {
      this.toaster.info('We are facing problem fetching the Student Grade details. Please contact administrator.');
    })
  }

  createTableHeaders() {
    this.GradeBookColumnHeader = [];
    const studentheaders = Object.keys(this.formGroup.value?.StudentMarks.at(0))?.filter((header: string) => {
      return header.toLowerCase() !== 'assessments' && header.toLowerCase() !== 'studentid'
    });
    this.AssetmentHeader = this.formGroup.value?.StudentMarks.at(0)?.Assessments.map((assestment: any) => {
      return {
        Name: assestment.AssessmentName,
        Type: assestment.TypeName.replace(/(\w)(\w*)/g,
          function (g0: any, g1: string, g2: string) { return g1.toUpperCase() + g2.toLowerCase(); }),
        CategoryName: assestment.AssessmentCategoryName,
        AssessmentID: assestment.AssessmentID,
        Points: assestment.Points,
        GradeDetails: assestment?.GradeDetail,
        CreatedDate: assestment.CreatedDate,
        CountInOverall: assestment.CalculatedField
      };
    });
    this.GradeBookColumnHeader.push(...studentheaders, ...this.AssetmentHeader);
  }
  hideMeAndShowOriginalInput(event: any) {
    console.log(event);
  }
  onCategoryChange(event: any) {
    if (event.checked) {
      this.selectedCategory.push(event.source.value);
    }
    else {
      this.selectedCategory = this.selectedCategory.filter((cat: any) => cat != event.source.value);
    }

    this.applyFilter();
  }
  onEnrollmentChange(event: any) {
    if (event.checked) {
      this.selectedEnrollment.push(event.source.value);
    }
    else {
      this.selectedEnrollment = this.selectedEnrollment.filter((enroll: any) => enroll != event.source.value);
    }

    this.applyFilter();
  }


  applyFilter() {
    debugger
    this.resetFormGroup();

    if (this.selectedCategory.length > 0) {
      this.formGroup.value.StudentMarks.filter((student: any) => {
        const filterStudentMarks = student.Assessments.filter((Assesstment: any) => {
          return this.selectedCategory.includes(Assesstment.AssessmentCategoryId);
        });
        student.Assessments = filterStudentMarks.length > 0 ? filterStudentMarks : null;

        return student.Assessments != null;
      });
    }

    // if(this.selectedEnrollment.length > 0){
    //   debugger
    //   let obj = this.formGroup.value.StudentMarks.filter((x:any) => {
    //     return this.selectedEnrollment.includes((z:any) => z == x['Enrollment Status']);
    //   }); 
    // }

    this.createTableHeaders();
  }
  chkData(std: any) {
    if (this.selectedEnrollment.length > 0) {
      if (this.selectedEnrollment.includes(std.value['Enrollment Status'])) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  resetFormGroup() {
    const masterData = cloneDeep(this.masterGradeBookData);
    if (this.formGroup.dirty) {
      this.Students().controls.forEach((modStudent: any) => {
        if (modStudent.touched) {
          modStudent.value.Assessments.forEach((modAssess: any) => {
            masterData.forEach((Student: any) => {
              const index = Student.Assessments.findIndex((ass: any) => ass.AssessmentID === modAssess.AssessmentID);
              if (index != -1) {
                Student.Assessments[index] = modAssess;
              }
            });
          });
        }
      });
    }
    this.formGroup.value.StudentMarks = masterData;
  }
  getGrades(header: any) {
    return JSON.parse(header.GradeDetails);
  }
  Headers() {
    return this.GradeBookColumnHeader;
  }
  Students() {
    return this.formGroup?.get('StudentMarks') as FormArray;
  }

  Assessments(studentIndex: number) {
    return this.Students()?.at(studentIndex)?.get('Assessments') as FormArray;
  }
  AssessmentsByName(studentIndex: number, assesstmentName: string) {

    const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
      return asset.value.AssessmentName === assesstmentName;
    });
    return asseFiltered;
  }
  openRubric(studentIndex: number, assesstmentName: string) {
    const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
      return asset.value.AssessmentName === assesstmentName;
    });
    this.SelectedStudent = this.Students()?.at(studentIndex).value.StudentId;
    this.addEditRubic(asseFiltered?.value.RecordId, asseFiltered?.value.Placeholder, studentIndex, assesstmentName, true);
  }
  addEditRubic(rubicId: number = 0, selectedCriteria: any, studentIndex: number, assesstmentName: string, readonly: boolean = false) {
    let rubicData: Rubic = this.RubicObject;
    if (rubicId === 0) {
      this.toaster.info('We cannot find the rubic in the database. Please contact Administartor.');
    }
    else {
      this.rubicService.Get(rubicId, this.SelectedAcademicSession, this.SelectedClass, this.SelectedSection, this.SelectedSubject, this.SelectedStudent).subscribe((responseData) => {
        const response = JSON.parse(JSON.stringify(responseData));
        if (response && response.length > 0) {
          rubicData = this.rubicService.transformRubicData(response);
          this.initRubicDialog(rubicData, selectedCriteria, studentIndex, assesstmentName, readonly);
        }
        else {
          this.toaster.info('We cannot find the rubic in the database. Please contact Administartor.');
        }
      });
    }
  }
  initRubicDialog(rubicData: any, selectedCriteria: any, studentIndex: number, assesstmentName: string, readonly: boolean = false) {
    this.dialogRef = this.dialog.open(RubicDialogComponent, {
      width: '80%',
      data: {
        Collection: {
          defaultCriteria: this.defaultCriteria,
          rubicObject: rubicData,
          userID: this.user.userId,
          studentIndex: studentIndex,
          assesstmentName: assesstmentName,
          prevSelectedCriteria: selectedCriteria,
          closeCallback: this.closeDialogWithData.bind(this),
          readOnly: readonly,
          AcademicSessionId: this.SelectedAcademicSession,
          ClassId: this.SelectedClass,
          SectionId: this.SelectedSection,
          SubjectId: this.SelectedSubject,
        }
      },
      disableClose: true,
    });
  }
  closeDialogWithData(data: any, studentIndex: number, assesstmentName: string) {
    if (data && data.length > 0) {
      const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
        return asset.value.AssessmentName === assesstmentName;
      });
      let total = 0;
      data.map((criteria: any) => {
        total =
          total + Number(criteria.AttributeValue);
      });
      asseFiltered?.markAsDirty();
      //asseFiltered?.patchValue({'Criterias': data.map((e: any) => e.Criteria).join(",")});
      //asseFiltered?.patchValue({'Attributes': data.map((e: any) => e.RubicCriteriaAttributeId).join(",")});
      asseFiltered?.patchValue({ 'Placeholder': data });
      asseFiltered?.patchValue({ 'Marks': total });
      console.log(asseFiltered);
    }
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  validateMaxNumber(studentIndex: number, assesstmentName: string, e: any) {
    const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
      return asset.value.AssessmentName === assesstmentName;
    });
    if (Number(e.target.value) > Number(asseFiltered?.value.Points)) {
      this.setErrorOnRow(asseFiltered);
    }
    else {
      asseFiltered?.setErrors(null);
    }

  }

  getMarksFromGrade(event: any) {
    event.target.setAttribute('hidden', true);
    event.target.nextSibling.removeAttribute('hidden');
  }
  validateGradingChange(studentIndex: number, assesstmentName: string, e: any) {
    const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
      return asset.value.AssessmentName === assesstmentName;
    });
    const grades = JSON.parse(asseFiltered?.value.GradeDetail);
    e.target.setAttribute('hidden', true);
    e.target.previousSibling.removeAttribute('hidden');

    if (grades) {

      asseFiltered?.setErrors(null);
      let isMatchFound = false;
      grades.forEach((grade: any) => {
        if (Number(e.target.value) >= grade.MinValue && Number(e.target.value) <= grade.MaxValue) {
          isMatchFound = true;
          e.target.previousSibling.value = grade.DenotedAlpha;
        }
      });

      if (!isMatchFound) {
        e.target.previousSibling.value = '';
        this.setErrorOnRow(asseFiltered);
      }
    }
    else {
      this.setErrorOnRow(asseFiltered);
    }
  }

  setErrorOnRow(asseFiltered: any) {
    asseFiltered?.setErrors({ 'incorrect': true });
    asseFiltered?.markAsDirty();
    asseFiltered?.markAsTouched();
  }
  calculateMarks(studentIndex: number, assesstmentName: string) {
    let totalOverall: number[] = [];
    const assetments = this.Assessments(studentIndex)?.value;
    const asseFiltered = (this.Students()?.at(studentIndex)?.get('Assessments') as FormArray).controls.find((asset: any) => {
      return asset.value.AssessmentName === assesstmentName;
    });

    this.distinctAssessmentCategories.forEach((category: any) => {
      const assets = assetments.filter((assestment: any) => { return assestment.AssessmentName != assesstmentName && assestment.AssessmentCategoryName === category.AssessmentCategoryName });
      let overall = assets.reduce((acc: number, val: any) => {
        if (val.CalculatedField) {
          let marks = val.Marks;
          if (val.TypeName === this.GradingSystem.Percentage) {
            marks = marks / 100 * Number(val.Points);
          }
          const formula = (Number(marks) / Number(val.Points)) * (Number(val.Weightage) === 0 ? 1 : Number(val.Weightage));
          acc = acc + formula;
        }
        return acc;
      }, 0);

      totalOverall.push(overall / assets.length);
    });

    const result = totalOverall.reduce<number>((accumulator, current) => {
      return accumulator + current;
    }, 0);
    asseFiltered?.patchValue({ 'Marks': result });
  }
  checkActionPermission(actionName: any) {
    let screen = 'Grade Book';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }
  public toggleField() {
    let result = this.validateAllSelections();
    if (!result) {
      this.toaster.error("Please select all options Class, Section and Subject.")
      return;

    }
    this.classToggled = !this.classToggled;
    this.getGradeBookLog();
  }
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  openComments(templateRef: TemplateRef<any>, studentIndex: number, assesstmentIndex: number) {
    this.openedCommentsDialog = this.dialog.open(templateRef, {
      data: {
        studentIndex: studentIndex,
        assesstmentIndex: assesstmentIndex,
        Comment: this.Assessments(studentIndex).at(assesstmentIndex).value.Comment
      }
    });

  }

  closeComments() {
    this.openedCommentsDialog.close();
  }

  patchCommentsData(studentIndex: number, assesstmentIndex: number) {
    this.Assessments(studentIndex).at(assesstmentIndex).patchValue({
      Comment: this.rteObj.getHtml()
    });

    this.closeComments();
  }

  saveGradeBook() {
    //debugger;
    if (this.formGroup.status == 'VALID') {
      const data = JSON.stringify(this.formGroup.getRawValue().StudentMarks);
      const request = {
        JsonData: data,
        CategoryId: 0,
        AcademicSessionId: this.SelectedAcademicSession,
        ClassId: this.SelectedClass,
        SectionId: this.SelectedSection,
        SubjectId: this.SelectedSubject,
        userId: this.user.userId
      }
      this.gradeBookService.upsert(request).subscribe((response: any) => {
        if (response.responseData) {
          this.toaster.success('The data has been saved successfully.');

          if (this.classToggled)
            this.getGradeBookLog();
        }
        else {
          this.toaster.error('The data has not been saved successfully. Please contact administrator.');
        }
      });
    }
    else {
      this.toaster.warning('There is no data to be saved.');
    }

  }

  openImportDialog() {
    let result = this.validateAllSelections();
    if (!result) {
      this.toaster.error("Please select all options Class, Section and Subject.")
      return;

    }

    this.dialog.open(ImportDialogComponent, {
      data: {
        AcademicSessionId: this.SelectedAcademicSession,
        ClassId: this.SelectedClass,
        SectionId: this.SelectedSection,
        SubjectId: this.SelectedSubject
      }
    });



  }

  validateAllSelections() {
    if (this.SelectedAcademicSession > 0 && this.SelectedClass > 0 && this.SelectedSection > 0 && this.SelectedSubject > 0)
      return true;
    else
      return false;

  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      disableClose: true
    }).beforeClosed().subscribe(() => {
      this.hideUploadTab = false;
      this.isFileSelected = false;
    });
  }

  openDialogImport(templateRef: TemplateRef<any>) {

    let result = this.validateAllSelections();
    if (!result) {
      this.toaster.error("Please select all options Class, Section and Subject.")
      return;
    }

    this.dialog.open(templateRef, {
      disableClose: true
    }).beforeClosed().subscribe(() => {
      this.hideUploadTab = false;
      this.isFileSelected = false;
      this.getGradeBook();
    });
  }



  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];
    this.selectedName = this.selectedFile.name;

  }
  onFileSelected() {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      this.selectedFile = fi.files[0].name;
      this.isFileSelected = true;
    }
    else
      this.isFileSelected = false;
  }

  addFile(caller: string = "", isAlumni: boolean = false): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.isUploadPressed = true;
      this.hideUploadTab = true;
      this.parseExcel(fileToUpload, caller, isAlumni);
    }
  }

  parseExcel(file: File, caller: string, isAlumni: boolean = false): any {

    let workBook: any = null;
    let parsedExcelRowArray = null;
    let rawExcel: File;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true });
      parsedExcelRowArray = workBook.SheetNames.reduce(
        (initial: { [x: string]: unknown[] }, name: string | number) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, {
            header: 0,
            defval: "",
            raw: false,
            dateNF: 'MM/dd/yyyy'
          });
          rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
          //XLSX.writeFile(workBook,"myfile.xlsx");
          return initial[name];
        },
        {}
      );
      return this.openParsedExcelDataInWindow(parsedExcelRowArray, new File([new Blob([rawExcel], { type: this.fileType })], `${file.name.split('.').slice(0, -1).join('.')}${this.fileExtension}`), caller, isAlumni);
    };

    reader.readAsBinaryString(file);
  }

  openParsedExcelDataInWindow(parsedExcelData: any[], file: File, caller: string, isAlumni: boolean = false): void {
    if (parsedExcelData.length === 0) {
      this.toaster.error(`The file uploaded is empty`);
      this.hideUploadTab = false;
      this.isFileSelected = false;
    }
    else
      this.getInputControlFromReadExcel(parsedExcelData, file, caller, isAlumni);
  }

  getInputControlFromReadExcel(parsedExcelData: any[], file: File, caller: string, isAlumni: boolean = false) {
    const parsedControls: FormInputBase<string>[] = [];
    const getExcelHeaders = Object.keys(parsedExcelData[0]);
    const dropdownOptions: { key: any; value: any; }[] = [];
    let parsedDropdownControls: any = [];
    const formData = this.getDropDownValuesAsFormData();
    this.gradeBookService.getFieldsForDropdownOptions(formData).subscribe((res: any) => {
      getExcelHeaders.forEach((header, index) => {
        dropdownOptions.push({
          key: header,
          value: header
        });
      });

      if (res.responseData && res.responseData.length > 0) {
        let index = 0;
        for (var i = 0; i < res.responseData.length; i++) {
          parsedDropdownControls.push(new DropdownInput({
            key: res.responseData[i].fieldName,
            label: res.responseData[i].fieldName,
            controlType: 'dropdown',
            options: dropdownOptions,
            order: ++index,
            required: res.responseData[i].fieldName === 'Admission Number' ? true : res.responseData[i].isRequired,
          }));
        }
      }

      parsedControls.push(...parsedDropdownControls);
      parsedControls.sort((a, b) => a.order - b.order);

      this.uploadedFile = file;
      this.caller = caller;

      if (this.componentRef && this.componentRef.instance)
        this.remove(this.unique_key_child);

      this.loadChild(parsedControls, this.uploadedFile, this.caller, getExcelHeaders, isAlumni);
    });
  }

  loadChild(parsedControls: FormInputBase<string>[], uploadedFile: File, caller: string, excelheaders: string[], isAlumni: boolean = false): void {
    let componentFactory = this.resolver.resolveComponentFactory(MappingContainerStudentGradeComponent);
    this.componentRef = this.mappingContainer.createComponent(componentFactory);
    this.renderComponent(parsedControls, excelheaders, isAlumni);
    this.componentsReferences.push(this.componentRef);
  }
  remove(key: number) {
    if (this.mappingContainer.length < 1) return;
    let componentRef = this.componentsReferences.filter(
      x => x.instance.unique_key == key
    )[0];

    let vcrIndex: number = this.componentsReferences.indexOf(componentRef as any);
    this.mappingContainer.remove(vcrIndex);
    this.componentsReferences = this.componentsReferences.filter(
      x => x.instance.unique_key !== key
    );
  }


  renderComponent(parsedControls: FormInputBase<string>[], excelheaders: string[], isAlumni: boolean = false) {
    if (this.componentRef) {
      this.componentRef.instance.dynamicInputControls = parsedControls;
      this.componentRef.instance.uploadedFile = this.uploadedFile;
      this.componentRef.instance.caller = this.caller;
      this.componentRef.instance.excelheaders = excelheaders;
      this.unique_key_child = ++this.child_unique_key;
      this.componentRef.instance.unique_key = this.unique_key_child;
      this.componentRef.instance.parentRef = this;
      this.componentRef.instance.academicSessionId = this.SelectedAcademicSession;
      this.componentRef.instance.classId = this.SelectedClass;
      this.componentRef.instance.sectionId = this.SelectedSection;
      this.componentRef.instance.subjectId = this.SelectedSubject;
    }
  }


  transform(date: any): string {
    let dateTime = new Date(date);
    const offset = new Date().getTimezoneOffset();
    dateTime.setMinutes(dateTime.getMinutes() - offset);
    return moment(dateTime).format(this.dateTimeFormat);
  }




}





