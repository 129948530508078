import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { model } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { ManageAssessmentAndGradingsService } from '../services/manage-assessment-and-gradings.service';
import { MasterService } from 'src/app/services/master.service';
import { UserRole } from 'src/environments/environment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export interface Student {
  studentID: bigint;
  studentName: string;
  classID: number;
  sectionID: number;
}
@Component({
  selector: 'app-report-cards',
  templateUrl: './report-cards.component.html',
  styleUrls: ['./report-cards.component.css']
})


export class ReportCardsComponent implements OnInit {

  users: any;
  user: any;
  permissions: any;
  logo: any;
  studentList: Student[] = [];
  filterForm: FormGroup;
  StudentName: any;
  StudentID: any;
  filteredOptions: Observable<Student[]>;
  studentFlag: boolean;
  showPrintButton: boolean = false;
  isTeacherUser: boolean = false;
  ToParent: boolean = false;
  ToStudent: boolean = false;
  ViaSMS: boolean = false;
  NotificationToStudent() { this.ToStudent = !this.ToStudent }
  NotificationToParent(){this.ToParent = !this.ToParent}
  NotificationViaSMS(){
    this.ViaSMS = !this.ViaSMS;
  }
  @ViewChild('pdfTable') pdfTable: ElementRef;
  constructor(
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private masterService: MasterService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
    if (localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.logo = localStorage.getItem('Logo');
    } else {
      this.logo = '../../../../../../assets/img/admin/org_logo.png';
    }
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      studentID: ['', Validators.required]
    });
    var roleID = localStorage.getItem('roleID')
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    this.getAllStudent();
    this.permissionService.checkComponentHasScreenPermission('Report Card');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
  }
  checkActionPermission(actionName: any) {
    let screen = 'Report Card';
    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }
  getAllStudent() {
    this.manageAssessmentService.GetAllStudent().subscribe((res: any) => {
      this.studentList = res.responseData;
      this.filteredOptions = this.filterForm.controls.studentID.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.studentName)),
        map((studentName) =>
          studentName ? this._filter(studentName) : this.studentList.slice()
        )
      );
    });
  }
  private _filter(name: string): Student[] {
    const filterValue = name.toLowerCase();
    let data = this.studentList.filter((option: any) =>
      option.studentName.toLowerCase().includes(filterValue)
    );
    if (data.length == 0) {
      this.studentFlag = true;
    } else {
      this.studentFlag = false;
    }
    return this.studentList.filter((option: any) =>
      option.studentName.toLowerCase().includes(filterValue)
    );
  }

  filterStudent(event: any) {
    console.warn(event);
  }
  ClassID: any;
  SectionID: any;
  filterSectionListByStudentId(data: any, fieldName: string) {
    if (data.option !== undefined) {
      this.StudentName = data.option.value.studentName;
      this.StudentID = data.option.value.studentID;
      this.ClassID = data.option.value.classID;
      this.SectionID = data.option.value.sectionID;
    }
  }
  activityLog:any;
  ActivityLogTrack()
  {
    
    this.activityLog={
      ActivityLogTrack:{
              ModuleName: 'Academic & Learning',
              SubModuleName: 'Asessment & Gradings',
              ActivityTypeId: 10,
              Description: 'Report card of student' + ' '+ this.studentNameLog +' '+ 'viewed by',
              ScreenName: 'Report Card',
              RecordTypeId: 55,
              RecordId: this.studentId ,   
              Type: 0
            }
    }
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response:any)=>{
      if(response!=null && response !=undefined && response !=''){
        let responseData=response.responseData;
        console.log("responseData:",responseData);
      }
  
  
    });
  }
  displayFn(studentData: Student): string {
    return studentData && studentData.studentName ? studentData.studentName : '';
  }
  studentDetails: any;
  reportCardTemplateID: any = 0;
  reportCardFormatID: any;
  hideEnableDisableButton: boolean = false;
  studentId:any;
  studentNameLog:any;
  onSubmit() {
    this.checkReportCardEnable();
    this.manageAssessmentService.GetStudentAssignedTemplate(this.StudentID, this.ClassID, this.SectionID).subscribe((res: any) => {
      debugger
      console.warn('>>>>>>>>>>>>>>', res.responseData);
      this.studentId=res.responseData.studentDetails[0]?.StudentID;
      this.studentNameLog=res.responseData.studentDetails[0]?.Name;
      this.showPrintButton = true;
      this.studentDetails = res.responseData;
      this.reportCardTemplateID = res.responseData.studentDetails[0]?.ReportCardtemplateID;
      this.reportCardFormatID = res.responseData.studentDetails[0]?.ReportCardsFormatID;
      this.ActivityLogTrack();
    });
   
    this.hideEnableDisableButton = true;
  }

  checkEnableReportObj:any;
  checkRadioReportCardEnable:boolean;
  checkRadioReportCardDisable:boolean;
  checkReportCardEnable(){
    this.manageAssessmentService.CheckReportCardEnableDisable(this.StudentID, this.ClassID, this.SectionID).subscribe((res: any) => {
      if(res.responseData==true){
        this.checkRadioReportCardEnable=true;
        this.checkRadioReportCardDisable=false;
      }
      else{
        this.checkRadioReportCardDisable=true;
        this.checkRadioReportCardEnable=false;
      }
    });
  }
  enableReportObj = {};
  clickOnEnabled(event: any) {
    this.enableReportObj = {
      studentID: this.StudentID,
      classID: this.ClassID,
      sectionID: this.SectionID,
      isReportCardEnable: true
    }
    this.EnableDisableReportCard();
  }
  clickOnDisabled(event: any) {
    this.enableReportObj = {
      studentID: this.StudentID,
      classID: this.ClassID,
      sectionID: this.SectionID,
      isReportCardEnable: false
    }
    this.EnableDisableReportCard();
  }

  EnableDisableReportCard() {
    this.manageAssessmentService.EnableDisableReportCard(this.enableReportObj).subscribe((res: any) => {
      if (res.status == 0 || res.status == 1) {
        this.toaster.success(res.message);
      }
    });
  }
  sendStudentReportCard(){
    if (!this.ToStudent && !this.ToParent) {
      this.toaster.error("Please select report card send to");
    }
    else if (!this.ViaSMS) {
      this.toaster.error("Please select report card send via");
    }
    else {
      let base64String;
      html2canvas(this.pdfTable.nativeElement).then(canvas => {
        // Few necessary setting options
        let pdf = new jsPDF('p', 'px', 'a4'); // A4 size page of PDF
        const contentDataURL = canvas.toDataURL('image/png')
        var width = pdf.internal.pageSize.getWidth();
        var height = canvas.height * width / canvas.width;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
                 const pdfData = pdf.output('arraybuffer');
        const binarydata = new Uint8Array(pdfData);
        let binary = '';
        for (let i = 0; i < binarydata.length; i++) {
          binary += String.fromCharCode(binarydata[i]);
        }
        base64String = btoa(binary);
        const data = {
          StudentID: this.StudentID,
          ReportBytes: base64String,
          NotifyToParent: this.ToParent,
          NotifyToStudent: this.ToStudent,
          IsSendNotificationBySms: this.ViaSMS,
          ClassName: this.studentDetails.studentDetails[0].ClassName,
          Section:  this.studentDetails.studentDetails[0].SectionName
        };
        this.manageAssessmentService.sendReportCrad(data).subscribe((res: any) => {
          if (res.status == 0 || res.status == 1) {
            this.toaster.success(res.message);
          }
        });

      });
    }
  }

}
