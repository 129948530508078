<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Payment Details</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" *ngIf="!isTeacherUser" ><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Payment Deatils</li>
                </ol>
            </div>
        </div>
        <div class="">
          <div class="add_button">
            <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
          </div>
        </div>
    <!-- </div> -->
  </div>
  <div class="cardWidget">
    <app-teacherstabs></app-teacherstabs>

    <div class="cardBody">
      <div class="row">
        <!-- <div class="col-lg-10 col-sm-12">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Year</mat-label>
                      <mat-select placeholder="Select Year" formControlName="Select Year">
                        <mat-option value="Select Status">2022</mat-option>
                      </mat-select>
                      <mat-error>
                        Please Select a Year </mat-error>
                    </mat-form-field>
      
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Month</mat-label>
                      <mat-select placeholder="Select Month" formControlName="Select Month">
                        <mat-option value="Select Status">March</mat-option>
                      </mat-select>
                      <mat-error>
                        Please Select a Year </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-12">
                <button class="btn btn-primary w-100" matRipple> Get Details
                </button>
              </div> -->
        <div class="col-lg-9 col-sm-12 mt-2">
          <form [formGroup]="form" (ngSubmit)="onFilterSubmit()" class="w-100">
            <div class="row">

              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Month" (selectionChange)="monthSelection($event.value)" formControlName="month">
                    <mat-option value="" disabled>Select month</mat-option>
                    <mat-option *ngFor="let month of months" [value]="month.monthNumber">
                      {{month.monthName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Year" (selectionChange)="yearSelection($event.value)" formControlName="year">
                    <mat-option value="" disabled>Select year</mat-option>
                    <mat-option *ngFor="let y of years" [value]="y.yearNumber">
                      {{y.yearName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-2 col-sm-12">
                <button type="submit" class="btn btn-primary w-100" matRipple>Get Details
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-2 col-sm-12 mt-2">
          <button class="btn btn-reset w-100" (click)="resetForm($event)">Reset</button>
        </div>
      </div>

      <!-- <div class="mat-elevation-z8">
        <div class="table-responsive">
          <table mat-table [dataSource]="collection">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Payment Date</th>
              <td mat-cell *matCellDef="let element;let i = index;">{{element.date}} </td>
            </ng-container>

            <ng-container matColumnDef="salary">
              <th mat-header-cell *matHeaderCellDef>Salary/Month</th>
              <td mat-cell *matCellDef="let element"> {{element.salary}} </td>
            </ng-container>

            <ng-container matColumnDef="paidAmt">
              <th mat-header-cell *matHeaderCellDef>Amount Paid</th>
              <td mat-cell *matCellDef="let element"> {{element.paidAmt}} </td>
            </ng-container>

            <ng-container matColumnDef="absent">
              <th mat-header-cell *matHeaderCellDef>Total Absent</th>
              <td mat-cell *matCellDef="let element"> {{element.absent}} </td>
            </ng-container>

            <ng-container matColumnDef="month">
              <th mat-header-cell *matHeaderCellDef>Month</th>
              <td mat-cell *matCellDef="let element"> {{element.month}} </td>
            </ng-container>

            <ng-container matColumnDef="button">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <Button title="view" class="tbactionbtn" routerLink="/admin/academicslearning/viewpaymentdeatils"><i
                    class="fas fa-eye"></i></Button>


              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div> -->

      <div class="mat-elevation-z8">
        <div class="table-responsive" *ngIf="collection != null">
          <table mat-table [dataSource]="collection">

            <!-- <ng-container matColumnDef="paymentDetailsID">
              <th mat-header-cell *matHeaderCellDef>Payment ID</th>
              <td mat-cell *matCellDef="let element">{{element.paymentDetailsID}} </td>
            </ng-container> -->

            <!-- <ng-container matColumnDef="userID">
              <th mat-header-cell *matHeaderCellDef>Teacher ID</th>
              <td mat-cell *matCellDef="let element"> {{element.userID}} </td>
            </ng-container> -->

            <ng-container matColumnDef="paymentDate">
              <th mat-header-cell *matHeaderCellDef>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.paymentDate != '0001-01-01T00:00:00'">
                  {{element.paymentDate | date: dateFormat}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="salaryForMonthName">
              <th mat-header-cell *matHeaderCellDef>Month</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForMonthName}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="absentForYear">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element"> {{element.absentForYear}}</td>
            </ng-container> -->

            <ng-container matColumnDef="totalAbsent">
              <th mat-header-cell *matHeaderCellDef>Total Absent</th>
              <td mat-cell *matCellDef="let element"> {{element.totalAbsent}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="salaryForMonth">
              <th mat-header-cell *matHeaderCellDef>Salary For Month</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForMonth}}</td>
            </ng-container> -->

            <ng-container matColumnDef="salaryForYear">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForYear}}</td>
            </ng-container>

            <ng-container matColumnDef="salaryBeforeDeduction">
              <th mat-header-cell *matHeaderCellDef>Salary Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryBeforeDeduction}} </td>
            </ng-container>

            <ng-container matColumnDef="deductionAmount">
              <th mat-header-cell *matHeaderCellDef>Deduction Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.deductionAmount}} </td>
            </ng-container>

            <ng-container matColumnDef="salaryAfterDeduction">
              <th mat-header-cell *matHeaderCellDef>Salary After Deduction {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryAfterDeduction}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="salaryAmount">
              <th mat-header-cell *matHeaderCellDef>Salary Amt</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryAmount}}</td>
            </ng-container> -->

            <ng-container matColumnDef="bonusAmount">
              <th mat-header-cell *matHeaderCellDef>Bonus Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.bonusAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="adjustableAmount">
              <th mat-header-cell *matHeaderCellDef>Adjustable Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.adjustableAmount}}</td>
            </ng-container>

            <ng-container matColumnDef="paidAmount">
              <th mat-header-cell *matHeaderCellDef>Paid Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.paidAmount}}</td>
            </ng-container>

            <ng-container matColumnDef="alreadyPaidAmount">
              <th mat-header-cell *matHeaderCellDef>Already Paid Amt {{currency}}</th>
              <td mat-cell *matCellDef="let element"> {{element.alreadyPaidAmount}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="paymentUpfrontStatus">
              <th mat-header-cell *matHeaderCellDef>Is Upfront</th>
              <td mat-cell *matCellDef="let element"> {{element.paymentUpfrontStatus}}</td>
            </ng-container> -->

            <ng-container matColumnDef="paymentStatus">
              <th mat-header-cell *matHeaderCellDef>Payment Status</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.paymentStatus == true && element.payableAmount == 0">
                  Fully Paid
                </span>
                <span *ngIf="element.paymentStatus == true && element.payableAmount > 0">
                  Partially Paid
                </span>
                <span *ngIf="element.paymentStatus == false">
                  Unpaid
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="button">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  <!-- <a routerLink="/admin/academicslearning/viewpaymentdeatils" [queryParams]="{id:element.paymentDetailsID}" routerLinkActive="active"></a> -->
                  <button title="view" class="tbactionbtn" (click)="actionDialogPopup(element)">
                  <i class="fas fa-eye"></i></button>
                  <!-- <button title="print" class="tbactionbtn" (click)="salaryslipopup(salarytslip, element)">
                  <i class="fas fa-print"></i></button> -->
                </ng-container>
              </td>
            </ng-container>
            <!-- </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

      <!-- <section class="teacherRecipt">
        <div class="hreaderdesign">
          <div class="col-lg-4">
            <img src="../../../../../../../assets/img/admin/org_logo.png" alt="instittue logo" width="150px">
          </div>
          <div class="col-lg-4 text-center">
            <h2 class="haed">Institution Name</h2>
            <h6 class="Instaddress">Institution Address</h6>
            <h4 class="paylip">Payslip for the month of October,2020</h4>
          </div>
          <div class="col-lg-4"></div>
        </div>
        <div class="tabeleoneforDeatils">
          <div class="row">
            <div class="col-lg-6 border bordernew p-3">
              <div class="deatilsofteacher">
                <div class="teachersalutation">Name:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Designation:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Department:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">location:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">LOP:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
            </div>
            <div class="col-lg-6 border p-3">
              <div class="deatilsofteacher">
                <div class="teachersalutation">Emplaoy ID:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Bank Name:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Bank Account Number:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Pan:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabelesalaryforDeatils">
          <div class="row">
            <div class="col-lg-6 border bordernew p-3">
              <div class="deatilsofteacher bordernew border-bottom">
                <div class="teachersalutation"><b>Earning</b></div>
                <div class="teacherinfo"><b>Amount</b></div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Basic:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">HRA:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Telephone Reimbursement:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Bonus:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">LTA:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Specail Allowence:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
            </div>
            <div class="col-lg-6 border p-3">
              <div class="deatilsofteacher bordernew border-bottom">
                <div class="teachersalutation"><b>Deduction</b></div>
                <div class="teacherinfo"><b>Amount</b></div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Income Tax:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Provident Fund:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
              <div class="deatilsofteacher">
                <div class="teachersalutation">Professional Tax:</div>
                <div class="teacherinfo">Munwar</div>
              </div>
        
            </div>
          </div>
        </div>
        <div class="tabeleoneforDeatils">
          <div class="row">
            <div class="col-lg-6 border  p-3">
              <div class="deatilsofteacher mb-0">
                <div class="teachersalutation"><b>Total Earning:</b></div>
                <div class="teacherinfo"><b>Munwar</b></div>
              </div>
      
            </div>
            <div class="col-lg-6 border p-3">
              <div class="deatilsofteacher mb-0">
                <div class="teachersalutation"><b>Total Deduction:</b></div>
                <div class="teacherinfo"><b>Munwar</b></div>
              </div>      
            </div>
          </div>
        </div>
        <p class="netpayed"><b>Net pay is $10000</b></p>
        <hr>
        <div class="text-center">
          <p><b>This is system genrated slip</b></p>

          <p>Genrated by Creo</p>
          <div>For more info log on to <a href="javascript:void(0)">creo.cms</a></div>
        </div>
      </section> -->
    </div>
  </div>
</div>

<ng-template #viewPayment>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
      <h5 mat-dialog-title>Payment for: {{paymentForName}}</h5>
    </div>

    <mat-dialog-content class="mat-typography">
      <form [formGroup]="paymentForm">
        <div class="row">

          <div class="col-12">
            <!-- <mat-form-field appearance="outline" class="w-100"> -->
            <mat-label>Pay Upfront: </mat-label>
            <mat-checkbox class="example-margin" matInput formControlName="upfrontPayment" readonly></mat-checkbox>
            <!-- </mat-form-field> -->
            <!-- <mat-checkbox  class="example-margin">Yes</mat-checkbox>
                  <mat-checkbox  class="example-margin">No</mat-checkbox> -->

          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Salary After Deduction</mat-label>
              <input matInput type="number" formControlName="salaryAfterDeduction" readonly>
            </mat-form-field>
          </div>

          <div class="col-12 mt-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Bonus Amount</mat-label>
              <input matInput type="number" formControlName="bonusAmount" readonly>
            </mat-form-field>
          </div>



          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Paying Amount</mat-label>
              <input matInput type="number" formControlName="paidAmount" readonly>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Comment</mat-label>
              <textarea input matInput placeholder="Comment" formControlName="comment" readonly></textarea>
            </mat-form-field>
          </div>

        </div>
      </form>
    </mat-dialog-content>
  </div>
</ng-template>
<ng-template #salarytslip>
  <div class="dialog-lg">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle" style="display: flex; justify-content:space-between;align-items:center;">
      <h5 mat-dialog-title>Salary Slip</h5>
      <button class="mr-2" class="printbtn"  printSectionId="print-section" ngxPrint [useExistingCss]="true"><i class="fas fa-print"></i></button>
    </div>
    <div class="col-lg-4 text-left">
    
      <!-- <i class="fas fa-print mr-3" title="Print" printSectionId="print-section" printTitle="Invoice" ngxPrint  style="font-size: 20px;"></i> -->
    </div>
       <mat-dialog-content class="mat-typography" id="print-section">
        <section class="teacherRecipt">
          <div class="hreaderdesign">
            <div class="col-lg-4">
              <img [src]="logo" alt="logo" width="150px" style="margin-bottom: 15px;">
            </div>
            <div class="col-lg-4 text-center">
              <h2 class="haed">{{OrgName}}</h2>
              <h4 class="paylip">Payslip for the month of {{salaryForMonthName}},{{salaryForYear}}</h4>
            </div>
            <div class="col-lg-4"></div>
          </div>
          <div class="tabeleoneforDeatils">
            <div class="row">
              <div class="col-lg-6 border bordernew p-3">
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Name:</div>
                  <div class="teacherinfo">{{teacherDetails.firstName}} {{teacherDetails.lastName}}</div>
                </div>
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Designation:</div>
                  <div class="teacherinfo">Teacher</div>
                </div>
                <!-- <div class="deatilsofteacher">
                  <div class="teachersalutation">Department:</div>
                  <div class="teacherinfo">MOD</div>
                </div> -->
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Location:</div>
                  <div class="teacherinfo">{{teacherDetails.address}}</div>
                </div>
                <!-- <div class="deatilsofteacher">
                  <div class="teachersalutation">LOP:</div>
                  <div class="teacherinfo">0.0</div>
                </div> -->
              </div>
              <div class="col-lg-6 border p-3">
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Teacher ID:</div>
                  <div class="teacherinfo">{{teacherDetails.teacherID}}</div>
                </div>
                <!-- <div class="deatilsofteacher">
                  <div class="teachersalutation">Bank Name:</div>
                  <div class="teacherinfo">Andra Bank</div>
                </div>
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Bank Account Number:</div>
                  <div class="teacherinfo">2541654145</div>
                </div>
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Pan:</div>
                  <div class="teacherinfo">HTHVNb799y7</div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="tabelesalaryforDeatils">
            <div class="row">
              <div class="col-lg-6 border bordernew p-3">
                <div class="deatilsofteacher bordernew border-bottom">
                  <div class="teachersalutation"><b>Earning</b></div>
                  <div class="teacherinfo"><b>Amount</b></div>
                </div>
                <div *ngFor="let field of salaryField">
                  <div class="deatilsofteacher" *ngIf="field.deductionType == false">
                    <div class="teachersalutation">{{field.salaryFormFieldName}}:</div>
                    <div class="teacherinfo">{{currency}}{{field.salaryFormFieldValue}}</div>
                  </div>
                </div>
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Bonus Amount:</div>
                  <div class="teacherinfo">{{currency}}{{bonusAmount}}</div>
                </div>
              </div>
              <div class="col-lg-6 border p-3">
                <div class="deatilsofteacher bordernew border-bottom">
                  <div class="teachersalutation"><b>Deduction</b></div>
                  <div class="teacherinfo"><b>Amount</b></div>
                </div>
                <div *ngFor="let field of salaryField">
                  <div class="deatilsofteacher" *ngIf="field.deductionType == true">
                    <div class="teachersalutation">{{field.salaryFormFieldName}}:</div>
                    <div class="teacherinfo">{{currency}}{{field.salaryFormFieldValue}}</div>
                  </div>
                </div>
                <div class="deatilsofteacher">
                  <div class="teachersalutation">Deduction Amount:</div>
                  <div class="teacherinfo">{{currency}}{{deductionAmount}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabeleoneforDeatils">
            <div class="row">
              <div class="col-lg-6 border  p-3">
                <div class="deatilsofteacher mb-0">
                  <div class="teachersalutation"><b>Total Earning:</b></div>
                  <div class="teacherinfo"><b>{{currency}}{{totalEarning}}</b></div>
                </div>
        
              </div>
              <div class="col-lg-6 border p-3">
                <div class="deatilsofteacher mb-0">
                  <div class="teachersalutation"><b>Total Deduction:</b></div>
                  <div class="teacherinfo"><b>{{currency}}{{totalDeduction}}</b></div>
                </div>      
              </div>
            </div>
          </div>
          <p class="netpayed"><b>Net pay is {{currency}}</b></p>
          <hr>
          <div class="text-center">
            <p><b>This is system genrated slip</b></p>
  
            <p>Genrated by Creo</p>
            <div>For more info log on to <a href="javascript:void(0)">creo.cms</a></div>
          </div>
        </section>
      </mat-dialog-content>

  </div>

</ng-template>