import {
  Component,
  OnInit,
  TemplateRef,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
//import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SessionStatusDropdownService } from 'src/app/masterServices/sessionStatusDropdownServices/session-status-dropdown.service';
import { AcademicSessionService } from '../content/academics-and-learning/courses-and-subjects/services/academicSessionServices/academic-session.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { FilterModel, Metadata } from '../../common/MasterModel/masterModel';
//import { EnquiryModel } from '../InquiryModel';
//import { ToastrService } from 'ngx-toastr';
//import { EnquiriesService } from '../../common/services/enquiriesServices/enquiries.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from './User';
import { CommonService } from 'src/app/common/core-services/common.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { MasterService } from 'src/app/services/master.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  toggle: boolean = true;
  addAcademiceForm: FormGroup;
  editacademicForm: FormGroup;
  signUpForm: FormGroup;
  hide = true;
  hide1 = true;
  academicSessions: any;
  statusListSelect = [
    { value: '1', viewValue: 'Upcoming' },
    { value: '2', viewValue: 'Progress' },
  ];
  sessionStatus: any;
  showpopupflag: boolean;
  statusID: any;
  status: any;
  roleID: any;
  IsStudentOrPreant: boolean = false;
  IsTeacher: boolean = false;
  change() {
    this.toggle = !this.toggle;
  }
  users: any;
  user: User;
  selected = 0;
  permissions: any = [];
  dashboard: any;
  academic: any;
  orgSetting: any;
  communications: any;
  administration: any;
  orgID: any;
  resetForm: FormGroup;
  userId: any;
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  croppedImage: any;
  //croppedImage: any = '../../../../../../assets/img/admin/org_logo.png';
  organizationType: any;
  bindForm: any;
  siteSubscriptionPlan: any;
  emailSubscriptionPlan: any;
  SMSSubscriptionPlan: any;
  OrgBranches: any;
  branchId = 0;
  menuVisible = false;
  headermenu: boolean = false;
  headermenu3: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private academicSessionService: AcademicSessionService,
    private toaster: ToastrService,
    private sessionStatusDropdownService: SessionStatusDropdownService,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService,
    private router: Router,
    private commonService: CommonService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.orgID = currentUser.orgID;
    this.userId = currentUser.userId;
    if (currentUser != null) {
      this.user = currentUser;
    }
    let branchId = localStorage.getItem('currentBranchId');
    if (branchId) {
      this.selected = Number(branchId);
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('subjects-allocation') || event.url.includes('manage-timetable')
          || event.url.includes('view-timetable?id=') || event.url.includes('manage-student-attendance') ||
          event.url.includes('student-submission-analytics') || event.url.includes('attendance-settings') ||
          event.url.includes('manage-teacher-attendance') || event.url.includes('attendance-submission-analytics')
          || event.url.includes('manage-grade') || event.url.includes('report-cards')
          || event.url.includes('manage-assessment') || event.url.includes('grade-setup')
        ) {
          this.academicSessionID = Number(currentUser.academicSessionID)
          this.isGlobal = true;
        } else {
          this.isGlobal = false;
          this.academicSessionID = Number(0)
        }
      }
    });
  }
  isGlobal: boolean;
  academicSessionID: any
  get confirm_subdomain(): AbstractControl {
    return this.signUpForm.controls['subDomainName'];
  }
  previousStateVal: any = [];
  previousCountryVal: any = [];
  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'state') {
      if (serachTxt != '') {
        this.states = this.previousStateVal.filter((x: any) => x.stateName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.states = this.previousStateVal;
      }
    } else if (type == 'country') {
      if (serachTxt != '') {
        this.countries = this.previousCountryVal.filter((x: any) => x.countryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.countries = this.previousCountryVal;
      }
    }
  }
  megaMenuHandler() {
    alert(1);
    this.menuVisible = true
  }
  onBlurMethodtxt(value: any) {
    this.datas = value.target.value;
    this.checkDomain(value.target.value)
  }

  activityLogsForNotification: any;

  isShown: boolean = false;
  activityLogId: any = [];
  dataids: any;
  getNotificationLogs() {
    sessionStorage.setItem('Loader', '0');
    this.masterService.ActivityLogForNotification().subscribe((res: any) => {

      sessionStorage.setItem('Loader', '1');
      this.activityLogsForNotification = res.responseData;
      this.activityLogsForNotification.forEach((data: any) => {
        if (!data.isShown) {
          this.isShown = true;
        }
        this.activityLogId.push(data.activityLogID);
      })

    });
  }


  updateNotification() {
    this.isShown = false;
    sessionStorage.setItem('Loader', '0');
    this.masterService.updateNotificationIsShown(this.activityLogId).subscribe(res => {
      sessionStorage.setItem('Loader', '1');
      if (res) {
        this.isShown = false;
      }
    })


  }

  subdomainExist: boolean;
  subdomainAvailable: boolean;
  datas: any;
  domainObj: any
  checkDomain(data: any) {
    const regexp = new RegExp('^[a-zA-Z0-9]+$');
    const result = regexp.test(data);
    if (!result) {
      this.signUpForm.patchValue({
        subDomainName: ''
      })
      this.toaster.error("Special characters not allowed")
      return
    }
    this.domainObj = {
      subdomain: data
    }
    this.academicSessionService.CheckSubdomain(this.domainObj).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.confirm_subdomain.setErrors({ mismatchSubdomain: true });
        this.signUpForm.setErrors({ mismatchSubdomain: true });
        this.subdomainExist = true
        this.subdomainAvailable = false;
      } else {
        this.subdomainExist = false
        this.subdomainAvailable = true;
      }
    });
  }
  ngOnInit(): void {

    this.roleID = localStorage.getItem('roleID')
    if (this.roleID == UserRole.Student || this.roleID == UserRole.Parent) {
      this.IsStudentOrPreant = true;
    }
    else if (this.roleID == UserRole.Teacher) {
      this.IsTeacher = true;
    }
    let token = sessionStorage.getItem('AccessToken');
    this.getDateDiff(token);
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions) {
      if (this.permissions.length == 0) {
        this.roleService
          .getPermissionByUserID(this.user.userId)
          .subscribe((res: any) => {
            if (res.status > 0) {
              this.permissionService.addPermissions(res.responseData);
              console.log("PERMISSSION", res.responseData)
              this.permissions = this.permissionService.getAllPermissions();
              this.dashboard = this.permissionService.checkModulePermission('Dashboard')
              this.academic = this.permissionService.checkModulePermission('Academic & Learning')
              this.communications = this.permissionService.checkModulePermission('Communication')
              this.administration = this.permissionService.checkModulePermission('Administration')
              this.orgSetting = true;
            }
          });
      } else {
        this.dashboard = this.permissionService.checkModulePermission('Dashboard')
        this.academic = this.permissionService.checkModulePermission('Academic & Learning')
        this.communications = this.permissionService.checkModulePermission('Communication')
        this.administration = this.permissionService.checkModulePermission('Administration')

      }
    } else {
      this.roleService
        .getPermissionByUserID(this.user.userId)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.permissionService.addPermissions(res.responseData);
            this.permissions = this.permissionService.getAllPermissions();
            this.dashboard = this.permissionService.checkModulePermission('Dashboard')
            this.academic = this.permissionService.checkModulePermission('Academic & Learning')
            this.communications = this.permissionService.checkModulePermission('Communication')
            this.administration = this.permissionService.checkModulePermission('Administration')
            this.orgSetting = true;
          }
        });
    }

    this.showpopupflag = false;

    if (this.data.length != 0) {
      this.showpopupflag = true;
      this.editacademicForm = this.fb.group({
        academicSessionID: [0],
        academicSessionName: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        academicSessionStatusID: ['', Validators.required],
      });
      this.getAcademicsession();
    }

    this.getMasterDropdown();

    this.addAcademiceForm = this.fb.group({
      academicSessionID: [0],
      academicSessionName: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      academicSessionStatusID: ['', Validators.required],
    });

    this.signUpForm = this.fb.group({
      organizationName: ['', Validators.required],
      organizationShortName: ['', Validators.required],
      maximumStudentAllowed: ['', Validators.required],
      organizationType: ['', Validators.required],
      subDomainName: ['', Validators.required],
      // subscriptionID: ['', Validators.required],
      // emailSubscriptionPlanID: ['', Validators.required],
      // smsSubscriptionPlanID: ['', Validators.required],
      address: [''],
      countryID: [''],
      stateID: [''],
      city: [''],
      postCode: ['']
    });

    this.masterDropdown();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.resetForm = this.formBuilder.group({
      //userName: ['', Validators.required],
      currentPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required]
    });
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      console.log("ACADEMIC Session", res.responseData)
      this.academicSessions = res.responseData;
    })
    this.getBranchData();
    //this.pageAPermssion = this.getallpageperssion(compA);
    this.getOrganizationById();
    this.GetUserData();
    this.getNotificationLogs();
    setInterval(() => {
      this.getNotificationLogs();
    }, 15000);

  }
  // private _filter(value: any): any {
  //   throw new Error('Method not implemented.');
  // }
  dayDiff: string = '';
  showWarning: boolean = false;
  expMsg: string = '';
  getDateDiff(token: any) {
    if (token != 'null') {
      let dayCount = 0;
      let jsonToken = JSON.parse(atob(token.split('.')[1]));
      // let subscriptionID = jsonToken.azp;
      let endDate = new Date(String(this.datePipe.transform(jsonToken.acr, 'MM/dd/yyyy'))).getTime();
      let todayDate = new Date(String(this.datePipe.transform(new Date(), 'MM/dd/yyyy'))).getTime();
      let sec = endDate - todayDate;
      dayCount = Number((sec / (1000 * 3600 * 24)) + 1);
      dayCount = dayCount < 0 ? 0 : dayCount;
      this.dayDiff = String(dayCount);
      if (dayCount == 0) {
        this.expMsg = 'Your site subscription plan is expired. Please get subscription plan to keep continue.'
        this.showWarning = true;
      } else if (dayCount < 20) {
        this.expMsg = 'Your site subscription plan will be expired in ' + this.dayDiff + ' day(s). Please get subscription plan to keep continue.'
        this.showWarning = true;
      } else {
        this.showWarning = false;
      }
    }
  }
  countries: any;

  states: any;
  state: any = [];
  GetStateList(countryId: any) {
    this.signUpForm.controls['stateID'].setValue('');
    let countryID = countryId.option.value;
    this.masterService.GetStateDropdown(countryID).subscribe((res: any) => {
      this.states = res.responseData;
      this.state = [...this.states];
      this.previousStateVal = Object.assign([], this.states);
      console.log(res);
    })
  }
  resetPassword(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.resetForm.reset();
  }
  // getOrganizationById() {

  //   this.masterService.GetOrganizationById(this.orgID).subscribe((res: any) => {
  //     console.log('orgData', res);
  //     this.croppedImage = res.responseData['organizationLogo'];
  //   });
  // }
  // croppedImage: any = '../../../../../../assets/img/admin/org_logo.png';
  CheckLogoStatus: any;
  orgLogoImg: any;
  getOrganizationById() {
    this.masterService.GetOrganizationById(this.orgID).subscribe((res: any) => {
      console.log("orgData", res);

      this.orgLogoImg = res.responseData[0]['organizationLogoImg'];
      let obj = {
        "orgLogoImg": this.orgLogoImg
      }
      localStorage.setItem('orgLogoImg', JSON.stringify(obj))

      this.CheckLogoStatus = res.status;
      if (res.status == 1) {
        console.log("orgData1", res.responseData[0]['organizationLogo']);
        if (res.responseData[0]['organizationLogo'] != "" && res.responseData[0]['organizationLogo'] != null) {
          this.croppedImage = res.responseData[0]['organizationLogo'];
          localStorage.setItem('OrgLogo', this.croppedImage);
        } else {
          this.CheckLogoStatus = 0;
          this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
        }
      }
      else {
        this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
      }
    });

  }

  userProfile: any;
  CheckUserStatus: any;
  GetUserData() {
    this.masterService.GetUserById(this.userId).subscribe((res: any) => {
      this.CheckUserStatus = res.status;
      if (res.status == 1) {
        if (res.responseData['profilePhoto'] != "" && res.responseData['profilePhoto'] != null) {
          this.userProfile = res.responseData['profilePhoto'];

        } else {
          this.CheckUserStatus = 0;
          this.userProfile = '../../../../assets/img/admin/display.jpg';
        }
      }
      else {
        this.userProfile = '../../../../assets/img/admin/display.jpg';
      }
      // if (res.responseData['profilePhoto'] != "" || res.responseData['profilePhoto'] == null || res.responseData['profilePhoto'] == undefined) {
      //   this.userProfile = res.responseData['profilePhoto']
      // }
      // else {

      //   this.userProfile = '../../../../assets/img/admin/display.jpg';

      // }

    });

  }

  selectStatus(event: any) {
    this.statusID = event.value;
    console.log('event.value', event.value);
  }

  checkModulePermission(moduleName: any) {
    //return this.permissions.find((x: any) => x.subModuleName == subModuleName);
    return this.permissionService.checkModulePermission(moduleName)
  }

  checkSubModulePermission(subModuleName: any) {
    //return this.permissions.find((x: any) => x.subModuleName == subModuleName);
    return this.permissionService.checkSubModulePermission(subModuleName)
  }

  checkScreenPermission(screen: any) {

    //return this.permissions.find((x: any) => x.screenName == screen);
    return this.permissionService.checkScreenPermission(screen)
  }



  getAcademicsession() {
    this.academicSessionService
      .GetSubscriptionPlanById(this.data.id)
      .subscribe((res: any) => {
        this.editacademicForm.patchValue({
          academicSessionID: res.responseData.academicSessionID,
          academicSessionName: res.responseData.academicSessionName,
          startDate: res.responseData.startDate,
          endDate: res.responseData.endDate,
          academicSessionStatusID: res.responseData.academicSessionStatusID,
        });
      });
  }

  oneditSubmit() {
    if (this.editacademicForm.invalid) {
      return;
    }
    var startDate = new Date(moment(this.editacademicForm.value.startDate).add(6, 'h').format());
    var endDate = new Date(moment(this.editacademicForm.value.endDate).add(6, 'h').format());
    this.editacademicForm.patchValue({
      startDate: startDate,
      endDate: endDate
    })

    console.log(
      'academicSessionStatusID',
      this.editacademicForm.value
    );
    if (startDate < endDate) {
      this.academicSessionService
        .UpdateAcademicSession(this.editacademicForm.value)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.editacademicForm.reset();
            this.onEdit.emit();
          } else {
            this.toaster.error(res.message);
          }
        });
    } else {
      this.toaster.error('End date is greater than to the start date');
    }
  }

  // resetPassword(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  //   this.resetForm.reset();
  // }
  adminPasswordObj: {}
  savePassword() {
    if (this.resetForm.invalid) {
      return;
    }
    this.adminPasswordObj = {
      "userID": this.userId,
      "currentPassword": this.resetForm.value.currentPassword,
      "password": this.resetForm.value.password,
      "iSSendEmail": true,
    }
    console.log("ONSAVEPASSWORD", this.adminPasswordObj)
    this.masterService.ChangePassword(this.adminPasswordObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.resetForm.reset();
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
      this.resetForm.setErrors({ mismatch: true });
    }
  }

  // getting the form control elements
  get password(): AbstractControl {
    return this.resetForm.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.resetForm.controls['confirmPassword'];
  }

  onSubmit() {
    if (this.addAcademiceForm.invalid) {
      return;
    }
    var startDate = new Date(moment(this.addAcademiceForm.value.startDate).add(6, 'h').format());
    var endDate = new Date(moment(this.addAcademiceForm.value.endDate).add(6, 'h').format());
    this.addAcademiceForm.patchValue({
      startDate: startDate,
      endDate: endDate
    })

    console.log(
      'academicSessionStatusID',
      this.addAcademiceForm.value
    );
    if (startDate < endDate) {
      this.academicSessionService
        .AddAcademicSession(this.addAcademiceForm.value)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.addAcademiceForm.reset();
            this.dialog.closeAll();
            window.location.reload();
          } else {
            this.toaster.error(res.message);
          }
        });
    } else {
      this.toaster.error('End date is greater than to the start date');
    }
  }

  getMasterDropdown() {
    this.sessionStatusDropdownService
      .AcademicSessionStatusDropdown()
      .subscribe((res: any) => {
        this.sessionStatus = res.responseData;
        if (!this.data.id) {
          this.status = this.sessionStatus.splice(-1);
        }
        //this.status = this.sessionStatus.slice(0, -1)
        console.log('this.status', this.status);
        // this.status = this.sessionStatus.map((x:any) => x.academicSessionStatusID).slice(0,this.status.length-1);
        // console.log("this.status", this.status);
      });
  }

  loginDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  reset() {
    this.addAcademiceForm.reset();
    this.signUpForm.reset();
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ThemeID');
    localStorage.removeItem('rolePermissions');
    localStorage.removeItem('instituteDetails');
    sessionStorage.removeItem('AccessToken');
    sessionStorage.removeItem('rolePermissions');
    localStorage.removeItem('isShownSubscription');
    this.router.navigate(['/']);
  }

  Organizationprofile() {
    this.router.navigate(['/admin/academicslearning/profile']);
  }

  Userprofile() {
    this.router.navigate(['/admin/login-user-profile']);
  }

  branchDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  countryName: any = [];
  dispalyFns(countryID: any) {
    this.countryName = this.country.filter((a: any) => a.countryID == countryID);
    return this.countryName[0]?.countryName
  }
  stateName: any = [];
  dispalyStateAuto(stateName: any) {
    this.stateName = this.state.filter((a: any) => a.stateID == stateName)
    return this.stateName[0]?.stateName;
  }
  country: any
  masterDropdown() {
    this.sessionStatusDropdownService
      .getOrganizationType()
      .subscribe((res: any) => {
        this.organizationType = res.responseData;
      });

    this.sessionStatusDropdownService
      .GetSiteSubscriptionPlanDropdown()
      .subscribe((res: any) => {
        this.siteSubscriptionPlan = res.responseData;
      });

    this.sessionStatusDropdownService
      .GetEmailSubscriptionPlanDropdown()
      .subscribe((res: any) => {
        this.emailSubscriptionPlan = res.responseData;
        console.log('this.emailSubscriptionPlan', this.emailSubscriptionPlan);
      });

    this.sessionStatusDropdownService
      .GetSMSSubscriptionPlanDropdown()
      .subscribe((res: any) => {
        this.SMSSubscriptionPlan = res.responseData;
      });

    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      this.country = [...this.countries];
      this.previousCountryVal = Object.assign([], this.countries);
      console.log("countries", this.countries);
    })
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphaNumber(event: any) {
    this.commonService.checkSubDomain(event);
  }
  registerOrgApiCall() {
    if (this.signUpForm.invalid) {
      return;
    }

    this.bindForm = {
      organizationID: this.user.orgID,
      organizationName: this.signUpForm.controls.organizationName.value,
      organizationShortName:
        this.signUpForm.controls.organizationShortName.value,
      subDomainName: this.signUpForm.controls.subDomainName.value,
      organizationType: this.signUpForm.controls.organizationType.value,
      maximumStudentAllowed:
        this.signUpForm.controls.maximumStudentAllowed.value,
      // connectionStringName: 'DefaultConnection',
      // organizationDatabaseName: 'CMSApplicationDB',
      // subscriptionID: this.signUpForm.controls.subscriptionID.value,
      // subscriptionStartDate: '2021-10-20T18:35:38.167Z',
      // subscriptionEndDate: '2021-10-20T18:35:38.167Z',
      // emailSubscriptionPlanID:
      //   this.signUpForm.controls.emailSubscriptionPlanID.value,
      // smsSubscriptionPlanID:
      //   this.signUpForm.controls.smsSubscriptionPlanID.value,
      address: this.signUpForm.controls.address.value,
      countryID: this.signUpForm.controls.countryID.value,
      stateID: this.signUpForm.controls.stateID.value,
      city: this.signUpForm.controls.city.value,
      postCode: this.signUpForm.controls.postCode.value
    };

    this.academicSessionService
      .postBranchData(this.bindForm)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success('Branch Created Successfully', 'Success');
          this.signUpForm.reset();
          this.getBranchData();
        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
  }

  getBranchData() {
    this.sessionStatusDropdownService.GetOrganizationDropdown(this.user.username).subscribe((res: any) => {
      this.OrgBranches = res.responseData;
    });
  }

  changeBranches(event: any) {
    if (event.value > 0) {

      let target = event.source.selected._element.nativeElement;
      const subDomainName = target.getAttribute('sub-domain');
      this.branchId = event.value;
      // localStorage.setItem("currentBranchId", event.value);

      let { host, pathname, protocol } = new URL(this.document.location.href);
      const nodes = host.toLowerCase().replace('www.', '').split('.');
      let redirectURL: string = '';
      if (nodes.length < 3) {
        // redirectURL = protocol + '//' + subDomainName + '.' + host + pathname;
        redirectURL = 'https://' + subDomainName + '.creocms.com.au';
      }
      else {
        // redirectURL = protocol + '//' + host.replace(nodes[0], subDomainName) + pathname;
        redirectURL = 'https://' + subDomainName + '.creocms.com.au';
      }

      localStorage.removeItem('currentUser');
      localStorage.removeItem('ThemeID');
      localStorage.removeItem('rolePermissions');
      localStorage.removeItem('instituteDetails');
      sessionStorage.removeItem('AccessToken');
      sessionStorage.removeItem('rolePermissions');
      localStorage.removeItem('isShownSubscription');
      this.document.location.href = redirectURL;

      this.toaster.success("Branch switch Successfully", 'Success');
    }
  }
  changeSession(event: any) {
    this.users = localStorage.getItem('currentUser')
    const currentuser = JSON.parse(this.users);
    currentuser.academicSessionID = event.value;
    this.academicSessionService.AcademicSessionValueChange(event.value);
    localStorage.setItem("currentUser", JSON.stringify(currentuser))
    window.location.reload();
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  clickEvent() {
    this.headermenu = true;
  }
  clickEvent1() {
    this.headermenu = false;
  }

  clickEvent2() {
    this.headermenu3 = true;
  }
  clickEvent3() {
    this.headermenu3 = false;
  }
  clickEvent4() {
    this.headermenu3 = false;
  }

  hideOtherMenu() {
    this.headermenu3 = false;
    this.headermenu = false;
  }

}
