import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute } from '@angular/router';
import { ClassService } from '../../../courses-and-subjects/services/classServices/class.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { SubjectService } from '../../../courses-and-subjects/services/subjectServices/subject.service';
import { ChangeDetectionStrategy, ViewChild, TemplateRef, ViewEncapsulation, } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { ClassTimeTableService } from 'src/app/services/class-time-table.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonService } from 'src/app/common/core-services/common.service';
import { map, startWith } from 'rxjs/operators';
import { TeacherService } from 'src/app/services/teacher.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { UserRole } from 'src/environments/environment';

@Component({
  selector: 'app-teachersclass-schedule',
  templateUrl: './teachersclass-schedule.component.html',
  styleUrls: ['./teachersclass-schedule.component.css']
})
export class TeachersclassScheduleComponent implements OnInit {
  academicSessions: any;
  classsectionList: any;
  form: FormGroup;
  teacherID: any[] = [];
  monShedule: any[] = [];
  tueShedule: any[] = [];
  wedShedule: any[] = [];
  thuseShedule: any[] = [];
  friShedule: any[] = [];
  satShedule: any[] = [];
  sunShedule: any[] = [];
  classListDropdown: any;
  teacherList: any;
  studentName: string;
  isTeacherUser: boolean = false;
  constructor(
    private classtimeTableService: ClassTimeTableService,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private classService: ClassService,
    private toaster: ToastrService,
    private studentService: StudentService,
    private teacherService: TeacherService,
    private teachersService: TeachersService,
    private activatedRoute: ActivatedRoute,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private subjectService: SubjectService,
  ) { }

  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID');
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id == undefined ? null : params.id;
    });
    this.form = this.formBuilder.group({
      sessionID: [],

    })
    this.getMasterDropdown();
  }
  view: CalendarView = CalendarView.Week;
  activeDayIsOpen: boolean = true;
  CalendarView = CalendarView;

  viewDate: Date = new Date();

  AccdemicSessionID: any;
  // selectClassSectionByID(event: any) {
  //   this.AccdemicSessionID = event.value
  //   this.classService.GetClassSectionDropdownByAcademicSessionID(this.studentID, this.AccdemicSessionID).subscribe((res: any) => {
  //     this.classsectionList = res.responseData;
  //     this.AccdemicSessionID=0;
  //   })
  // }
  sectionListDropdown: any;
  subjectList: any;
  getMasterDropdown() {
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
      this.studentName = res.responseData[0].studentName;
      console.log("name", this.academicSessions);

    });

    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
      console.log("INSIDE CLASS", this.classListDropdown)
    });
    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
      console.log("this.sectionListDropdown", this.sectionListDropdown);

    });
    this.subjectService.AllSubject().subscribe((res: any) => {
      this.subjectList = res.responseData;
      console.log("all subject", this.subjectList)
    })
    this.studentService.GetTeacherDropdown().subscribe((res: any) => {
      this.teacherList = res.responseData;
      console.log("all teacher", this.teacherList)
    })

    this.masterService.getAllDays().subscribe((res: any) => {
      this.dayList = res.responseData;
      console.log("all Days", this.dayList)
    })

    this.GetTeacherById();
 
    
  }

  // teacher by new core field
  getFieldAndGroupDto: any;
  firstName: string;
  lastName: string;
  fullName: string;
  GetTeacherById() {
    this.teachersService.getDynamicTeacherById(this.teacherID).subscribe((res: any) => {
      console.log("TEACHERDATA", res.responseData)

      let teacherdetail = res.responseData.coreTeacherDetails;
      this.getFieldAndGroupDto = teacherdetail.groups;
      console.warn("bycore", this.getFieldAndGroupDto)
      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if (f.fieldName == 'First Name') {
            this.fullName = '';
            this.fullName += f.fieldValue;
            this.fullName += ' '
          }
          if (f.fieldName == 'Last Name') {
            this.fullName += f.fieldValue;
          }
        })
      })
      console.warn('fullname', this.fullName);
    })
  }

  //end of core teacher table
  onSelect(data: any) {
    console.log("SLELELE", data)
  }
  apiObj: any
  NoFoundsFlag: boolean;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  className: string;
  teacherName: string;
  subjectName: string;
  sheduleList: any;
  dayList: any;
  startDate: any;
  endDate: any;
  async onSubmit() {
    ////debugger;
    this.monShedule = [];
    this.tueShedule = [];
    this.wedShedule = [];
    this.thuseShedule = [];
    this.friShedule = [];
    this.satShedule = [];
    // if(!this.form.value.sessionID){
    //   this.toaster.error('please select acedemic session and class-setion');
    //   return;
    // }
    console.log("this.form.value", this.form.value);
    this.NoFoundsFlag = true;
    this.apiObj = {
      "teacherID": this.teacherID,
      "academicSessionID": 1//this.form.value.sessionID,
      // "studentID": 4,
      // "academicSessionID": 1,
      // "classID": 3,
      // "sectionID": 1
    }
    console.log("onsubmit", this.apiObj);
    this.teacherService.getTeacherClassSchedule(this.apiObj).subscribe(async (res: any) => {
      console.log('res===============', res.responseData);
      if (res.responseData.length == 0) {
        this.toaster.error('no record found');
        return;
      }
      this.events = [];
      this.sheduleList = res.responseData;
      for (var i = 0; i < res.responseData.length; i++) {
        if (res.responseData[i].isRecurring === true) {
          let data = await this.classtimeTableService.gettTimetableDataByID(res.responseData[i].timeTableCategoryID).then((dat: any) => {
            return dat;
          })
          this.startDate = new Date(data.responseData.startDate);
          this.endDate = new Date(data.responseData.endDate);
          console.log('Day master', res.responseData[i].dayMasterID)
          var arrList = this.getDateArray(this.getDayData(res.responseData[i].dayMasterID))
          console.log("ARRAYLIST", arrList)
          for (var j = 0; j < arrList.length; j++) {
            this.events.push({
              start: new Date(this.getStartDate(arrList[j], res.responseData[i].startTime)),
              end: new Date(this.getStartDate(arrList[j], res.responseData[i].endTime)),
              title: res.responseData[i].title + "<br>" + this.filterSubject(res.responseData[i].subjectID) + "(" + this.filterClass(res.responseData[i].classID) + ")" + "<br>" + this.fullName,
              color: {
                primary: res.responseData[i].color,
                secondary: res.responseData[i].color,
              },
              //actions: this.actions,
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: true,
              id: res.responseData[i].classTimeTableID
            })
          }
        }

        else {
          let titleTag = '';
          if (res.responseData[i].title) {
            titleTag += res.responseData[i].title + "<br>";
          }
          if (this.filterSubject(res.responseData[i].subjectID)) {
            titleTag += this.filterSubject(res.responseData[i].subjectID);
          }
          if (this.filterClass(res.responseData[i].classID)) {
            titleTag += "(" + this.filterClass(res.responseData[i].classID)
          }
          if (this.filterSection(res.responseData[i].sectionID)) {
            titleTag += "-" + this.filterSection(res.responseData[i].sectionID) + ")" + "<br>";
          }
          if (this.fullName) {
            titleTag += this.fullName
          }

          var date = moment().startOf('week').add(this.getDayData(res.responseData[i].dayMasterID), 'days')
          this.events.push({
            start: new Date(this.getStartDate(date, res.responseData[i].startTime)),
            end: new Date(this.getStartDate(date, res.responseData[i].endTime)),
            title: titleTag,
            // title: res.responseData[i].title + "<br>" + this.filterSubject(res.responseData[i].subjectID) + "(" + this.filterClass(res.responseData[i].classID) + ")" + "<br>" + this.filterTeacher(res.responseData[i].teacherID),
            color: {
              primary: res.responseData[i].color,
              secondary: res.responseData[i].color,
            },
            //actions: this.actions,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: true,
            id: res.responseData[i].classTimeTableID
          })
        }
      }
      this.refresh.next();
      console.log("FINAL EVENT", this.events)

      this.filterShedule(res.responseData);
      this.apiObj = {};
    })
  }
  filterShedule(data: any) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].dayMasterID == 2) {
        this.monShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
      if (data[i].dayMasterID == 3) {
        this.tueShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
      if (data[i].dayMasterID == 4) {
        this.wedShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
        console.log("WED", this.wedShedule)
      }
      if (data[i].dayMasterID == 5) {
        this.thuseShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
      if (data[i].dayMasterID == 6) {
        this.friShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
      if (data[i].dayMasterID == 7) {
        this.satShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
      if (data[i].dayMasterID == 8) {
        this.sunShedule.push({
          title: data[i].title,
          startTime: data[i].startTime,
          endTime: data[i].endTime,
          class: this.filterClass(data[i].classID),
          section: this.filterSection(data[i].sectionID),
          subject: this.filterSubject(data[i].subjectID),
          teacher: this.filterTeacher(data[i].teacherID)
        })
      }
    }
  }
  filterClass(id: any) {
    for (var i = 0; i < this.classListDropdown.length; i++) {
      if (this.classListDropdown[i].classID == id) {
        console.log("ClassName", this.classListDropdown[i].className)
        return this.classListDropdown[i].className
      }

    }
  }
  filterSection(id: any) {
    for (var i = 0; i < this.sectionListDropdown.length; i++) {
      if (this.sectionListDropdown[i].sectionID == id) {
        return this.sectionListDropdown[i].sectionName
      }

    }
  }
  filterSubject(id: any) {
    for (var i = 0; i < this.subjectList.length; i++) {
      if (this.subjectList[i].subjectID == id) {
        return this.subjectList[i].subjectName
      }
    }
  }
  filterTeacher(id: any) {
    console.log("IDD TEACHER", id)
    for (var i = 0; i < this.teacherList.length; i++) {
      console.log("TeacherID", this.teacherList.teacherID)
      if (id == this.teacherList[i].teacherID) {
        console.log("TecherList", this.teacherList[i].teacherName)
        return this.teacherList[i].teacherName
      }

    }
  }



  getDayData(id: any) {
    var day
    for (var i = 0; i < this.dayList.length; i++) {
      if (id == this.dayList[i].dayMasterID) {
        console.log("Getedata", this.dayList[i].dayName)
        if (this.dayList[i].dayName == "Monday") {
          day = 1
        }
        if (this.dayList[i].dayName == "Tuesday") {
          day = 2
        }
        if (this.dayList[i].dayName == "Wednesday") {
          day = 3
        }
        if (this.dayList[i].dayName == "Thursday") {
          day = 4
        }
        if (this.dayList[i].dayName == "Friday") {
          day = 5
        }
        if (this.dayList[i].dayName == "Saturday") {
          day = 6
        }
        if (this.dayList[i].dayName == "Sunday") {
          day = 0
        }
      }
    }
    return day
  }

  getStartDate(startDate: any, startTime: any) {
    const date = moment(startDate).format('YYYY/MM/DD');
    const time = moment(startTime, ["h:mm A"]).format("HH:mm");
    console.log("DATE AND TIME", date, time)
    const completeDate = moment(date + ' ' + time).toString();
    console.log("COMPLETE DATE", completeDate)
    return completeDate
  }
  // waitForAPi=false;
  //    getStarEndDateCategory(categoryID:any){
  //     // ////debugger;
  //     this.classtimeTableService.getTimetableDataByID(categoryID).subscribe((res: any) => {
  //        console.log('startDate and End Date from API=============================', res.responseData);
  //        if(res){
  //         this.startDate = new Date(res.responseData.startDate);
  //         this.endDate = new Date(res.responseData.endDate);
  //         return true;
  //        }
  //        else {
  //         return false;
  //        }

  //      })
  //   }

  getDateArray(day: any) {
    ////debugger;
    console.log("Dayyyyy", day)
    var start = moment(this.startDate).subtract(7, 'day')
    var end = moment(this.endDate)
    //var day = 1;
    var result = [];
    var current = start.clone();
    while (current.day(7 + day).isBefore(end)) {
      result.push(current.clone());
    }
    return result.map(m => m.format())
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  setView(view: CalendarView) {
    this.view = view;
  }

}
