<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>{{firstName}} {{lastName}}</h2>
            <!-- <h2>Virat Singh</h2> -->
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" *ngIf="IsStudentOrPreant!=true"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <!-- <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-academic">Academicc &amp;
                            Learning</a></li> -->
                    <li class="breadcrumb-item"><a
                            routerLink="/admin/academicslearning/manage-students">Manage Students</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">View Profile</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
            </div>
          </div>

    </div>




    <div class="cardWidget">
        <!-- <div class="cardTitle centerVTItems justify-content-between">
            <h3>{{firstName}} {{lastName}}</h3>
        </div> -->
        <app-profile-tabs></app-profile-tabs>
        <div class="cardBody">
            <div class="profileDetailsWidget pt-4 d-md-flex">
                <div class="profilePicLeft form-group30">
                    <p class="chngePic">
                        <img [src]="croppedImage" alt="" />
                    </p>
                    <!-- <h3 class="mb-2">{{firstName}} {{lastName}}</h3> -->
                    <!-- <h3 class="usernamename mb-2">Virat Kumar</h3> -->
                    <div class="feesSection mt-3">
                        <h3>Fees</h3>
                        <div class="row-flex">
                            <div class="col m6">
                                <div class="feesPaid feeCol">
                                    <h4>{{paidAmount}}</h4>
                                    <p>Fees Paid</p>
                                </div>
                            </div>
                            <div class="col m6 borderLeft">
                                <div class="feesDue feeCol">
                                    <h4>{{unPaidAmount}}</h4>
                                    <p>Fees Due</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profileContentRight flex-grow-1">
                    <div *ngFor="let grp of getFieldAndGroupDto" class="proSection">
                        <div *ngIf="grp.groupName != 'ENROLLMENT'">
                        <h5>{{grp.groupName}}</h5>
                        <div class="row">
                            <div *ngFor="let f of grp.fields" class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>{{f.fieldName}} :</label>
                                    <span *ngIf="f.isDate == false">{{f.fieldValue}}</span>
                                    <span *ngIf="f.isDate == true" class="textareaValues">{{f.fieldValue | date: dateFormat}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!-- <div class="proSection">
                        <h5>Enrollment</h5>
                        <div class="row">
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>AcademicSession Name :</label>
                                    <span>{{academicSessionName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Class Name :</label>
                                    <span>{{className}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Section Name:</label>
                                    <span>{{sectionName}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Student Details</h5>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>First Name :</label>
                                    <span>{{firstName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Last Name :</label>
                                    <span>{{lastName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Admission Number:</label>
                                    <span>{{admissionNumber}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 c s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Email :</label>
                                    <span>{{email}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Date of Birth : </label>
                                    <span>{{dateOfBirth}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Gender :</label>
                                    <span>{{genderName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Blood Group :</label>
                                    <span>{{bloodGroupName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Student Contact :</label>
                                    <span>{{contactNumber}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Skills & Social Details</h5>
                        <div class="row">


                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Profile Summary :</label>
                                    <span>{{profileSummary}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Skills:</label>
                                    <span>{{skill}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>LinkedIn Link :</label>
                                    <span>{{linkedInlink}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Facebook Link :</label>
                                    <span>{{facebookLink}}</span>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Additional Details</h5>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Address :</label>
                                    <span>{{address}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Zip Code :</label>
                                    <span>{{zipCode}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Country :</label>
                                    <span>{{countryName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>State :</label>
                                    <span>{{stateName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>City :</label>
                                    <span>{{city}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Father Name :</label>
                                    <span>{{fatherName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Mother Name :</label>
                                    <span>{{motherName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Parent Contact:</label>
                                    <span>{{parentContact}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Parent Email:</label>
                                    <span>{{parentsEmail}}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>