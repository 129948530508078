import { Component, ViewChild, TemplateRef, AfterViewInit, ElementRef, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn } from '@angular/forms';
import { FilterModel, Metadata, StudentMata, DynamicStudentMeta } from 'src/app/common/MasterModel/masterModel';
import { UserModel } from './UserModel';
import { StudentService } from 'src/app/services/student.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { MasterService } from 'src/app/services/master.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { StudentEnrollmentStatusService } from 'src/app/masterServices/enrollmentStatusServices/student-enrollment-status.service';
import { Item, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, listConversionFilters } from '@syncfusion/ej2-angular-richtexteditor';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectEnrollmentStatusDropdownService } from 'src/app/masterServices/subjectenrollmentStatusIDropdown/subject-enrollment-status-dropdown.service';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { X } from '@angular/cdk/keycodes';
import { UserRole } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
//import { debug } from 'console';
//import { debug } from 'console';
export interface PeriodicElement {
  srno: string;
  titletext: string;
  descriptiontext: string;
  attachmenttext: string;
}
export interface AcademicSession {
  sessionID: string
  sessionName: string;
}
export interface Class {
  classID: string
  className: string;
}
export interface Section {
  sectionID: string
  sectionName: string;
}
export interface Subject {
  subjectID: string
  subjectName: string;
  isCore: boolean;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { srno: '1', titletext: 'Hydrogen', descriptiontext: "description", attachmenttext: 'H' },
];

@Component({
  selector: 'app-manage-students',
  templateUrl: './manage-students.component.html',
  styleUrls: ['./manage-students.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
  encapsulation: ViewEncapsulation.None,
})
export class ManageStudentsComponent implements AfterViewInit, OnInit {
  attachmentColumns: string[] = [
    'srno',
    'titletext',
    'descriptiontext',
    'attachmenttext',
  ];
  dataSource: any;
  @Output() onSave = new EventEmitter();
  @ViewChild('loginPopup', { read: TemplateRef })
  updateCreditialTemplate: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  stateCtrl = new FormControl();
  //sort!: MatSort;
  requestpages: any;
  form: FormGroup;
  columnForm: FormGroup;
  exportForm: FormGroup;
  importForm: FormGroup;
  exportField: any[] = [];
  metaData: any;
  private filterModel: FilterModel;
  private studentMata: StudentMata;
  private dybamicstudentMeta: DynamicStudentMeta;
  usersData: UserModel[];
  classToggled = false;
  selectedRow: any[];
  selectedData: any;
  selectedStudentTooltip: string = 'Select atleast one alumini';
  printFlag: boolean;
  updateCreditialForm: FormGroup;
  studentEnrollmentForm: FormGroup;
  studentEmailForm: FormGroup;
  subjectEnrollmentForm: FormGroup;
  studentSmsForm: FormGroup;
  dynamicFilterform: FormGroup;
  dynamicFilterboolean: boolean = false;
  passMatch: boolean;
  classListDropdown: any;
  sectionListDropdown: any;
  academicSessions: any;
  enrollClassList: any;
  studentEnrollmentStatus: any;
  activeItem: string = '';
  activeSubItem: string = '';
  studentEnrollObj: {};
  subjectEnrollObj: {};
  ElectiveSubjectEnrollObj: {};
  studentID: any;
  studentMultipleData: any;
  SubjectListDropdown: any;
  updateTextInPopUp: boolean;
  updateStudentEnrollmentForm: FormGroup;
  UpdateSubjectEnrollmentStatusForm: FormGroup;
  filterForm: FormGroup;
  subjectEnrollmentStatusDropdown: any;
  excelExportStudentList: any[];
  filterfielsdId: string = '';
  filterfieldsValue: string = '';

  getFilterStudentData: any = [];
  getNewFilterStudentData: any = [];
  getclassSectionModel: any;
  getColumnAndData: any;
  roleID: any;
  IsStudentOrParentUser: boolean  = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('textEditorValue')
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'NumberFormatList',
      'BulletFormatList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'SourceCode',
      '|',
      'Undo',
      'Redo',
    ],
  };
  displayedColumnsList: string[] = [];
  columnsToDisplay: any;
  hideshowGridColumnList: any[] = [];
  displayedColumns: Array<any> = [
    { displayName: 'select', key: 'select', class: '', width: '5%' },
    {
      displayName: 'Profile Picture',
      key: 'profilePhoto',
      isSort: false,
      class: '',
      width: '5%',
    },
    {
      displayName: 'First Name',
      key: 'firstName',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Last Name',
      key: 'lastName',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Email',
      key: 'email',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Class',
      key: 'className',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Section',
      key: 'section',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Students Enrollment Status',
      key: 'studentEnrollmentStatus',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Subjects Enrollment Status',
      key: 'subjectEnrollmentStatus',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Phone Number',
      key: 'contactNumber',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Admission Number',
      key: 'admissionNumber',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Skills',
      key: 'studentSkillID',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'LinkedIn Link',
      key: 'linkedInLink',
      isSort: false,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Facebook Link',
      key: 'facebookLink',
      isSort: false,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Parent Email',
      key: 'parentEmail',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Date of Birth',
      key: 'dateOfBirth',
      isSort: true,
      class: '',
      width: '10%',
    },
    {
      displayName: 'Profile Summary',
      key: 'profileSummary',
      isSort: true,
      class: '',
      width: '5%',
    },
    {
      displayName: 'Zipcode',
      key: 'zipcode',
      isSort: true,
      class: '',
      width: '5%',
    },
    { displayName: 'State', key: 'stateName', class: '', width: '5%' },
    { displayName: 'Country', key: 'countryName', class: '', width: '5%' },
    {
      displayName: 'Blood Group',
      key: 'bloodGroupName',
      class: '',
      width: '5%',
    },
    {
      displayName: 'Father Name',
      key: 'fatherName',
      isSort: true,
      class: '',
      width: '5%',
    },
    { displayName: 'Mother Name', key: 'motherName', class: '', width: '5%' },
    {
      displayName: 'Parent Contact',
      key: 'parentContact',
      class: '',
      width: '5%',
    },
    { displayName: 'Gender', key: 'genderName', class: '', width: '5%' },
    { displayName: 'Address', key: 'address', class: '', width: '5%' },
    { displayName: 'Action', key: 'Actions', class: '', width: '10%' },
  ];
  changeColumnsToDisplay: any;
  checkedAll: boolean = false;
  permissions: any = [];
  users: any;
  userName: any;
  url: any;
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Attachment', key: 'Attachment', class: 'fas fa-paperclip' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Reset', key: 'reset', class: 'fas fa-lock' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  orgID: any;
  constructor(
    private dialog: MatDialog,
    private studenService: StudentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private masterService: MasterService,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private studentEnrollmentStatusService: StudentEnrollmentStatusService,
    private activatedRoute: ActivatedRoute,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private subjectEnrollmentStatus: SubjectEnrollmentStatusDropdownService,
    public datepipe: DatePipe,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private datePipe: DatePipe
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.orgID = currentUser.orgID;
    if (currentUser != null) {
      this.userName = currentUser;
    }
    this.filterModel = new FilterModel();
    this.studentMata = new StudentMata();
    this.dybamicstudentMeta = new DynamicStudentMeta();
    this.usersData = new Array<UserModel>();
    this.dynamicFilterform = new FormGroup({});
    this.updateCreditialForm = this.formBuilder.group(
      {
        userName: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        logoutFromAll: [false],
        sendUpdateNotification: [false],
        studentId: [''],
      },
      { validators: this.passwordMatcher }
    );

    const parsedUrl = new URL(window.location.href);
    this.url = parsedUrl.origin;
  }

  async ngOnInit()  {
    var roleID = localStorage.getItem('roleID')
    if(roleID == UserRole.Student || roleID == UserRole.Parent){
      this.IsStudentOrParentUser = true;
    }
    this.permissions = this.permissionService.getAllPermissions();
    
    
    if (this.permissions == null  || this.permissions.length == 0) {
        this.roleService
        .getPermissionByUserID(this.userName.userId)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.permissionService.addPermissions(res.responseData);
            this.permissions = this.permissionService.getAllPermissions();
          }
        });
    }
 
  this.permissionService.checkComponentHasScreenPermission('Manage Students');
    this.ActivityLogTrack();
    this.GetStudentEnrollmentStatusID();
    this.GetSubjectDropdown();
    this.GetSubjectEnrollmentStatus();
    //this.ElectiveSubjectdropdown(this.SectionID);
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    this.userId = currentUser.userId;
    this.userEmail = currentUser.email;
    this.passMatch = false;
    this.selectedData = 0;
    this.metaData = new Metadata();
    this.selectedRow = [];
    this.form = this.formBuilder.group({
      profilePhoto: [''],
      firstName: [''],
      lastName: [''],
      admissionNumber: [''],
      contactNumber: [''],
      email: [''],
      studentSkillID: [''],
      linkedInLink: [''],
      facebookLink: [''],
    });
    this.columnForm = this.formBuilder.group({
      profilePhoto: true,
      firstName: true,
      lastName: true,
      middleName: null,
      grade: null,
      section: null,
      class: null,
      studentEnrollmentStatus: null,
      subjectEnrollmentStatus: null,
      admissionNo: true,
      email: true,
      phoneNumber: true,
      userId: '',
      studentEmail: false,
      dateOfBirth: false,
      studentContact: false,
      gender: false,
      bloodGroup: false,
      profileSummary: false,
      studentSkill: false,
      facebookProfile: false,
      linkedInProfile: false,
      address: false,
      zipcode: false,
      state: false,
      country: false,
      fatherName: false,
      motherName: false,
      parentContact: false,
      parentEmail: false,
      applicationId: false,
      enrollment: false,
    });
    this.studentEnrollmentForm = this.formBuilder.group({
      studentEnrollmentStatusID: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      academicSessionID: ['', Validators.required],
    });
    this.updateStudentEnrollmentForm = this.formBuilder.group({
      studentEnrollmentStatusID: ['', Validators.required],
    });
    this.subjectEnrollmentForm = this.formBuilder.group({
      subjectID: ['', Validators.required],
      classID: [''],
      sectionID: [''],
      academicSessionID: [''],
    });

    this.UpdateSubjectEnrollmentStatusForm = this.formBuilder.group({
      subjectEnrollmentStatusID: ['', Validators.required],
      // classID: ['', Validators.required],
      // sectionID: ['', Validators.required],
      // //subjectID: ['', Validators.required],
      // academicSessionID: ['', Validators.required],
      // subjectID: ['', Validators.required],
    });
    this.filterForm = this.formBuilder.group({
      subjectID: [''],
      classID: [''],
      sectionID: [''],
      academicSessionID: [''],
    });
    this.sectionfilteredOptions =
      this.filterForm.controls.sectionID.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.sectionName)),
        map((sectionName) =>
          sectionName
            ? this.Sectionfilter(sectionName)
            : this.sectionList.slice()
        )
      );
    this.subjectfilteredOptions =
      this.filterForm.controls.subjectID.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.subjectName)),
        map((subjectName) =>
          subjectName
            ? this.Subjectfilter(subjectName)
            : this.subjectList.slice()
        )
      );
    this.printFlag = false;
    //this.getUsersList(this.filterModel, '', '');
    this.InitialGridCall();
    this.getMasterDropdown();
    //this.getstudentColumn();
    //this.GetStudentByIdData();
    this.getTableData();
  }

  addFile(): void {
    //console.log("ImportStudent");

    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.studenService.ImportStudents(fileToUpload).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
  }

  activityLog: any;
  ActivityLogTrack() {


    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Students',
        ActivityTypeId: 10,
        Description: 'Students list viewed by',
        ScreenName: 'Manage Students',
        RecordTypeId: 1,
        RecordId: 0,
        Type: 0
      }
    }


    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  toolTipText1: string;
  DisabledPointer1() {
    if (
      this.filterForm.controls.classID.value &&
      this.filterForm.controls.sectionID.value &&
      this.filterForm.controls.academicSessionID.value &&
      this.filterForm.controls.subjectID.value
    ) {
      this.toolTipText1 = '';
      return false;
    } else {
      this.toolTipText1 =
        'You need to select academic session, class, section and subjects in filter to perform this action';
      return true;
    }
  }
  toolTipText: string;
  DisabledPointer() {
    if (
      this.filterForm.controls.classID.value &&
      this.filterForm.controls.sectionID.value &&
      this.filterForm.controls.academicSessionID.value
    ) {
      this.toolTipText = '';
      return false;
    } else {
      this.toolTipText =
        'You need to select academic session, class and section in filter to perform this action';
      return true;
    }
  }
  ngAfterViewInit(): void {
    //  this.dataSource.sort = this.sort;
  }

  checkActionPermission(actionName: any) {
    let screen = 'Manage Students';

    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    ////let permission = this.permissionService.getFilterScreen(screen);

    //return permission.children.find((x: any) => x.actionName == actionName);
    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }

  GetStudentEnrollmentStatusID() {
    this.studentEnrollmentStatusService
      .StudentEnrollmentStatusDropdown()
      .subscribe((res: any) => {
        //console.log("Enrollment Status",res.responseData)
        this.studentEnrollmentStatus = res.responseData;
      });
  }

  GetSubjectEnrollmentStatus() {
    this.subjectEnrollmentStatus
      .getSubjectEnrollmentStatusDropdown()
      .subscribe((res: any) => {
        this.subjectEnrollmentStatusDropdown = res.responseData;
      });
  }
  subjectListDropdown: any;
  GetSubjectDropdown() {
    this.subjectService.AllSubject().subscribe((res: any) => {
      this.subjectListDropdown = res.responseData;
    });
  }

  user: any;
  userId: any;
  hide = true;
  hide1 = true;
  userEmail: any;
  sessionList: AcademicSession[] = [];
  sessionFlag: boolean;
  sessionfilteredOptions: Observable<AcademicSession[]>;
  filteredOptions: Observable<Class[]>;
  sectionfilteredOptions: Observable<Section[]>;
  subjectfilteredOptions: Observable<Subject[]>;
  classFlag: boolean;
  sectionFlag: boolean;
  subjectFlag: boolean;
  classList: Class[] = [];
  sectionList: Section[] = [];
  subjectList: Subject[] = [];
  sessiondisplayFn(sessionData: AcademicSession): string {
    return sessionData && sessionData.sessionName
      ? sessionData.sessionName
      : '';
  }
  displayFn(classData: Class): string {
    return classData && classData.className ? classData.className : '';
  }
  sectiondisplayFn(section: Section): string {
    return section && section.sectionName ? section.sectionName : '';
  }
  subjectdisplayFn(subject: Subject): string {
    return subject && subject.subjectName ? subject.subjectName : '';
  }
  private sessionfilter(name: string): AcademicSession[] {
    const filterValue = name.toLowerCase();
    let data = this.sessionList.filter((option) =>
      option.sessionName.toLowerCase().includes(filterValue)
    );
    if (data.length == 0) {
      this.sessionFlag = true;
    } else {
      this.sessionFlag = false;
    }
    return this.sessionList.filter((option) =>
      option.sessionName.toLowerCase().includes(filterValue)
    );
  }
  private _filter(name: string): Class[] {
    const filterValue = name.toLowerCase();
    let data = this.classList.filter((option) =>
      option.className.toLowerCase().includes(filterValue)
    );
    if (data.length == 0) {
      this.classFlag = true;
    } else {
      this.classFlag = false;
    }
    return this.classList.filter((option) =>
      option.className.toLowerCase().includes(filterValue)
    );
  }
  private Sectionfilter(name: string): Section[] {
    const filterValue = name.toLowerCase();
    let data = this.sectionList.filter((option) =>
      option.sectionName.toLowerCase().includes(filterValue)
    );
    if (data.length == 0) {
      this.sectionFlag = true;
    } else {
      this.sectionFlag = false;
    }
    return this.sectionList.filter((option) =>
      option.sectionName.toLowerCase().includes(filterValue)
    );
  }
  private Subjectfilter(name: string): Subject[] {
    const filterValue = name.toLowerCase();
    let data = this.subjectList.filter((option) =>
      option.subjectName.toLowerCase().includes(filterValue)
    );
    if (data.length == 0) {
      this.subjectFlag = true;
    } else {
      this.subjectFlag = false;
    }
    return this.subjectList.filter((option) =>
      option.subjectName.toLowerCase().includes(filterValue)
    );
  }

  test(id: any) {
    //console.log(id);
  }

  filterSection(event: any) {
    this.sectionService
      .GetSectionByClassId(event.value)
      .subscribe((res: any) => {
        this.sectionListDropdown = res.responseData;
      });
    this.studentMata.classID = event.value;
    //this.getTableData();
  }

  filterSubject(event: any) {
    this.subjectService
      .GetSubjectBySectionId(event.value)
      .subscribe((res: any) => {
        this.SubjectListDropdown = res.responseData;
      });

    this.studentMata.sectionID = event.value;
    this.getTableData();
  }

  filterstudent(event: any) {
    this.studentMata.subjectID = event.value;
    this.getTableData();
  }

  getMasterDropdown() {
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData;
      this.enrollClassList = res.responseData;
      for (var i = 0; i < res.responseData.length; i++) {
        this.classList.push({
          classID: res.responseData[i].classID,
          className: res.responseData[i].className,
        });
      }
      this.filteredOptions = this.filterForm.controls.classID.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.className)),
        map((className) =>
          className ? this._filter(className) : this.classList.slice()
        )
      );
    });

    // this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
    //   this.sectionListDropdown = res.responseData;
    // });
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      //console.log("ACADEMIC Session", res.responseData)
      this.academicSessions = res.responseData;
      for (var i = 0; i < res.responseData.length; i++) {
        this.sessionList.push({
          sessionID: res.responseData[i].academicSessionID,
          sessionName: res.responseData[i].academicSessionName,
        });
        if (res.responseData[i].academicSessionName == "Default Session") {
          this.filterForm.controls['academicSessionID'].setValue({
            sessionID: res.responseData[i].academicSessionID,
            sessionName: res.responseData[i].academicSessionName
          });
          this.dybamicstudentMeta.AcademicSessionID = res.responseData[i].academicSessionID
          this.SessionID = res.responseData[i].academicSessionID,
            this.SessionName = res.responseData[i].academicSessionName
        }

      }
      //this.filterForm.controls.academicSessionID.setValue(1);
      this.sessionfilteredOptions =
        this.filterForm.controls.academicSessionID.valueChanges.pipe(
          startWith(''),
          map((value) =>
            typeof value === 'string' ? value : value.subjectName
          ),
          map((sessionName) =>
            sessionName
              ? this.sessionfilter(sessionName)
              : this.sessionList.slice()
          )
        );
    });
  }
  // SessionName: any;
  // SessionID: any;
  // academicSessionEvent(data: any) {
  //   this.SessionName = data.option.value.sessionName;
  //   this.SessionID = data.option.value.sessionID;
  // }

  ClassName: any;
  ClassID: any;
  ClassFieldId: any;
  filterSectionListByClassId(data: any, fieldName: string) {
    if (data.option !== undefined) {
      this.ClassName = data.option.value.className;
      this.ClassID = data.option.value.classID;
    }

    //  this.ClassFieldId = this.coreFieldList.find(e => e.fieldName === fieldName).fieldID;
    //  if(data.option!==undefined)
    //  this.setFilterValue(this.ClassFieldId,this.ClassID,fieldName);// put value in filter
    //  else if(data.target.value==undefined || data.target.value=='')
    //  this.setFilterValue(this.ClassFieldId,data.target.value,fieldName);//remove value from filter

    if (data.option !== undefined) {
      this.dybamicstudentMeta.ClassID = data.option.value.classID;
      this.dybamicstudentMeta.SectionID = '';
      this.dybamicstudentMeta.SubjectID = '';
      this.filterForm.controls.sectionID.setValue('');
      this.filterForm.controls.subjectID.setValue('');
      this.sectionList = [];
      this.subjectList = [];
      this.sectionService
        .GetSectionByClassId(data.option.value.classID)
        .subscribe((res: any) => {
          if (res.responseData && res.responseData.length > 0) {
            for (var i = 0; i < res.responseData.length; i++) {
              this.sectionList.push({
                sectionID: res.responseData[i].sectionID,
                sectionName: res.responseData[i].sectionName,
              });
            }
          } else {
            this.sectionList = [];
          }
          // this.dybamicstudentMeta.filterFieldIds = this.SessionFieldId + ',' + this.ClassFieldId;
          // this.dybamicstudentMeta.filterFieldValue = this.SessionID + ',' + this.ClassFieldId;
          // this.filterfielsdId = this.dybamicstudentMeta.filterFieldIds;
          // this.filterfieldsValue = this.dybamicstudentMeta.filterFieldValue;

          //  this.studentMata.classID = data.option.value.classID;
          //  this.getUsersList(this.studentMata, '', '');
          this.sectionfilteredOptions =
            this.filterForm.controls.sectionID.valueChanges.pipe(
              startWith(''),
              map((value) =>
                typeof value === 'string' ? value : value.sectionName
              ),
              map((sectionName) =>
                sectionName
                  ? this.Sectionfilter(sectionName)
                  : this.sectionList.slice()
              )
            );
        });
      //console.log("DYnmaicMeta",this.dybamicstudentMeta)
      this.getTableData();
    }
  }

  SectionName: any;
  SectionID: any;
  SectionFieldId: any;
  filterSubjectListBySectionId(data: any, fieldName: string) {
    if (data.option !== undefined) {
      this.ElectiveSubjectdropdown(data.option.value.sectionID);
      this.SectionName = data.option.value.sectionName;
      this.SectionID = data.option.value.sectionID;
    }

    //  this.SectionFieldId = this.coreFieldList.find(e => e.fieldName === fieldName).fieldID;
    //
    //  if(data.option!==undefined)
    //  this.setFilterValue(this.SectionFieldId,this.SectionID,fieldName)
    //  else if(data.target.value==undefined || data.target.value=='')
    //  this.setFilterValue(this.SectionFieldId,data.target.value,fieldName)

    if (data.option !== undefined) {
      this.subjectList = [];
      this.dybamicstudentMeta.SectionID = data.option.value.sectionID;
      this.dybamicstudentMeta.SubjectID = '';
      this.filterForm.controls.subjectID.setValue('');
      this.subjectService
        .GetSubjectBySectionId(data.option.value.sectionID)
        .subscribe((res: any) => {
          //this.subjectList = res.responseData;
          if (res.responseData && res.responseData.length > 0) {
            for (var i = 0; i < res.responseData.length; i++) {
              this.subjectList.push({
                subjectID: res.responseData[i].subjectID,
                subjectName: res.responseData[i].subjectName,
                isCore: res.responseData[i].isCore,
              });
            }
          } else {
            this.subjectList = [];
          }

          // this.studentMata.sectionID = data.option.value.sectionID;
          // this.getUsersList(this.studentMata, '', '');

          this.subjectfilteredOptions =
            this.filterForm.controls.subjectID.valueChanges.pipe(
              startWith(''),
              map((value) =>
                typeof value === 'string' ? value : value.subjectName
              ),
              map((subjectName) =>
                subjectName
                  ? this.Subjectfilter(subjectName)
                  : this.subjectList.slice()
              )
            );
        });
      //console.log("DYnmaicMeta",this.dybamicstudentMeta)
      this.getTableData();
    }
  }
  ElectiveSubjectListDropdown: any;
  electiveSubjectFlag: boolean;
  ElectiveSubjectdropdown(id: any) {
    this.masterService.GetElectiveSubjectDropDown(id).subscribe((res: any) => {
      this.ElectiveSubjectListDropdown = res.responseData;
      if (res.responseData == 0) {
        this.electiveSubjectFlag = true;
      } else {
        this.electiveSubjectFlag = false;
      }
    });
    //console.log("ElectiveSubjectListDropdown", this.SectionID);
  }
  filterElectiveSubject(event: any) {
    this.masterService
      .GetElectiveSubjectDropDown(event.value)
      .subscribe((res: any) => {
        this.SubjectListDropdown = res.responseData;
      });
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(
      changeState.pageNumber,
      this.filterModel.pageSize,
      changeState.sort,
      changeState.order,
      this.filterModel.searchText
    );
    //this.getUsersList(this.filterModel, '', '');
  }

  onSubmit() {
    this.requestpages = {
      pageSize: 10,
      pageNumber: 1,
      //"profilePhoto":this.form.controls.profilePhoto.value,
      firstName: this.form.controls.firstName.value,
      lastName: this.form.controls.lastName.value,
      admissionNumber: this.form.controls.admissionNumber.value,
      contactNumber: this.form.controls.contactNumber.value,
      email: this.form.controls.email.value,
      studentSkillID: this.form.controls.studentSkillID.value,
      // "facebookLink": this.form.controls.facebookLink.value,
      //"linkedInLink": this.form.controls.linkedInLink.value,
      columnName: '',
      sortOrder: '',
    };
    //this.getUsersList(this.requestpages, '', '');
    //this.AdminUserList();
  }

  // onTableActionClick(actionObj?: any) {
  //   const id = actionObj.data && actionObj.data.studentID;
  //   switch ((actionObj.action || '').toUpperCase()) {
  //     case 'EDIT':
  //       this.editCall(id);
  //       break;
  //     case 'RESET':
  //       this.resetCall(id);
  //       break;
  //     case 'DELETE':
  //       {

  //         this.dialogService
  //           .confirm(`Are you sure you want to delete this user?`)
  //           .subscribe((result: any) => {
  //             if (result == true) {
  //               this.DeleteStaff(id);
  //             }
  //           });
  //       }
  //       break;
  //     case 'DETAILS':
  //       this.infoCall(id);
  //       break;
  //     case 'ATTACHMENT':
  //       this.singleattachment(id);
  //       break;
  //     default:
  //       break;
  //   }
  // }
  onselect(data: any) {
    if (data == undefined) {
      this.printFlag = false;
    } else {
      if (data.length != undefined) {
        if (data.length == 0) {
          this.printFlag = false;
          this.selectedData = data.length;
        } else {
          this.selectedRow = data;
          console.log("selected row", this.selectedRow)
          this.selectedData = data.length;
          this.printFlag = true;
        }
      }
    }
    if (this.selectedData > 0) {
      this.selectedStudentTooltip = 'Students selected';
    } else {
      this.selectedStudentTooltip = 'Please select atleast one alumini';
    }
  }

  applyFilter(searchText: string = '') {
    this.setPaginatorModel(
      1,
      this.filterModel.pageSize,
      this.filterModel.sortColumn,
      this.filterModel.sortOrder,
      searchText
    );
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      //this.getUsersList(this.filterModel, '', '');
    }
  }

  // getUsersList(filterModel: any, tags: any, roleIds: any) {
  //   this.studenService.GetStudentList(filterModel).subscribe((res: any) => {
  //     if (res.status > 0) {
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;
  //     } else {
  //       this.metaData = new Metadata();
  //     }
  //   });
  // }

  setPaginatorModel(
    pageNumber: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    searchText: string
  ) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  infoCall(studentId: any) {
    this.router.navigate(
      ['/admin/academicslearning/manage-students/view-profile'],
      { queryParams: { id: studentId } }
    );
  }
  editCall(studentId: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(
      ['/admin/academicslearning/manage-students/add-student'],
      { queryParams: { id: studentId } }
    );
    // "../manage-students/view-profile"
    // routerLink ="../manage-students/add-student"
  }
  printId() {
    this.router.navigate(
      ['/admin/academicslearning/manage-students/manage-id'],
      { queryParams: { data: this.selectedRow } }
    );
  }
  printReport() {
    this.router.navigate(['/admin/academicslearning/report-cards'], {
      queryParams: { data: this.selectedRow },
    });
  }
  singleattachment(studentId: any) {
    this.router.navigate(
      ['/admin/academicslearning/manage-students/add-attachment'],
      { queryParams: { id: studentId } }
    );
  }
  attachment() {
    this.router.navigate(
      ['/admin/academicslearning/manage-students/add-attachment'],
      { queryParams: { data: this.selectedRow } }
    );
  }
  deleteId: any;
  studentIdList: any;
  studentIdList1: any[] = []
  studentData: any;
  studentsid: any;
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef, {});
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.studentIdList1 = [{
      studentsID1: this.studentsid
    }]
    // this.studentIdList1=this.studentIdList.studentsID;
    this.GetStudentByIdList();
  }

  updateStudentEnrollmentStatusByStudentId(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {});
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetStudentByIdList();
    this.updateStudentEnrollmentForm = this.formBuilder.group({
      // studentID: [0],
      // classID: ['', Validators.required],
      // sectionID: ['', Validators.required],
      // academicSessionID: ['', Validators.required],
      studentEnrollmentStatusID: ['', Validators.required],
    });

    this.studenService
      .GetStudentEnrollmentDetailsId(this.studentsid[0])
      .subscribe((res: any) => {
        if (res.status != 1) {
          this.toaster.success(
            'Please enroll student then update enrollment status'
          );
        }
        this.studentEnrollmentForm.patchValue({
          studentID: res.responseData['studentID'],
          academicSessionID: res.responseData['academicSessionID'],
          classID: res.responseData['classID'],
          sectionID: res.responseData['sectionID'],
          studentEnrollmentStatusID:
            res.responseData['studentEnrollmentStatusID'],
        });
      });
  }
  GetStudentByIdList() {
    this.studenService.GetStudentByMultiId(this.studentIdList).subscribe((res: any) => {
      this.studentData = res.responseData;
    });
  }
  resetCall(id: any) {
    var studentData = {
      "studentsID": [id]
    }
    this.dialog.open(this.updateCreditialTemplate);
    this.studenService.GetStudentByIdData(studentData).subscribe((res: any) => {
      this.updateCreditialForm.patchValue({
        userName: res.responseData[0].email,
        studentId: res.responseData[0].studentID,
        logoutFromAll: false,
        sendUpdateNotification: false,
      });
    });
  }

  passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('password');
    const confirmPasswordControl = c.get('confirmPassword');
    if (passwordControl?.value === confirmPasswordControl?.value) {
      return null;
    }

    return { match: true };
  }

  onMatch() {
    this.passMatch = true;
    if (
      this.updateCreditialForm.value.password ===
      this.updateCreditialForm.value.confirmPassword
    ) {
      this.passMatch = false;
    } else {
      this.passMatch = true;
    }
  }

  updateCreditialObj: any;
  updateCreditial() {
    if (this.updateCreditialForm.value.password != this.updateCreditialForm.value.confirmPassword) {
      return;
    }
    //console.log("FINALDATA", this.updateCreditialForm.value)
    this.updateCreditialObj = {
      userID: this.userId,
      password: this.updateCreditialForm.value.password,
      isDevicedLoggedOut: this.updateCreditialForm.value.logoutFromAll,
      isSendEmail: this.updateCreditialForm.value.sendUpdateNotification,
      studentsID: this.updateCreditialForm.value.studentId,
    };
    console.log("UpdateData", this.updateCreditialObj)
    this.studenService
      .updateStudentCreditial(this.updateCreditialObj)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          this.updateCreditialForm.reset();
        } else {
          this.toaster.error(res.message, 'Error');
          this.updateCreditialForm.reset();
        }
      });
  }

  DeleteStudent(id: any) {
    this.StudentData = [];
    this.StudentData = {
      studentIDs: [id],
    };
    this.dialogService
      .confirm(`Are you sure you want to delete this student?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.studenService
            .deletecoreStudent(this.StudentData)
            .subscribe((res: any) => {
              if (res.status > 0) {
                this.toaster.success(res.message, 'Success');
                // window.location.reload();
                this.getTableData();
              } else {
                this.toaster.error(res.message, 'Error');
              }
            });
        }
      });
  }
  DeleteMultipleStudent() {
    this.StudentData = [];
    this.StudentData = {
      studentIDs: this.selectedRow,
    };
    this.dialogService
      .confirm(`Are you sure you want delete this students !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.studenService
            .deletecoreStudent(this.StudentData)
            .subscribe((res: any) => {
              if (res.status > 0) {
                this.toaster.success(res.message);
                // window.location.reload();
                this.getTableData();
              } else {
                this.toaster.error(res.message);
              }
            });
        }
      });
  }
  resetNew() {
    // this.form.reset();
    this.InitialGridCall();
    // this.getUsersList(this.requestpages, '', '')
    this.printFlag = false;
  }

  reset() {
    this.form.reset();
    this.InitialGridCall();
    this.studentEnrollmentForm.reset();
    this.subjectEnrollmentForm.reset();
    //this.getUsersList(this.requestpages, '', '');
    //this.selectedRow = [];
    this.selectedData = 0;
    this.printFlag = false;
  }

  resetEmailD() {
    this.studentEmailForm.reset();
    this.customboxhide = false;
    this.boxhide = true;
  }
  resetSMSForm() {
    this.studentSmsForm.reset();
    this.customboxhideSMS = false;
    this.boxhidesms = true;
  }

  InitialGridCall() {
    this.requestpages = {
      pageSize: 10,
      pageNumber: 1,
      firstName: '',
      lastName: '',
      admissionNumber: '',
      email: '',
      contactNumber: '',
      columnName: '',
      sortOrder: '',
    };
  }

  onSubmitStudentEnrollment() {
    this.studentEnrollObj = {
      studentID: this.studentsid,
      academicSessionID:
        this.studentEnrollmentForm.controls.academicSessionID.value,
      classID: this.studentEnrollmentForm.controls.classID.value,
      sectionID: this.studentEnrollmentForm.controls.sectionID.value,
      studentEnrollmentStatusID:
        this.studentEnrollmentForm.controls.studentEnrollmentStatusID.value,
    };

    this.studenService
      .StudentEnrollment(this.studentEnrollObj)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reloadCurrentRoute();
          this.reset();
        } else {
          this.toaster.error(res.message);
        }
      });
    // if (this.updateTextInPopUp == false) {
    //   this.studentEnrollObj = {
    //     "studentID": this.studentsid,
    //     "academicSessionID": this.studentEnrollmentForm.controls.academicSessionID.value,
    //     "classID": this.studentEnrollmentForm.controls.classID.value,
    //     "sectionID": this.studentEnrollmentForm.controls.sectionID.value,
    //     "studentEnrollmentStatusID": this.studentEnrollmentForm.controls.studentEnrollmentStatusID.value,
    //   }
    //   //console.log("this.studentEnrollObj", this.studentEnrollObj);

    //   this.studenService.StudentEnrollment(this.studentEnrollObj).subscribe((res: any) => {
    //     if (res.status > 0) {
    //       this.toaster.success(res.message);
    //       this.reloadCurrentRoute();
    //       this.reset()
    //     }
    //     else {
    //       this.toaster.error(res.message);
    //     }
    //   });
    // }

    // else {
    //   this.studentEnrollObj = {
    //     "studentID": this.studentsid,
    //     "studentEnrollmentStatusID": this.studentEnrollmentForm.controls.studentEnrollmentStatusID.value,
    //   }

    //   this.studenService.UpdateStudentEnrollmentStatus(this.studentEnrollObj).subscribe((res: any) => {
    //     if (res.status > 0) {
    //       this.toaster.success(res.message);
    //       this.reloadCurrentRoute();
    //       this.reset();
    //     }
    //     else {
    //       this.toaster.error(res.message);
    //     }
    //   })
    // }
  }

  onSubmitUpdateStudentEnrollmentStatus() {
    this.studentEnrollObj = {
      studentID: this.studentsid,
      academicSessionID: this.SessionID,
      classID: this.ClassID,
      sectionID: this.SectionID,
      studentEnrollmentStatusID:
        this.updateStudentEnrollmentForm.controls.studentEnrollmentStatusID
          .value,
    };
    this.studenService
      .UpdateStudentEnrollmentStatus(this.studentEnrollObj)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reloadCurrentRoute();
          this.reset();
        } else {
          this.toaster.error(res.message);
        }
      });
    // else {
    //   this.studentEnrollObj = {
    //     "studentID": this.studentsid,
    //     "studentEnrollmentStatusID": this.studentEnrollmentForm.controls.studentEnrollmentStatusID.value,
    //   }

    //   this.studenService.UpdateStudentEnrollmentStatus(this.studentEnrollObj).subscribe((res: any) => {
    //     if (res.status > 0) {
    //       this.toaster.success(res.message);
    //       this.reloadCurrentRoute();
    //       this.reset();
    //     }
    //     else {
    //       this.toaster.error(res.message);
    //     }
    //   })
    // }
  }

  updateSubjectEnrollmentStatusByStudentId(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {});
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetStudentByIdList();
    this.UpdateSubjectEnrollmentStatusForm = this.formBuilder.group({
      studentID: [0],
      // classID: ['', Validators.required],
      // sectionID: ['', Validators.required],
      // subjectID: ['', Validators.required],
      // academicSessionID: ['', Validators.required],
      subjectEnrollmentStatusID: ['', Validators.required],
    });

    // this.studenService.GetSubjectEnrollmentDetailsId(this.studentsid[0]).subscribe((res: any) => {
    //   //console.log("updateSubjectEnrollment", res.responseData);

    //   this.UpdateSubjectEnrollmentStatusForm.patchValue({
    //     studentID: res.responseData['studentID'],
    //     // academicSessionID: res.responseData['academicSessionID'],
    //     // classID: res.responseData['classID'],
    //     // sectionID: res.responseData['sectionID'],
    //     subjectID: res.responseData['subjectID'],
    //     subjectEnrollmentStatusID: res.responseData['subjectEnrollmentStatusID']
    //   });
    // })
  }

  onSubmitUpdateSubjectEnrollmenttatus() {
    this.subjectEnrollObj = {
      studentID: this.studentsid,
      subjectID: this.SubjectID,
      academicSessionID: this.SessionID,
      classID: this.ClassID,
      sectionID: this.SectionID,
      subjectEnrollmentStatusID:
        this.UpdateSubjectEnrollmentStatusForm.controls
          .subjectEnrollmentStatusID.value,
    };

    this.studenService
      .UpdateSubjectEnrollmentStatus(this.subjectEnrollObj)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reloadCurrentRoute();
          this.reset();
        } else {
          this.toaster.error(res.message);
        }
      });
  }

  subjectIdList: any[];
  onElementChange(event: any) {
    this.subjectIdList = [];

    for (var i = 0; i < event.value.length; i++) {
      this.subjectIdList.push(JSON.stringify(event.value[i].subjectID))
    }
    console.log("EVENTTT", this.subjectIdList);

  }
  onSubmitElectiveSubjectEnrollment() {
    if (this.form.invalid) {
      return;
    } else {
      // let subjectIDobj = this.subjectEnrollmentForm.controls['subjectID'].value;
      // let subjectIDs = subjectIDobj.map((item: any) => item.subjectID);
      // this.subjectEnrollmentForm.controls['subjectID'].patchValue(subjectIDs);
      this.subjectEnrollObj = {
        studentID: this.studentsid,
        academicSessionID: this.SessionID,
        classID: this.ClassID,
        sectionID: this.SectionID,
        subjectID: this.subjectIdList
      };
      console.log("subjectEnrollObj", this.subjectEnrollObj)
      this.studenService
        .SubjectEnrollment(this.subjectEnrollObj)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.reloadCurrentRoute();
            this.reset();
          } else {
            this.toaster.error(res.message);
          }
        });
    }
  }

  // unselectAll() {
  //   this.columnForm.patchValue({
  //     profilePhoto: true,
  //     firstName: true,
  //     lastName: false,
  //     middleName: false,
  //     grade: false,
  //     section: false,
  //     class: false,
  //     studentEnrollmentStatus: false,
  //     subjectEnrollmentStatus: false,
  //     admissionNo: false,
  //     email: false,
  //     phoneNumber: false,
  //     studentEmail: false,
  //     dateOfBirth: false,
  //     studentContact: false,
  //     gender: false,
  //     bloodGroup: false,
  //     profileSummary: false,
  //     studentSkill: false,
  //     facebookProfile: false,
  //     linkedInProfile: false,
  //     address: false,
  //     zipcode: false,
  //     state: false,
  //     country: false,
  //     fatherName: false,
  //     motherName: false,
  //     parentContact: false,
  //     parentEmail: false,
  //     applicationId: false,
  //     enrollment: false
  //   })
  //   this.onSelectColumn(1);
  // }

  // selectAll() {
  //   this.columnForm.patchValue({
  //     profilePhoto: true,
  //     firstName: true,
  //     lastName: true,
  //     middleName: true,
  //     grade: true,
  //     section: true,
  //     class: true,
  //     studentEnrollmentStatus: true,
  //     subjectEnrollmentStatus: true,
  //     admissionNo: true,
  //     email: true,
  //     phoneNumber: true,
  //     studentEmail: true,
  //     dateOfBirth: true,
  //     studentContact: true,
  //     gender: true,
  //     bloodGroup: true,
  //     profileSummary: true,
  //     studentSkill: true,
  //     facebookProfile: true,
  //     linkedInProfile: true,
  //     address: true,
  //     zipcode: true,
  //     state: true,
  //     country: true,
  //     fatherName: true,
  //     motherName: true,
  //     parentContact: true,
  //     parentEmail: true,
  //     applicationId: true,
  //     enrollment: true
  //   })
  //   this.onSelectColumn(1);
  // }

  // getstudentColumn() {
  //   this.studenService.GetStudentTablecolumn(0).subscribe((res: any) => {
  //     this.columnForm.patchValue({
  //       profilePhoto: true,
  //       firstName: res.responseData.firstName ?? false,
  //       lastName: res.responseData.lastName ?? false,
  //       middleName: res.responseData.middleName ?? false,
  //       grade: res.responseData.grade ?? false,
  //       section: res.responseData.section ?? false,
  //       class: res.responseData.class ?? false,
  //       studentEnrollmentStatus: res.responseData.studentEnrollmentStatus ?? false,
  //       subjectEnrollmentStatus: res.responseData.subjectEnrollmentStatus ?? false,
  //       admissionNo: res.responseData.admissionNo ?? false,
  //       email: res.responseData.email ?? false,
  //       phoneNumber: res.responseData.phoneNumber ?? false,
  //       userId: res.responseData.userId ?? false,
  //       studentEmail: res.responseData.email ?? false,
  //       dateOfBirth: res.responseData.dateOfBirth ?? false,
  //       studentContact: res.responseData.studentContact ?? false,
  //       gender: res.responseData.gender ?? false,
  //       bloodGroup: res.responseData.bloodGroup ?? false,
  //       profileSummary: res.responseData.profileSummary == "true" ? true : false,
  //       studentSkill: res.responseData.studentSkill ?? false,
  //       facebookProfile: res.responseData.facebookProfile ?? false,
  //       linkedInProfile: res.responseData.linkedInProfile ?? false,
  //       address: res.responseData.address ?? false,
  //       zipcode: res.responseData.zipcode ?? false,
  //       state: res.responseData.state ?? false,
  //       country: res.responseData.country ?? false,
  //       fatherName: res.responseData.fatherName ?? false,
  //       motherName: res.responseData.motherName ?? false,
  //       parentContact: res.responseData.parentContact ?? false,
  //       parentEmail: res.responseData.parentEmail ?? false,
  //       applicationId: res.responseData.applicationId ?? false,
  //       enrollment: res.responseData.enrollment ?? false
  //     })
  //     this.onSelectColumn(0);
  //   })
  // }

  // onSelectColumn(type: any) {
  //   this.columnsToDisplay = [
  //     { displayName: 'select', key: 'select', class: '', width: '5%' },
  //     { displayName: 'Profile Picture', key: 'profilePhoto', isSort: false, class: '', width: '5%' },
  //     { displayName: 'First Name', key: 'firstName', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Last Name', key: 'lastName', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Email', key: 'email', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Class', key: 'className', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Section', key: 'section', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Students Enrollment Status', key: 'studentEnrollmentStatus', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Subjects Enrollment Status', key: 'subjectEnrollmentStatus', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Phone Number', key: 'contactNumber', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Admission Number', key: 'admissionNumber', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Skills', key: 'studentSkillID', isSort: true, class: '', width: '5%' },
  //     { displayName: 'LinkedIn Link', key: 'linkedInLink', isSort: false, class: '', width: '5%' },
  //     { displayName: 'Facebook Link', key: 'facebookLink', isSort: false, class: '', width: '5%' },
  //     { displayName: 'Parent Email', key: 'parentEmail', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Date of Birth', key: 'dateOfBirth', isSort: true, class: '', width: '10%' },
  //     { displayName: 'Profile Summary', key: 'profileSummary', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Zipcode', key: 'zipCode', isSort: true, class: '', width: '5%' },
  //     { displayName: 'State', key: 'stateName', class: '', width: '5%' },
  //     { displayName: 'Country', key: 'countryName', class: '', width: '5%' },
  //     { displayName: 'Blood Group', key: 'bloodGroupName', class: '', width: '5%' },
  //     { displayName: 'Father Name', key: 'fatherName', isSort: true, class: '', width: '5%' },
  //     { displayName: 'Mother Name', key: 'motherName', class: '', width: '5%' },
  //     { displayName: 'Parent Contact', key: 'parentContact', class: '', width: '5%' },
  //     { displayName: 'Gender', key: 'genderName', class: '', width: '5%' },
  //     { displayName: 'Address', key: 'address', class: '', width: '5%' },
  //     { displayName: 'Action', key: 'Actions', class: '', width: '10%' }
  //   ];
  //   this.displayedColumns = this.columnsToDisplay
  //   //let count = this.displayedColumns.length

  //   if (this.columnForm.value.profilePhoto == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Profile Picture");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.firstName == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "First Name");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.lastName == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Last Name");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.email == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Email");
  //     this.displayedColumns.splice(index, 1);

  //   } if (this.columnForm.value.phoneNumber == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Phone Number");
  //     this.displayedColumns.splice(index, 1);

  //   } if (this.columnForm.value.admissionNo == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Admission Number");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.parentEmail == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Parent Email");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.dateOfBirth == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Date of Birth");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.profileSummary == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Profile Summary");
  //     this.displayedColumns.splice(index, 1);

  //   } if (this.columnForm.value.zipcode == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Zipcode");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.country == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Country");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.state == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "State");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.linkedInProfile == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "LinkedIn Link");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.facebookProfile == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Facebook Link");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.section == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Section");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.bloodGroup == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Blood Group");
  //     this.displayedColumns.splice(index, 1);
  //   }

  //   if (this.columnForm.value.fatherName == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Father Name");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.motherName == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Mother Name");
  //     this.displayedColumns.splice(index, 1);

  //   }
  //   if (this.columnForm.value.parentContact == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Parent Contact");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.gender == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Gender");
  //     this.displayedColumns.splice(index, 1);
  //   }

  //   if (this.columnForm.value.class == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Class");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.address == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Address");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.studentSkill == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Skills");
  //     this.displayedColumns.splice(index, 1);
  //   }

  //   if (this.columnForm.value.studentEnrollmentStatus == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Students Enrollment Status");
  //     this.displayedColumns.splice(index, 1);
  //   }
  //   if (this.columnForm.value.subjectEnrollmentStatus == false) {
  //     let index = this.displayedColumns.findIndex(x => x.displayName === "Subjects Enrollment Status");
  //     this.displayedColumns.splice(index, 1);
  //   }

  //   if (type == 1) {
  //     this.studenService.UpdateStudentTable(this.columnForm.value).subscribe((res: any) => {
  //     })
  //   }
  // }
  unselectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = false;
    }
  }
  selectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = true;
    }
  }

  asdas() {
    // this.excelExportStudentList = [];
    // let index = 0;
    // if (this.selectedData > 0) {
    //   this.usersData.filter(x => this.selectedRow.some(z => x.studentID == z)).forEach((x: any) => {
    //     let stritem = "";
    //     // var datad: any = {};
    //     // for (const field in this.exportForm.controls) { // 'field' is a string
    //     //   if (this.exportForm.controls[field].value == true) {
    //     //     datad[field] = "" + x[field] + "";
    //     //   }
    //     // }
    //     var data: any = {};
    //     if (this.exportForm.value.firstName == true) {
    //       stritem += 'FirstName: "+x.firstName+",'
    //       //this.excelExportStudentList[index].push({
    //       // this.excelExportStudentList.push({
    //       //   FirstName: ""+x.firstName+"",
    //       // })
    //       data['FirstName'] = "" + x.firstName + "";
    //     }
    //     if (this.exportForm.value.lastName == true) {
    //       stritem += 'LastName: "' + x.lastName + '",'
    //       // this.excelExportStudentList.push({
    //       //   LastName: ""+x.lastName+"",
    //       // })
    //       data['LastName'] = "" + x.lastName + "";
    //     }
    //     if (this.exportForm.value.admissionNo == true) {
    //       stritem += 'AdmissionNo: "+x.admissionNumber+",'
    //       // this.excelExportStudentList.push({
    //       //   AdmissionNo: ""+x.admissionNumber+"",
    //       // })
    //       data['AdmissionNo'] = "" + x.admissionNumber + "";
    //     }
    //     if (this.exportForm.value.studentEmail == true) {
    //       stritem += 'ParentEmail: "+x.email+"'
    //       // this.excelExportStudentList.push({
    //       //   ParentEmail: ""+x.email+"",
    //       // })
    //       data['StudentEmail'] = "" + x.email + "";
    //     }
    //     if (this.exportForm.value.studentContact == true) {
    //       stritem += 'studentContact: "+x.contactNumber+"'
    //       // this.excelExportStudentList.push({
    //       //   studentContact: ""+x.contactNumber+"",
    //       // })
    //       data['studentContact'] = "" + x.dateOfBirth + "";
    //     }
    //     if (this.exportForm.value.studentContact == true) {
    //       stritem += 'studentContact: "+x.contactNumber+"'
    //       // this.excelExportStudentList.push({
    //       //   studentContact: ""+x.contactNumber+"",
    //       // })
    //       data['studentContact'] = "" + x.contactNumber + "";
    //     }
    //     if (this.exportForm.value.gender == true) {
    //       data['gender'] = "" + x.genderName + "";
    //     }
    //     if (this.exportForm.value.bloodGroup == true) {
    //       data['bloodGroup'] = "" + x.bloodGroupName + "";
    //     }
    //     if (this.exportForm.value.profileSummary == true) {
    //       data['profileSummary'] = "" + x.profileSummary + "";
    //     }
    //     if (this.exportForm.value.studentSkill == true) {
    //       data['Skill'] = "" + x.studentSkillID + "";
    //     }
    //     if (this.exportForm.value.facebookProfile == true) {
    //       data['facebookProfile'] = "" + x.facebookLink + "";
    //     }
    //     if (this.exportForm.value.linkedInProfile == true) {
    //       data['linkedInProfile'] = "" + x.linkedInLink + "";
    //     }
    //     if (this.exportForm.value.address == true) {
    //       data['address'] = "" + x.address + "";
    //     }
    //     if (this.exportForm.value.zipcode == true) {
    //       data['zipcode'] = "" + x.zipCode + "";
    //     }
    //     if (this.exportForm.value.state == true) {
    //       data['state'] = "" + x.stateName + "";
    //     }
    //     if (this.exportForm.value.country == true) {
    //       data['country'] = "" + x.countryName + "";
    //     }
    //     if (this.exportForm.value.fatherName == true) {
    //       data['fatherName'] = "" + x.fatherName + "";
    //     }
    //     if (this.exportForm.value.motherName == true) {
    //       data['motherName'] = "" + x.motherName + "";
    //     }
    //     if (this.exportForm.value.parentContact == true) {
    //       data['parentContact'] = "" + x.parentContact + "";
    //     }
    //     if (this.exportForm.value.parentEmail == true) {
    //       data['parentEmail'] = "" + x.parentEmail + "";
    //     }
    //     // if (this.exportForm.value.applicationId == true) {
    //     //   data['applicationId'] = "" + x.studentID + "";
    //     // }
    //     // if (this.exportForm.value.enrollment == true) {
    //     //   data['enrollment'] = "" + x.studentID + "";
    //     // }
    //     //  this.excelExportStudentList.push({
    //     //             stritem
    //     //     })
    //     this.excelExportStudentList.push(
    //       data
    //     )
    //     index++;
    //   })
    //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.excelExportStudentList);
    //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //   XLSX.writeFile(wb, 'studentdetail.xlsx');
    // }
  }

  export() {
    //  this.dybamicstudentMeta.pageSize = 10000;
    this.studenService
      .getStudentTableList(this.dybamicstudentMeta)
      .subscribe((res: any) => {
        if (res.status > 0) {
          console.log("METATATA", res)
          this.metaData = res.meta;
          //comment by ankit for filter
          this.coreList = res.responseData.coreStudents;
          if (this.studentIdList1[0].studentsID1.length > 0) {
            this.coreList1 = res.responseData.coreStudents.filter((x: any) => this.studentIdList1[0].studentsID1.some((z: any) => z == x.studentID));
          } else {
            this.coreList1 = this.coreList;
          }

          this.coreFieldList = res.responseData.fieldListItems;
          this.hideshowGridColumnList = res.responseData.customGridUserSettings;

          this.coreList1.map((e) => {
            let imagepath =
              e.profilePhoto == null ||
                e.profilePhoto == undefined ||
                e.profilePhoto == ''
                ? ''
                : e.profilePhoto;
            let studentID =
              e.studentID == null ||
                e.studentID == undefined ||
                e.studentID == ''
                ? ''
                : e.studentID;
            if (imagepath != '') {
              e.fields.push({
                fieldName: 'profilePhoto',
                fieldID: 0,
                fieldValue: imagepath,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            if (studentID != '') {
              e.fields.push({
                fieldName: 'studentID',
                fieldID: 0,
                fieldValue: studentID,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            return e;
          });

          this.coreList.map((e) => {
            let imagepath =
              e.profilePhoto == null ||
                e.profilePhoto == undefined ||
                e.profilePhoto == ''
                ? ''
                : e.profilePhoto;
            let studentID =
              e.studentID == null ||
                e.studentID == undefined ||
                e.studentID == ''
                ? ''
                : e.studentID;
            if (imagepath != '') {
              e.fields.push({
                fieldName: 'profilePhoto',
                fieldID: 0,
                fieldValue: imagepath,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            if (studentID != '') {
              e.fields.push({
                fieldName: 'studentID',
                fieldID: 0,
                fieldValue: studentID,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            return e;
          });

          if (this.dynamicFilterboolean === false) {
            this.dynamicFilterFields = this.coreFieldList.filter(
              (e) =>
                e.fieldName === 'Admission Number' ||
                e.fieldName === 'Contact' ||
                e.fieldName === 'First Name' ||
                e.fieldName === 'Last Name' ||
                e.fieldName === 'Email'
            );
            for (let i = 0; i < this.dynamicFilterFields.length; i++) {
              this.dynamicFilterform.addControl(
                this.dynamicFilterFields[i].fieldName,
                new FormControl('')
              );
            }
          }

          this.setListToDisplay();
          this.setColumnToDisplay();
          this.setHideandShowList();
          setTimeout(() => {
            this.getAllStudentData();
          }, 3500);
        } else {
          this.dybamicstudentMeta = new DynamicStudentMeta();
        }
        for (
          var i = 0;
          i < res.responseData.customGridUserSettings.length;
          i++
        ) {
          if (
            res.responseData.customGridUserSettings[i].fieldName === 'Class'
          ) {
          } else if (
            res.responseData.customGridUserSettings[i].fieldName === 'Section'
          ) {
          } else if (
            res.responseData.customGridUserSettings[i].fieldName ===
            'Academic Sessions'
          ) {
          }
          // else {
          //   this.exportField.push({
          //     fieldName: res.responseData.customGridUserSettings[i].fieldName,
          //     checkbox: false,
          //   });
          // }
        }
        // this.exportField.push({
        //   fieldName: 'Enrollment',
        //   checkbox: false,
        // });
      });
  }

  getAllStudentData() {
    this.getFilterStudentData = [];
    this.getNewFilterStudentData = [];
    this.getclassSectionModel = [];
    this.getColumnAndData = [];

    //console.log("exportField ", this.exportField);
    //console.log("coreList ", this.coreList);

    var classSectionDataHighestCount = 0;
    this.coreList1.forEach((coreStudentDataEle: any, index: number) => {

      if (classSectionDataHighestCount == 0) {
        classSectionDataHighestCount =
          coreStudentDataEle.classSectionModel.length;
      } else {
        if (
          classSectionDataHighestCount <
          coreStudentDataEle.classSectionModel.length
        ) {
          classSectionDataHighestCount =
            coreStudentDataEle.classSectionModel.length;
        }
      }
    });
    this.coreList1.forEach((element1) => {
      const myClonedObject = JSON.parse(JSON.stringify(element1));
      let count = 1;
      element1.classSectionModel.forEach((element: any) => {
        //  debugger
        let classSectionModelElement = element;

        // let academicSessionsObj = {
        //   fieldName: "Academic Sessions" ,
        //   fieldID: 0,
        //   fieldOrder: 0,
        //   fieldTypeID: 0,
        //   fieldTypeName: "",
        //   fieldValue: classSectionModelElement.studentEnrollmentStatus,
        //   isDate: false,
        //   isEmail: false,
        //   isRequried: false,
        //   isShowInGrid: false,
        //   label: "Academic Sessions",
        // }
        // myClonedObject.fields.push(academicSessionsObj);

        let classObj = {
          fieldName: 'ClassName',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.className,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Class',
        };
        myClonedObject.fields.push(classObj);

        let sectionObj = {
          fieldName: 'SectionName',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.sectionName,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Section',
        };
        myClonedObject.fields.push(sectionObj);

        let enrollmentObj = {
          fieldName: 'Enrollment',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.enrollment,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Enrollment',
        };
        myClonedObject.fields.push(enrollmentObj);
      });
      count = count + 1;
      ////console.log("count a", count)
      this.getFilterStudentData.push(myClonedObject.fields);
      console.log("this.filtered students", this.getFilterStudentData)
    });

    // this.getColumnAndData = this.exportField.filter((x) =>
    //   this.OnlyTrueCheckBox(x)
    // );

    this.exportField.forEach(element => {

      if (element.checkbox === true) {
        this.getColumnAndData.push(element)
      }
    });
    console.log("getColumnAndData ", this.getColumnAndData)

    // var obj4: { [k: string]: any } = {};
    //   obj4['fieldName'] = 'studentID';
    //   obj4['checkbox'] = true;
    //   this.getColumnAndData.push(obj4)

    if (
      this.getColumnAndData.findIndex(
        (element: any) => element.fieldName === 'Enrollment'
      ) >= 0
    ) {
      var obj1: { [k: string]: any } = {};
      obj1['fieldName'] = 'Academic Sessions';
      obj1['checkbox'] = true;
      this.getColumnAndData.push(obj1);

      var obj2: { [k: string]: any } = {};
      obj2['fieldName'] = 'ClassName';
      obj2['checkbox'] = true;
      this.getColumnAndData.push(obj2);

      var obj3: { [k: string]: any } = {};
      obj3['fieldName'] = 'SectionName';
      obj3['checkbox'] = true;
      this.getColumnAndData.push(obj3);
    }

    this.getNewFilterStudentData = this.getFilterStudentData.map(
      (student: any) => {
        return student.filter((studentElement: any) => {
          if (
            this.getColumnAndData.findIndex(
              (element: any) => element.fieldName === studentElement.fieldName
            ) >= 0
          ) {
            //if (this.getColumnAndData.findIndex((element: any) => element.fieldName.includes(studentElement.fieldName)) >= 0) {
            return studentElement;
          }
        });
      }
    );

    var Heading: any = [];
    var HeadingTemp: any = [];

    //19-April-2022 Legasov Commented as header were getting duplicate 
    // for (let index = 0; index <= classSectionDataHighestCount; index++) {
    //   debugger
    //   this.getColumnAndData.forEach((element: any) => {
    //     if (index == 0) {
    //       HeadingTemp.push(element.fieldName);
    //     } else {
    //       if (
    //         element.fieldName != 'studentID' ||
    //         element.fieldName != 'Academic Sessions'
    //       ) {
    //         HeadingTemp.push(element.fieldName + index);
    //       }
    //     }
    //   });
    // }

    //19-April-2022 Legasov added new changes for header
    this.getColumnAndData.forEach((element: any) => {
      HeadingTemp.push(element.fieldName);
    });

    Heading.push(HeadingTemp);

    //console.log("Heading", Heading)

    var Data: any[] = [];

    var dynamicObjCount: { [n: string]: any } = {};

    this.studentIdList1[0].studentsID1 = [];
    this.studentIdList = [];
    this.selectedId = [];
    this.selection.clear();
    this.selectedRow = [];
    this.onselect(this.selectedId);
    this.getNewFilterStudentData.forEach((studentData: any, index: number) => {
      var obj: { [k: string]: any } = {};

      var currentcount = 0;
      studentData.forEach((item: any) => {
        console.log("items value student", item)
        if (obj.hasOwnProperty(item.fieldName)) {
          var tcount = dynamicObjCount[item.fieldName];
          if (item.fieldName == 'Date of Birth') {
            item.fieldValue = this.datePipe.transform(String(item.fieldValue), 'MM-dd-yyyy');
          }
          obj[item.fieldName + tcount] = item.fieldValue;

          dynamicObjCount[item.fieldName] = dynamicObjCount[item.fieldName] + 1;
        } else {
          if (item.fieldName == 'Date of Birth') {
            item.fieldValue = this.datePipe.transform(String(item.fieldValue), 'MM-dd-yyyy');
          }
          dynamicObjCount[item.fieldName] = 1;
          obj[item.fieldName] = item.fieldValue;
        }
      });
      Data.push(obj);

    });


    //Had to create a new workbook and then add the header
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, Data);

    // XLSX.utils.sheet_add_aoa(ws, [
    //   ["first row after data", 1],
    //   ["second row after data", 2]
    // ], { origin: -1 });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'StudentList');

    XLSX.writeFile(wb, 'ManageStudentList.xlsx');
  }

  OnlyTrueCheckBox(value: any) {
    return this.exportField.some(
      (i) => i.fieldName === value.fieldName && i.checkbox === true
    );
  }

  formbind: any;
  studentEmailSent(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '80vw',
    });
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetCustomTemplateList();
    this.GetStudentByIdData();
    this.studentEmailForm = this.formBuilder.group({
      fromEmail: [],
      subject: ['', Validators.required],
      parentEmail: [''],
      body: ['', Validators.required],
      parentCheck: false,
      studentEmailCheck: true,
      customEmail: [],
      template: [''],
      senderEmail: ['info@creocms.com.au'],
      customCheck: false,
      attachment: ['']
    });
    this.dataVariablebox = true;
  }

  GetStudentByIdData() {
    console.log("STARTUPDATAAT", this.studentIdList)
    var data = this.studentIdList;
    this.studenService.GetStudentByIdData(data).subscribe((res: any) => {
      this.studentData = res.responseData;
      //console.log("STUDENTDATATATA",this.studentData)
    });
  }
  insMail: boolean = true;
  currentUserMail: boolean = false;
  mailFrom: any;
  instituteMail() {
    this.insMail = true;
    this.currentUserMail = false;
  }
  userMail() {
    this.insMail = false;
    this.currentUserMail = true;
  }

  submitted = false;
  //EmailList: any;
  //selectedTemplate: any;
  //id:any=15

  dataVariablebox = true;
  dataVaiable() {
    if (this.dataVariablebox == true) {
      this.dataVariablebox = false;
    } else {
      this.dataVariablebox = true;
    }
  }

  StudentIdList: any;
  CustomEmailList: any;

  customtemplate: any[] = [];
  GetCustomTemplateList() {
    this.studenService.GetCuastomEmailTemplate().subscribe((res: any) => {
      console.log("CustomeEmailTemplateList", res.responseData)
      this.customtemplate = res.responseData;
      for (var i = 0; i < this.customtemplate.length; i++) {
        if (this.customtemplate[i].templateName === "Default") {
          this.studentEmailForm.patchValue({
            'template': this.customtemplate[i].emailTemplateID,
            'body': this.customtemplate[i].emailBody
          });
        }
      }
    });
  }

  selectedTemplate: any;
  EmailList: any;
  selectChangeTemplate(event: any) {
    this.studenService
      .GetEmailTemplateById(this.studentEmailForm.controls.template.value)
      .subscribe((tempRes: any) => {
        this.studentEmailForm.patchValue({
          body: tempRes['responseData'].emailBody,
        });
      });
  }

  // selectChangeHandler(event:any){
  //   ////debugger;
  //   this.selectedTemplate = event.target.value;
  //   this.studenService.GetEmailTemplateById(event.target.value).subscribe((tempRes:any)=>{
  //     this.studentEmailForm.patchValue({
  // fromEmail: [],
  // subject: ['', Validators.required],
  // parentEmail: [''],
  /// body: tempRes["responseData"].emailBody
  // parentCheck: false,
  // customEmail:[]
  //   emailTemplateID:tempRes['emailTemplateID'],
  //   templateName: tempRes['templateName'],
  //   templateSubject: tempRes['templateSubject'],
  //   emailFrom: tempRes['emailFrom'],
  //   contents: tempRes['contents'],
  //     });
  //     var data = tempRes["responseData"].emailBody;

  //     // //console.log("All Email Template",data)
  //   });
  // }
  attachmentData: any[] = [];
  uploadFile(event: any) {
    this.attachmentData = [];
    console.log("UPLOADD", event.target.files)
    for (var i = 0; i < event.target.files.length; i++) {
      this.attachmentData.push(event.target.files[i]);
    }
  }
  onSubmitstudentEmail() {
    //console.log("Student Data While Save", this.studentData)
    this.submitted = true;
    if (this.studentEmailForm.invalid) {
      return;
    }
    // this.EmailList = [];
    // this.studentData.forEach((item: any) => {
    // this.EmailList.push(item.email)
    //});
    this.StudentIdList = [];
    this.studentData.forEach((element: any) => {
      this.StudentIdList.push(element.studentID);
    });
    if (this.boxhide == false) {
      this.EmailList = [];
      this.studentData.forEach((item: any) => {
        this.EmailList.push(item.parentEmail);
      });
    }
    if (this.customboxhide == true) {
      this.CustomEmailList = [];
      let cust = this.studentEmailForm.controls.customEmail.value as string;
      if (cust) {
        let custlist = cust.split(',');
        if (custlist.length > 0) {
          custlist.forEach((element) => {
            this.CustomEmailList.push(element);
          });
        }
      }
      //console.log(this.EmailList)
    }
    if (this.insMail) {
      this.mailFrom = 'info@creocms.com.au';
    } else {
      this.mailFrom = this.userEmail;
    }
    let isFileAttached: boolean;
    if (this.attachmentData.length > 0) {
      isFileAttached = true;
    }
    else {
      isFileAttached = false
    }
    // this.formbind = {
    //   StudentID: this.StudentIdList,
    //   Subject: this.studentEmailForm.controls.subject.value,
    //   body: this.studentEmailForm.controls.body.value,
    //   parent: this.boxhide,
    //   studentCheck: this.studentEmailForm.controls.studentEmailCheck.value,
    //   CustomEmailMutiple: this.CustomEmailList,
    //   FromEmail: this.mailFrom,
    // };
    //make a sevice for send email which in student Controller with name  SendEmailToStudent
    console.log("this.studentSmsForm.controls", this.studentEmailForm.controls)
    this.studenService
      .studentEmailAttachment(this.StudentIdList, this.CustomEmailList, this.studentEmailForm.controls.subject.value, this.studentEmailForm.controls.body.value,
        this.orgID, this.mailFrom, this.attachmentData, isFileAttached, false, this.studentEmailForm.controls.studentEmailCheck.value,
        this.studentEmailForm.controls.parentCheck.value, this.customboxhide)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reloadCurrentRoute();
          this.resetEmailD();
        } else {
          this.toaster.error(res.message);
        }
      });
  }

  boxhide = true;
  onparentChange() {
    if (this.studentEmailForm.controls.parentCheck.value) {
      this.boxhide = false;
    } else {
      this.boxhide = true;
      // this.studentEmailForm.controls.parentEmail.setValue('');
      //this.studentEmailForm.controls.parentEmail.setErrors(null)
    }
  }

  studentEmailCheck = true;
  onStudentEmailChange() {
    this.studentEmailCheck = false;
  }
  customboxhide = false;
  oncustomChange() {
    this.customboxhide = !this.customboxhide;
  }
  customboxhideSMS = false;
  oncustomChangeSMS() {
    this.customboxhideSMS = !this.customboxhideSMS;
  }

  /// for message send
  formbindmsms: any;
  studentSmsSent(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {});
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetStudentByIdData();
    this.studentSmsForm = this.formBuilder.group({
      parentContactNo: [''],
      message: ['', Validators.required],
      parentCheckSms: false,
      customSMS: [],
    });
  }

  boxhidesms = true;
  onparentSmsChange() {
    if (this.studentSmsForm.controls.parentCheckSms.value) {
      this.boxhidesms = false;
    } else {
      this.boxhidesms = true;
      this.studentSmsForm.controls.parentContactNo.setValue('');
      //this.studentSmsForm.controls.parentContactNo.setErrors(null)
    }
  }
  parentContact: any;
  onSubmitstudentSms() {
    this.submitted = true;

    if (this.studentSmsForm.invalid) {
      return;
    }
    this.parentContact = [];
    this.studentData.forEach((item: any) => {
      this.parentContact.push(item.contactNumber);
    });
    if (this.boxhidesms == false) {
      this.studentData.forEach((item: any) => {
        this.parentContact.push(item.parentContactNumber);
      });
    }
    if (this.customboxhideSMS == true) {
      let cust = this.studentSmsForm.controls.customSMS.value as string;
      if (cust) {
        let custlist = cust.split(',');
        if (custlist.length > 0) {
          custlist.forEach((element) => {
            this.parentContact.push(element);
          });
        }
      }
      //console.log(this.EmailList)
    }
    this.formbind = {
      multipleMobileNo: this.parentContact,
      message: this.studentSmsForm.controls.message.value,
    };
    this.studenService.StudentSentSms(this.formbind).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      } else {
        this.toaster.error(res.message);
      }
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  Block() {
    this.dialogService
      .confirm(`Are you sure you want block this student !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.BlockStudent();
        }
      });
  }
  StudentData: any;
  BlockStudent() {
    this.studentsid = [];
    this.StudentData = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.StudentData = {
      studentID: this.studentsid,
      status: true,
    };
    //console.log("STUDENTBLOCK",this.StudentData)
    // this.studenService.ChangeStudentStatus(this.StudentData).subscribe((res: any) => {
    //   if (res.status > 0) {
    //     this.toaster.success(res.message);
    //     this.reset();
    //     this.onSave.emit();
    //     this.reloadCurrentRoute();
    //   }
    //   else {
    //     this.toaster.error(res.message);
    //   }
    // });
  }
  Unblock() {
    this.dialogService
      .confirm(`Are you sure you want unblock this student !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.UnblockStudent();
        }
      });
  }
  UnblockStudent() {
    this.studentsid = [];
    this.StudentData = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.StudentData = {
      studentID: this.studentsid,
      status: false,
    };
    this.studenService
      .ChangeStudentStatus(this.StudentData)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reset();
          this.reloadCurrentRoute();
        } else {
          this.toaster.error(res.message);
          this.reloadCurrentRoute();
        }
      });
  }
  Enable() {
    this.dialogService
      .confirm(`Are you sure you want Enable this student reportcard !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.EnableStudentReportcard();
        }
      });
  }
  EnableStudentReportcard() {
    this.studentsid = [];
    this.StudentData = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.StudentData = {
      studentID: this.studentsid,
      status: true,
    };
    this.studenService
      .StudentReportcardEnableDisable(this.StudentData)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reset();
          this.onSave.emit();
          this.reloadCurrentRoute();
        } else {
          this.toaster.error(res.message);
        }
      });
  }
  Disable() {
    this.dialogService
      .confirm(`Are you sure you want Disable this student reportcard!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.DisableStudentReportcard();
        }
      });
  }
  DisableStudentReportcard() {
    this.studentsid = [];
    this.StudentData = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.StudentData = {
      studentID: this.studentsid,
      status: false,
    };
    this.studenService
      .StudentReportcardEnableDisable(this.StudentData)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reset();
          this.reloadCurrentRoute();
        } else {
          this.toaster.error(res.message);
          this.reloadCurrentRoute();
        }
      });
  }

  MovetoAlumni() {
    this.dialogService
      .confirm(`Are you sure you want move this student to alumni !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.MoveStudentToAlumni();
        }
      });
  }
  MoveStudentToAlumni() {
    this.studentsid = [];
    this.StudentData = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.StudentData = {
      studentID: this.studentsid,
    };
    this.studenService
      .AddStudentToAlumni(this.StudentData)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reset();
          this.reloadCurrentRoute();
        } else {
          this.toaster.error(res.message);
        }
      });
  }
  // email Invite to
  studentEmailSentInvite() {
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetStudentByIdData();
    this.dialogService
      .confirm(`Are you sure you want invite to login!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.emailInviteCall();
        }
      });
  }

  emailInviteCall() {
    let arrx: any = [];
    this.studentData.forEach((item: any) => {
      arrx.push(item.email);
    });
    let msg =
      "<p><span>Hello,<br>Please click to login site <a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'>[orgUrl]</a></span></p><p><span><a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'><br></a></span><br></p><p><span>thank you</span></p><p><span>team support</span></p><p><span><br></span></p><p><span><br></span><br></p>";
    // let msg="<h1>hello</h1>"
    let data = {
      toEmailMutiple: arrx,
      subject: 'Invite to Login',
      body: msg,
      EmailType: 1,
    };

    this.studenService
      .StudentLoginInvitationEmail(data)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.reset();
          this.reloadCurrentRoute();
        } else {
          this.toaster.error(res.message);
        }
      });
  }
  /// end

  /// invite to SMS
  studentSmsSentInvite() {
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.studentsid.push(this.selectedRow[i]);
    }
    this.studentIdList = {
      studentsID: this.studentsid,
    };
    this.GetStudentByIdData();
    this.dialogService
      .confirm(`Are you sure you want invite to login!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.smsInviteCall();
        }
      });
  }

  smsInviteCall() {
    let arrd: any = [];
    this.studentData.forEach((item: any) => {
      arrd.push(item.contactNumber);
    });
    let data = {
      multipleMobileNo: arrd,
      SMSType: 1,
      message:
        'Hello,\n Please click to login site ' + this.url + '/ \n thank you \n team support',
    };
    this.studenService.StudentSentSms(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      } else {
        this.toaster.error(res.message);
      }
    });
  }

  SessionName: any;
  SessionID: any;
  SessionFieldId: any;
  setFilterValue(id: any, val: any, fieldName: any) {
    if (this.filterArray.length == 0) {
      if (val !== undefined && val !== null && val !== '') {
        this.filterArray.push({
          id: id,
          val: val,
          fieldName: fieldName,
        });
      }
    } else {
      // field in array
      if (
        this.filterArray.find((m: any) => m.fieldName == fieldName) !==
        undefined
      ) {
        if (val == undefined || val == null || val == '') {
          this.filterArray = this.filterArray.filter(
            (m: any) => m.fieldName !== fieldName
          );
        } else {
          this.filterArray.find((m: any) => m.fieldName == fieldName).id = id;
          this.filterArray.find((m: any) => m.fieldName == fieldName).val = val;
          this.filterArray.find(
            (m: any) => m.fieldName == fieldName
          ).fieldName = fieldName;
        }
      } // field not in array
      else {
        if (val !== undefined && val !== null && val !== '') {
          this.filterArray.push({
            id: id,
            val: val,
            fieldName: fieldName,
          });
        }
      }
    }
  }
  filterArray: any = [];
  filterSession(event: any, fieldName: string) {
    if (event.option !== undefined) {
      this.SessionID = event.option.value.sessionID;
      this.SessionName = event.option.value.sessionName;
    }
    // this.SessionFieldId = this.coreFieldList.find(e => e.fieldName === fieldName).fieldID;

    // if(event.option!==undefined)
    // this.setFilterValue(this.SessionFieldId,this.SessionID,fieldName);
    // else if(event.target.value==undefined || event.target.value=='')
    //  this.setFilterValue(this.SessionFieldId,event.target.value,fieldName);
    if (event.option !== undefined) {
      this.dybamicstudentMeta.AcademicSessionID = event.option.value.sessionID;
      this.dybamicstudentMeta.ClassID = '';
      this.dybamicstudentMeta.SectionID = '';
      this.dybamicstudentMeta.SubjectID = '';
      this.filterForm.controls.sectionID.setValue('');
      this.filterForm.controls.subjectID.setValue('');
      this.filterForm.controls.classID.setValue('');
      this.classDropdownService.ClassDropdown().subscribe((res: any) => {
        this.classListDropdown = res.responseData;
      });
      this.getTableData();
    }
  }
  GetStudentByStuEnrollStatus(id: any, setting: any) {
    if (this.activeItem === setting) {
      this.activeItem = '';
      this.dybamicstudentMeta.StudentEnrollmentStatusID = 0;
    } else {
      this.activeItem = setting;
      this.dybamicstudentMeta.StudentEnrollmentStatusID = id;
    }
    //console.log("DYnmaicMeta",this.dybamicstudentMeta)
    this.getTableData();
    //this.getUsersList(this.studentMata, '', '');
  }
  GetStudentBySubEnrollStatus(id: any, setting: any) {
    if (this.activeSubItem === setting) {
      this.activeSubItem = '';
      this.dybamicstudentMeta.SubjectEnrollmentStatusID = 0;
    } else {
      this.activeSubItem = setting;
      this.dybamicstudentMeta.SubjectEnrollmentStatusID = id;
    }
    //console.log("DYnmaicMeta",this.dybamicstudentMeta)
    this.getTableData();
  }

  SubjectName: any;
  SubjectID: any;
  SubjectType: boolean;
  SubjectFieldId: string;
  getStudentBySubjectID(event: any, fieldName: string) {
    this.dybamicstudentMeta.SubjectID = event.option.value.subjectID;
    this.SubjectID = event.option.value.subjectID;
    //console.log("DYnmaicMeta",this.dybamicstudentMeta)
    this.getTableData();
    return;
    //console.log("SubjectType1",event );

    if (event.option !== undefined) {
      this.SubjectName = event.option.value.subjectName;
      this.SubjectID = event.option.value.subjectID;
      this.SubjectType = event.option.value.isCore;
    }
    // this.studentMata.subjectID = event.option.value.subjectID;
    // this.getUsersList(this.studentMata, '', '');
    this.SubjectFieldId = this.coreFieldList.find(
      (e) => e.fieldName === fieldName
    ).fieldID;
    if (event.option !== undefined)
      this.setFilterValue(this.SubjectFieldId, this.SubjectID, fieldName);
    else if (event.target.value == undefined || event.target.value == '')
      this.setFilterValue(this.SubjectFieldId, event.target.value, fieldName);

    //if(event.option==undefined && (event.target.value!==undefined && event.target.value!==''))
    //return;
    // this.dybamicstudentMeta.filterFieldIds = this.SessionFieldId + ',' + this.ClassFieldId +  ',' + this.SectionFieldId +  ',' + this.SubjectFieldId;
    // this.dybamicstudentMeta.filterFieldValue = this.SessionID + ',' + this.ClassID +  ',' + this.SectionID +  ',' + this.SubjectID;
    // this.filterfielsdId = this.dybamicstudentMeta.filterFieldIds;
    // this.filterfieldsValue = this.dybamicstudentMeta.filterFieldValue;
    if (this.filterArray.length > 0) this.dynamicFilter();
    //console.log("SubjectType", this.SubjectType);
  }
  diplayList: any[] = [];
  coreList: any[] = [];
  a: any;
  coreList1: any[] = [];
  coreFieldList: any[] = [];
  coreListNew: any[] = [];
  _returnList: any[] = [];
  dynamicFilterFields: any[] = [];
  itemperPage: number[] = [];
  getTableData() {
    this.exportField = [];
    if (this.filterArray.length > 0) {
      this.dybamicstudentMeta.filterFieldIds = this.filterArray
        .map((a: any) => a.id)
        .join(',');
      this.dybamicstudentMeta.filterFieldValue = this.filterArray
        .map((a: any) => a.val)
        .join(',');
      this.dybamicstudentMeta.pageNumber = 1;
    } else {
      this.dybamicstudentMeta.filterFieldIds = '';
      this.dybamicstudentMeta.filterFieldValue = '';
    }
    this.studenService
      .getStudentTableList(this.dybamicstudentMeta)
      .subscribe((res: any) => {
        if (res.status > 0) {
          //console.log("METATATA", res)
          this.selectedId = [];
          this.selection.clear();
          this.onselect(this.selectedId);
          this.metaData = res.meta;
          this.coreList = res.responseData.coreStudents;
          this.coreFieldList = res.responseData.fieldListItems;
          this.hideshowGridColumnList = res.responseData.customGridUserSettings;
          this.coreList.map((e) => {
            let imagepath =
              e.profilePhoto == null ||
                e.profilePhoto == undefined ||
                e.profilePhoto == ''
                ? ''
                : e.profilePhoto;
            let studentID =
              e.studentID == null ||
                e.studentID == undefined ||
                e.studentID == ''
                ? ''
                : e.studentID;
            if (imagepath != '') {
              e.fields.push({
                fieldName: 'profilePhoto',
                fieldID: 0,
                fieldValue: imagepath,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            if (studentID != '') {
              e.fields.push({
                fieldName: 'studentID',
                fieldID: 0,
                fieldValue: studentID,
                label: 'Profile Photo',
                fieldTypeID: 0,
                fieldTypeName: '',
                fieldOrder: 0,
                isRequried: false,
                isEmail: false,
                isDate: false,
                isShowInGrid: false,
              });
            }
            return e;
          });

          if (this.dynamicFilterboolean === false) {
            this.dynamicFilterFields = this.coreFieldList.filter(
              (e) =>
                e.fieldName === 'Admission Number' ||
                e.fieldName === 'Contact' ||
                e.fieldName === 'First Name' ||
                e.fieldName === 'Last Name' ||
                e.fieldName === 'Email'
            );
            for (let i = 0; i < this.dynamicFilterFields.length; i++) {
              this.dynamicFilterform.addControl(
                this.dynamicFilterFields[i].fieldName,
                new FormControl('')
              );
            }
          }

          this.setListToDisplay();
          this.setColumnToDisplay();
          this.setHideandShowList();
        } else {
          this.dybamicstudentMeta = new DynamicStudentMeta();
        }
        for (
          var i = 0;
          i < res.responseData.customGridUserSettings.length;
          i++
        ) {
          if (
            res.responseData.customGridUserSettings[i].fieldName === 'Class'
          ) {
          } else if (
            res.responseData.customGridUserSettings[i].fieldName === 'Section'
          ) {
          } else if (
            res.responseData.customGridUserSettings[i].fieldName ===
            'Academic Sessions'
          ) {
          } else {
            this.exportField.push({
              fieldName: res.responseData.customGridUserSettings[i].fieldName,
              checkbox: false,
            });
          }
        }
        // this.exportField.push({
        //   fieldName: 'Enrollment',
        //   checkbox: false,
        // });

        var totalcount = res.meta.totalRecords / 500;
        if (totalcount > 1) {
          var value = Math.round(totalcount) + 1
          this.itemperPage = [10, 100, 150]
        }
        else {
          this.itemperPage = [10, 100, 150]
        }
      });
  }
  SortChange(sortState: Sort) {
    this.dataSource.sort = this.sort;
  }
  displayColumnRow: any[] = [];
  displayListRow: any[] = [];
  setListToDisplay() {
    this.diplayList = [];
    for (var user = 0; user < this.coreList.length; user++) {
      let tempUserFields: any[] = [];
      for (
        var userField = 0;
        userField < this.coreList[user].fields.length;
        userField++
      ) {
        tempUserFields.push(this.coreList[user].fields[userField]);
      }
      var _user = {
        fields: tempUserFields,
        transactionID: tempUserFields[0].studentID,
      };
      this.diplayList.push(_user);
    }
    this.coreListNew = [];
    this.coreListNew = this.diplayList;
    this._returnList = this.setListForDrogandDrop(this.coreListNew);
    //console.log("DISPlaylist", this._returnList);

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = this._returnList;
    this.dataSource.sort = this.sort;
  }

  setListForDrogandDrop(list: any[]): any[] {
    //console.log("Testing", list)
    var tempList: any[] = list.map((s) => Object.assign(s));
    let returnList: any = [];
    tempList.forEach((element) => {
      let ReqModel: any = {};
      let studentID = 0;
      element.fields.forEach((ele: any) => {
        var timestamp = Date.parse(ele.fieldValue);

        if (isNaN(timestamp) == false && ele.isDate == true) {
          ReqModel[ele.fieldName] = this.datepipe.transform(
            ele.fieldValue,
            'MM/dd/yyyy'
          );
        } else {
          ReqModel[ele.fieldName] = ele.fieldValue ? ele.fieldValue : '—';
        }
      });
      returnList.push(ReqModel);
    });
    return returnList;
  }
  setHideandShowList() {
    this.hideshowGridColumnList = this.hideshowGridColumnList.filter(
      (x) => x.fieldName != 'Academic Sessions'
    );
    this.hideshowGridColumnList.sort(function (a, b) {
      return a.gridUserSettingOrder - b.gridUserSettingOrder;
    });
  }
  setColumnToDisplay() {
    this.displayedColumnsList = [];
    this.coreFieldList.sort(function (a, b) {
      return a.gridUserSettingOrder - b.gridUserSettingOrder;
    });

    console.log("THIS.coreFieldList", this.coreFieldList);


    for (var i = 0; i < this.coreFieldList.length; i++) {
      if (this.coreFieldList[i].isShowInGrid == true) {
        this.displayedColumnsList.push(this.coreFieldList[i].fieldName);
      }
    }
    console.log("this.displayedColumnsList", this.displayedColumnsList);


    //console.log("AFTERDATA", this.coreFieldList)
    // this.coreFieldList.forEach((colunm, index) => {
    //   colunm.index = index;
    //   this.displayedColumnsList[index] = colunm.fieldName;
    // });
    let newArr = new Set(this.displayedColumnsList);
    this.displayedColumnsList = [];
    this.displayedColumnsList = [...newArr];
    this.displayedColumnsList.splice(0, 0, 'select');
    this.displayedColumnsList.splice(1, 0, 'profilePhoto');
    if (this.displayedColumnsList.length != 0) {
      for (var i = 0; i < this.displayedColumnsList.length; i++) {
        if (this.displayedColumnsList[i] == 'select') {
          this.displayedColumnsList.splice(i, 1);
        }
        if (this.displayedColumnsList[i] == 'Academic Sessions') {
          this.displayedColumnsList.splice(i, 1);
        }
      }
    }

    let changedColumns = (this.displayedColumnsList || []).map((obj) => obj);
    changedColumns.splice(0, 0, 'select');
    changedColumns.push('Actions');
    this.changeColumnsToDisplay = changedColumns;
    //console.log("DISPLAYCOLUMNLIST", this.changeColumnsToDisplay)
  }
  selection = new SelectionModel<any>(true, []);
  selectedId: any[] = [];
  isAllSelected() {
    this.selectedId = [];
    if (this.selection.selected.length != 0) {
      for (var i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].studentID) {
          this.selectedId.push(
            JSON.stringify(this.selection.selected[i].studentID)
          );
        }
      }
    }
    this.onselect(this.selectedId);
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) => this.selection.select(row));
    if (this.selection.selected.length == 0) {
      this.selectedId = [];
      this.onselect(this.selectedId);
    }
  }
  changee(event: any, data: any) {
    if (event.checked == false) {
      for (var i = 0; i < this.selectedId.length; i++) {
        if (this.selectedId == data.studentID) {
          this.selectedId.splice(i, 1);
        }
      }
      this.onselect(this.selectedId);
    }
  }
  checked: boolean = true;
  selectAllField() {
    this.hideshowGridColumnList.map((a) => (a.isShowInGrid = true));
    this.hideshowList = {
      showHideFieldAndGroups: this.hideshowGridColumnList,
    };
    this.addHideshowList();
  }
  unselectAllField() {
    this.hideshowGridColumnList.map((a) => (a.isShowInGrid = false));
    this.hideshowGridColumnList.find(
      (g: any) => g.fieldName == 'First Name'
    ).isShowInGrid = true;
    this.hideshowList = {
      showHideFieldAndGroups: this.hideshowGridColumnList,
    };
    this.addHideshowList();
  }
  drop(event: CdkDragDrop<string[]>) {
    this.hideshowList = {};
    this.hideshowList = {
      showHideFieldAndGroups: this.hideshowGridColumnList,
    };
    moveItemInArray(
      this.hideshowGridColumnList,
      event.previousIndex,
      event.currentIndex
    );
    this.addHideshowList();
  }
  hideshowList: any;
  onCheck() {
    this.hideshowList = {};
    this.hideshowGridColumnList.find(
      (g: any) => g.fieldName == 'First Name'
    ).isShowInGrid = true;
    this.hideshowList = {
      showHideFieldAndGroups: this.hideshowGridColumnList,
    };
    this.addHideshowList();
  }
  public handlePage(e: any) {
    this.dybamicstudentMeta.pageNumber = e.pageIndex + 1;
    this.dybamicstudentMeta.pageSize = e.pageSize;
    this.getTableData();
  }
  addHideshowList() {
    //console.log("ONSAVEHIDESHOW", this.hideshowList)
    this.studenService
      .addHideandShowList(this.hideshowList)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.getTableData();
          this.selectedId = [];
          this.selection.clear();
          this.onselect(this.selectedId);
        } else {
          this.toaster.error(res.message);
        }
      });
  }
  dynamicFilter() {
    this.dynamicFilterboolean = true;
    // this.dybamicstudentMeta.filterFieldIds = '';
    //this.dybamicstudentMeta.filterFieldValue = '';
    //if (this.filterfielsdId !== '') {
    //this.dybamicstudentMeta.filterFieldIds = this.filterfielsdId ;
    //this.dybamicstudentMeta.filterFieldValue = this.filterfieldsValue;
    // }
    Object.keys(this.dynamicFilterform.controls).forEach((key) => {
      //console.log('data ' + this.dynamicFilterform.controls[key].value);
      //if (this.dynamicFilterform.controls[key].value !== '') {
      const value = this.dynamicFilterform.controls[key].value;
      const field = this.coreFieldList.find((e) => e.fieldName === key).fieldID;
      //  if (this.dybamicstudentMeta.filterFieldIds === '') {
      //    this.dybamicstudentMeta.filterFieldIds = field;
      //    this.dybamicstudentMeta.filterFieldValue = value;
      //  } else {
      //if(field!==undefined && field!=='' && field!==null && value!==undefined && value!=='' && value!==null)
      // {
      this.setFilterValue(field, value, key);
      // }
      //this.dybamicstudentMeta.filterFieldIds = this.dybamicstudentMeta.filterFieldIds + ',' + field;
      //this.dybamicstudentMeta.filterFieldValue = this.dybamicstudentMeta.filterFieldValue + ',' + value;
      //}
      //}
    });
    this.getTableData();
  }

  resetFilter() {
    this.dynamicFilterform.reset();
    this.filterForm.reset();
    this.dybamicstudentMeta.filterFieldIds = '';
    this.dybamicstudentMeta.filterFieldValue = '';
    this.filterArray = [];
    this.dybamicstudentMeta = new DynamicStudentMeta();
    for (var i = 0; i < this.academicSessions.length; i++) {
      if (this.academicSessions[i].academicSessionName == "Default Session") {
        this.dybamicstudentMeta.AcademicSessionID = this.academicSessions[i].academicSessionID
      }
    }
    this.getTableData();
  }

  getStudentExcelTable() {
    let element = document.getElementById('manage-student-excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'StudentList');

    XLSX.writeFile(wb, 'ManageStudentList.xlsx');
  }
}
function studentEmailSent(studentEmailSent: any, arg1: { height: string; width: string; }) {
  throw new Error('Function not implemented.');
}