<div #testElement class="container container1580">
    <div class="headingcontent">
      <div class="pageTitle mt-0">
        <h2>Manage Registration </h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="../../academicslearning/manage-academic"><i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Manage Registration Fields
            </li>
          </ol>
        </div>
      </div>
      <div class="">
        <div class="add_button">
          <button class="addbtn btn" matRipple (click)="AddUpdateDynamicFormGroupFields()"
            *ngIf="formType=='1' ? checkActionPermission('Update Student Fields') : checkActionPermission('Update Teachers Fields')">Update</button>
        </div>
      </div>
    </div>
    <div class="cardWidget">
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <h4 class="text-colored mb-4">Add Field</h4>
          <div class="fieldTypes form-fields-block">
            <div class="fieldType dynamicallyAddableField form-field" matRipple *ngFor="let field of singlLineWithFields"
              [dndDraggable]="field" [dndEffectAllowed]="'copy'" (dndEnd)="onDragEnd($event)">
              <i class="fas fa-font"></i>
              <div>Text</div>
            </div>
            <div class="fieldType dynamicallyAddableField form-field" matRipple *ngFor="let field of multiLineWithFields"
              [dndDraggable]="field" [dndEffectAllowed]="'copy'" (dndEnd)="onDragEnd($event)">
              <i class="fas fa-align-justify"></i>
              <div>Textarea</div>
            </div>
            <div class="fieldType dynamicallyAddableField form-field" matRipple *ngFor="let field of DateWithFields"
              [dndDraggable]="field" [dndEffectAllowed]="'copy'" (dndEnd)="onDragEnd($event)">
              <i class="fas fa-calendar-minus"></i>
              <div>Date</div>
            </div>
            <div class="fieldType dynamicallyAddableField form-field" matRipple *ngFor="let field of SelectionWithFields"
              [dndDraggable]="field" [dndEffectAllowed]="'copy'" (dndEnd)="onDragEnd($event)">
              <i class="fas fa-hand-point-up"></i>
              <div>Selection</div>
            </div>
            <div class="fieldType dynamicallyAddableField form-field" matRipple *ngFor="let field of CheckBoxWithFields"
              [dndDraggable]="field" [dndEffectAllowed]="'copy'" (dndEnd)="onDragEnd($event)">
              <i class="fas fa-check-square"></i>
              <div>Checkbox</div>
            </div>         
          </div>
          <div class="separtor"></div>
          <div class="tab-entery-form" matRipple [attr.aria-expanded]="!isCollapsed" aria-controls="collapsedform">
            <a class="add-field-group-link" (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapsedform"><b><i class="fa fa-plus"></i> Add Field
                Group</b></a>
            <form [formGroup]="groupForm" id="field-group-entry-form" class="field-group-entry-form" id="collapsedform"
              [collapse]="isCollapsed">
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100 mt-2">
                  <mat-label>Field Group Name</mat-label>
                  <input matInput type="text" formControlName="groupName" />
                </mat-form-field>
              </div>
              <div class="col-md-12 text-right">
                <button (click)="CreateGroup()" [disabled]="!groupForm.valid" type="button"
                  class="btn btn-primary btn-sm mr-2" matRipple>
                  Add
                </button>
                <button type="button" (click)="isCollapsed = !isCollapsed;cancle()" class="btn btn-reset btn-sm"
                  matRipple>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
  
        <div class="col-lg-6 col-sm-12">
          <h4 class="text-colored mb-4">
            Fields
            <i class="fas fa-info-circle" [popover]="informationForForm" triggers="mouseenter:mouseleave"
              [adaptivePosition]="true" placement="bottom"></i>
          </h4>
          <section cdkDropList [cdkDropListData]="groupsWIthFields"
            (cdkDropListDropped)="dropGroup($event, groupsWIthFields)">
            <mat-accordion *ngFor="let group of groupsWIthFields" (dndDrop)="onDrop($event, group)" [dndDropzone]="[]"
              cdkDrag>
              <mat-expansion-panel class="mb-4" cdkDropList [cdkDropListData]="group.Fields"
                (cdkDropListDropped)="drop($event, group)">
                <mat-expansion-panel-header matRipple>
                  <mat-panel-title class="pannelheading">
                    <div class="text withoutbottom">
                
                      <mat-form-field appearance="legacy" class="w-100 withoutbottom">
                        <input matInput type="text" [(ngModel)]="group.groupName" class="withoutbottom">
                      </mat-form-field>
  
  
  
                    </div>
                    <div class="actions">
                      <i class="fas fa-arrows-alt mr-3"></i>
                      <i class="fa-trash fas" (click)="DeleteGroup(group)"></i>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <div *ngFor="let f of group.fields">
                    <div *ngIf="f.isDeleted==false" cdkDrag class="textfield" matRipple (click)="onClickOption(f)"
                      [attr.aria-expanded]="!options" aria-controls="options">
                      <div class="textualphase d-flex align-item-center">
                        <p class="textheading" contentEditable="true">
                          {{ f.fieldName }}
                        </p>
                      </div>
                      <div class="text-right badges">
                        <span class="labeled active ml-2" *ngIf="f.isUnpublished==false">
                          Published
                        </span>
                        <span class="labeled disable ml-2" *ngIf="f.isUnpublished==true">
                          Unpublish
                        </span>
                      </div>
                      <div class="actionsbtns">
                        <i class="fas fa-arrows-alt mr-3"></i>
                        <i *ngIf="f.isDynamicField==true" (click)="DeleteField(f,group)" class="fa-trash fas mr-3"></i>
                        <i class="fas fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                 
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </section>
        </div>
        <div class="col-lg-3 col-sm-12">
          <div class="cardWidget mt-30 textFieldoptions" id="textfieldoptions" [collapse]="options">
            <form [formGroup]="textBoxform">
              <div class="headofcard mb-3">
                <h4 class="text-colored" *ngIf="textBoxform.value.FieldTypeName == 'Textbox'">
                  Text Field
                </h4>
                <h4 class="text-colored" *ngIf="textBoxform.value.FieldTypeName == 'Checkbox'">
                  Checkbox Field
                </h4>
                <h4 class="text-colored" *ngIf="textBoxform.value.FieldTypeName == 'Dropdown'">
                  Selection Field
                </h4>
                <h4 class="text-colored" *ngIf="textBoxform.value.FieldTypeName == 'Date'">
                  Date Field
                </h4>
                <h4 class="text-colored" *ngIf="textBoxform.value.FieldTypeName == 'Textarea'">
                  Textarea Field
                </h4>
                <i class="fas fa-times cursor-pointer" (click)="options = !options" [attr.aria-expanded]="!options"
                  aria-controls="options"></i>
              </div>
  
              <h5 class="mb-3" *ngIf="textBoxform.value.FieldTypeName == 'Textbox'">
                Field Id :text_{{ textBoxform.value.FieldID }}
              </h5>
              <h5 class="mb-3" *ngIf="textBoxform.value.FieldTypeName == 'Textarea'">
                Field Id :Textarea_{{ textBoxform.value.FieldID }}
              </h5>
              <h5 class="mb-3" *ngIf="textBoxform.value.FieldTypeName == 'Date'">
                Field Id :Date_{{ textBoxform.value.FieldID }}
              </h5>
              <h5 class="mb-3" *ngIf="textBoxform.value.FieldTypeName == 'Checkbox'">
                Field Id :Checkbox_{{ textBoxform.value.FieldID }}
              </h5>
              <h5 class="mb-3" *ngIf="textBoxform.value.FieldTypeName == 'Dropdown'">
                Field Id :Selection_{{ textBoxform.value.FieldID }}
              </h5>
  
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Label</mat-label>
                <input [readonly]="textBoxform.value.IsDynamicField == false" matInput (input)="onChangelabel($event)"
                  type="text" formControlName="FieldName" />
              </mat-form-field>
  
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Field Type</mat-label>
                <mat-select placeholder="Text Field" formControlName="FieldTypeID" [disabled]="true">
                  <mat-option *ngFor="let type of fieldTypeList" [value]="type.fieldTypeID">{{ type.fieldTypeName
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field *ngIf="textBoxform.value.IsDynamicField == true" class="w-100" appearance="outline">
                <mat-label>Select Status</mat-label>
                <mat-select formControlName="IsUnpublished" (selectionChange)="onIsUnpublished($event)"
                  placeholder="Select Status">
                  <mat-option value="1">Publish</mat-option>
                  <mat-option value="2">Unpublish</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label
                  *ngIf="textBoxform.value.FieldTypeName == 'Dropdown' || textBoxform.value.FieldTypeName == 'Checkbox'">Options</mat-label>
                <textarea
                  *ngIf="textBoxform.value.FieldTypeName == 'Dropdown' || textBoxform.value.FieldTypeName == 'Checkbox'"
                  (input)="onChangeOption($event)" matInput placeholder="Option1, Option2, Option3,...... "
                  formControlName="DefaultValue"></textarea>
                <mat-label
                  *ngIf="textBoxform.value.FieldTypeName !== 'Dropdown' && textBoxform.value.FieldTypeName !== 'Checkbox'">Default
                  Value</mat-label>
                <input type="text"
                  *ngIf="textBoxform.value.FieldTypeName !== 'Dropdown' && textBoxform.value.FieldTypeName !== 'Checkbox'"
                  (input)="onChangeOption($event)" matInput placeholder="Default Value" formControlName="DefaultValue" />
                <mat-error> Options is required </mat-error>
              </mat-form-field>
  
              <!-- <mat-checkbox class="example-margin ml-0 ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
              [adaptivePosition]="true"
              placement="bottom"></i></mat-checkbox> -->
  
              <h5 class="mb-3 mt-3">Rules</h5>
              <mat-checkbox [ngClass]="DisabledPointer1()?  'disablePointer' : ''" [disabled]="DisabledPointer1()"
                class="example-margin ml-0 " (change)="onRequried($event)"
                formControlName="IsRequried">Required</mat-checkbox>
              <mat-checkbox
                *ngIf="textBoxform.value.IsNumber!==true && textBoxform.value.Date!==true && textBoxform.value.FieldTypeName !== 'Textarea' && textBoxform.value.FieldTypeName !== 'Date' && textBoxform.value.FieldTypeName !== 'Dropdown' && textBoxform.value.FieldTypeName !== 'Checkbox'"
                class="example-margin ml-0 " (change)="onEmail($event)" formControlName="Email">Email</mat-checkbox>
              <mat-checkbox
                *ngIf="textBoxform.value.IsNumber!==true && textBoxform.value.Email!==true &&textBoxform.value.FieldTypeName !== 'Textarea' && textBoxform.value.FieldTypeName !== 'Dropdown' && textBoxform.value.FieldTypeName !== 'Checkbox'"
                class="example-margin ml-0 " (change)="onDate($event)" formControlName="Date">Date</mat-checkbox>
              <mat-checkbox
                *ngIf="textBoxform.value.Email!==true && textBoxform.value.Date!==true &&textBoxform.value.FieldTypeName !== 'Date' && textBoxform.value.FieldTypeName !== 'Dropdown' && textBoxform.value.FieldTypeName !== 'Checkbox'"
                class="example-margin ml-0 " (change)="onIsNumber($event)"
                formControlName="IsNumber">Number</mat-checkbox>
  
              <h5 class="mb-3 mt-3">Visible To</h5>
              <mat-checkbox class="example-margin ml-0 " (change)="onVisibleToStudent($event)"
                formControlName="VisibleToStudent">Students</mat-checkbox>
              <mat-checkbox class="example-margin ml-0 " (change)="onVisibleToParent($event)"
                formControlName="VisibleToParent">Parents</mat-checkbox>
              <mat-checkbox class="example-margin ml-0" (change)="onVisibleToTeacher($event)"
                formControlName="VisibleToTeacher">Teachers</mat-checkbox>
  
              <h5 class="mb-3 mt-3">Editable To</h5>
              <mat-checkbox class="example-margin ml-0 " (change)="onEditableToStudent($event)"
                formControlName="EditableToStudent">Students</mat-checkbox>
              <mat-checkbox class="example-margin ml-0 " (change)="onEditableToParent($event)"
                formControlName="EditableToParent">Parents</mat-checkbox>
            </form>
          </div>
        </div>
      </div>
      <div class="add_button text-right">
        <button class="addbtn btn" matRipple (click)="AddUpdateDynamicFormGroupFields()"
          *ngIf="formType=='1' ? checkActionPermission('Update Student Fields') : checkActionPermission('Update Teachers Fields')">Update</button>
      </div>
    </div>
  </div>
  <ng-template #informationForForm>
    <p class="popovertext">
      -Drag &amp; Drop to update the display order of fields.<br />
      - Click on the settings icon to update the field options/settings.<br />
      - Only published fields will be displayed in students form / profile
      page.<br />
      - Fields marked <b style="color: red"> * </b> is required and mandatory.
    </p>
  </ng-template>
