<div class="container container1580">
    <div class="headingcontent">
      <div class="pageTitle mt-0">
        <h2>Report Cards</h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="!isTeacherUser"><a routerLink="/admin"><i
                  class="fas fa-home"></i> Home</a></li>
            <li class="breadcrumb-item" ><a routerLink="../manage-grade">Grade Book</a></li>
            <li class="breadcrumb-item active" aria-current="page">Report Cards</li>
          </ol>
        </div>
      </div>
 
    </div>
  
    <div class="cardWidget">
        <div class="assesmentTabs">
            <a *ngIf="checkScreenPermission('Grade Book')" routerLink="../manage-grade" routerLinkActive="active"><i class="far fa-bookmark"></i> Grade Book</a>
            <a *ngIf="checkScreenPermission('Report Card')" routerLink="../report-cards" routerLinkActive="active"><i class="far fa-list-alt"></i> Report Cards</a>
            <a *ngIf="checkScreenPermission('Assessment')" routerLink="../manage-assessment" routerLinkActive="active"><i class="far fa-file-alt"></i> Assessment</a>
            <a *ngIf="checkScreenPermission('Grade Setup')" routerLink="../grade-setup" routerLinkActive="active"><i class="fas fa-chalkboard-teacher"></i> Grade Setup</a>
        </div>
        <div class="cardBody" *ngIf="checkActionPermission('View Report Card')">
            <form [formGroup]="filterForm" >
                <div class="row-flex">
                
                    <div class="col-sm-8 col-xl-9 col-md-8 s12 m4 mt-3 mt-md-0">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Enter Student Name</mat-label>
                            <input type="text" (input)="filterSectionListByStudentId($event,'Student')" matInput
                            formControlName="studentID" [matAutocomplete]="classauto">
                            <mat-autocomplete #classauto="matAutocomplete" [displayWith]="displayFn"
                            (optionSelected)="filterSectionListByStudentId($event,'Student')">
                                <mat-option *ngFor="let student of filteredOptions | async" [value]="student">
                                {{ student.studentName }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error
                            *ngIf="filterForm.get('studentID')?.touched && filterForm.get('studentID')?.errors?.required">
                            Please Select a Student
                            </mat-error>
                        </mat-form-field>
                    </div>
                
                    <div class="col-md-4 col-xl-3 s12 m4">
                        <div class="btnsWidget m-0">
                            <button type="button" [disabled]="filterForm.invalid" (click)="onSubmit()" class="btn btn-primary btn-block m-0 mt-1" matRipple>Find </button>
                        </div>
                    </div>
                </div>
            </form>
        
            <div class="reportCardsWidget mt-3 mt-md-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex btnsWidget reportButtons">
                        <div class="custom-check" *ngIf="hideEnableDisableButton">
                            <input type="radio" id="Enabled" name="radio-group" (change)="clickOnEnabled($event)" [checked]="checkRadioReportCardEnable">
                            <label for="Enabled" class="selected"><span>Enabled</span></label>
                        </div>
                        <div class="custom-check" *ngIf="hideEnableDisableButton">
                            <input type="radio" id="Disabled" name="radio-group" (change)="clickOnDisabled($event)" [checked]="checkRadioReportCardDisable">
                            <label for="Disabled"><span>Disabled</span></label>
                        </div>
                    </div>

                    <div *ngIf="showPrintButton">
                        <i class="fas fa-ellipsis-v" [matMenuTriggerFor]="printMenu"></i>
                            <mat-menu #printMenu="matMenu" xPosition="before">
                                <button mat-menu-item [useExistingCss]="true"
                                printSectionId="print-section"
                                ngxPrint>
                                    Print Black &amp; White
                                </button>
                                <button mat-menu-item mat-menu-item [useExistingCss]="true"
                                styleSheetFile="../../../../../../assets/css/theme/default.css" 
                                printSectionId="print-section"
                                ngxPrint>
                                    Print Color
                                </button>
                            </mat-menu>
                    </div>

                </div>
                <div class="row-flex" id="print-section" #pdfTable>
                    <!-- <div class="col-md-4 col-xl-3 s2"> -->
                        <!-- <div class="manageCardLeft">
            
                            <mat-select placeholder="Grade Book" class="form-control borderRadius8  mt-2">
                                <mat-option value=" ">Name 1</mat-option>
                                <mat-option value=" ">Name 2</mat-option>
                                <mat-option value=" ">Name 3</mat-option>
                            </mat-select>

                            <mat-form-field class="w-100 mt-2" appearance="outline">
                                <mat-label>Grade -8 Maths Science </mat-label>
                                <input matInput placeholder="Previous education" required name="Account Name">
                                <mat-error>
                                    Grade -8 Maths Science is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>11- Science Technology
                                </mat-label>
                                <input matInput placeholder="Previous education" required name="Account Name">
                                <mat-error>
                                    Grade -11 Maths Science is required
                                </mat-error>
                            </mat-form-field>
                            
                            <h5 class="mt-3">Report Cards</h5>
                            <mat-form-field class="w-100 mt-2" appearance="outline">
                                <mat-label>Grade -8 Maths Science </mat-label>
                                <input matInput placeholder="Previous education" required name="Account Name">
                                <mat-error>
                                    Grade -8 Maths Science is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>11- Science Technology
                                </mat-label>
                                <input matInput placeholder="Previous education" required name="Account Name">
                                <mat-error>
                                    Grade -11 Maths Science is required
                                </mat-error>
                            </mat-form-field>

                            
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>ER-Economics
                                </mat-label>
                                <input matInput placeholder="ER-Economics" required name="ER-Economics">
                                <mat-error>
                                    ER-Economics is required
                                </mat-error>
                            </mat-form-field>
        
        
                        
        
        
                        </div> -->
                    <!-- </div> -->
                    <div class=" col-md-13 col-xl-12 s12">
                        <div class="reportCardFrame">
                            <app-default-template *ngIf="reportCardFormatID == 0" [studentData] = "studentDetails"></app-default-template>
                            <app-report-cards-template1 *ngIf="reportCardTemplateID == 3" [studentData] = "studentDetails"></app-report-cards-template1>
                            <app-report-cards-template2 *ngIf="reportCardTemplateID == 4" [studentData] = "studentDetails"></app-report-cards-template2>
                            
                        </div>
                    </div>
                </div>
                <div class="row  mt-md-4" *ngIf="showPrintButton">
                    <div class="col-md-10 col-sm-12">
                <div class="notiOptions flex-grow-1">
                   Send  Report Card
                    <div class="notiOptionWidget">
                      <div class="notiOptionList">
                        <div class="leftLabel">Send to: </div>
                        <div class="rightCheckBoxes">
                          <mat-checkbox class="example-margin" (click)="NotificationToParent()">Parents</mat-checkbox>
                          <mat-checkbox class="example-margin" (click)="NotificationToStudent()">Students</mat-checkbox>
                        </div>
                      </div>
                      <div class="notiOptionList">
                        <div class="leftLabel">Send via:</div>
                        <div class="rightCheckBoxes">
                          <mat-checkbox class="example-margin" (click)="NotificationViaSMS()">SMS</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <div class="">
                      <button class="btn btn-primary btn-block" (click)="sendStudentReportCard()" matRipple>Submit
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

