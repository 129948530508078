<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Grade Books</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngIf="!isTeacherUser"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Grade Books</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="cardWidget">
    <div class="assesmentTabs">
      <a *ngIf="checkScreenPermission('Grade Book')" routerLink="../manage-grade" routerLinkActive="active"><i
          class="far fa-bookmark"></i> Grade Book</a>
      <a *ngIf="checkScreenPermission('Report Card')" routerLink="../report-cards" routerLinkActive="active"><i
          class="far fa-list-alt"></i> Report Cards</a>
      <a *ngIf="checkScreenPermission('Assessment')" routerLink="../manage-assessment" routerLinkActive="active"><i
          class="far fa-file-alt"></i> Assessment</a>
      <a *ngIf="checkScreenPermission('Grade Setup')" routerLink="../grade-setup" routerLinkActive="active"><i
          class="fas fa-chalkboard-teacher"></i> Grade Setup</a>
    </div>
    <div class="cardBody" *ngIf="checkActionPermission('View Grade Book')">
      <div class="row">
        <div class="col-lg-10 col-sm-12">
          <div class="row">
            <div class="col-lg-4 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Class</mat-label>


                <!-- <mat-select placeholder="Select Class" [(ngModel)]="SelectedClass" (selectionChange)="onSelectClassChange(SelectedClass)">
                  <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'class')"> -->

                  <input type="text" (input)="onKeyFilter($event, 'class')" matInput 
                   [matAutocomplete]="classauto">
                 

                  <mat-autocomplete #classauto="matAutocomplete" 
                  (optionSelected)="onSelectClassChange($event.option.value)" [displayWith]="dispalyFns.bind(this)" >


                  <mat-option *ngFor="let class of ClassDropdownData" [value]="class.classID" >{{ class.className }}</mat-option>
                <!-- </mat-select> -->
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Section</mat-label>


                
                <!-- <mat-select placeholder="Select Section" [(ngModel)]="SelectedSection" (selectionChange)="onSelectSectionChange(SelectedSection)">
                  <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'section')"> -->


                  <input type="text" (input)="onKeyFilter($event, 'section')" matInput
                   [matAutocomplete]="sectionAuto" [formControl]="selectedData">


                  <mat-autocomplete #sectionAuto="matAutocomplete" (optionSelected)="onSelectSectionChange($event.option.value)"
                  [displayWith]="dispalySectionAuto.bind(this)" >


                  <mat-option *ngFor="let section of SectionDropdownData" [value]="section.sectionID" >{{ section.sectionName }}</mat-option>
                <!-- </mat-select> -->
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Subjects</mat-label>
                <!-- <mat-select placeholder="Select Class" [(ngModel)]="SelectedSubject"> -->


                  <!-- <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'subject')"> -->

                  <input type="text" (input)="onKeyFilter($event, 'subject')" matInput
                  [matAutocomplete]="subjectAuto" [formControl]="selectedData1">


                 <mat-autocomplete #subjectAuto="matAutocomplete" (optionSelected)="onSelectSubjectChange($event.option.value)"
                 [displayWith]="dispalySubjectAuto.bind(this)" >
                 
                  <mat-option *ngFor="let subject of SubjectDropdownData" [value]="subject.subjectID" >{{ subject.subjectName }}</mat-option>

                  


                <!-- </mat-select> -->
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-12">
          <div class="">
            <button type="button" class="btn btn-primary w-100" matRipple (click)="getGradeBook()">Find <svg xmlns="http://www.w3.org/2000/svg"
                width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                  <path id="Path"
                    d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                    transform="translate(-3 -5.95)" fill="#fff" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select Section</mat-label>
              <mat-select placeholder="Select Section">
                <mat-option value="Select Section">Select Section</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
      <!-- <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select Subjects</mat-label>
              <mat-select placeholder="Select Class">
                <mat-option value="Select Class">Select Class</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
    </div>
<div class="row">
  <div class="col-lg-6 col-sm-0" *ngIf="formGroup && this.formGroup?.value.StudentMarks.length > 0"> <button class="btn btn-primary btn-sm" [disabled]="!formGroup?.valid" matRipple (click)="saveGradeBook()">Save</button></div>
  <div class="col-lg-6 col-sm-12">
    <div class="tableActionTop mb-5">

      <div class="rightTableActions centerItemvert">
        <div class="iconsset d-flex">
        </div>

        <div class="buttonsset d-flex align-items-center">

          <div class="enrolmentTable tableActions" *ngIf="formGroup && this.formGroup?.value.StudentMarks.length > 0">


            <!-- <div class="multiCheckstatus mr-2">
              <mat-form-field class="w-100" appearance="outline">
                <mat-select placeholder="Select Status" #select multiple > 
                  <mat-option (change)="onEnrollmentChange($event);" *ngFor="let status of Status" [value]="status">
                  
                    {{status.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <!-- <div class="multiCheckstatus mr-2">
              <mat-form-field class="w-100" appearance="outline">
                <mat-select placeholder="Select Status" #select multiple 
                  (ngModelChange)="onEnrollmentChange($event);">
                  <mat-option (click)="optionClick()" *ngFor="let status of Status" [value]="status.viewValue">
                    {{status.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

            <a mat-button [matMenuTriggerFor]="enrollment" matRipple class="ddButtonType"
              matTooltip="Student and Subject Enrollment Status filter">Enrollment <i
                class="fa fa-caret-down rightItem"></i></a>
            <mat-menu #enrollment="matMenu" xPosition="before" class="actionSelectedDD">
              <ng-container *ngFor="let enrollment of Status; let i =index">
              <button mat-menu-item>
                <mat-checkbox class="example-margin m-0" (change)="onEnrollmentChange($event);" [value]="enrollment.viewValue">
                  {{enrollment.viewValue}}
                </mat-checkbox>
              </button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="selectedColTable tableActions" *ngIf="formGroup && this.formGroup?.value.StudentMarks.length > 0">
            <button mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType">
              <span>Assesment Category</span><i class="fa fa-caret-down rightItem"></i></button>
            <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
              <div>
                <h4 class="actionSelected">Assesment Category</h4>
                <ng-container *ngFor="let category of distinctAssessmentCategories; let i =index">
                  <button mat-menu-item>
                    <mat-checkbox class="example-margin m-0"  (change)="onCategoryChange($event);" [value]="category.AssessmentCategoryId">
                      {{category.AssessmentCategoryName}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </div>
            </mat-menu>
          </div>
          <div class="selectedColTable tableActions" *ngIf="formGroup && this.formGroup?.value.StudentMarks.length > 0">
            <button mat-button [matMenuTriggerFor]="beforeMenunew" class="dotbutton">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
            <mat-menu #beforeMenunew="matMenu" xPosition="before" class="actionSelectedDD">
              <div>
                <!-- <h4 class="actionSelected">Assesment Category</h4>
                    <hr> -->
                <button mat-menu-item (click)="openDialogImport(importStudentGradeBook)">
                  <i title="Import" class="fas fa-upload mr-1"></i> Import
                </button>
                <button mat-menu-item (click)="exportGradeBook()">

                  <i title="Export" class="fas fa-download"></i> Export

                </button>
                <button mat-menu-item (click)="openImportDialog()">

                  <i class="fa fa-table" title="downlaod" ></i> Downlaod import file Template

                </button>


              </div>


            </mat-menu>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

<div class="grade-book-list">
 <div class="table-responsive mb-3" *ngIf="formGroup">
  
  <form [formGroup]="formGroup">
    <table class="mat-table cdk-table">
      <thead class="text-nowrap">
        <ng-container *ngIf="this.formGroup?.value.StudentMarks.length  > 0">
          <th class="mat-header-cell cdk-header-cell ng-star-inserted">
            S.No <span class="ui-column-resizer"> </span>
          </th>
        </ng-container>
        
          <ng-container
            *ngFor="
              let header of Headers();
              let headerIndex = index
            "
          >
          <ng-container *ngIf="!header.hasOwnProperty('Name')">
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">
              {{header}} 
            </th>
          </ng-container>

          <ng-template class="p-2" #tooltip>
            <h5 class="text-center">Assesment</h5>
            <hr>
            <div>Name: {{header.Name}}</div>
            <div>Category: {{header.CategoryName}}</div>
            <div>Points: {{header.Points}}</div>
            <div>Grading Scales: {{header.Type}}</div>
           
             <div *ngIf="header.Type.toLowerCase() === GradingSystem.Grade.toLowerCase()">
               <ul>
                 <ng-container *ngFor="let grade of getGrades(header); let ind = index">
                    <li><strong class="ml-2 mr-2">{{grade.MinValue}}</strong>-<strong class="ml-2 mr-2">{{grade.MaxValue}}</strong>=<strong class="ml-2 mr-2">{{grade.DenotedAlpha}}</strong></li>
                    <hr>
                   </ng-container>
               </ul>
             </div>
           
            <div>Count In Overall: {{header.CountInOverall ? 'Yes': 'No'}}</div>
            <div>Created Date: {{header.CreatedDate}}</div>
        
            
          </ng-template>

          <ng-container *ngIf="header.hasOwnProperty('Name')">
            <th class="mat-header-cell cdk-header-cell ng-star-inserted">
              {{header.Name}} <i *ngIf="header.Type != GradingSystem.CalculatedLowerCase" class="fas fa-info-circle" [popover]="tooltip" triggers="mouseenter:mouseleave"
              [adaptivePosition]="true"
              placement="bottom"></i>
            </th>
          </ng-container>
             
          </ng-container>
      </thead>
      <tbody>
        <mat-form-field class="w-100" appearance="outline" class="ng-hide">
              <input
                matInput
              type="text"
              name="AcademicSessionId"
              class="ng-hide"
              formControlName="AcademicSessionId"
                />
                <input
                matInput
                type="text"
                name="ClassId"
                class="ng-hide"
                formControlName="ClassId"
              />
              <input
                  matInput
                type="text"
                name="SectionId"
                class="ng-hide"
                formControlName="SectionId"
              />
              <input
              matInput
              type="text"
              name="SubjectId"
              class="ng-hide"
              formControlName="SubjectId"
              />
      </mat-form-field>

        <ng-container
          formArrayName="StudentMarks"
          *ngFor="    let student of Students()?.controls;  let studentIndex = index ">
          <tr
            [formGroupName]="studentIndex" *ngIf="chkData(student)"
            class="mat-header-row cdk-header-row ng-star-inserted"
          >
          <td class="mat-cell cdk-cell"> {{(studentIndex + 1)}} </td>

            <td class="mat-cell cdk-cell">
                    <mat-label
                      [hidden]="true"
                    >{{student.value.StudentId}}</mat-label>
                    <mat-label
                    >{{student.value["First Name"]}}</mat-label> 
               
            </td>
            <td class="mat-cell cdk-cell">
              <mat-label
            >{{student.value["Last Name"]}}</mat-label>
            </td>
            <td class="mat-cell cdk-cell">
              <mat-label
            > <span class="tags">{{student.value["Enrollment Status"]}}</span></mat-label>
            </td>

            <ng-container
              formArrayName="Assessments"
              *ngFor="
              let assesstmentHeader of AssetmentHeader
                let assesstmentHeaderIndex = index
              "
            >

              <td [formGroupName]="assesstmentHeaderIndex" class="mat-cell cdk-cell postion-relative cursor-pointer effects">
                <i class="fas fa-comment chatcommnet" (click)="openComments(Comment,studentIndex,assesstmentHeaderIndex)" ></i>
                
                <ng-container *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName === GradingSystem.Calculated">
                  <input type="text" class="s-input-span" aria-label="Small" formControlName="Marks" [value]="calculateMarks(studentIndex,assesstmentHeader.Name)">
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="OverallScoreComment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.OverallScoreComment">
                </ng-container>

                <ng-container *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName === GradingSystem.Manual">
                  <input type="text" class="s-input-span" aria-label="Small" formControlName="Marks" (change)="validateMaxNumber(studentIndex,assesstmentHeader.Name,$event)">
                  <i *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.AssessmentName != 'Overall'" class="fas fa-arrow-circle-down customizeddropdownicon" [matMenuTriggerFor]="dropscores" matRipple ></i>
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="Comment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Comment">
                  <div *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.invalid && 
                  AssessmentsByName(studentIndex,assesstmentHeader.Name)?.errors && 
                  (AssessmentsByName(studentIndex,assesstmentHeader.Name)?.dirty || AssessmentsByName(studentIndex,assesstmentHeader.Name)?.touched)">
                  <small class="text-danger smalled float-r">
                    Invaild Data
                  </small>
                  </div>
                </ng-container>

                <ng-container *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName === GradingSystem.Percentage">
                  <input type="text" class="s-input-span" aria-label="Small" formControlName="Marks" placeholder="%"><span>%</span>
                  <i class="fas fa-arrow-circle-down customizeddropdownicon" [matMenuTriggerFor]="dropscores" matRipple ></i>
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="Comment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Comment">
                </ng-container>

                <ng-container *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName === GradingSystem.Grade">
                  <input type="text" [readonly]="true" class="s-input-span grades" [hidden]="false" aria-label="Small" formControlName="RenderGrade" (click)="getMarksFromGrade($event)">
                  <input type="text" class="s-input-span marks" [readonly]="false" [hidden]="true" aria-label="Small" formControlName="Marks"  [attr.data-marks]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Marks" (blur)="validateGradingChange(studentIndex,assesstmentHeader.Name,$event)">
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="Comment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Comment">
                  <i class="fas fa-arrow-circle-down customizeddropdownicon" [matMenuTriggerFor]="dropscores" matRipple ></i>
                  <div *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.invalid && 
                  AssessmentsByName(studentIndex,assesstmentHeader.Name)?.errors && 
                  (AssessmentsByName(studentIndex,assesstmentHeader.Name)?.dirty || AssessmentsByName(studentIndex,assesstmentHeader.Name)?.touched)">
                  <small class="text-danger float-r">
                     You have entered an invalid grade
                  </small>
                  </div>
                </ng-container>
                

                <ng-container *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName === GradingSystem.Rubric">
                  <input type="text" class="s-input-span" aria-label="Small" formControlName="Marks" (change)="validateMaxNumber(studentIndex,assesstmentHeader.Name,$event)" >/{{AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Points}} <i class="fas fa-th-large customizeddropdownicon boxed mr-1" (click)="openRubric(studentIndex,assesstmentHeader.Name)"></i>  <i class="fas fa-arrow-circle-down customizeddropdownicon" [matMenuTriggerFor]="dropscores" matRipple ></i>
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="Comment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Comment">
                  <div *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.invalid && 
                  AssessmentsByName(studentIndex,assesstmentHeader.Name)?.errors && 
                  (AssessmentsByName(studentIndex,assesstmentHeader.Name)?.dirty || AssessmentsByName(studentIndex,assesstmentHeader.Name)?.touched)">
                  <small class="text-danger smalled float-r">
                    Invaild Data
                  </small>
                  </div>
                </ng-container>

                <ng-container *ngIf="!GradingSystem.GradingScales.includes(AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.TypeName)">
                  <input type="text" class="s-input-span" aria-label="Small" formControlName="Marks">
                  <i *ngIf="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.AssessmentName != 'Overall'" class="fas fa-arrow-circle-down customizeddropdownicon" [matMenuTriggerFor]="dropscores" matRipple ></i>
                  <input type="text" [hidden]="true" class="s-input-span" aria-label="Small" formControlName="Comment" [value]="AssessmentsByName(studentIndex,assesstmentHeader.Name)?.value.Comment">
                </ng-container>
                

                <mat-menu #dropscores="matMenu" xPosition="before" class="actionSelectedDD">
                  <button mat-menu-item>
                   DS(Drop Score)
                  </button>
                  <button mat-menu-item>
                  I(Incomplete)
                  </button>
                  <button mat-menu-item>
                  EX(Excused)
                  </button>
                  <button mat-menu-item>
                 RPL(Recoganition of Previous Learning)
                  </button>
                  <button mat-menu-item>
                  EXE(Exempted)
                  </button>
                  <button mat-menu-item>
                    RS(Resit)
                    </button>
                    <button mat-menu-item>
                      RT(Retake)
                      </button>
        
                </mat-menu>
              </td>

            </ng-container>
           
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div *ngIf="this.formGroup?.value.StudentMarks.length  === 0">
      <div class="alert alert-info">
        <strong>Info!</strong>  No records found...
      </div>
     </div>
  </form>
  </div> 

  <!-- <div class="mat-elevation-z8">
    <table class="table dataTable">
      <thead>
        <tr>S.No</tr>
        <tr>First Name</tr>
        <tr>Last Name</tr>
        <tr>Enrollment Status</tr>
      </thead>
    </table>
  </div> -->
  <ng-container *ngIf="formGroup">
    <a class="text-colored text-underline" (click)="toggleField()">View Edit Logs</a>

    <div class="note"><b>Note: </b>Edit activity is available for previous 30 days only.</div>
    
    <div class="table-responsive mb-3 mt-3" *ngIf="classToggled">
      <table mat-table [dataSource]="dataSourcelogs">
        <ng-container matColumnDef="changes">
          <th mat-header-cell *matHeaderCellDef>Changes</th>
          <td mat-cell *matCellDef="let element"> {{element.changes}} </td>
        </ng-container>
    
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element"> {{element.date +'Z' | date : dateTimeFormat}} </td>
        </ng-container>
    
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let element"> {{element.detail}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnstwo"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnstwo;"></tr>
      </table>
    </div>
  </ng-container>

</div>
</div>


<ng-template class="p-2"  #ClassSchedules>
  <h5 class="text-center">Assesment</h5>
  <hr>
  <div>Name: Pardeep</div>
  <div>Category: Abc</div>
  <div>Examination</div>
  <div>Points: 80</div>
  <div>Grading Scales: Manual Entry</div>
  <div>CCE: Disable</div>
  <div>Count In Overall: Yes</div>
  <div>Created Date: 11-09-2022</div>
 </ng-template>
 <ng-template #importStudentGradeBook>
  <div class="dialog-lg">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
          <h2 mat-dialog-title>Import Grade Book Scores</h2>
      </div>
      <div class="widget widget-tabs">
          <div class="widget-head customTabHeaderParent">
              <ul class="d-flex">
                  <li id="uploadStudentTab" class="customTabHeader active" [ngClass]="{'selected': hideUploadTab === false}"><i class="fas fa-upload mr-2"></i> 1. Upload
                  </li>
                  <li id="importStudentTab" class="customTabHeader active" [ngClass]="{'selected': hideUploadTab === true}"><i class="fas fa-file-import mr-2"></i> 2. Import
                  </li>
              </ul>
          </div>
          <div class="widget-body mt-3">
              <div class="tab-content box-shadow-none">
                  <div class="tab-pane active" id="tab1"  *ngIf="!hideUploadTab">
                      <form id="fileUploadForm mt-3" action="/1/settings/uploadFileCheck" method="post">
                          <input #fileInput type="file" accept=".xlsx" (change)="onFileSelected()">
                         
                          <div id="toBeUpdatedBlock" class="radio-checkbox-block" style="margin-top: 20px;">

                          </div>
                          
                          <div class="text-right">
                              <div style="margin-top: 15px ;">
                                  <button id="uploadFile" matRipple class="btn btn-primary btn-sm" [disabled]="!isFileSelected"
                                  (click)="addFile()"><i
                                          class="fa fa-upload"></i> Upload</button>
                              </div>
                              <div class="animated-loader-block" id="uploadLoader"
                                  style="display:none; margin:0px 0px 0px 5px;top:8px;">
                                  <div class="loader"></div>
                              </div>
                          </div>

                          <div id="response_block"
                              style="line-height: 25px ; font-weight: 700 ; max-height: 290px; overflow: auto;  ">
                          </div>
                          <input type="hidden" name="_custCSRFToken"
                              value="VjYrK1NraWZMdVFaRnNrRGw1N1psVGpxUFN5U08wVkxGZkwzdHJhLzZtbz0="><input
                              type="hidden" name="_acds_id" value="1">
                      </form>



                  </div>
              </div>
           
              <div class="importfields importforms" >
                              <div class="fluid-container">
                                
                                  <ng-container #mappingContainer></ng-container>
                              </div>
              </div>
         
          </div>
      </div>
  </div>

</ng-template>


 <ng-template #Comment let-data>
  <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle">
          <h2 mat-dialog-title>Comment</h2>
      </div>
      <mat-dialog-content class="mat-typography pb-2">
      <ejs-richtexteditor  #richComments  required [value]="data.Comment"
      [toolbarSettings]='tools'>
    
    </ejs-richtexteditor> 

    <div class="text-right pt-4">
      <button class="btn btn-primary btn-sm" (click)="patchCommentsData(data.studentIndex,data.assesstmentIndex)" matRipple>Save</button>
      <button class="btn btn-reset btn-sm ml-2" matRipple (click)="closeComments()">Cancel</button>
    </div>
  
   </mat-dialog-content>
  </div>
</ng-template>
<ng-template #rubric>
  <div class="dialog-lg">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle">
          <h2 mat-dialog-title>Rubric Grading Scale</h2>
      </div>
      <mat-dialog-content class="mat-typography pb-2">
  
          
  
          <div class="">
              <div class="gradeListHead gradelistWidget">
                  <div class="criteriaCol">Criteria</div>
                  <div class="scaleItemsCol">Grading Scale</div>
                  <div class="scalePointsCol text-right">Points</div>
              </div>
              <div class="gradeListrow gradelistWidget">
                  
                  <div class="titlesofRubic">
                      <div class="criteriaCol titles" >Title</div><br>
                     <div class="criteriaCol subtitles" >Sub title</div>
                   </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >5</div>
                          <div class="scaleText" >Excellent</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >3</div>
                          <div class="scaleText" >Good</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >2</div>
                          <div class="scaleText" >Satisfactory</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >1</div>
                          <div class="scaleText" >Needs Improvement</div>
                          <div class="scaleTexticons"></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right ml-3">5</div>
                  <!-- <mat-icon class="deleteIconRight">delete</mat-icon> -->
              </div>
              <div class="gradeListrow gradelistWidget">
                 
                  <div class="titlesofRubic">
                      <div class="criteriaCol titles" >Title</div><br>
                     <div class="criteriaCol subtitles" >Sub title</div>
                   </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >5</div>
                          <div class="scaleText" >Excellent</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >3</div>
                          <div class="scaleText" >Good</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >2</div>
                          <div class="scaleText" >Satisfactory</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >1</div>
                          <div class="scaleText" >Needs Improvement</div>
                          <div class="scaleTexticons"></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right ml-3">5</div>
                  <!-- <mat-icon class="deleteIconRight">delete</mat-icon> -->
              </div>
              <div class="gradeListrow gradelistWidget">
               
                      <div class="titlesofRubic">
                          <div class="criteriaCol titles" >Title</div><br>
                         <div class="criteriaCol subtitles" >Sub title</div>
                       </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >5</div>
                          <div class="scaleText" >Excellent</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >3</div>
                          <div class="scaleText" >Good</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >2</div>
                          <div class="scaleText" >Satisfactory</div>
                          <div class="scaleTexticons"></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" >1</div>
                          <div class="scaleText" >Needs Improvement</div>
                          <div class="scaleTexticons"></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right ml-3">5</div>
                  <!-- <mat-icon class="deleteIconRight">delete</mat-icon> -->
              </div>
  
              <div class="gradeListrowTotal gradelistWidget">
                  <div class="criteriaCol"><a href="javascript:void(0)" class="underlinedLink"></a></div>
                  <div class="scaleItemsCol text-right">Total Points</div>
                  <div class="scalePointsCol text-right pr-4">15</div>
              </div>
          </div>
  
    
      </mat-dialog-content>
    <div class="text-right pt-4">
      <button class="btn btn-primary btn-sm" matRipple>Save</button>
      <button class="btn btn-reset btn-sm ml-2" matRipple>Close</button>
    </div>
  
  
  </div>
</ng-template>

<ng-template #importfiles>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
        <h2 mat-dialog-title>Import Grade Book Scores</h2>
    </div>
    <mat-dialog-content class="pb-2">
      <div class="widget widget-tabs">
        <div class="widget-head customTabHeaderParent">
            <ul class="d-flex">
                <li id="uploadStudentTab" class="customTabHeader active selected" ><i class="fas fa-upload mr-2"></i> 1. Upload
                </li>
                <li id="importStudentTab" class="customTabHeader active" ><i class="fas fa-file-import mr-2"></i> 2. Import
                </li>
            </ul>
            </div>
            <div class="widget-body mt-3">
              <div class="tab-content box-shadow-none">
                  <div class="tab-pane active" id="tab1" >
                      <form id="fileUploadForm mt-3" action="/1/settings/uploadFileCheck" method="post">
                          <input #fileInput type="file" accept=".xlsx">
                      </form>
                  </div>

              </div>
            </div>
            <div class="widget-body mt-3">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Admission Number</mat-label>
                    <mat-select placeholder="Admission Number" formControlName="Admission Number">
                      <mat-option value="Admission Number">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a Admission Number</mat-error>
                  </mat-form-field>
    
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Overall Score Comment</mat-label>
                    <mat-select placeholder="Overall Score Comment" formControlName="CSE Score">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a CSE Score</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>CSE Score</mat-label>
                    <mat-select placeholder="CSE Score" formControlName="CSE Score">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a CSE Score</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>CSE Comment</mat-label>
                    <mat-select placeholder="CSE Comment" formControlName="CSE Comment">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a CSE Comment</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>New Assment Score</mat-label>
                    <mat-select placeholder="New Assment Score" formControlName="New Assment Score">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a New Assment Score</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>New Assment Comment</mat-label>
                    <mat-select placeholder="New Assment Comment" formControlName="New Assment Comment">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a New Assment Comment</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Unit Test Ass Score</mat-label>
                    <mat-select placeholder="Unit Test Ass Score" formControlName="Unit Test Ass Score">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a Unit Test Ass Score</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Unit Test Ass Comment</mat-label>
                    <mat-select placeholder="Unit Test Ass Comment" formControlName="Unit Test Ass Comment">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a Unit Test Ass Comment</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Check Grade Score</mat-label>
                    <mat-select placeholder="Check Grade Score" formControlName="Check Grade Score">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a Check Grade Score</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Check Grade  Comment</mat-label>
                    <mat-select placeholder="check Grade Comment" formControlName="check Grade Comment">
                      <mat-option value="CSE Score">123</mat-option>
                    </mat-select>
                    <mat-error>
                      Please Select a check Grade  Comment</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
         
        
        </div>
        <div class="form-group17 text-right mt-3">
          <button class="btn btn-reset btn-sm" mat-dialog-close>Back</button>
          <button class="btn btn-primary btn-sm ml-2" mat-dialog-close>Import</button>
        
        </div>
      </mat-dialog-content>
  </div>
</ng-template>
<ng-template #downlaodimport>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Import File Template</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a  class="tags underlinedLink">Select All</a> / <a 
          class="tags underlinedLink">Unselect All</a>
      </p>
      <div class="row-flex">
        <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
            Admission Number
          </mat-checkbox>
        </div>
        <div  class="col s12 m6 form-group">
         
        </div>
        <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
           First Name
          </mat-checkbox>
        </div>
        <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
           Last Name
          </mat-checkbox>
        </div>
        <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
           Overall Score Comment
          </mat-checkbox>
        </div>
        <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
            CSE Score
           </mat-checkbox>
          </div>
           <div  class="col s12 m6 form-group">
          <mat-checkbox class="example-margin">
            CSE Comment
           </mat-checkbox>
          </div>
          <div  class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
             New Assesment Score
             </mat-checkbox>
            </div>
            <div  class="col s12 m6 form-group">
              <mat-checkbox class="example-margin">
               New Assesment Comment
               </mat-checkbox>
              </div>
              <div  class="col s12 m6 form-group">
                <mat-checkbox class="example-margin">
                 Unit Test Ass Score 
                 </mat-checkbox>
                </div>
                <div  class="col s12 m6 form-group">
                  <mat-checkbox class="example-margin">
                   Unit Test Ass Comment
                   </mat-checkbox>
                  </div>
                  <div  class="col s12 m6 form-group">
                    <mat-checkbox class="example-margin">
                     check Grade Score 
                     </mat-checkbox>
                    </div>
                    <div  class="col s12 m6 form-group">
                      <mat-checkbox class="example-margin">
                       check Grade Comment 
                       </mat-checkbox>
                      </div>
      </div>
      <div class="form-group17 text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close>Downlaod</button>
      </div>

      </mat-dialog-content>
    </div>
</ng-template>

