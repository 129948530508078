import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../../../assesment-and-gradings/services/manage-assessment-and-gradings.service';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-rcard',
  templateUrl: './rcard.component.html',
  styleUrls: ['./rcard.component.css']
})
export class RCardComponent implements OnInit {
  reportCardClassSecForm: FormGroup;
  showPrintButton: boolean = false;
  IsStudentOrPreant: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    ) { 
      this.activatedRoute.queryParams.subscribe(params => {
        this.studentID = params.id == undefined ? null : params.id;
      });
    }

  studentID: any;
  ngOnInit(): void {
    this.getAllStudentClass();
    var roleID  = localStorage.getItem('roleID')
    if(roleID== UserRole.Student || roleID== UserRole.Parent){
      this.IsStudentOrPreant = true;
    }
  }

  studentList: any;
  studentName: any;
  ClassID: any;
  SectionID: any;
  getAllStudentClass() {
    this.manageAssessmentService.GetAllStudentID(this.studentID).subscribe((res: any) => {
      this.studentList = res.responseData;
      this.studentName = this.studentList[0].showName;
      console.warn(this.studentList);
    });
  }
  studentDetails: any;
  reportCardTemplateID: any = 0;
  reportCardFormatID: any;
  reportCardEnable: any;

  getReportCard(data: any) {
    
    let details = this.studentList.filter((x: any) => x.id == data.value);
    this.ClassID = details[0].classID;
    this.SectionID = details[0].sectionID;

    this.manageAssessmentService.CheckReportCardEnableDisable(this.studentID, this.ClassID, this.SectionID).subscribe((res: any) => {
      if(res.responseData==true){
        this.getData();
      }
      else{
        this.toaster.error('Report Card is not enabled by Administrator/Organization.');
      }
    });
  }
  getData() {
    this.manageAssessmentService.GetStudentAssignedTemplate(this.studentID, this.ClassID, this.SectionID).subscribe((res: any) => {
      this.showPrintButton = true;
      this.studentDetails = res.responseData;
      this.reportCardTemplateID = res.responseData.studentDetails[0]?.ReportCardtemplateID;
      this.reportCardFormatID = res.responseData.studentDetails[0]?.ReportCardsFormatID;
    });
  }

}
