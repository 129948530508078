<div class="container container1580">
  <div class="headingcontent">
      <div class="pageTitle mt-0">
        <h2>Manage Attendence Settings</h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
            <li *ngIf="this.id=='1'|| this.id=='3'"class="breadcrumb-item"><a routerLink="../manage-student-attendance"> Manage Student Attendance</a></li>
            <li *ngIf="this.id=='2' || this.id=='4'"class="breadcrumb-item"><a routerLink="../manage-teacher-attendance"> Manage Teacher's Attendance</a></li>
           
            <!-- <li *ngIf="checkScreenPermission('Attendance Submission Analytics') && (this.id=='1' || this.id=='3')" class="breadcrumb-item"><a routerLink="../student-submission-analytics"> Attendance Submission
              Analytics</a></li>

            <li *ngIf="this.id=='2' || this.id=='4'"class="breadcrumb-item"><a routerLink="../attendance-submission-analytics"> Attendance Submission
              Analytics</a></li> -->


            <li class="breadcrumb-item active" aria-current="page">Manage Attendence Settings</li>
          </ol>
        </div>
      </div>
    
    </div>
<div class="cardWidget">
  <div class="assesmentTabs">
    <a *ngIf="checkScreenPermission('Manage Attendance') && (this.id=='1' || this.id=='3') " routerLink="../manage-student-attendance" routerLinkActive="active"><i class="fas fa-tasks"></i> Manage Student Attendance</a>
    <a *ngIf="this.id=='2'|| this.id=='4'" routerLink="../manage-teacher-attendance" routerLinkActive="active"><i class="fas fa-tasks"></i> Manage Teacher's Attendance</a>
    <a *ngIf="checkScreenPermission('Attendance Submission Analytics') && (this.id=='1' || this.id=='3')" routerLink="../student-submission-analytics" routerLinkActive="active"><i class="fas fa-chart-line"></i> Attendance Submission Analytics</a>
    <a *ngIf="this.id=='2' || this.id=='4'" routerLink="../attendance-submission-analytics"  routerLinkActive="active"><i
      class="fas fa-chart-line"></i> Attendance Submission
  Analytics</a>
    <a style="pointer-events: none;" *ngIf="checkScreenPermission('Attendance Status Setting')" routerLink="../attendance-settings" routerLinkActive="active"><i class="fas fa-cog"></i>Settings</a>
</div>
    <div class="cardBody">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-8">
              <h4 class="mb-4">Attendance Status</h4>
            </div>
            <div class="col-4">
              <button *ngIf="checkActionPermission('Add Status')" class="btn btn-primary btnsmall float-right" (click)="addStatus(addStatusPopup)" matRipple><i
                  class="fa fa-plus"></i> Add New
                Status</button>
            </div>
          </div>

          <p class="info-text">Manage required attendace status (e.g. Present. Absent, Tardy etc.)</p>
          <div class="table-responsive" *ngIf="checkActionPermission('List Status')">
            <table class="mat-table">
              <thead class="mat-table cdk-table mat-sort">
                <tr class="mat-header-row cdk-header-row">
                  <th class="mat-header-cell cdk-header-cell ">status</th>
                  <th class="mat-header-cell cdk-header-cell ">keyword</th>
                  <th class="mat-header-cell cdk-header-cell ">shortcode</th>
                  <th class="mat-header-cell cdk-header-cell ">color</th>
                  <th class="mat-header-cell cdk-header-cell ">actions</th>

                </tr>
              </thead>
              <tbody *ngIf="AttendanceStatusList != null; else elseBlock">
                <tr class="mat-row cdk-row" *ngFor="let att of AttendanceStatusList">
                  <td class="mat-cell cdk-cell">{{att.statusName}}</td>
                  <td class="mat-cell cdk-cell">{{att.keywordCode}}</td>
                  <td class="mat-cell cdk-cell">{{att.statusSortCode}}</td>
                  <!-- <td class="mat-cell cdk-cell"><span class="attendance-color success"></span></td> -->
                  <td class="mat-cell cdk-cell">
                    <div class="colorsselected" [ngStyle]="{'background-color': att.colorCode }">
                    </div>
                  </td>
                  <td class="mat-cell cdk-cell">
                    <button
                    *ngIf="checkActionPermission('Edit Status')" class="tbactionbtn ng-star-inserted" title="Edit"
                      (click)="editStatus(att.attendanceStatusMasterID, addStatusPopup)"><i aria-hidden="true"
                        class="fa-edit fas"></i></button>
                    <button class="tbactionbtn ng-star-inserted" title="Delete" *ngIf="checkActionPermission('Delete Status') && !att.isDefault"><i
                        (click)="deleteStatus(att.attendanceStatusMasterID)" aria-hidden="true"
                        class="fa-trash fas"></i></button>
                  </td>
                </tr>
              </tbody>
              <ng-template #elseBlock>
                No Attendance Status Found
              </ng-template>
            </table>

          </div>



        </div>
        <div class="col-md-6">

          <!-- commenting code as suggested by client(it is suggestion if the functionality is not present then it should be hide for a time being.) -->

          <!-- <h4 class="mb-4">Attendance Sessions Meetings</h4>
          <p class="info-text mt-fit">Manage settings like whether you want subject wise attendance or not and set sessions if
            you need
            attendace for multiple times each day.
          </p> -->

          <!-- <div class="attendance-options">

            <table>
              <tr>
                <td width="210"> <label for="">Subject wise attendance?</label></td>
                <td>
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1">Yes</mat-radio-button>
                    <mat-radio-button class="pl-0" value="2">No</mat-radio-button>
                  </mat-radio-group>
                </td>
              </tr>
              <tr>
                <td width="210"><label for="">Mutliple sessions each day</label></td>
                <td>
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1">Yes</mat-radio-button>
                    <mat-radio-button class="pl-0" value="2">No</mat-radio-button>
                  </mat-radio-group>
                </td>
              </tr>
            </table>
          </div> -->


          <!-- <h4 class="mb-4 mt-4">Manage Sessions</h4>

          <div class="table-responsive">
            <table class="mat-table">
              <thead class="mat-table cdk-table mat-sort">
                <tr class="mat-header-row cdk-header-row">
                  <th class="mat-header-cell cdk-header-cell ">Sr.No</th>
                  <th class="mat-header-cell cdk-header-cell ">Session Id</th>
                  <th class="mat-header-cell cdk-header-cell ">Name</th>
                  <th class="mat-header-cell cdk-header-cell ">Actions</th>


                </tr>
              </thead>
              <tbody>
                <tr class="mat-row cdk-row">
                  <td class="mat-cell cdk-cell">present</td>
                  <td class="mat-cell cdk-cell">keyword 1</td>
                  <td class="mat-cell cdk-cell">LS</td>
                  <td class="mat-cell cdk-cell">
                    <button class="tbactionbtn ng-star-inserted" title="Edit"><i aria-hidden="true"
                        class="fa-edit fas"></i></button>
                    <button class="tbactionbtn ng-star-inserted" title="Delete"><i aria-hidden="true"
                        class="fa-trash fas"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->






<ng-template #addStatusPopup>
  <div class="dialog-md">
    <div class="dialogTitle center-align loginTtile">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <h2 mat-dialog-title *ngIf="updateTextInPopUp">Update Status</h2>
      <h2 mat-dialog-title *ngIf="!updateTextInPopUp">Add Status</h2>
      <!-- <h2 mat-dialog-title *ngIf="form.value['StatusName'] != ''">Update Status</h2>
      <h2 mat-dialog-title *ngIf="form.value['StatusName'] == ''">Add Status</h2> -->
    </div>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Status </mat-label>
          <input required formControlName="StatusName" pattern="^[^\s]+(\s+[^\s]+)*$" matInput
            placeholder="e.g. Present or Absent" (keydown.space)="checkWhiteSpace($event)" maxlength="100">
          <mat-error *ngIf="form.get('StatusName')?.touched  && form.get('StatusName')?.errors?.required">
            Status name is required
          </mat-error>
          <mat-error *ngIf="form.get('StatusName')?.hasError('pattern')">
            Please enter valid status name
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Shortcode</mat-label>
          <input required type="text" formControlName="StatusSortCode" pattern="^[^\s]+(\s+[^\s]+)*$" matInput
            placeholder="e.g. P or A" (keydown.space)="checkWhiteSpace($event)" maxlength="20">
          <mat-error *ngIf="form.get('StatusSortCode')?.touched  && form.get('StatusSortCode')?.errors?.required">
            Status code is required
          </mat-error>
          <mat-error *ngIf="form.get('StatusSortCode')?.hasError('pattern')">
            Please enter valid status code
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline" *ngIf="!updateTextInPopUp">
          <mat-label>Keyword</mat-label>
          <input required type="text" formControlName="keywordCode" pattern="^[^\s]+(\s+[^\s]+)*$" matInput
            (keydown.space)="checkWhiteSpace($event)" maxlength="20">
          <mat-error *ngIf="form.get('keywordCode')?.touched  && form.get('keywordCode')?.errors?.required">
            Keyword is required
          </mat-error>
          <mat-error *ngIf="form.get('keywordCode')?.hasError('pattern')">
            Please enter valid Keyword
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="w-100" appearance="outline" floatLabel="always">
          <mat-label>Color</mat-label>
          <input class="colortag" matInput [(colorPicker)]="color" (colorPickerChange)="colorChange($event)" formControlName="ColorCode"
             [style.background]="color" />
          <mat-error *ngIf="form.get('ColorCode')?.touched  && form.get('ColorCode')?.errors?.required">
            Color is required
          </mat-error>
        </mat-form-field> -->
        <div class="form-group dflex w-100">
          <input class="colortag" pattern="[0-9]+" matInput [(colorPicker)]="color" (colorPickerChange)="colorChange($event)" autocomplete="off"
            [style.background]="color" />
          <p class="Color-tags-heading ml-3">Pick the Tags Color:</p>
        </div>
        <mat-checkbox class="example-margin" (change)="changeIsRelatedWithPresentAbsent($event.checked)"
          [checked]="isRelatedWithPresentAbsent">
          Relate with Present & Absent
        </mat-checkbox>

        <div class="mx-4 my-2" *ngIf="isRelatedWithPresentAbsent">
          1 (custom status) equals
          <input type="text" class="popinputdesign" required formControlName="PercentagePresent" (keypress)="allowOnlyNumber($event)" pattern="[0-9]+" [min]=0 [max]=100 (keyup) = "maintainPresentAbsent($event)" >
          % Present and
          <input type="text" class="popinputdesign" required formControlName="PercentageAbsent" (keypress)="allowOnlyNumber($event)" pattern="[0-9]+" [min]=0 [max]=100  (keyup) = "maintainPresentAbsent($event)">
          % Absent
        </div>
        <mat-error *ngIf="totalSumOfPresentAndAbsent">
          Sum of present % and absent % should be equal to 100
        </mat-error>
        <mat-error *ngIf="(form.get('PercentagePresent')?.hasError('max') || form.get('PercentageAbsent')?.hasError('max')) && totalSumOfPresentAndAbsent==false">
          Please enter 0 to 100 number.
        </mat-error>
        <mat-error *ngIf="form.get('PercentagePresent')?.hasError('pattern') || form.get('PercentageAbsent')?.hasError('pattern')">
          Please enter whole number.
        </mat-error>
        <div class="proSection float-right mt-2">
          <button type="submit" [disabled]="form.invalid || totalSumOfPresentAndAbsent" class="btn btn-sm btn-primary mr-2" matRipple>Submit</button>
          <button class="btn btn-reset btn-sm" mat-dialog-close matRipple>Close</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>