import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { StudentService } from 'src/app/services/student.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/common/core-services/common.service';
//import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { Router, ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MasterService } from 'src/app/services/master.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { UserRole } from 'src/environments/environment';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-student-submission-analytics',
  templateUrl: './student-submission-analytics.component.html',
  styleUrls: ['./student-submission-analytics.component.css']
})
export class StudentSubmissionAnalyticsComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild('SelectedInput') SelectedInput: ElementRef;
  teacherCtrl = new FormControl();
  displayedColumns: string[] = ['date', 'class', 'section', 'subject', 'session'];
  dataSource: any;
  campaignOne: FormGroup;
  campaignTwo: FormGroup;
  users: any;
  user: any;
  permissions: any;
  multiselector: FormGroup;
  multiSubject: FormGroup;
  multiTeacher: FormGroup;
  toppings = new FormControl('', Validators.required);
  subjects = new FormControl();
  teacher = new FormControl();
  selectedItems: any[] = [];
  dropdownSettings: any = {};
  dropdownSettingsforsubject: any = {};
  dropdownSettingsforTeacher: any = {};
  disabled = false;
  ViewStudent = new FormControl();
  filteredTeacher: Observable<any[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  toppingList: any
  teacherData: any
  ShowFilter = true;
  dateFormat: any;
  isTeacherUser: boolean = false;
  // = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];


  dateArray: any = [];
  myArr: any = [];
  constructor(private studentService: StudentService,
    private commonService: CommonService, private route: Router,
    private toaster: ToastrService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService
    //private teacherService:StudentService,
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
      this.dateFormat = currentUser.dateFormat;
    }
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.campaignOne = new FormGroup({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
    });
    this.campaignTwo = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.multiselector = this.formBuilder.group({
      selectedClassSectionID: [this.selectedItems]
    }
    );
    this.multiSubject = this.formBuilder.group({
      subject: [this.selectedItems]
    }
    );
    this.multiTeacher = this.formBuilder.group({
      teacher: [this.selectedItems]
    }
    );






    this.filteredTeacher = this.teacherCtrl.valueChanges.pipe(
      startWith(null),
      map((parent: string | null) => parent ? this._filterTeacher(parent) : this.individualTeacherdata.slice()));
    console.log(this.filteredTeacher);






  }
  lenChk: number = 0;
  valueChk(data: any) {
    if (data = []) {
      this.showCheckBox == false;
      this.subjectCheckBox = false;
      this.teacherCheckBox = false;


    }
    else if (data.length != this.lenChk && this.showCheckBox == true) {
      this.SelectedSubjects();
    }
    this.lenChk = data.length;
    console.log(this.lenChk);
  }
  ngOnInit(): void {
    //Legasov Added 25 March 2022
    var roleID = localStorage.getItem('roleID')
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    this.permissionService.checkComponentHasScreenPermission('Attendance Submission Analytics');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    console.warn('Data In Data Source', this.dataSource)

    //  this.subjectData='';
    //this.getStudentAnalyticsRecords();
    this.getClassSection();
    //this.getTeacher();
    //this.getStudentSubjects();
    //  this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'idDropDown',
    //   textField: 'dropDown',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: this.ShowFilter
    // };
    // this.dropdownSettingsforsubject={
    //   singleSelection: false,
    //   idField: 'subjectID',
    //   textField: 'subjectName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: this.ShowFilter
    // }
    // this.dropdownSettingsforTeacher={
    //   singleSelection: false,
    //   idField: 'teacherID',
    //   textField: 'teacherName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: this.ShowFilter
    // }


  }







  subjectData: any
  getStudentSubjects() {
    this.masterService.GetSubjectDropdown().subscribe((res: any) => {
      this.subjectData = res.responseData;
    })
  }
  studentAnalyticsData: any;
  getClassSection() {
    this.studentService.GetCombineClassSectionForAnalytics().subscribe((res: any) => {
      this.toppingList = res.responseData
      console.log('Classsection Data', this.toppingList)
    })
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }
  checkActionPermission(actionName: any) {
    let screen = 'Attendance Submission Analytics';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  getStudentAnalyticsRecordsFinal() {
    this.studentService.GetStudentAnalyticsRecordsFinal(this.dataObj).subscribe((res: any) => {
      if (res.responseData == null) {
        this.dataSource = ''
        this.studentAnalyticsData = [];
        this.dateArray = []
        this.toaster.error("No record found")
      }
      else {
        if (res.statusCode == 200) {
          this.dataSource = ''
          this.myArr = [];
          this.studentAnalyticsData = [];

          this.dateArray.forEach((element: any) => {
            let data = res.responseData.filter((x: any) => moment(x.date).format('YYYY-MM-DD') == element);
            let subjectDatas = [...new Map(res.responseData.map((item: any) =>
              [item['subjectID'], item])).values()];

            subjectDatas.forEach((x: any) => {
              let filterData = data.filter((y: any) => y.subjectID == x.subjectID);
              if (filterData.length > 0) {
                let obj: StudentAnalyticsRecords = {
                  Date: element,
                  classID: filterData[0].classID,
                  sectionID: filterData[0].sectionID,
                  subjectID: filterData[0].subjectID,
                  className: filterData[0].className,
                  sectionName: filterData[0].sectionName,
                  subjectName: filterData[0].subjectName,
                  TeacherName: filterData[0].teacherName,
                  attendanceStatusMasterID: filterData[0].attendanceStatusMasterID,
                  DateFromDB: filterData[0].date ? moment(filterData[0].date).format('YYYY-MM-DD') : element,
                  TeacherID: filterData[0].teacherID,
                  keyy: 'autoclick',
                  IsSubmitted: filterData[0].isSubmitted
                }
                this.studentAnalyticsData.push(obj);
              } else {
                let obj: StudentAnalyticsRecords = {
                  Date: element,
                  classID: x.classID,
                  sectionID: x.sectionID,
                  subjectID: x.subjectID,
                  className: x.className,
                  sectionName: x.sectionName,
                  subjectName: x.subjectName,
                  TeacherName: x.teacherName,
                  attendanceStatusMasterID: x.attendanceStatusMasterID,
                  DateFromDB: x.date ? moment(x.date).format('YYYY-MM-DD') : element,
                  TeacherID: x.teacherID,
                  keyy: 'autoclick',
                  IsSubmitted: false
                }
                this.studentAnalyticsData.push(obj);
              }

            });
          });
          if (this.dataObj.view == 'all') {
            this.dataSource = this.studentAnalyticsData;
          }
          else {
            this.dataSource = this.studentAnalyticsData.filter((x: any) => x.IsSubmitted == false);
          }
          this.dateArray = []

        }
        else {
          this.dataSource = ''
          this.studentAnalyticsData = [];
          this.dateArray = []
        }
      }
    });
  }

  getStudentAnalyticsRecords() {
    this.studentService.GetStudentAnalyticsRecords(this.dataObj).subscribe((res: any) => {
      console.warn('Data In Data Source', res.responseData)
      console.log('Satarting Data Source', this.dataSource)
      console.log('starting Student Analytics', this.studentAnalyticsData)
      if (res.responseData == null) {
        this.dataSource = ''
        this.studentAnalyticsData = [];
        this.dateArray = []
        this.toaster.error("No record found")
      }
      else {
        if (res.statusCode == 200) {
          this.dataSource = ''
          this.myArr = [];
          this.studentAnalyticsData = [];

          console.log('mid Data Source', this.dataSource)
          console.log('mid Student Analytics', this.studentAnalyticsData)

          // start
          let resdta = res.responseData;
          let finaldata = res.responseData;
          resdta.forEach((element: any, key: any) => {
            if (element.date == '') {
              let tt = finaldata.filter((x: any) => {
                return (x.classID == element.classID && x.sectionID == element.sectionID && x.subjectID == element.subjectID && x.teacherID == element.teacherID)
              });
              if (tt.length === 1) {
                this.myArr.push(finaldata[key]);
              }
            } else {
              let ttt = finaldata.filter((x: any) => {
                return (x.classID == element.classID && x.sectionID == element.sectionID && x.subjectID == element.subjectID && x.teacherID == element.teacherID && x.date == element.date)
              });
              if (ttt) {
                let lenn = ttt.length - 1;
                let idx = finaldata.indexOf(ttt[lenn]);
                this.myArr.push(finaldata[idx]);
                for (let i = 0; i < ttt.length; i++) {
                  let idx = finaldata.indexOf(ttt[i]);
                  delete finaldata[idx];
                }
              }
            }
          });
          // end


          ////debugger;
          finaldata = this.myArr;

          this.myArr.forEach((x: any) => {

            let ttt = finaldata.filter((element: any) => {
              return (element.classID == x.classID && element.sectionID == x.sectionID && element.subjectID == x.subjectID && element.teacherID == x.teacherID)
            });
            if (ttt) {
              this.dateArray.forEach((element: any) => {
                let dtcase = ttt.filter((z: any) => {
                  let tt = moment(z.date).format('YYYY-MM-DD');
                  return (tt == element || z.date == '')
                });
                if (dtcase.length > 0) {
                  dtcase.forEach((y: any) => {
                    let obj: StudentAnalyticsRecords = {
                      Date: element,
                      classID: y.classID,
                      sectionID: y.sectionID,
                      subjectID: y.subjectID,
                      className: y.className,
                      sectionName: y.sectionName,
                      subjectName: y.subjectName,
                      TeacherName: y.teacherName,
                      attendanceStatusMasterID: y.attendanceStatusMasterID,
                      DateFromDB: y.date ? moment(y.date).format('YYYY-MM-DD') : element,
                      TeacherID: y.teacherID,
                      keyy: 'autoclick'
                    }
                    this.studentAnalyticsData.push(obj);
                    let idx = finaldata.indexOf(y);
                    delete finaldata[idx];
                  })
                } else {
                  let obj: StudentAnalyticsRecords = {
                    Date: element,
                    classID: x.classID,
                    sectionID: x.sectionID,
                    subjectID: x.subjectID,
                    className: x.className,
                    sectionName: x.sectionName,
                    subjectName: x.subjectName,
                    TeacherName: x.teacherName,
                    attendanceStatusMasterID: x.attendanceStatusMasterID,
                    DateFromDB: element,
                    TeacherID: x.teacherID,
                    keyy: 'autoclick'
                  }
                  this.studentAnalyticsData.push(obj);
                }

              })

            }
          })


          // this.dateArray.forEach((x: any) => {
          //   this.myArr.forEach((y: any) => {
          //    if(x===moment(y.date).format('YYYY-MM-DD')){
          //       let obj: StudentAnalyticsRecords = {
          //         Date: x,
          //         classID: y.classID,
          //         sectionID: y.sectionID,
          //         subjectID: y.subjectID,
          //         className: y.className,
          //         sectionName: y.sectionName,
          //         subjectName: y.subjectName,
          //         TeacherName:y.teacherName,
          //         attendanceStatusMasterID:y.attendanceStatusMasterID,
          //         DateFromDB:moment(y.date).format('YYYY-MM-DD'),
          //         TeacherID:y.teacherID,
          //         keyy:'autoclick'
          //      }
          //      this.studentAnalyticsData.push(obj)
          //     }

          //     if(y.date==""){
          //       let obj: StudentAnalyticsRecords = {
          //         Date: x,
          //         classID: y.classID,
          //         sectionID: y.sectionID,
          //         subjectID: y.subjectID,
          //         className: y.className,
          //         sectionName: y.sectionName,
          //         subjectName: y.subjectName,
          //         TeacherName:y.teacherName,
          //         attendanceStatusMasterID:y.attendanceStatusMasterID,
          //         DateFromDB:x,
          //         TeacherID:y.teacherID,
          //         keyy:'autoclick'
          //       }
          //       this.studentAnalyticsData.push(obj)
          //     }

          //   })
          // })

          this.dataSource = this.studentAnalyticsData;
          this.dateArray = []
          console.log('end Data Source', this.dataSource)
          console.log('end Student Analytics', this.studentAnalyticsData)

          console.log('DataSource Data is Here ', this.dataSource)
          // }
        }
        else {
          this.dataSource = ''
          this.studentAnalyticsData = [];
          this.dateArray = []
        }
      }
    })

  }

  classId: any
  setionId: any
  dataObj: any
  teacherId: any
  subjectId: any
  showAccourding: any
  onSubmit() {
    this.setionId = []
    this.classId = []
    this.teacherId = []
    this.subjectId = []
    let allselected = this.toppings.value
    for (let i = 0; i < allselected.length; i++) {
      let classSection = allselected[i].idDropDown
      console.log(classSection)
      var array = classSection.split(',')
      this.classId.push(array[0]),
        this.setionId.push(array[1])
    }
    if (this.teacherCheckBox == true) {
      for (let i of this.teacher.value) {
        console.log(i.teacherID);
        this.teacherId.push(i.teacherID);
      }
    }
    if (this.subjectCheckBox == true) {
      for (let i of this.subjects.value) {
        console.log(i.subjectID);
        this.subjectId.push(i.subjectID);
      }
    }



    this.showAccourding = (this.ViewStudent.value) === null ? 'all' : this.ViewStudent.value
    this.dataObj = {
      'classId': this.classId.toString(),
      'sectionId': this.setionId.toString(),
      'startDate': moment(this.campaignOne.value.start).format('YYYY-MM-DD'),
      'endDate': moment(this.campaignOne.value.end).format('YYYY-MM-DD'),
      'view': (this.ViewStudent.value) === null ? 'all' : this.ViewStudent.value,
      'teacherId': this.teacherId.toString(),
      'subjectId': this.subjectId.toString()
    }
    //this.classSetionId=this.toppings.value
    //console.log(this.classSetionId.get('classID').value)
    //console.log(this.ViewStudent.value)
    this.getBetweenDates(this.campaignOne.value.start, this.campaignOne.value.end);
    // this.getStudentAnalyticsRecords();
    this.getStudentAnalyticsRecordsFinal();
    console.log("Hello From Student Analytics", this.dataObj)
  }
  ExportTOExcel() {
    // console.warn(this.table.nativeElement)
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    // console.log("last value",ws)
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, 'SheetJS.xlsx');
    let dataToExport = this.dataSource
      .map((x: any) => ({
        Date: x.Date,
        Class: x.className,
        Section: x.sectionName,
        Subject: x.subjectName,
        Session: x.attendanceStatusMasterID == '5' || x.attendanceStatusMasterID == "" ? 'No' : 'Yes'
      }));

    let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
    let workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    XLSX.writeFile(workBook, 'Student Attandance Report.xlsx');
  }

  infoCall(teacherId: any) {
    //this.router.navigate(['/view-staff/']);
    //this.route.navigate(["/admin/academicslearning/manage-students/view-profile"], { queryParams: { id: studentId } });
    this.route.navigate(["/admin/academicslearning/manage-teachers/view-teacher"], { queryParams: { id: teacherId } });
  }
  getBetweenDates(startDate: any, endDate: any) {
    ////debugger;
    var currentDate = moment(startDate);
    var eD = moment(endDate);
    while (currentDate <= eD) {
      this.dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
  }

  getRecordsAccordingToDate(element: any) {
    console.log(element);
    this.commonService.setAttSubmissionData(element);
    // this.route.navigateByUrl('../manage-student-attendance');
  }


  subjectCheckBox = false;
  teacherCheckBox = false;
  showCheckBox = false
  classsection: any
  multipleSectedclass: any
  multipleSectedsection: any
  classSectionObj: any
  SelectedSubjects() {
    this.showCheckBox = true;
    this.subjectCheckBox = true
    this.teacherCheckBox = false
    console.warn(this.toppings.value);

    //alert("you selected")
    this.classsection = this.toppings.value
    let allselected = this.toppings.value
    this.multipleSectedclass = []
    this.multipleSectedsection = []
    for (let i = 0; i < allselected.length; i++) {
      let classSection = allselected[i].idDropDown
      console.log(classSection)
      var array = classSection.split(',')
      this.multipleSectedclass.push(array[0]),
        this.multipleSectedsection.push(array[1])
    }
    console.warn(this.multipleSectedclass)
    console.warn(this.multipleSectedsection)
    // allselected.forEach((element:any)=> {
    //   console.log(element);
    // });
    // //this.classsection.push(event.value)
    //this.classsection.forEach((cs:any)=>{
    // for(let i=0;i<cs.length;i++){
    //this.multipleSectedclass.push(cs.classID)
    //this.multipleSectedsection.push(cs.sectionID)
    // }
    //})

    this.classSectionObj = {
      'classID': this.multipleSectedclass.toString(),
      'sectionID': this.multipleSectedsection.toString()
    }
    this.studentService.GetSubjectTeacherForClasssection(this.classSectionObj).subscribe((res: any) => {
      this.subjectData = res.responseData
      console.log('selected dropdown Data=========================', this.subjectData)
    });

  }
  // teacher chips box work
  selectable = true;
  removable = true;
  Teachers: any[] = [];
  public individualTeacherdata: Array<{ text: string, teacherName: string }>;
  autocompleteTagsOptionActivated = false
  SelectedTeacher() {
    this.teacherCheckBox = true
    this.subjectCheckBox = false
    console.warn(this.toppings.value);
    //alert("you selected")
    this.classsection = this.toppings.value
    let allselected = this.toppings.value
    this.multipleSectedclass = []
    this.multipleSectedsection = []
    for (let i = 0; i < allselected.length; i++) {
      let classSection = allselected[i].idDropDown
      console.log(classSection)
      var array = classSection.split(',')
      this.multipleSectedclass.push(array[0]),
        this.multipleSectedsection.push(array[1])
    }
    console.warn(this.multipleSectedclass)
    console.warn(this.multipleSectedsection)



    this.classSectionObj = {
      'classID': this.multipleSectedclass.toString(),
      'sectionID': this.multipleSectedsection.toString()
    }
    this.studentService.GetTeacherForMultiClasssection(this.classSectionObj).subscribe((res: any) => {
      this.teacherData = []
      res.responseData.forEach((element: any) => {
        if (element.teacherName != "") {
          this.teacherData.push(element)
        }

      });
      console.log('Teacher dropdown Data=========================', this.teacherData)
    });

    //multi select dropdown  start


    //multiselect


  }
  getTeacher() {
    this.studentService.GetTeacherDropdown().subscribe((res: any) => {
      this.teacherData = res.responseData
      this.individualTeacherdata = res.responseData
      console.log('Teacher Data=========================', this.teacherData)
    });
  }
  removeTeacher(removeIndex: string): void {
    const removeTeacher = this.Teachers.indexOf(removeIndex);

    if (removeTeacher >= 0 || removeTeacher < 0) {
      this.Teachers.splice(removeTeacher, 1);
    }
  }
  addTeacher(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Value
    if ((event.value || '').trim() && !this.autocompleteTagsOptionActivated) {
      this.Teachers.push(value.trim());

    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.teacherCtrl.setValue(null);
  }
  optionActivated($event: MatAutocompleteActivatedEvent) {
    if ($event.option) {
      this.autocompleteTagsOptionActivated = false;
    }
  }
  selectedTeacher(event: MatAutocompleteSelectedEvent): void {
    if (!this.Teachers.includes(event.option.viewValue)) {
      this.Teachers.push(event.option.viewValue);
    }
    else {
      this.toaster.error('Record Already Selected');
    }
    this.SelectedInput.nativeElement.value = '';
    this.teacherCtrl.setValue(null);
    this.autocompleteTagsOptionActivated = true;

  }
  private _filterTeacher(teacher: string) {
    return this.individualTeacherdata.filter(parent => parent.teacherName.toLowerCase().indexOf(teacher.toLowerCase()) === 0);
  }

  // end teacher chips box work
  //start get subject for selected classe section

  //end get subject for selected classe section

}

export interface StudentAnalyticsRecords {
  Date: Date,
  classID: number,
  sectionID: number,
  subjectID: number,
  className: '',
  sectionName: '',
  subjectName: '',
  TeacherName: '',
  attendanceStatusMasterID: '',
  DateFromDB: any,
  TeacherID: any,
  keyy: any,
  IsSubmitted?: boolean
}






