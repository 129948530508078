<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2 *ngIf="studentID == 0">Add Student</h2>
            <h2 *ngIf="studentID > 0">Edit Student</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage
                            Student</a></li>
                    <li *ngIf="studentID == 0" class="breadcrumb-item active" aria-current="page">Add Student</li>
                    <li *ngIf="studentID > 0" class="breadcrumb-item active" aria-current="page">Edit Student</li>
                </ol>
            </div>
        </div>
               
 <div class="">
    <div class="add_button">
      <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
    </div>
  </div>
    </div>

    <div class="cardWidget">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <!-- <div class="cardTitle centerVTItems justify-content-between">
                    <h3>Add Details</h3>
                  
                </div> -->

            </div>
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-lg-2 cols-sm-12">
                                <div class="profilePicLeft form-group30">
                                    <!-- <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Profile pic <span class="req">*</span></mat-label>
                                        <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                                    </mat-form-field>     -->
                                    <p class="chngePic">
                                        <input type="file" accept="image/*"
                                            (change)="actionDialogPopup(uploadPopup,$event)">
                                        <span class="icon centerItem">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <img [src]="croppedImage" alt="">
                                    </p>

                                    <!-- <p class="chngePic">
                                            <input type="file" accept="image/*" (change)="fileChangeEvent($event)">
                                            <span class="icon centerItem">
                                                <mat-icon>edit</mat-icon>
                                            </span>
                                            <img [src]="croppedImage" alt="image">
                                        </p> -->
                                    <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->

                                    <!-- <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event)"
                                        (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()"></image-cropper> -->

                                    <!-- <img [src]="croppedImage" /> -->

                                </div>
                            </div>
                            <div class="col-lg-10 cols-sm-12">
                                <div class="profileContentRight flex-grow-1">
                                    <div *ngFor="let grp of getFieldAndGroupDto">
                                        <div *ngIf="grp.preDefinedGroup=='ENROLLMENT' && studentID==0" class="proSection pt-0">
                                            <h5><i class="fas fa-vote-yea mr-2"></i> {{grp.groupName}}</h5>

                                            <div class="row">
                                                <ng-container *ngFor="let f of grp.fields">

                                                    <div *ngIf="f.fieldName=='Academic Sessions'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Academic Session</mat-label>
                                                            <!-- <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,'Academic Sessions')"
                                                                placeholder="Session" required
                                                                formControlName="academicSessionID">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'academic')"> -->

                                                                <input type="text" (input)="onKeyFilter($event, 'academic')" matInput
                                                                formControlName="academicSessionID" [matAutocomplete]="academicsauto">
                                      
                                      
                                                                <mat-autocomplete #academicsauto="matAutocomplete" 
                                                                (optionSelected)="ddlChange($event.option.value,grp,'Academic Sessions')"    [displayWith]="dispalyAcademicSession.bind(this)" >


                                                                <mat-option value="" [disabled]="true">Select Academic Sessions
                                                                </mat-option>
                                                                <mat-option *ngFor="let session of academicSessions"
                                                                    [value]="session.academicSessionID">
                                                                    {{session.academicSessionName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                                *ngIf="form.get('academicSessionID')?.touched  && form.get('academicSessionID')?.errors?.required">
                                                                Academic Session is required
                                                            </mat-error> -->
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Class'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Class</mat-label>
                                                            <!-- <mat-select  placeholder="Class" required
                                                                (selectionChange)="filterSectionListByClassId($event.value,grp,'Class')"
                                                                formControlName="classID">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'class')"> -->

                                                                <input type="text" (input)="onKeyFilter($event, 'class')" matInput
                                                                formControlName="classID" [matAutocomplete]="classauto">
                                      
                                                                <mat-autocomplete #classauto="matAutocomplete" 
                                                                (optionSelected)="filterSectionListByClassId($event.option.value,grp,'Class')" [displayWith]="displayClass.bind(this)" >

                                                                <mat-option value="" [disabled]="true">Select Class</mat-option>
                                                                <mat-option *ngFor="let class of classes"
                                                                    [value]="class.classID">
                                                                    {{class.className}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                                *ngIf="form.get('classID')?.touched  && form.get('classID')?.errors?.required">
                                                                Class is required
                                                            </mat-error> -->
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>


                                                    <div *ngIf="f.fieldName=='Section'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Section</mat-label>
                                                            <!-- <mat-select 
                                                                (selectionChange)="ddlChange($event.value,grp,'Section')"
                                                                placeholder="Section" required
                                                                formControlName="sectionID">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'section')"> -->


                                                                <input type="text" (input)="onKeyFilter($event, 'section')" matInput
                                                                formControlName="sectionID" [matAutocomplete]="sectionauto">
                                      
                                      
                                                                <mat-autocomplete #sectionauto="matAutocomplete" 
                                                                (optionSelected)="ddlChange($event.option.value,grp,'Section')"     [displayWith]="displaySectionFns.bind(this)" >

                                                                <mat-option value="" [disabled]="true">Select Sections
                                                                </mat-option>
                                                                <mat-option *ngFor="let section of sections"
                                                                    [value]="section.sectionID">
                                                                    {{section.sectionName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                                *ngIf="form.get('sectionID')?.touched  && form.get('sectionID')?.errors?.required">
                                                                Section is required
                                                            </mat-error> -->
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let grp of getFieldAndGroupDto">

                                        <div *ngIf="grp.preDefinedGroup=='PERSONAL INFORMATION'" class="proSection">
                                            <h5><i class="fas fa-user mr-2"></i> {{grp.groupName}}</h5>

                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='Student Email'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Student Email</mat-label>
                                                            <input matInput 
                                                                (blur)="onBlurMethodtxt($event,grp,'Student Email')"
                                                                placeholder="email" required name="email" matInput
                                                                formControlName="email" type="email"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$" >
                                                            <mat-error
                                                                *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                                Student Email is required
                                                            </mat-error>
                                                            <mat-error *ngIf="form.get('email')?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Date of Birth'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Date of Birth</mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,'Date of Birth')"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput placeholder="Date Of Birth" required
                                                                name="DateOfBirth" formControlName="dateOfBirth"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get('dob')?.touched  && form.get('dob')?.errors?.required">
                                                                Date of Birth is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Blood Group'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <!-- <mat-label>Blood Group</mat-label> -->
                                                            <!-- <input matInput  placeholder="Blood Group" required name="bloodGroup" formControlName="bloodGroup"> -->
                                                            <mat-label>Select Blood Group</mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,'Blood Group')"
                                                                placeholder="Blood Group" [required]="f.isRequried==true" name="bloodGroup"
                                                                formControlName="bloodGroupID">
                                                                <mat-option value="" disabled>Select Blood Group
                                                                </mat-option>
                                                                <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                                    [value]="bloodGroup.bloodGroupID">
                                                                    {{bloodGroup.bloodGroupName}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="form.get('bloodGroupID')?.touched  && form.get('bloodGroupID')?.errors?.required">
                                                                Blood Group is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    
                                                    <div *ngIf="f.fieldName=='Gender'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Gender</mat-label>
                                                            <mat-select 
                                                                (selectionChange)="ddlChange($event.value,grp,'Gender')"
                                                                placeholder="Gender" required name="genderID"
                                                                formControlName="genderID">
                                                                <mat-option value="" disabled>Select Gender</mat-option>
                                                                <mat-option *ngFor="let gender of genders"
                                                                    [value]="gender.genderID">
                                                                    {{gender.genderName}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="form.get('genderID')?.touched  && form.get('genderID')?.errors?.required">
                                                                Gender is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Contact'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">

                                                            <ngx-mat-intl-tel-input style="border: none;"
                                                                (ngModelChange)="onBlurPhone($event,grp,'Contact')"
                                                                [preferredCountries]="['au', 'us']"
                                                                [enablePlaceholder]="true" [enableSearch]="true"
                                                                name="ContactNumber" formControlName="contactNumber"
                                                                #phone>
                                                            </ngx-mat-intl-tel-input>
                                                            <mat-hint class="hintexted">eg :
                                                                {{phone.selectedCountry.placeHolder}}</mat-hint>
                                                            <mat-error
                                                                *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                                Student contact is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                        class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                 [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                 matInput formControlName="{{ f.fieldName }}">
                                                                <mat-error *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true" 
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-option value="" disabled>Select {{f.fieldName}}
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngFor="let drp of splitValues(f.defaultValue)"
                                                                    [value]="drp">
                                                                    {{drp}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                    class="col s12 m6 py-2">
                                                        <div class="w-100" appearance="outline">
                                                           
                                                            <ng-container>
                                                                <ul class="check d-flex">
                                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                                    <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                        <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                            (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                            {{ chk }}
                                                                        </mat-checkbox>
                                                                    </li>
                                                                </ul>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                matInput [name]="f.fieldName"
                                                                [placeholder]="f.fieldName" 
                                                                matInput formControlName="{{ f.fieldName }}"
                                                                type="email" pattern="^[^\s]+(\s+[^\s]+)*$" [required]="f.isRequried==true"
                                                                >
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                input [required]="f.isRequried==true"
                                                                (keypress)="allowAlphaNumber($event)" matInput
                                                                [placeholder]="f.fieldName"
                                                                formControlName="{{ f.fieldName }}"
                                                                ></textarea>
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="grp.preDefinedGroup=='ACADEMIC DETAILS'" class="proSection">
                                            <h5><i class="fas fa-book-reader mr-2"></i> {{grp.groupName}}</h5>

                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='First Name'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>First Name </mat-label>
                                                            <input 
                                                                (blur)="onBlurMethodtxt($event,grp,'First Name')"
                                                                placeholder="First Name" required name="firstname"
                                                                matInput pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="firstName" (keypress)="allowAlphabetOnly($event)">
                                                            <mat-error
                                                                *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                                First Name is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('firstName')?.hasError('pattern')">
                                                                Please enter valid First Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Last Name'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Last Name</mat-label>
                                                            <input 
                                                                (blur)="onBlurMethodtxt($event,grp,'Last Name')"
                                                                placeholder="Last Name" required name="lastName"
                                                                matInput pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="lastName" (keypress)="allowAlphabetOnly($event)">
                                                            <mat-error
                                                                *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                                Last Name is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('lastName')?.hasError('pattern')">
                                                                Please enter valid Last Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Admission Number'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Admission Number</mat-label>
                                                            <input maxlength="10" [readonly]="studentID>0"
                                                                (blur)="onBlurMethodtxt($event,grp,'Admission Number')"
                                                                matInput placeholder="Admission Number" required
                                                                name="admissionNumber" formControlName="admissionNumber"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$">
                                                            <mat-error
                                                                *ngIf="form.get('admissionNumber')?.touched  && form.get('admissionNumber')?.errors?.required">
                                                                Admission Number is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('admissionNumber')?.hasError('pattern')">
                                                                Please enter valid Admission Number
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                        class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                 [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                type="number" (keypress)="allowOnlyNumber($event)"  [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-option value="" disabled>Select {{f.fieldName}}
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngFor="let drp of splitValues(f.defaultValue)"
                                                                    [value]="drp">
                                                                    {{drp}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                    class="col s12 m6 py-2">
                                                        <div class="w-100" appearance="outline">
                                                           
                                                            <ng-container>
                                                                <ul class="check d-flex">
                                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                                    <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                        <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                            (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                            {{ chk }}
                                                                        </mat-checkbox>
                                                                    </li>
                                                                </ul>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                matInput [name]="f.fieldName"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                matInput formControlName="{{ f.fieldName }}"
                                                                type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                >
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                input  [required]="f.isRequried==true"
                                                                (keypress)="allowAlphaNumber($event)" matInput
                                                                [placeholder]="f.fieldName"
                                                                formControlName="{{ f.fieldName }}"
                                                                ></textarea>
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div *ngIf="grp.preDefinedGroup=='SKILLS & SOCIAL DETAILS'" class="proSection">
                                            <h5><i class="fas fa-star mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='Profile Summary'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Profile Summary</mat-label>
                                                            <textarea
                                                                (blur)="onBlurMethodtxt($event,grp,'Profile Summary')"
                                                                input (keypress)="allowAlphaNumber($event)" matInput
                                                                placeholder="Profile Summary"
                                                                formControlName="profileSummary"
                                                                ></textarea>
                                                                <mat-error
                                                                *ngIf="form.get('profileSummary')?.touched  && form.get('profileSummary')?.errors?.required">
                                                                Profile Summary is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Skills'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Skills</mat-label>
                                                            <input matInput
                                                                (blur)="onBlurMethodtxt($event,grp,'Skills')"
                                                                placeholder="Skills" [required]="f.isRequried==true"
                                                                formControlName="studentSkillID">
                                                                <mat-error
                                                                *ngIf="form.get('studentSkillID')?.touched  && form.get('studentSkillID')?.errors?.required">
                                                                Skills is required
                                                            </mat-error>
                                                            <!-- (keypress)="allowAlphaNumber($event)" -->
                                                        </mat-form-field>
                                                    </div>
                                                    <!-- <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Student Skills </mat-label>
                                                    <mat-select placeholder="Stdent Skills" formControlName="studentSkillID">
                                                        <mat-option *ngFor="let skill of skills " [value]="skill.studentSkillID">
                                                            {{skill.skill}}
                                                        </mat-option>
                                                    </mat-select>
                                                    
                                                </mat-form-field>
                                            </div> -->

                                                    <div *ngIf="f.fieldName=='Facebook Profile Link'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Facebook Profile Link</mat-label>
                                                            <input
                                                                (blur)="onBlurMethodtxt($event,grp,'Facebook Profile Link')"
                                                                matInput placeholder="Facebook Profile Link" [required]="f.isRequried==true"
                                                                 formControlName="facebookLink">
                                                            <!-- (keypress)="allowAlphabetOnly($event)" -->
                                                            <mat-error
                                                                *ngIf="form.get('facebookLink')?.touched  && form.get('facebookLink')?.errors?.required">
                                                                Facebook Profile Link is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='LinkedIn Profile Link'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>LinkedIn Profile Link</mat-label>
                                                            <input
                                                                (blur)="onBlurMethodtxt($event,grp,'LinkedIn Profile Link')"
                                                                matInput type="url" placeholder="LinkedIn Profile Link"
                                                                formControlName="linkedInlink" [required]="f.isRequried==true">
                                                                <mat-error
                                                                *ngIf="form.get('linkedInlink')?.touched  && form.get('linkedInlink')?.errors?.required">
                                                                LinkedIn Profile Link is required
                                                                </mat-error>
                                                            <!-- (keypress)="allowAlphabetOnly($event)" -->
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                        class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                 [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                    class="col s12 m6">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-option value="" disabled>Select {{f.fieldName}}
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngFor="let drp of splitValues(f.defaultValue)"
                                                                    [value]="drp">
                                                                    {{drp}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                    class="col s12 m6  py-2">
                                                        <div class="w-100" appearance="outline">
                                                           
                                                            <ng-container>
                                                                <ul class="check d-flex">
                                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                                    <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                        <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                            (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                            {{ chk }}
                                                                        </mat-checkbox>
                                                                    </li>
                                                                </ul>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                    class="col s12 m6 ">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                matInput [name]="f.fieldName"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                matInput formControlName="{{ f.fieldName }}"
                                                                type="email" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                    class="col s12 m6 ">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                    class="col s12 m6 ">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                    class="col s12 m6 ">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                input 
                                                                (keypress)="allowAlphaNumber($event)" matInput
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                ></textarea>
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div *ngIf="grp.preDefinedGroup=='ADDITIONAL DETAILS'" class="proSection">
                                            <h5><i class="fas fa-plus-square mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='Address'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Address</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Address')"
                                                                matInput placeholder="address" [required]="f.isRequried==true"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="address"
                                                                >
                                                            <mat-error *ngIf="form.get('address')?.hasError('pattern')">
                                                                Please enter valid Address
                                                            </mat-error>
                                                            <mat-error
                                                            *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                            Address is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Country'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Country</mat-label>
                                                            <!-- <mat-select
                                                                
                                                                (selectionChange)="ddlChange($event.value,grp,'Country')"
                                                                placeholder="Country" 
                                                                (valueChange)="GetStateList($event)" [required]="f.isRequried==true"
                                                                formControlName="countryID">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'country')"> -->


                                                                <input type="text" (input)="onKeyFilter($event, 'country')" matInput
                                                                formControlName="countryID" [matAutocomplete]="countryauto">
                                                               
                
                                                                <mat-autocomplete #countryauto="matAutocomplete" 
                                                                (optionSelected)="GetStateList($event);ddlChange($event.option.value,grp,'Country');" [displayWith]="dispalyFns.bind(this)" >

                                                                <mat-option value="" [disabled]="true">Select Country
                                                                </mat-option>
                                                                <mat-option *ngFor="let country of countries"
                                                                    [value]="country.countryID">
                                                                    {{country.countryName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                            *ngIf="form.get('countryID')?.touched  && form.get('countryID')?.errors?.required">
                                                            Country is required
                                                            </mat-error> -->
                                                            </mat-autocomplete>
                                                        </mat-form-field>



                                                    </div>

                                                    <!-- [disabled]="true" -->
                                                    <div *ngIf="f.fieldName=='State'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>State</mat-label>
                                                            <!-- <mat-select 
                                                                (selectionChange)="ddlChange($event.value,grp,'State')"
                                                                placeholder="State" formControlName="stateID" [required]="f.isRequried==true">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'state')"> -->



                                                                <input type="text" (input)="onKeyFilter($event, 'state')" matInput
                                                                formControlName="stateID" [matAutocomplete]="stateAuto">
                
                
                                                                <mat-autocomplete #stateAuto="matAutocomplete" 
                                                                (optionSelected)= "ddlChange($event.option.value,grp,'State')"  [displayWith]="dispalyStateAuto.bind(this)">


                                                                <mat-option value="" [disabled]="true">Select State</mat-option>
                                                                <mat-option *ngFor="let state of states"
                                                                    [value]="state.stateID">
                                                                    {{state.stateName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                            *ngIf="form.get('stateID')?.touched  && form.get('stateID')?.errors?.required">
                                                            State is required
                                                            </mat-error> -->
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='City'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>city</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'City')"
                                                                 matInput placeholder="city"
                                                                formControlName="city" [required]="f.isRequried==true">
                                                                <mat-error
                                                            *ngIf="form.get('city')?.touched  && form.get('city')?.errors?.required">
                                                            city is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Zip Code'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Zip Code</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Zip Code')"
                                                                (keypress)="allowAlphaNumber($event)" maxlength="10"
                                                                matInput placeholder="Zip Code"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="zipCode" [required]="f.isRequried==true">
                                                                <mat-error
                                                            *ngIf="form.get('zipCode')?.touched  && form.get('zipCode')?.errors?.required">
                                                            Zip Code is required
                                                            </mat-error>
                                                            <mat-error *ngIf="form.get('zipCode')?.hasError('pattern')">
                                                                Please enter valid Zip Code
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Parents ID'"
                                                        class="col s12 m6 form-group">
                                                        <!-- <p></p> -->
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Parents ID</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Parents ID')"
                                                                (blur)="onBlurMethod()" matInput [readonly]="studentID>0"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$" placeholder="Parents ID"
                                                                [required]="f.isRequried==true" name="parentsNumber"
                                                                formControlName="parentsNumber" maxlength="20">
                                                            <mat-error
                                                                *ngIf="form.get('parentsNumber')?.hasError('pattern')">
                                                                Please enter valid Parents ID
                                                            </mat-error>
                                                            <mat-error
                                                            *ngIf="form.get('parentsNumber')?.touched  && form.get('parentsNumber')?.errors?.required">
                                                            Parents ID is required
                                                            </mat-error>
                                                            <mat-hint class="hintexted">If you have your parentId please
                                                                enter above (eg : PI01524)</mat-hint>
                                                            <!-- <mat-error
                                                        *ngIf="form.get('fatherName')?.touched  && form.get('fatherName')?.errors?.required">
                                                        Father's Name is required
                                                    </mat-error> -->
                                                        </mat-form-field>

                                                    </div>


                                                    <div *ngIf='f.fieldName=="FathersName"'
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Father's Name </mat-label>
                                                            <input (blur)='onBlurMethodtxt($event,grp,"FathersName")'
                                                                 (keypress)="allowAlphaNumber($event)"
                                                                matInput placeholder="Father's Name" [required]="f.isRequried==true"
                                                                name="fatherName" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="fatherName" (keypress)="allowAlphabetOnly($event)">
                                                                <mat-error
                                                                *ngIf="form.get('fatherName')?.touched  && form.get('fatherName')?.errors?.required">
                                                                Father's Name is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('fatherName')?.hasError('pattern')">
                                                                Please enter valid Father's Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf='f.fieldName=="MothersName"'
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Mother's Name </mat-label>
                                                            <input (blur)='onBlurMethodtxt($event,grp,"MothersName")'
                                                                 (keypress)="allowAlphaNumber($event)"
                                                                matInput placeholder="Mother's Name" [required]="f.isRequried==true"
                                                                name="motherName" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="motherName" (keypress)="allowAlphabetOnly($event)">
                                                                <mat-error
                                                                *ngIf="form.get('motherName')?.touched  && form.get('motherName')?.errors?.required">
                                                                Mother's Name is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('motherName')?.hasError('pattern')">
                                                                Please enter valid Mother's Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Parents Contact'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                             <mat-label class="telinput">Parents Contact</mat-label>
                                                            <!--<input
                                                                (blur)="onBlurMethodtxt($event,grp,'Parents Contact')"
                                                                (keypress)="allowOnlyNumber($event)"
                                                                pattern="[0-9]+" maxlength="10" matInput
                                                                placeholder="Parents Contact" required
                                                                name="parentContact" formControlName="parentContact">
                                                                <mat-error
                                                                *ngIf="form.get('parentContact')?.touched  && form.get('parentContact')?.errors?.required">
                                                                Parents Contact is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('parentContact')?.hasError('pattern')">
                                                                Please enter valid Parents Contact
                                                            </mat-error> -->
                                                            <ngx-mat-intl-tel-input style="border: none;"
                                                                (ngModelChange)="onBlurParentPhone($event,grp,'Parents Contact')"
                                                                [preferredCountries]="['au', 'us']"
                                                                [enablePlaceholder]="true" [enableSearch]="true"
                                                                name="ParentNumber" formControlName="parentContact"
                                                                #parentphone>
                                                            </ngx-mat-intl-tel-input>
                                                            <mat-hint class="hintexted">eg :
                                                                {{parentphone.selectedCountry.placeHolder}}</mat-hint>
                                                                <mat-error
                                                                *ngIf="form.get('parentContact')?.hasError('pattern')">
                                                                Please enter valid Parents Contact
                                                            </mat-error> 
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Parents Email'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Parent's Email</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Parents Email')"
                                                                matInput placeholder="Parent's Email" [readonly]="studentID>0"
                                                                name="parentEmail" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="parentEmail" [required]="f.isRequried==true"
                                                                name="parentEmail" >
                                                                <mat-error
                                                                *ngIf="form.get('parentEmail')?.touched  && form.get('parentEmail')?.errors?.required">
                                                                Parent's Email is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('parentEmail')?.hasError('pattern')">
                                                                Please enter valid Parent Email
                                                            </mat-error>
                                                        </mat-form-field>

                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                    class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                             [placeholder]="f.fieldName"
                                                             matInput
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                             matInput
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <mat-select
                                                            (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                            [placeholder]="f.fieldName" 
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-option value="" disabled>Select {{f.fieldName}}
                                                            </mat-option>
                                                            <mat-option
                                                                *ngFor="let drp of splitValues(f.defaultValue)"
                                                                [value]="drp">
                                                                {{drp}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                class="col-12 py-2">
                                                    <div class="w-100" appearance="outline">
                                                       
                                                        <ng-container>
                                                            <ul class="check d-flex">
                                                                <mat-label>{{f.fieldName}} </mat-label>
                                                                <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                    <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                        (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                        {{ chk }}
                                                                    </mat-checkbox>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            matInput [name]="f.fieldName"
                                                            [placeholder]="f.fieldName" 
                                                            matInput formControlName="{{ f.fieldName }}"
                                                            type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                            >
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                            Please enter valid email
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            input 
                                                            (keypress)="allowAlphaNumber($event)" matInput
                                                            [placeholder]="f.fieldName"
                                                            formControlName="{{ f.fieldName }}"
                                                            ></textarea>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="grp.preDefinedGroup!=='ENROLLMENT' && grp.preDefinedGroup!=='ADDITIONAL DETAILS' && grp.preDefinedGroup!=='SKILLS & SOCIAL DETAILS' && grp.preDefinedGroup!=='ACADEMIC DETAILS' && grp.preDefinedGroup!=='PERSONAL INFORMATION'"
                                            class="proSection">
                                            <h5><i class="fas fa-book-reader mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row">
                                            <div class="col-lg-6 col-sm-12" *ngFor="let f of grp.fields">
                                                
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                        class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                 [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>

                                                                
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}">
                                                                <mat-option value="" disabled>Select {{f.fieldName}}
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngFor="let drp of splitValues(f.defaultValue)"
                                                                    [value]="drp">
                                                                    {{drp}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                    class="col-12 py-2">
                                                        <div class="w-100" appearance="outline">
                                                           
                                                            <ng-container>
                                                                <div class="row">
                                                                    <div class="col-1">
                                                                    <mat-label class="text-colored">{{f.fieldName}} </mat-label><br>
                                                                    </div>
                                                                    <div class="col-11 flex-wrap">
                                                                        <ul class="check d-flex flex-wrap">
                                                                            <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                            <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                                (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                                {{ chk }}
                                                                            </mat-checkbox>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>  
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                matInput [name]="f.fieldName"
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                matInput formControlName="{{ f.fieldName }}"
                                                                type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                >
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput [placeholder]="f.fieldName"
                                                                 matInput [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                    class="col-12">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                                input 
                                                                (keypress)="allowAlphaNumber($event)" matInput
                                                                [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                                formControlName="{{ f.fieldName }}"
                                                                ></textarea>
                                                                <mat-error
                                                                *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                                {{ f.fieldName }} is required
                                                                </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                    <div class="proSection text-right">
                                        <button n-submit type="submit" [disabled]="!form.valid" (click)="onSubmit()"
                                            class="btn btn-primary btn-sm" matRippple>Submit <svg
                                                xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478"
                                                viewBox="0 0 12.611 8.478">
                                                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                                    <path id="Path"
                                                        d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                                                        transform="translate(-3 -5.95)" fill="#fff" />
                                                </g>
                                            </svg></button>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </main> -->
<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle  loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()" matRipple>Save</button>
    </div>
</ng-template>

