<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>View Payment Details</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" *ngIf="!isTeacherUser" ><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/paymentdetails">Payment
                            Deatils</a></li>
                    <li class="breadcrumb-item active" aria-current="page">View Payment Details</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple (click)="back()">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">


        <div class="cardBody">
            <div class="row mt-2">
                <div style="width: 100%;">
                    <!-- <i class="material-icons popupClose" mat-dialog-close>clear</i> -->
                    <div class="dialogTitle" style="display: flex; justify-content:space-between;align-items:center;">
                      <h5 mat-dialog-title>Salary Slip</h5>
                      <button class="mr-2" class="printbtn"  printSectionId="print-section" ngxPrint [useExistingCss]="true"><i class="fas fa-print"></i></button>
                    </div>
                    <div class="col-lg-4 text-left">
                    
                      <!-- <i class="fas fa-print mr-3" title="Print" printSectionId="print-section" printTitle="Invoice" ngxPrint  style="font-size: 20px;"></i> -->
                    </div>
                       <mat-dialog-content class="mat-typography" id="print-section">
                        <section >
                          <div class="hreaderdesign">
                            <div class="col-lg-4">
                              <img [src]="logo" alt="logo" width="150px" style="margin-bottom: 15px;">
                            </div>
                            <div class="col-lg-4 text-center">
                              <h2 class="haed">{{OrgName}}</h2>
                              <h4 class="paylip">Payslip for the month of {{salaryForMonthName}},{{salaryForYear}}</h4>
                            </div>
                            <div class="col-lg-4"></div>
                          </div>
                          <div class="tabeleoneforDeatils">
                            <div class="row">
                              <div class="col-lg-6 border bordernew p-3">
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Name:</div>
                                  <div class="teacherinfo">{{teacherDetails?.firstName}} {{teacherDetails?.lastName}}</div>
                                </div>
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Designation:</div>
                                  <div class="teacherinfo">Teacher</div>
                                </div>
                                <!-- <div class="deatilsofteacher">
                                  <div class="teachersalutation">Department:</div>
                                  <div class="teacherinfo">MOD</div>
                                </div> -->
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Location:</div>
                                  <div class="teacherinfo">{{teacherDetails?.address}}</div>
                                </div>
                                <!-- <div class="deatilsofteacher">
                                  <div class="teachersalutation">LOP:</div>
                                  <div class="teacherinfo">0.0</div>
                                </div> -->
                              </div>
                              <div class="col-lg-6 border p-3">
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Teacher ID:</div>
                                  <div class="teacherinfo">{{teacherDetails?.teacherID}}</div>
                                </div>
                                <!-- <div class="deatilsofteacher">
                                  <div class="teachersalutation">Bank Name:</div>
                                  <div class="teacherinfo">Andra Bank</div>
                                </div>
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Bank Account Number:</div>
                                  <div class="teacherinfo">2541654145</div>
                                </div>
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Pan:</div>
                                  <div class="teacherinfo">HTHVNb799y7</div>
                                </div> -->
                              </div>
                            </div>
                          </div>
                          <div class="tabelesalaryforDeatils">
                            <div class="row">
                              <div class="col-lg-6 border bordernew p-3">
                                <div class="deatilsofteacher bordernew border-bottom">
                                  <div class="teachersalutation"><b>Earning</b></div>
                                  <div class="teacherinfo"><b>Amount</b></div>
                                </div>
                                <div *ngFor="let field of salaryField">
                                  <div class="deatilsofteacher" *ngIf="field.deductionType == false">
                                    <div class="teachersalutation">{{field.salaryFormFieldName}}:</div>
                                    <div class="teacherinfo">{{currency}}{{field.salaryFormFieldValue}}</div>
                                  </div>
                                </div>
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Bonus Amount:</div>
                                  <div class="teacherinfo">{{currency}}{{bonusAmount}}</div>
                                </div>
                              </div>
                              <div class="col-lg-6 border p-3">
                                <div class="deatilsofteacher bordernew border-bottom">
                                  <div class="teachersalutation"><b>Deduction</b></div>
                                  <div class="teacherinfo"><b>Amount</b></div>
                                </div>
                                <div *ngFor="let field of salaryField">
                                  <div class="deatilsofteacher" *ngIf="field.deductionType == true">
                                    <div class="teachersalutation">{{field.salaryFormFieldName}}:</div>
                                    <div class="teacherinfo">{{currency}}{{field.salaryFormFieldValue}}</div>
                                  </div>
                                </div>
                                <div class="deatilsofteacher">
                                  <div class="teachersalutation">Adjustable Amount:</div>
                                  <div class="teacherinfo">{{currency}}{{deductionAmount}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tabeleoneforDeatils">
                            <div class="row">
                              <div class="col-lg-6 border  p-3">
                                <div class="deatilsofteacher mb-0">
                                  <div class="teachersalutation"><b>Total Earning:</b></div>
                                  <div class="teacherinfo"><b>{{currency}}{{totalEarning}}</b></div>
                                </div>
                        
                              </div>
                              <div class="col-lg-6 border p-3">
                                <div class="deatilsofteacher mb-0">
                                  <div class="teachersalutation"><b>Total Deduction:</b></div>
                                  <div class="teacherinfo"><b>{{currency}}{{totalDeduction}}</b></div>
                                </div>      
                              </div>
                            </div>
                          </div>
                          <p class="netpayed"><b>Net pay is {{currency}}{{paidAmount}}</b></p>
                          <hr>
                          <div class="text-center">
                            <p><b>This is system genrated slip</b></p>
                  
                            <p>Genrated by Creo</p>
                            <div>For more info log on to <a href="javascript:void(0)">creo.cms</a></div>
                          </div>
                        </section>
                      </mat-dialog-content>
                
                  </div>
                <!-- <div class="col-lg-6 col-sm-12" *ngIf="paymentDetailData != undefined">
                    <div class="col-12" aria-readonly="true">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Payment Date</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.paymentDate | date: dateFormat" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Salary Before Deduction</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.salaryBeforeDeduction" readonly>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Deduction Amount</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.deductionAmount" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Salary After Deduction</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.salaryAfterDeduction" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Salary</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.salaryAmount" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Absent</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.totalAbsent" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Bonus Amount</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.bonusAmount" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Actual Payment</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.paidAmount" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Month</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.salaryForMonthName" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Year</mat-label>
                            <input matInput type="number" [value]="paymentDetailData.salaryForYear" readonly>
                        </mat-form-field>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Comment</mat-label>
                            <textarea input matInput placeholder="Comment" [value]="paymentDetailData.comment" readonly></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div class="text-right">
                            <button class="btn btn-primary btn-sm" matRipple (click)="previousPage()">Back
                            </button>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-lg-6 col-sm-12"> -->
                    <!-- <ng-container *ngFor="let item of generateArray(paymentDetailData)">
                        <div class="row">
                            <mat-label>{{item.key}} : </mat-label>
                            <mat-label> {{item.value}}</mat-label>
                        </div>
                    </ng-container> -->
                    <!-- <li *ngFor="let item of generateArray(paymentDetailData)">{{item.key}}: {{item.value}}</li> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>

<!-- <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Payment Date</mat-label>
                            <input matInput placeholder="Payment Date" required name="Payment Date"
                                 [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>
                                Start date is required
                            </mat-error>
                        </mat-form-field> -->
                        <!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Released By</mat-label>
                            <input matInput type="text" [value]="paymentDetailData.paidAmount">
                        </mat-form-field> -->