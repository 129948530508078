import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { LayoutModule } from './layout/layout.module';
// import { HomeComponent } from './layout/home/home.component';
import { SharedModule } from './common/shared/shared.module';
import { AuthGuardService } from './common/auth/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./subdomain/subdomain.module').then(m => m.SubdomainModule)
  },
  { path: 'admin',
   canActivate:[AuthGuardService],
   loadChildren: () => import('./app-layout/app-layout.module').then(m => m.AppLayoutModule) }



  // { path: 'path', component: HomeComponent },
  // {
  //   path:'main',
  //   loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  // },
  //academics-and-learning
  // {
  //   path: 'academics',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/manage-academics/manage-academics.module').then(m => m.ManageAcademicsModule)
  // },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/admin/admin.module').then(m => m.AdminModule)
  // },
  // {
  //   path: 'alumni',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/alumni/alumni.module').then(m => m.AlumniModule)
  // },
  // {
  //   path: 'attendance',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/manage-attendance/manage-attendance.module').then(m => m.ManageAttendanceModule)
  // },
  // {
  //   path: 'student',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/student/student.module').then(m => m.StudentModule)
  // },
  // {
  //   path: 'teacher',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/teacher/teacher.module').then(m => m.TeacherModule)
  // },
  // {
  //   path: 'gradings',
  //   loadChildren: () => import('./app-layout/content/academics-and-learning/manage-academic-and-gradings/manage-academic-and-gradings.module').then(m => m.ManageAcademicAndGradingsModule)
  // },


  // // communication
  // { path: 'events', loadChildren: () => import('./communication/events/events.module').then(m => m.EventsModule) },
  // { path: 'messaging', loadChildren: () => import('./communication/messaging/messaging.module').then(m => m.MessagingModule) },
  // { path: 'shift', loadChildren: () => import('./communication/shift-management/shift-management.module').then(m => m.ShiftManagementModule) },
  // { path: 'timeTable', loadChildren: () => import('./communication/time-table-management/time-table-management.module').then(m => m.TimeTableManagementModule) },


  // // administration
  // { path: 'feeInvoicing', loadChildren: () => import('./administration/fees-and-invoicing/fees-and-invoicing.module').then(m => m.FeesAndInvoicingModule) },
  // { path: 'finance', loadChildren: () => import('./administration/finance-and-accounting/finance-and-accounting.module').then(m => m.FinanceAndAccountingModule) },
  // { path: 'reports', loadChildren: () => import('./administration/reports/reports.module').then(m => m.ReportsModule) },

  // // AcademicSession
  // { path: 'academicSession', loadChildren: () => import('./app-layout/content/academics-and-learning/academic-session/academic-session.module').then(m => m.AcademicSessionModule) },

  // // reports
  // { path: 'reports', loadChildren: () => import('./administration/reports/reports.module').then(m => m.ReportsModule) },

  // // Core
  // //{ path: 'reports', loadChildren: () => import('./administration/reports/reports.module').then(m => m.ReportsModule)},

  // // OBE
  // { path: 'obe', loadChildren: () => import('./app-layout/content/academics-and-learning/obe/obe.module').then(m => m.ObeModule) },

  // // Organization SetUp
  // { path: 'OrgSetUp', loadChildren: () => import('./common/organization-set-up/organization-set-up.module').then(m => m.OrganizationSetUpModule) },

  // // Shared
  // { path: 'shared', loadChildren: () => import('./common/shared/shared.module').then(m => m.SharedModule) },

  // // Super Admin
  // //{ path: 'superAdmin', loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)},


  // // Layout
  // //{ path: 'layout', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'}),
    SharedModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
