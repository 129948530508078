import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';
import { UserRole } from 'src/environments/environment';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {
  form: FormGroup;
  studentID: number;
  data: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  genderName: string;
  admissionNumber: string
  email: string
  fatherName: string
  motherName: string
  parentsContact: string
  parentsEmail: string
  profilePhoto: string = '../../../../../../../assets/img/admin/avatar.jpg';
  facebookLink: string
  linkedInlink: string
  profileSummary: string
  skill: string
  contactNumber: string
  address: string
  zipCode: string
  stateName: string
  countryName: string
  city: string
  bloodGroupName: string
  academicSessionName: string
  className: string
  sectionName: string
  parentContact: string
  getFieldAndGroupDto: any;
  fieldTypeDto: any;
  croppedImage: any = '../../../assets/img/display.jpg';
  paidAmount:"0.00";
  unPaidAmount:"0.00";
  users:any;
  dateFormat:any;
  IsStudentOrPreant: boolean = false;
  constructor(

    private activatedRoute: ActivatedRoute,
    private studentService: StudentService

  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
    });
  var  roleID  = localStorage.getItem('roleID')
    if(roleID== UserRole.Student || roleID== UserRole.Parent){
      this.IsStudentOrPreant = true;
    }

    this.GetStudentByID();
    this.getPaidAndUnpaidAmount();
  }
  GetStudentByID(){
  this.studentService.GetdynamicStudentByID(this.studentID).subscribe((res: any) => {
    let studentdetail=  res.responseData.coreStudentDetails;
    this.getFieldAndGroupDto=studentdetail.groups;
    console.log("GETDATA",this.getFieldAndGroupDto)
    if (studentdetail.profilePhoto!==undefined && studentdetail.profilePhoto!==null && studentdetail.profilePhoto!=='' ) {
      this.croppedImage = studentdetail.profilePhoto;
    }
    this.getFieldAndGroupDto.forEach((grp: any) => {
      grp.fields= grp.fields.filter((f:any) =>f.isUnpublished==false);
      grp.fields.forEach((f: any) => {
        if (f.fieldName == 'First Name') {
          this.firstName = f.fieldValue;
        }
        if (f.fieldName == 'Last Name') {
          this.lastName = f.fieldValue;
        }
      })
    });
  });
  }
  getPaidAndUnpaidAmount(){
    this.studentService.getStudentPaidAndUnPaidAmount(this.studentID).subscribe((res: any) => {
      if(res.responseData.length != 0){
        this.paidAmount = res.responseData[0].paidAmount;
        this.unPaidAmount = res.responseData[0].unPaidAmount
      }
    });
  }
}

