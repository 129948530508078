import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageClassSectionAndSubjectsComponent } from './courses-and-subjects/manage-class-section-and-subjects/manage-class-section-and-subjects.component';
import { ManageAlumniComponent } from './student/manage-alumni/manage-alumni.component';
import { ManageStudentsComponent } from './student/manage-students/manage-students.component';
import { NewAcademicSessionsComponent } from './academic-sessions/new-academic-sessions/new-academic-sessions.component';
import { OldAcademicSessionsComponent } from './academic-sessions/old-academic-sessions/old-academic-sessions.component';
import { ManageGradeComponent } from './assesment-and-gradings/manage-grade/manage-grade.component';
import { ReportCardsComponent } from './assesment-and-gradings/report-cards/report-cards.component';
import { ManageAssessmentComponent } from './assesment-and-gradings/manage-assessment/manage-assessment.component';
import { GradeSetupComponent } from './assesment-and-gradings/grade-setup/grade-setup.component';
import { ManageTeachersComponent } from './teachers/manage-teachers/manage-teachers.component';
import { ViewTeacherComponent } from './teachers/manage-teachers/view-teacher/view-teacher.component';
import { EditTeacherComponent } from './teachers/manage-teachers/edit-teacher/edit-teacher.component';
import { SubjectsAllocationComponent } from './teachers/subjects-allocation/subjects-allocation.component';
import { ManageTeacherAttendanceComponent } from './teachers/manage-teacher-attendance/manage-teacher-attendance.component';
import { AttendanceSubmissionAnalyticsComponent } from './teachers/manage-teacher-attendance/attendance-submission-analytics/attendance-submission-analytics.component';
import { ViewProfileComponent } from './student/manage-students/view-profile/view-profile.component';
import { EnrollmentComponent } from './student/manage-students/view-profile/enrollment/enrollment.component';
import { ActivitiesComponent } from './student/manage-students/view-profile/activities/activities.component';
import { RCardComponent } from './student/manage-students/view-profile/rcard/rcard.component';
import { AttendanceComponent } from './student/manage-students/view-profile/attendance/attendance.component';
import { ClassScheduleComponent } from './student/manage-students/view-profile/class-schedule/class-schedule.component';
import { FeesAndInvoicingComponent } from './student/manage-students/view-profile/fees-and-invoicing/fees-and-invoicing.component';
import { AddStudentComponent } from './student/manage-students/add-student/add-student.component';
import { EditStudentComponent } from './student/manage-students/edit-student/edit-student.component';
import { ViewStudentComponent } from './student/manage-students/view-student/view-student.component';
import { ManageIdcardComponent } from './student/manage-students/manage-idcard/manage-idcard.component';
import { AddTeachersComponent } from './teachers/add-teachers/add-teachers.component';
import { ProfileComponent } from './profile/profile.component';
import { AddAttchmentComponent } from './student/manage-attachment/add-attchment/add-attchment.component';
import { ViewAttachmentComponent } from './student/manage-attachment/view-attachment/view-attachment.component';
import { EditAttachmentComponent } from './student/manage-attachment/edit-attachment/edit-attachment.component';
import { AddTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/add-teacher-attachment/add-teacher-attachment.component';
import { ViewTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/view-teacher-attachment/view-teacher-attachment.component';
import { EditTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/edit-teacher-attachment/edit-teacher-attachment.component';
import { AddAdminStaffComponent } from './Admin-Staff/add-admin-staff/add-admin-staff.component';
import { ManageAdminStaffComponent } from './Admin-Staff/manage-admin-staff/manage-admin-staff.component';
import { EditAdminStaffComponent } from './Admin-Staff/edit-admin-staff/edit-admin-staff.component';
import { ViewAdminStaffComponent } from './Admin-Staff/view-admin-staff/view-admin-staff.component';
import { AttendanceSettingsComponent } from './teachers/manage-teacher-attendance/attendance-settings/attendance-settings.component';
import { ManageTeacherFieldComponent } from './teachers/manage-teacher-field/manage-teacher-field.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { AddRolesComponent } from './manage-roles/add-roles/add-roles.component';
import { EditRolesComponent } from './manage-roles/edit-roles/edit-roles.component';
import { ManageStudentAttendanceComponent } from './student/manage-student-attendance/manage-student-attendance.component';
import { LoginUserProfileComponent } from './login-user-profile/login-user-profile.component';
import { TemplateOneComponent } from './assesment-and-gradings/grade-setup/template-one/template-one.component';
import { TemplateTwoComponent } from './assesment-and-gradings/grade-setup/template-two/template-two.component';
import { CustomformsComponent } from './customforms/customforms.component';
import { TeachersFormsComponent } from './teachers/teachers-forms/teachers-forms.component';
import { StudentSubmissionAnalyticsComponent } from './student/manage-student-attendance/student-submission-analytics/student-submission-analytics.component';
import { TeacherstabsComponent } from './teachers/manage-teachers/teacherstabs/teacherstabs.component';
import { TeachersclassScheduleComponent } from './teachers/manage-teachers/teachersclass-schedule/teachersclass-schedule.component';
import { ReportcardformatsComponent } from './assesment-and-gradings/grade-setup/reportcardformats/reportcardformats.component';
import { TemplateThreeComponent } from './assesment-and-gradings/grade-setup/template-three/template-three.component';
import { ManageBranchGridComponent } from './manage-branch/manage-branch-grid/manage-branch-grid.component';
import { PaymentdetailsComponent } from './teachers/manage-teachers/paymentdetails/paymentdetails.component';
import { SalarydetailsComponent } from './teachers/manage-teachers/salarydetails/salarydetails.component';
import { ViewpaymentdetailsComponent } from './teachers/manage-teachers/viewpaymentdetails/viewpaymentdetails.component';
import { TeacherspaymentComponent } from './teachers/teacherspayment/teacherspayment.component';
import { EditReportCardComponent } from './assesment-and-gradings/grade-setup/edit-report-card/edit-report-card.component';
import { EditTemplateOneComponent } from './assesment-and-gradings/grade-setup/edit-template-one/edit-template-one.component';
import { EditTemplateTwoComponent } from './assesment-and-gradings/grade-setup/edit-template-two/edit-template-two.component';
import { EditTemplateThreeComponent } from './assesment-and-gradings/grade-setup/edit-template-three/edit-template-three.component';
import { EditTemplateFourComponent } from './assesment-and-gradings/grade-setup/edit-template-four/edit-template-four.component';
import { ManageRegistrationsComponent } from './manage-registrations/manage-registrations.component';


const routes: Routes = [
  { path: 'manage-students', component: ManageStudentsComponent },
  { path: 'manage-alumni', component: ManageAlumniComponent },
  { path: 'manage-academic', component: ManageClassSectionAndSubjectsComponent },
  { path: 'new-academic-sessions', component: NewAcademicSessionsComponent },
  { path: 'old-academic-sessions', component: OldAcademicSessionsComponent },
  { path: 'manage-grade', component: ManageGradeComponent },
  { path: 'report-cards', component: ReportCardsComponent },
  { path: 'manage-assessment', component: ManageAssessmentComponent },
  { path: 'grade-setup', component: GradeSetupComponent },
  {path: 'manage-branch-grid', component: ManageBranchGridComponent},
  { path: 'manage-teachers', component: ManageTeachersComponent },
  { path: 'manage-teachers/view-teacher', component: ViewTeacherComponent },
  { path: 'manage-teachers/edit-teacher', component: EditTeacherComponent },
  { path: 'subjects-allocation', component: SubjectsAllocationComponent },
  { path: 'manage-student-attendance', component: ManageStudentAttendanceComponent },
  { path: 'manage-teacher-attendance', component: ManageTeacherAttendanceComponent },
  { path: 'attendance-submission-analytics', component: AttendanceSubmissionAnalyticsComponent },
  { path: 'manage-students/view-profile', component: ViewProfileComponent },
  { path: 'manage-students/enrollment', component: EnrollmentComponent },
  { path: 'manage-students/activities', component: ActivitiesComponent },
  { path: 'manage-students/attendance', component: AttendanceComponent },
  { path: 'manage-students/rcard', component: RCardComponent },
  { path: 'manage-students/class-schedule', component: ClassScheduleComponent },
  { path: 'manage-students/fees-and-invoicing', component: FeesAndInvoicingComponent },
  { path: 'manage-students/add-student', component: AddStudentComponent },
  { path: 'manage-students/edit-student', component: EditStudentComponent },
  { path: 'manage-students/view-student', component: ViewStudentComponent },
  { path: 'manage-students/manage-id', component: ManageIdcardComponent },
  { path: 'manage-teachers/add-teachers', component: AddTeachersComponent },
  { path: 'login-user-profile', component: LoginUserProfileComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'manage-students/add-attachment', component: AddAttchmentComponent },
  { path: 'manage-students/view-attachment', component: ViewAttachmentComponent },
  { path: 'manage-students/edit-attachment', component: EditAttachmentComponent },
  {path: 'manage-teacher-attachment/add-teacher-attachment' , component: AddTeacherAttachmentComponent},
  {path: 'manage-teacher-attachment/edit-teacher-attachment' , component: EditTeacherAttachmentComponent},
  {path: 'manage-teacher-attachment/view-teacher-attachment' , component: ViewTeacherAttachmentComponent},
  {path: 'manage-teachers/manage-teacher-field',component: ManageTeacherFieldComponent},
  {path: 'add-admin-staff' , component: AddAdminStaffComponent},
  {path: 'manage-admin-staff' , component: ManageAdminStaffComponent},
  {path: 'edit-admin-staff' , component: EditAdminStaffComponent},
  {path: 'view-admin-staff' , component: ViewAdminStaffComponent},
  {path: 'attendance-settings', component: AttendanceSettingsComponent},
  {path: 'manage-roles', component: ManageRolesComponent},
  {path: 'manage-roles/add-roles', component: AddRolesComponent},
  {path: 'manage-roles/edit-roles', component: EditRolesComponent},
  {path: 'template1', component: TemplateOneComponent},
  {path: 'template2', component: TemplateTwoComponent},
  {path: 'template3', component: TemplateThreeComponent},
  {path: 'customforms', component: CustomformsComponent },
  {path: 'teachers-forms', component: TeachersFormsComponent},
  {path:'manage-teachers/teachersclass-schedule',component:TeachersclassScheduleComponent},
  {path:'student-submission-analytics',component:StudentSubmissionAnalyticsComponent},
  {path:'reportcardformats',component:ReportcardformatsComponent},
  {path:'manage-teachers/salarydetails', component:SalarydetailsComponent},
  {path:'manage-teachers/paymentdetails', component:PaymentdetailsComponent},
  {path:'viewpaymentdeatils',component:ViewpaymentdetailsComponent},
  {path:'teacherspayment',component:TeacherspaymentComponent},
  {path:'edit-report-card',component:EditReportCardComponent},
  {path:'Manage-Registration',component:ManageRegistrationsComponent},


  // {path:'edit-template-one',component:EditTemplateOneComponent },
  // {path:'edit-template-two',component:EditTemplateTwoComponent},
  // {path:'edit-template-three',component:EditTemplateThreeComponent},
  // {path:'edit-template-four',component:EditTemplateFourComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademicRoutingModule { }
