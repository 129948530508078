import { Component, OnInit, TemplateRef } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { PaymentDetailsService } from 'src/app/services/payment-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatDatepicker } from '@angular/material/datepicker';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-paymentdetails',
  templateUrl: './paymentdetails.component.html',
  styleUrls: ['./paymentdetails.component.css']
})
export class PaymentdetailsComponent implements OnInit {

  collection :any;
  filterTeacherID: number = 0;
  todaysDate: any;
  month: any;
  year: any;
  years: any[]=[];
  paymentForName: string = '';
  paymentForm: FormGroup;
  form: FormGroup;
  dateFormat:string;
  isTeacherUser: boolean = false;
  
  displayedColumns: string[] = [
    //'paymentDetailsID',
    //'userID',
    'paymentDate',

    //'salaryForMonth',
    'salaryForMonthName',
    //'absentForYear',
    'salaryForYear',
    'salaryBeforeDeduction',
    'deductionAmount',
    'salaryAfterDeduction',
    'bonusAmount',
    'adjustableAmount',
    // 'salaryAmount',
    'paidAmount',
    'alreadyPaidAmount',
    'totalAbsent',

    //'paymentUpfrontStatus',
    'paymentStatus',
    'button',
  ];
  months: any[]=[
    {monthName:"January",monthNumber:1},
    {monthName:"February",monthNumber:2},
    {monthName:"March",monthNumber:3},
    {monthName:"April",monthNumber:4},
    {monthName:"May",monthNumber:5},
    {monthName:"June",monthNumber:6},
    {monthName:"July",monthNumber:7},
    {monthName:"August",monthNumber:8},
    {monthName:"September",monthNumber:9},
    {monthName:"October",monthNumber:10},
    {monthName:"November",monthNumber:11},
    {monthName:"December",monthNumber:12}
  ]

  constructor(
    private paymentDetailService: PaymentDetailsService,
    private activatedRoute:ActivatedRoute,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { 
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.OrgName = currentUser.orgName;
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
      this.currency = currentUser.currency;
    }
  }
  OrgName:any;
  currency:any
  users:any
  logo:any;
  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID');
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.logo = localStorage.getItem('Logo');
    }else {
      this.logo = '../../../../../../assets/img/admin/org_logo.png';
    }
    this.collection = [];
    const format2 = 'YYYY-MM-DD';
    const format3 = 'YYYY';
    const format4 = 'M';

    this.todaysDate = moment(new Date()).format(format2);
    this.month = moment(new Date()).format(format3);
    this.year = moment(new Date()).format(format4);

    
    console.log('moment(new Date()).format(format2) YYYY', moment(new Date()).format(format3));
    console.log('moment(new Date()).format(format2) MM', moment(new Date()).format(format4));


    this.searchFilterForm();
    this.activatedRoute.queryParams.subscribe(params => {
      this.filterTeacherID = params.id==undefined?null:params.id;
    });

    this.form.patchValue({
      seletedDate: this.todaysDate,
      month: this.todaysDate,
      year:0
    });

    this.paymentPopForm();
    //this.GetYearAndMonth();
    this.getYear(2020)
    this.getTeacherPaymentDetails(this.month, this.year, this.filterTeacherID);
  }

  monthSelection(event: Event){
    console.log("event monthSelection", event);
    this.month = event;
  }

  yearSelection(event: Event){
    console.log("event yearSelection", event);
    this.year = event;
  }

  searchFilterForm() {
    this.form = this.fb.group({
      seletedDate: ['', ],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

  paymentPopForm() {
    this.paymentForm = this.fb.group({
      upfrontPayment: false,
      salaryAfterDeduction: [0, [Validators.required, Validators.min(1)]],
      bonusAmount: [0, [Validators.required, Validators.min(1)]],
      paidAmount: [0, [Validators.required, Validators.min(1)]],
      comment: ['', [Validators.required]]
    });

  }

  resetForm(event: Event){
    this.form.reset();

    // const format2 = 'YYYY-MM-DD';
    // this.todaysDate = moment(new Date()).format(format2);

    // this.form.patchValue({
    //   seletedDate: this.todaysDate,
    // });
  }

  onDateChange(event: any, datepicker: MatDatepicker<Moment>) {
    debugger
   //console.log("event.target.value;", event.target.value);
   console.log("datepicker", datepicker);
   console.log("date", this.form);
   datepicker.close();
  }
  
  onChange(event: any) {
    console.log("event.target.value;", event);
    const format2 = 'YYYY-MM-DD';
    let convertedDate = moment(event).format(format2)
    console.log("moment(date2).format(format2)", convertedDate );
    this.form.patchValue({
      seletedDate: convertedDate,
    });
    this.todaysDate = convertedDate;
  }

  onFilterSubmit() {
    if (this.form.valid) {
      this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID)
    }
    else {
      this.form.markAllAsTouched();
      this.toaster.error("Please validate month and year.");
    }
  }

  actionDialogPopup( element: any) {
    this.paymentForName = element.teacherName;
    this.router.navigate([ '/admin/academicslearning/viewpaymentdeatils' ], { queryParams: { id: element.paymentDetailsID} })
   if (element.salaryAfterDeduction > 0 && element.salaryAmount > 0) {

    //   this.paymentForm.patchValue({
    //   upfrontPayment: element.paymentUpfrontStatus,
    //   salaryAfterDeduction: element.salaryAfterDeduction,
    //   bonusAmount: 0,
    //   paidAmount: element.paidAmount,
    //   comment: ''
    // });
    // this.dialog.open(templateRef);

    //this.router.navigate([ '/admin/academicslearning/viewpaymentdeatils' ], { queryParams: { id: element.paymentDetailsID} })
   

   }
  //  else{
  //    this.toaster.error("Salary not released")
  //  }
    console.log("element", element)
  }

  // GetYearAndMonth() {
  //   console.log('this.collection', this.collection);

  //   this.paymentDetailService
  //     .GetYearAndMonthFromPaymentDetails()
  //     .subscribe((res: any) => {
  //       if (res.status > 0) {
  //         this.months = res.responseData.months
  //         //this.years = res.responseData.year
  //        console.log("GetYearAndMonth res", res);
  //       }
  //     });
  // }
  getYear(startYear:any){
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 2020;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    for(var i=0;i<years.length;i++){
       this.years.push({
        yearName:JSON.stringify(years[i]),
        yearNumber:years[i]
       })
    }
  }
  getTeacherPaymentDetails(year: number, month: number, filterTeacherID: number) {
    console.log('this.collection', this.collection);

    this.paymentDetailService
      .GetTeacherPaymentDetails(year, month, filterTeacherID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.collection = res.responseData;
          console.log('this.collection', this.collection);
        }
      });
  }

  salaryForYear:any;
  salaryForMonthName:any;
  teacherDetails:any;
  salaryField:any;
  deductionAmount:any;
  bonusAmount:any
  totalEarning:any;
  totalDeduction:any;
  salaryslipopup(templateRef: TemplateRef<any> , element: any){
    this.totalEarning = 0;
    this.totalDeduction = 0;
    console.log('ELEMENT', element);
    this.salaryForMonthName = element.salaryForMonthName;
    this.salaryForYear = element.salaryForYear;
    this.deductionAmount = element.deductionAmount;
    this.bonusAmount = element.bonusAmount;
    this.paymentDetailService
      .GetSalarySlipFormByTeacherID(element.userID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          console.log('this.collection', res.responseData);
          this.teacherDetails = res.responseData.coreTeacherDetails;
          this.salaryField = res.responseData.salaryFormDtos;
          for(let i=0;i<this.salaryField.length;i++){
            if(this.salaryField[i].deductionType==false){
              this.totalEarning = this.salaryField[i].salaryFormFieldValue + this.totalEarning
            }
            if(this.salaryField[i].deductionType==true){
              this.totalDeduction = this.salaryField[i].salaryFormFieldValue + this.totalDeduction
            }
          }
          this.totalEarning = this.bonusAmount + this.totalEarning;
          this.totalDeduction = this.totalDeduction + this.deductionAmount
          this.dialog.open(templateRef);
        }
      });
  }
}
// const ELEMENT_DATA: any[] = [
//   { date: '25-Jan-2022', salary: '15,000/-', paidAmt: '1600', absent: '2',month:'January',button:'' },
// ];