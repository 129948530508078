import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, RolesandPermission } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  permissions: any = [];
  rolePermissionsString: any;
  currentUserString: any;

  constructor(private router: Router, private toaster: ToastrService) { }

  addPermissions(permission: any) {
    this.permissions = permission;
    localStorage.setItem('rolePermissions', JSON.stringify(permission));
    console.log("hdada", this.permissions);
  }

  getAllPermissions() {
    //Legasov Added March-16-2022
    this.addRolePermissionToModel();
    return this.permissions;
  }

  getPermissionByScreenName(screenName: string) {
    return this.permissions.find((x: any) => x.screenName == screenName)
      .children;
  }

  //Legasov Added March-17-2022
  checkModulePermission(moduleName: any) {
    var response = false;

    this.addRolePermissionToModel();

    if (this.checkUserRoleID() === 1) {
      response = true;
    } else {
      response = this.permissions.find((x: any) => x.moduleName == moduleName);
    }

    return this.noPermissionRoute(response);
  }

  //Legasov Added March-17-2022
  checkSubModulePermission(subModuleName: any) {
    var response = false;

    this.addRolePermissionToModel();

    if (this.checkUserRoleID() === 1) {
      response = true;
    } else {
      response = this.permissions.find(
        (x: any) => x.subModuleName == subModuleName
      );
    }

    return this.noPermissionRoute(response);
  }

  //Legasov Added March-17-2022
  checkScreenPermission(screen: any) {

    var response = false;

    this.addRolePermissionToModel();

    if (this.checkUserRoleID() === 1) {
      response = true;
    } else {
      response = this.permissions?.find((x: any) => x?.screenName == screen);
    }

    return this.noPermissionRoute(response);
  }

  //Legasov Added March-17-2022
  getFilterScreen(screenName: any) {
    this.addRolePermissionToModel();

    return this.permissions.find((x: any) => x.screenName == screenName);
  }
  getReportFilterScreen(screenAction: any, screenName: any) {
    var response = false;
    var name = this.getFilterScreen(screenName);
    if (name) {
      var getScreenActions = name.children.find((x: any) => x.actionName === screenAction)
      if (getScreenActions) {
        response = true;
      }
    }
    return response
  }
  //Legasov Added March-17-2022
  checkScreenActionPermission(screenAction: any, screenName: any) {
    //console.log('screenAction', screenAction);
    var response = false;

    this.addRolePermissionToModel();

    if (this.checkUserRoleID() === 1) {
      response = true;
    } else {
      var getScreenActionNames = this.permissions.find(
        (x: any) => x.screenName === screenName
      );
      //console.log('getScreenActionNames', getScreenActionNames);
      response = getScreenActionNames.children.filter(
        (x: any) => x.actionName === screenAction
      );
    }

    return this.noPermissionRoute(response);
  }

  //Legasov Added March-17-2022
  getScreenActions(screenActionName: any) {
    this.addRolePermissionToModel();

    return this.permissions.find((x: any) => x.actionName == screenActionName);
  }

  checkUserRoleID() {
    this.currentUserString = localStorage.getItem('currentUser');
    var currentuserObj = JSON.parse(this.currentUserString);
    return currentuserObj.roleID;
  }

  //Legasov Added March-25-2022
  checkComponentHasScreenPermission(screenName: any) {
    this.rolePermissionsString = localStorage.getItem('rolePermissions');
    //console.log('permission', JSON.parse(this.rolePermissionsString));
    var response = false;

    this.addRolePermissionToModel();

    if (this.checkUserRoleID() === 1) {
      response = true;
    } else {
      response = this.permissions.find((x: any) => x.screenName == screenName);
    }

    response = this.noPermissionRoute(response);

    if (response == false) {
      this.router.navigate(['/admin/errorpage']);
      this.toaster.error("You don't have access to this page");
    }
  }

  //Legasov Added March-21-2022
  noPermissionRoute(screenFilterStatus: any) {
    let response = false;

    if (
      screenFilterStatus === undefined ||
      screenFilterStatus === 'undefined' ||
      screenFilterStatus == undefined ||
      screenFilterStatus == 'undefined'
    ) {
      screenFilterStatus = false;
      // if (this.router.url != '/admin/errorpage') {
      //   this.router.navigate(['/admin/errorpage']);
      // }
      return response;
    } else {
      return screenFilterStatus;
    }
  }

  //Legasov Added March-17-2022
  private addRolePermissionToModel() {
    this.rolePermissionsString = localStorage.getItem('rolePermissions');
    this.permissions = JSON.parse(this.rolePermissionsString);
  }
}
