<div *ngIf="!showpopupflag">
    <header class="adminheader">
        <div class="headerTop">
            <div class="container container1580">
                <button mat-icon-button (click)="change()" class="mobileMenu">
                    <mat-icon>menu</mat-icon>
                </button>
                <div class="topHeaderLeft">
                    <!-- <a href="/admin"><img src="../../../../assets/img/admin/logo.png" alt="" height="34"></a> -->
                    <a href="/admin"><img [src]="croppedImage" width="90" height="150" *ngIf="CheckLogoStatus==1"></a>
                    <a href="/admin"><img [src]="croppedImage" alt="org-logo" width="90" height="150"
                            *ngIf="CheckLogoStatus==0"></a>
                </div>


                <div class="megamenu-top">
                    <!-- <ul class="d-flex">
                        <li><a href="#">Dashboard</a></li>
                        <li><a href="#">Academic & Learning</a></li>
                        <li><a href="#">Communication</a></li>
                        <li><a href="#">Administration</a></li>
                    </ul> -->
                    <ul class="admin-menu">
                        <li><a class="pl-2" routerLink="/admin" routerLinkActive="active"
                                *ngIf!="IsStudentOrPreant"><span>Dashboard</span></a>
                        </li>
                        <li class="dropdown-megamenu"><a routerLinkActive="active"
                                *ngIf="checkModulePermission('Academic & Learning') && checkModulePermission('Manage Registration Field')" (mouseenter)="clickEvent()"
                                (mouseleave)="clickEvent3()"><span>Academic
                                    &amp; Learning</span></a>
                            <div class="megaMenu"
                                [ngClass]="[headermenu ? 'show' : 'none' ,(IsStudentOrPreant == true || IsTeacher == true)? 'studentmenu' : '']">
                                <div class="megaMenuRow">
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Courses & Subjects')">
                                        <i class="icon">
                                            <!-- <svg width="16" height="11.991" class="icon">
                                                <use xlink:href="#course-icon"></use>
                                            </svg> -->
                                        </i>
                                        <h4>Courses &amp; Subjects</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Class, Section and Subjects')">
                                                <a routerLink="academicslearning/manage-academic"
                                                    routerLinkActive="active" class="d-flex align-center"
                                                    (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.912"
                                                            height="19.738" viewBox="0 0 13.912 19.738">
                                                            <path id="Path_948" data-name="Path 948"
                                                                d="M26.669,8.377s-6.513,3.126-7.236,3.456a3.427,3.427,0,0,1-1.581.32,4.1,4.1,0,0,1-1.892-.544c-.434-.25-.364-.625,0-.8.23-.11,3.055-1.4,3.055-1.4l3.606-1.673.695.283V6.956a.745.745,0,0,0-1.056-.677S15.316,9.46,15.175,9.531c-1.005.505-1.323.779-1.352,2.059V23.137a2.4,2.4,0,0,0,1.2,2.109A5.692,5.692,0,0,0,20,25.487l7.219-3.814a.745.745,0,0,0,.4-.652l.115-11.965A.745.745,0,0,0,26.669,8.377ZM18.831,23.11l-.1.019c-1.519.287-3.394.028-3.8-1.154V20.318c.429,1.262,2.453,1.408,3.8,1.154l.1-.019Zm0-6.338c-1.51.313-3.481.083-3.895-1.135V13.979c.44,1.293,2.551,1.413,3.895,1.135Z"
                                                                transform="translate(-13.823 -6.211)" fill="#74c4ba" />
                                                        </svg>
                                                    </div>
                                                    Manage Class &amp; Subjects
                                                </a>
                                                <div class="arrowiconsonhover one">
                                                    <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </li>
                                        </ul>

                                        <!-- <h4 *ngIf="checkModulePermission('Manage Registration Field')"  style="margin-top: 20px;">Registration</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli">
                                                <a routerLink="academicslearning/Manage-Registration" [queryParams]="{formType: '3'}" routerLinkActive="active" class="d-flex align-center" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.912"
                                                            height="19.738" viewBox="0 0 13.912 19.738">
                                                            <g id="Production" transform="translate(-6.555 -5.87)">
                                                                <g id="Group_436" data-name="Group 436"
                                                                    transform="translate(6.555 5.87)">
                                                                    <path id="Path_954" data-name="Path 954"
                                                                        d="M11.284,16.993a1.037,1.037,0,0,0,1.466,0l4.722-4.723L16.006,10.8l-4.722,4.723A1.037,1.037,0,0,0,11.284,16.993Zm10.4-4.775a2.5,2.5,0,0,0,0-3.528L19.594,6.6a2.5,2.5,0,0,0-3.528,0L14.913,7.751l5.62,5.62ZM25.3,22.345a.693.693,0,0,0-.91-.364,25.653,25.653,0,0,1-4.835,1.477,22.993,22.993,0,0,0,3.325-5.206c.149-.334.272-.679,0-.991A.69.69,0,0,0,21.9,17.2l-.009.008c-2.951,2.716-6.142,4.407-11.8,4.583a.592.592,0,0,0-.57.551c-.033.505.429.61.822.638a16.5,16.5,0,0,0,9.443-2.236l.157-.1c-2.2,3.28-3.113,3.159-3.02,3.925a.69.69,0,0,0,.768.6,28.442,28.442,0,0,0,7.244-1.921A.693.693,0,0,0,25.3,22.345ZM14.082,19.822l5.471-5.471L18.22,13.019,13.5,17.741a1.037,1.037,0,0,0-.185,1.215l-3.654.961-1.3-1.3.969-3.646a1.037,1.037,0,0,0,1.216-.184l4.722-4.722L13.933,8.731,8.462,14.2a.554.554,0,0,0-.144.25L6.574,21.018a.554.554,0,0,0,.677.678l6.58-1.73A.554.554,0,0,0,14.082,19.822Z"
                                                                        transform="translate(-6.555 -5.87)"
                                                                        fill="#b98269" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    Manage Registration Field
                                                </a>
                                                <div class="arrowiconsonhover four">
                                                    <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </li>
                                        </ul> -->
                                    </div>

                                    

                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Students')">
                                        <!-- <i class="icon"><svg width="17.671" height="14.798" class="icon">
                                                <use xlink:href="#studentmm-icon"></use>
                                            </svg></i> -->
                                        <h4>Students</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Students')"><a
                                                    routerLink="academicslearning/manage-students"
                                                    routerLinkActive="active" class="d-flex align-center"
                                                    (click)="clickEvent1()">


                                                    <div class="site-nav-dropdown-icon-container menublue">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.184"
                                                            height="16.127" viewBox="0 0 18.184 16.127">
                                                            <g id="icon" transform="translate(-8.291 -10.973)">
                                                                <g id="Group_435" data-name="Group 435"
                                                                    transform="translate(8.291 10.973)">
                                                                    <path id="Path_953" data-name="Path 953"
                                                                        d="M22.016,24.122H18.981v.4a1.408,1.408,0,1,1-2.815,0v-.4H13.131a3.827,3.827,0,0,1-3.04-1.505V26.35a.75.75,0,0,0,.749.75H24.307a.75.75,0,0,0,.749-.75V22.616A3.826,3.826,0,0,1,22.016,24.122Zm2.5-10.8c-1.593-.435-3.92.064-6.467-.251-3.315-.409-5.688-3.616-8.617-1.213-1.893,1.553-1.264,4.509.656,6.205l0,0v2.344a3.046,3.046,0,0,0,3.04,2.925h3.035v-.408a1.408,1.408,0,1,1,2.815,0v.407h3.035a3.045,3.045,0,0,0,3.04-2.931V18.213A2.636,2.636,0,0,0,24.516,13.322ZM24.35,16.8c-.057,0-13.266,0-13.323,0-1.163-1.1-1.621-2.882-.6-3.721,1.967-1.614,3.8,1.111,7.428,1.558,3.734.461,6.748-.632,7.02,1.065A1.045,1.045,0,0,1,24.35,16.8ZM18.2,24.525v-1.6a.622.622,0,1,0-1.244,0v1.6a.622.622,0,1,0,1.244,0Z"
                                                                        transform="translate(-8.291 -10.973)"
                                                                        fill="#7094db" />
                                                                </g>
                                                            </g>
                                                        </svg>


                                                    </div>


                                                    Manage Students
                                                </a>

                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>

                                            <!-- </li> -->
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Alumni')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-alumni"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menured">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15"
                                                            viewBox="0 0 20 15">
                                                            <path id="Path_952" data-name="Path 952"
                                                                d="M10.816,88.662l4.817-1.846v2.247c0,1.583-4.453,2.814-5.625,2.814s-5.625-1.27-5.625-2.814V86.82l4.781,1.841a2.3,2.3,0,0,0,.828.154h.016a1.937,1.937,0,0,0,.356-.034A2.29,2.29,0,0,0,10.816,88.662Zm8.943,4.731a2.324,2.324,0,0,1-1.812-1.735.928.928,0,0,0-.126-1.527v-4.89l1.869-.716a.492.492,0,0,0,0-.92l-9.1-3.5a1.616,1.616,0,0,0-1.158,0L.327,83.6a.491.491,0,0,0,0,.92l9.085,3.5a1.615,1.615,0,0,0,1.159,0L16.883,85.6v4.531a.93.93,0,0,0,.009,1.621c.077,2.135.641,2.964.994,3.2C18.354,95.278,20.782,93.8,19.758,93.393Z"
                                                                transform="translate(-0.008 -80.002)" fill="#db7775" />
                                                        </svg>
                                                    </div>
                                                    Manage Alumni
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Student Fields')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/customforms"
                                                    [queryParams]="{formType: '1'}" routerLinkActive="active"
                                                    (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menuyellow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.805"
                                                            height="19.311" viewBox="0 0 18.805 19.311">
                                                            <g id="Production" transform="translate(-6.555 -5.87)">
                                                                <g id="Group_436" data-name="Group 436"
                                                                    transform="translate(6.555 5.87)">
                                                                    <path id="Path_954" data-name="Path 954"
                                                                        d="M11.284,16.993a1.037,1.037,0,0,0,1.466,0l4.722-4.723L16.006,10.8l-4.722,4.723A1.037,1.037,0,0,0,11.284,16.993Zm10.4-4.775a2.5,2.5,0,0,0,0-3.528L19.594,6.6a2.5,2.5,0,0,0-3.528,0L14.913,7.751l5.62,5.62ZM25.3,22.345a.693.693,0,0,0-.91-.364,25.653,25.653,0,0,1-4.835,1.477,22.993,22.993,0,0,0,3.325-5.206c.149-.334.272-.679,0-.991A.69.69,0,0,0,21.9,17.2l-.009.008c-2.951,2.716-6.142,4.407-11.8,4.583a.592.592,0,0,0-.57.551c-.033.505.429.61.822.638a16.5,16.5,0,0,0,9.443-2.236l.157-.1c-2.2,3.28-3.113,3.159-3.02,3.925a.69.69,0,0,0,.768.6,28.442,28.442,0,0,0,7.244-1.921A.693.693,0,0,0,25.3,22.345ZM14.082,19.822l5.471-5.471L18.22,13.019,13.5,17.741a1.037,1.037,0,0,0-.185,1.215l-3.654.961-1.3-1.3.969-3.646a1.037,1.037,0,0,0,1.216-.184l4.722-4.722L13.933,8.731,8.462,14.2a.554.554,0,0,0-.144.25L6.574,21.018a.554.554,0,0,0,.677.678l6.58-1.73A.554.554,0,0,0,14.082,19.822Z"
                                                                        transform="translate(-6.555 -5.87)"
                                                                        fill="#b98269" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    Manage Students
                                                    Field
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Attendance')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-student-attendance"
                                                    routerLinkActive="active" (click)="clickEvent1()">

                                                    <div class="site-nav-dropdown-icon-container menupurple">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.366"
                                                            height="17.394" viewBox="0 0 21.366 17.394">
                                                            <g id="icon" transform="translate(-7.656 -12.181)">
                                                                <g id="Group_439" data-name="Group 439"
                                                                    transform="translate(7.656 12.181)">
                                                                    <path id="Path_957" data-name="Path 957"
                                                                        d="M28.006,16.262h-8.3A1.015,1.015,0,0,0,18.7,17.277v6.887a1.015,1.015,0,0,0,1.015,1.015h8.3a1.015,1.015,0,0,0,1.015-1.015V17.277A1.015,1.015,0,0,0,28.006,16.262ZM26.992,23.15H20.725V18.292h6.267ZM12.624,12.181a5.026,5.026,0,0,0-4.969,4.91V28.533A1.042,1.042,0,0,0,8.7,29.575h7.854a1.041,1.041,0,0,0,1.041-1.041V17.091A5.021,5.021,0,0,0,12.624,12.181Zm-1.993,15.4a1.29,1.29,0,1,1,1.29-1.29A1.291,1.291,0,0,1,10.631,27.585Zm4.52-10.494a2.527,2.527,0,0,1-5.054,0h0a2.527,2.527,0,0,1,5.054,0Z"
                                                                        transform="translate(-7.656 -12.181)"
                                                                        fill="#8686e2" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    Manage
                                                    Attendance
                                                </a>
                                                <div class="arrowiconsonhover five">
                                                    <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Teachers & Staff/Admin')">

                                        <h4>Teachers &amp;Admin/Staffs</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Admin')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-admin-staff"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.759"
                                                            height="20.429" viewBox="0 0 18.759 20.429">
                                                            <g id="icon" transform="translate(-7.638 -5.469)">
                                                                <g id="Group_438" data-name="Group 438"
                                                                    transform="translate(7.638 5.469)">
                                                                    <path id="Path_956" data-name="Path 956"
                                                                        d="M13.894,7.9l2.368,1.959L15,12.166a8.216,8.216,0,0,0,7.721-2.057c-.826-3.236-1.313-4.639-2.494-4.639s-2.408,1.716-3.236,1.716-2.054-1.716-3.236-1.716-1.659,1.381-2.485,4.606a10.66,10.66,0,0,0,.968.792Zm12.5,5.323c0-1.2-1.848-1.32-3.048-1.365a9.872,9.872,0,0,1-12.674,0c-1.207.046-3.037.167-3.037,1.365,0,.662.67,1.516,1.773,2.316-.565,1.138-.4,3.646,1.185,4.491C11.651,23.7,14.871,25.9,17.016,25.9c1.94,0,5.334-1.9,6.416-5.871,1.584-.848,1.741-3.346,1.183-4.481C25.724,14.744,26.4,13.887,26.4,13.224ZM23.468,17.6c-.2,1.277-.9,1.26-1.189,1.3l-.106.457c-.832,3.6-3.817,5.152-5.157,5.152s-4.3-1.63-5.168-5.161l-.068-.44c-.267-.041-1.018.013-1.225-1.311a2.874,2.874,0,0,1,.041-1.32,13.749,13.749,0,0,0,3.477,1.3,1.043,1.043,0,1,0,1.462.249,12.945,12.945,0,0,0,2.968,0,1.042,1.042,0,1,0,1.461-.249,13.747,13.747,0,0,0,3.465-1.3A2.886,2.886,0,0,1,23.468,17.6Z"
                                                                        transform="translate(-7.638 -5.469)"
                                                                        fill="#e95a6b" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    Manage
                                                    Admins
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Teachers')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-teachers"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menuyellow">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.352"
                                                            height="17.244" viewBox="0 0 22.352 17.244">
                                                            <g id="_4999505_college_educate_education_glasses_nerd_icon"
                                                                data-name="4999505_college_educate_education_glasses_nerd_icon"
                                                                transform="translate(-5.482 -13.59)">
                                                                <path id="Path_958" data-name="Path 958"
                                                                    d="M10.234,25.656a.828.828,0,0,1-.824-.828v-9a2.24,2.24,0,0,1,2.235-2.235h1.582a.824.824,0,0,1,0,1.648H11.645a.587.587,0,0,0-.583.587v9a.828.828,0,0,1-.828.828Z"
                                                                    transform="translate(-2.206)" fill="#b98269" />
                                                                <path id="Path_959" data-name="Path 959"
                                                                    d="M47.817,25.656a.828.828,0,0,0,.824-.828v-9a2.24,2.24,0,0,0-2.235-2.235H44.824a.824.824,0,0,0,0,1.648h1.582a.587.587,0,0,1,.583.587v9a.828.828,0,0,0,.828.828Z"
                                                                    transform="translate(-21.636)" fill="#b98269" />
                                                                <path id="Path_960" data-name="Path 960"
                                                                    d="M30.585,37.029a.657.657,0,0,1-.657-.657,1.516,1.516,0,0,0-3.033,0,.657.657,0,0,1-1.315,0,2.831,2.831,0,1,1,5.663,0A.657.657,0,0,1,30.585,37.029Z"
                                                                    transform="translate(-11.289 -11.206)"
                                                                    fill="#b98269" />
                                                                <circle id="Ellipse_39" data-name="Ellipse 39"
                                                                    cx="4.164" cy="4.164" r="4.164"
                                                                    transform="translate(5.482 25.09) rotate(-46.39)"
                                                                    fill="#ebeef2" />
                                                                <circle id="Ellipse_40" data-name="Ellipse 40"
                                                                    cx="4.164" cy="4.164" r="4.164"
                                                                    transform="translate(17.853 28.072) rotate(-77.06)"
                                                                    fill="#ebeef2" />
                                                                <path id="Path_961" data-name="Path 961"
                                                                    d="M11.742,36a4.12,4.12,0,0,0-2.231.653,4.348,4.348,0,0,0-.1.9,4.164,4.164,0,0,0,4.164,4.164,4.12,4.12,0,0,0,2.231-.675,4.063,4.063,0,0,0,.1-.877A4.164,4.164,0,0,0,11.742,36Z"
                                                                    transform="translate(-2.206 -12.588)"
                                                                    fill="#dcdfe2" />
                                                                <path id="Path_962" data-name="Path 962"
                                                                    d="M37.912,36a4.146,4.146,0,0,0-2.231.653,4.348,4.348,0,0,0-.1.9A4.164,4.164,0,0,0,39.748,41.7a4.12,4.12,0,0,0,2.227-.653,4.063,4.063,0,0,0,.1-.876A4.164,4.164,0,0,0,37.912,36Z"
                                                                    transform="translate(-16.906 -12.588)"
                                                                    fill="#dcdfe2" />
                                                                <path id="Path_963" data-name="Path 963"
                                                                    d="M12.776,38.142a4.786,4.786,0,1,1,4.786-4.782,4.786,4.786,0,0,1-4.786,4.782Zm0-8.327a3.546,3.546,0,1,0,3.546,3.546,3.546,3.546,0,0,0-3.546-3.546Z"
                                                                    transform="translate(-1.409 -8.414)"
                                                                    fill="#b98269" />
                                                                <path id="Path_964" data-name="Path 964"
                                                                    d="M38.95,38.142a4.786,4.786,0,1,1,4.782-4.782A4.786,4.786,0,0,1,38.95,38.142Zm0-8.327A3.546,3.546,0,1,0,42.5,33.36,3.546,3.546,0,0,0,38.95,29.815Z"
                                                                    transform="translate(-16.109 -8.415)"
                                                                    fill="#b98269" />
                                                            </g>
                                                        </svg>

                                                    </div>Manage
                                                    Teachers
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>

                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Teacher Fields')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/customforms"
                                                    [queryParams]="{formType: '2'}" routerLinkActive="active"
                                                    (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menupurple">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.241"
                                                            height="18.626" viewBox="0 0 19.241 18.626">
                                                            <g id="icon" transform="translate(-6.183 -7.008)">
                                                                <g id="Group_437" data-name="Group 437"
                                                                    transform="translate(6.183 7.008)">
                                                                    <path id="Path_955" data-name="Path 955"
                                                                        d="M10.6,24.489H6.756a.572.572,0,1,0,0,1.144H10.6a.572.572,0,1,0,0-1.144Zm7.743-3.912-.058,1.46a18.462,18.462,0,0,0-3.814,1.426l1.152-1.776a.874.874,0,0,0,.827-.388.887.887,0,1,0-1.506-.052L13.8,23.011c.019-.3.031-.615.031-.957a14.938,14.938,0,0,0-.332-3.143l1.286-.634a4.321,4.321,0,0,1-.391-1.082l-1.807.891a.569.569,0,0,0-.3.65,13.805,13.805,0,0,1,.4,3.318,11.746,11.746,0,0,1-.31,2.824.573.573,0,0,0,.89.616,12.554,12.554,0,0,1,1.561-.93,17.167,17.167,0,0,1,4.138-1.511.575.575,0,0,0,.448-.537L19.5,20.5A3.32,3.32,0,0,1,18.347,20.578ZM24.4,7.372a2.248,2.248,0,0,0-3.111.66c-2.244,3.462-4.607,4.8-5.622,6.308a3.528,3.528,0,0,0,2.957,5.449h0a3.524,3.524,0,0,0,2.956-1.6c.932-1.487,1.226-4.244,3.478-7.7A2.249,2.249,0,0,0,24.4,7.372Zm-.8,1.886c-.139.313-1.28,2.343-4.663,5.432a11.686,11.686,0,0,0-.991.924A17.032,17.032,0,0,0,16.912,17.2c-.081.135-.217.054-.27-.062a2.3,2.3,0,0,1,.219-2.253,5.514,5.514,0,0,1,1.265-1.224,25.32,25.32,0,0,0,4.359-5.085.681.681,0,0,1,.943-.2C23.76,8.578,23.754,8.9,23.6,9.257Z"
                                                                        transform="translate(-6.183 -7.008)"
                                                                        fill="#8686e2" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>Manage Teacher Fields
                                                </a>
                                                <div class="arrowiconsonhover five">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <!-- <li><a href="javascript:void(0)">Manage Students Attendance</a></li> -->
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Teachers Attendance')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-teacher-attendance"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.366"
                                                            height="17.394" viewBox="0 0 21.366 17.394">
                                                            <g id="icon" transform="translate(-7.656 -12.181)">
                                                                <g id="Group_439" data-name="Group 439"
                                                                    transform="translate(7.656 12.181)">
                                                                    <path id="Path_957" data-name="Path 957"
                                                                        d="M28.006,16.262h-8.3A1.015,1.015,0,0,0,18.7,17.277v6.887a1.015,1.015,0,0,0,1.015,1.015h8.3a1.015,1.015,0,0,0,1.015-1.015V17.277A1.015,1.015,0,0,0,28.006,16.262ZM26.992,23.15H20.725V18.292h6.267ZM12.624,12.181a5.026,5.026,0,0,0-4.969,4.91V28.533A1.042,1.042,0,0,0,8.7,29.575h7.854a1.041,1.041,0,0,0,1.041-1.041V17.091A5.021,5.021,0,0,0,12.624,12.181Zm-1.993,15.4a1.29,1.29,0,1,1,1.29-1.29A1.291,1.291,0,0,1,10.631,27.585Zm4.52-10.494a2.527,2.527,0,0,1-5.054,0h0a2.527,2.527,0,0,1,5.054,0Z"
                                                                        transform="translate(-7.656 -12.181)"
                                                                        fill="#74c4ba" />
                                                                </g>
                                                            </g>
                                                        </svg>





                                                    </div>Manage
                                                    Teachers Attendance
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <!-- <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Teachers Payment')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/teacherspayment"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.366"
                                                            height="17.394" viewBox="0 0 21.366 17.394">
                                                            <g id="icon" transform="translate(-7.656 -12.181)">
                                                                <g id="Group_439" data-name="Group 439"
                                                                    transform="translate(7.656 12.181)">
                                                                    <path id="Path_957" data-name="Path 957"
                                                                        d="M28.006,16.262h-8.3A1.015,1.015,0,0,0,18.7,17.277v6.887a1.015,1.015,0,0,0,1.015,1.015h8.3a1.015,1.015,0,0,0,1.015-1.015V17.277A1.015,1.015,0,0,0,28.006,16.262ZM26.992,23.15H20.725V18.292h6.267ZM12.624,12.181a5.026,5.026,0,0,0-4.969,4.91V28.533A1.042,1.042,0,0,0,8.7,29.575h7.854a1.041,1.041,0,0,0,1.041-1.041V17.091A5.021,5.021,0,0,0,12.624,12.181Zm-1.993,15.4a1.29,1.29,0,1,1,1.29-1.29A1.291,1.291,0,0,1,10.631,27.585Zm4.52-10.494a2.527,2.527,0,0,1-5.054,0h0a2.527,2.527,0,0,1,5.054,0Z"
                                                                        transform="translate(-7.656 -12.181)"
                                                                        fill="#74c4ba" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>Teachers Payment
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li> -->
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Subject Allocations')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/subjects-allocation"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menublue">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.578"
                                                            height="16.267" viewBox="0 0 18.578 16.267">
                                                            <g id="Decentralization"
                                                                transform="translate(-2.995 -3.867)">
                                                                <path id="Path_966" data-name="Path 966"
                                                                    d="M21.379,11.531,19.944,10.1a.663.663,0,1,0-.938.938l.3.3H15.861a3.624,3.624,0,0,0-1.22-2.093l1.73-3,.111.413a.666.666,0,0,0,.641.491.654.654,0,0,0,.171-.023.663.663,0,0,0,.47-.812l-.524-1.959a.869.869,0,0,0-.548-.485.668.668,0,0,0-.265.017l-1.959.525A.663.663,0,1,0,14.811,5.7l.413-.111L13.495,8.579a3.4,3.4,0,0,0-2.421,0L9.346,5.584l.413.111A.663.663,0,0,0,10.1,4.414L8.144,3.888a.641.641,0,0,0-.127-.008.671.671,0,0,0-.134-.008.663.663,0,0,0-.552.484L6.806,6.317a.663.663,0,0,0,.47.812.646.646,0,0,0,.171.023.666.666,0,0,0,.641-.491l.11-.412,1.729,3a3.625,3.625,0,0,0-1.221,2.093H5.259l.3-.3a.663.663,0,1,0-.938-.938L3.189,11.53a.663.663,0,0,0,0,.938L4.624,13.9a.663.663,0,1,0,.938-.938l-.3-.3H8.707a3.624,3.624,0,0,0,1.22,2.093l-1.73,3-.11-.412a.664.664,0,1,0-1.282.343l.524,1.959a.666.666,0,0,0,.641.491.646.646,0,0,0,.171-.023l1.959-.524A.663.663,0,0,0,9.759,18.3l-.415.111,1.729-2.995a3.4,3.4,0,0,0,2.421,0l1.728,2.994L14.81,18.3a.663.663,0,0,0-.344,1.281l1.959.525a.664.664,0,0,0,.813-.469l.526-1.959a.664.664,0,1,0-1.282-.343l-.111.414-1.729-3a3.625,3.625,0,0,0,1.221-2.093h3.448l-.3.3a.663.663,0,1,0,.938.938l1.435-1.434a.663.663,0,0,0,0-.938ZM9.966,12a2.318,2.318,0,1,1,2.318,2.317A2.318,2.318,0,0,1,9.966,12Z"
                                                                    transform="translate(0)" fill="#7094db" />
                                                            </g>
                                                        </svg>





                                                    </div>Subjects
                                                    Allocation
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <!-- *ngIf="checkScreenPermission('Teachers Payment')": will add check later -->
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/teacherspayment"
                                                    routerLinkActive="active" (click)="clickEvent1()">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.793"
                                                            height="13.781" viewBox="0 0 18.793 13.781">
                                                            <g id="Layer_x0020_1" transform="translate(0 0)">
                                                                <path id="Path_995" data-name="Path 995"
                                                                    d="M32.377,48.17H19.213a.626.626,0,0,0,0,1.252H32.377a1.6,1.6,0,0,1,1.593,1.595v8.089A1.6,1.6,0,0,1,32.377,60.7h-13.1a1.6,1.6,0,0,1-1.593-1.593V50.954a.626.626,0,1,0-1.253,0v8.151a2.848,2.848,0,0,0,2.846,2.846h13.1a2.848,2.848,0,0,0,2.846-2.846V51.017a2.848,2.848,0,0,0-2.846-2.847Zm-2.1,8.893a.688.688,0,0,1-.688-.687.626.626,0,1,0-1.253,0A1.938,1.938,0,0,0,29.647,58.2v.354a.626.626,0,1,0,1.253,0V58.2a1.936,1.936,0,0,0-.626-3.77.688.688,0,1,1,.688-.688.626.626,0,1,0,1.253,0A1.937,1.937,0,0,0,30.9,51.918v-.356a.626.626,0,1,0-1.253,0v.356a1.935,1.935,0,0,0,.627,3.769.688.688,0,0,1,0,1.377ZM19.562,58.82h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Zm0-6.265h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Zm0,3.132h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Z"
                                                                    transform="translate(-16.43 -48.17)"
                                                                    fill="#e95a6b" />
                                                            </g>
                                                        </svg>

                                                    </div> Teachers Payment
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="megaMenuCol"> -->
                                    <!-- <i class="icon"><svg width="16.809" height="13.788" class="icon">
                            <use xlink:href="#lmsmm-icon"></use>
                        </svg></i> -->

                                    <!-- OBE Commeneted after client  -->
                                    <!-- <h4>OBE</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="../admin/obe/app-program-outcome"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menugreen">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.8"
                                                            height="16.633" viewBox="0 0 12.8 16.633">
                                                            <g id="_2318467_po_purchase_order_purchase_order_construction_purchase_order_software_icon"
                                                                data-name="2318467_po_purchase order_purchase order construction_purchase order software_icon"
                                                                transform="translate(-236.833 -201.356)">
                                                                <g id="Group_448" data-name="Group 448"
                                                                    transform="translate(236.833 201.356)">
                                                                    <g id="Group_445" data-name="Group 445"
                                                                        transform="translate(0)">
                                                                        <path id="Path_975" data-name="Path 975"
                                                                            d="M127,89.233H114.2V72.6h8.583l4.212,4.212V89.233Zm-11.519-1.276h10.238V77.34l-3.464-3.464h-6.774Z"
                                                                            transform="translate(-114.2 -72.6)"
                                                                            fill="#74c4ba" />
                                                                    </g>
                                                                    <g id="Group_446" data-name="Group 446"
                                                                        transform="translate(7.681 0.636)">
                                                                        <path id="Path_976" data-name="Path 976"
                                                                            d="M282.978,90.683H278.5V86.2h1.281v3.2h3.2Z"
                                                                            transform="translate(-278.5 -86.2)"
                                                                            fill="#74c4ba" />
                                                                    </g>
                                                                    <path id="Path_977" data-name="Path 977"
                                                                        d="M189.024,224.941a1.108,1.108,0,0,1-.369.9,1.565,1.565,0,0,1-1.047.313h-.332v1.3H186.5V223.8h1.169a1.539,1.539,0,0,1,1.01.285A1.039,1.039,0,0,1,189.024,224.941Zm-1.748.58h.257a.844.844,0,0,0,.533-.14.5.5,0,0,0,.178-.411.505.505,0,0,0-.15-.4.706.706,0,0,0-.467-.131h-.351Z"
                                                                        transform="translate(-183.12 -216.732)"
                                                                        fill="#74c4ba" />
                                                                    <path id="Path_978" data-name="Path 978"
                                                                        d="M247.078,224.479a1.981,1.981,0,0,1-.449,1.393,1.955,1.955,0,0,1-2.581,0,1.993,1.993,0,0,1-.449-1.4,1.945,1.945,0,0,1,.453-1.393,1.968,1.968,0,0,1,2.585,0A1.993,1.993,0,0,1,247.078,224.479Zm-2.665,0a1.528,1.528,0,0,0,.234.921.817.817,0,0,0,.7.309q.926,0,.926-1.234c0-.823-.309-1.234-.921-1.234a.808.808,0,0,0-.7.313A1.519,1.519,0,0,0,244.413,224.479Z"
                                                                        transform="translate(-237.551 -215.588)"
                                                                        fill="#74c4ba" />
                                                                </g>
                                                            </g>
                                                        </svg>





                                                    </div>Manage PO
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="../admin/obe/app-course-outcome"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menublue">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.8"
                                                            height="16.633" viewBox="0 0 12.8 16.633">
                                                            <g id="_2318467_po_purchase_order_purchase_order_construction_purchase_order_software_icon"
                                                                data-name="2318467_po_purchase order_purchase order construction_purchase order software_icon"
                                                                transform="translate(-236.833 -201.356)">
                                                                <g id="Group_448" data-name="Group 448"
                                                                    transform="translate(236.833 201.356)">
                                                                    <g id="Group_450" data-name="Group 450">
                                                                        <g id="Group_445" data-name="Group 445">
                                                                            <path id="Path_975" data-name="Path 975"
                                                                                d="M127,89.233H114.2V72.6h8.583l4.212,4.212V89.233Zm-11.519-1.276h10.238V77.34l-3.464-3.464h-6.774Z"
                                                                                transform="translate(-114.2 -72.6)"
                                                                                fill="#7094db" />
                                                                        </g>
                                                                        <g id="Group_449" data-name="Group 449">
                                                                            <g id="Group_446" data-name="Group 446"
                                                                                transform="translate(7.681 0.636)">
                                                                                <path id="Path_976" data-name="Path 976"
                                                                                    d="M282.978,90.683H278.5V86.2h1.281v3.2h3.2Z"
                                                                                    transform="translate(-278.5 -86.2)"
                                                                                    fill="#7094db" />
                                                                            </g>
                                                                            <text id="CO"
                                                                                transform="translate(3.5 10.316)"
                                                                                fill="#7094db" font-size="4"
                                                                                                                                                               font-weight="700">
                                                                                <tspan x="0" y="0">CO</tspan>
                                                                            </text>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div> Manage CO
                                                </a>
                                                <div class="arrowiconsonhover two">
                                                    <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="../admin/obe/app-po-attainment-tree-list"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menured">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23"
                                                            viewBox="0 0 30 23">
                                                            <g id="Group_454" data-name="Group 454"
                                                                transform="translate(-924 -167)">
                                                                <g id="Group_451" data-name="Group 451">
                                                                    <g id="Rectangle_219" data-name="Rectangle 219"
                                                                        transform="translate(936 167)" fill="none"
                                                                        stroke="#e95a6b" stroke-width="1">
                                                                        <rect width="7" height="7" stroke="none" />
                                                                        <rect x="0.5" y="0.5" width="6" height="6"
                                                                            fill="none" />
                                                                    </g>
                                                                    <g id="Group_453" data-name="Group 453">
                                                                        <g id="Rectangle_220" data-name="Rectangle 220"
                                                                            transform="translate(924 183)" fill="none"
                                                                            stroke="#e95a6b" stroke-width="1">
                                                                            <rect width="7" height="7" stroke="none" />
                                                                            <rect x="0.5" y="0.5" width="6" height="6"
                                                                                fill="none" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Rectangle_221" data-name="Rectangle 221"
                                                                        transform="translate(947 183)" fill="none"
                                                                        stroke="#e95a6b" stroke-width="1">
                                                                        <rect width="7" height="7" stroke="none" />
                                                                        <rect x="0.5" y="0.5" width="6" height="6"
                                                                             fill="none" />
                                                                    </g>
                                                                    <line id="Line_10" data-name="Line 10" y1="6"
                                                                        transform="translate(939.5 173.5)" fill="none"
                                                                        stroke="#e95a6b" stroke-width="1" />
                                                                    <line id="Line_11" data-name="Line 11" x2="23"
                                                                        transform="translate(927.5 179.5)" fill="none"
                                                                        stroke="#e95a6b" stroke-width="1" />
                                                                </g>
                                                                <g id="Group_452" data-name="Group 452">
                                                                    <line id="Line_12" data-name="Line 12" y1="4"
                                                                        transform="translate(927.5 179.5)" fill="none"
                                                                        stroke="#e95a6b" stroke-linecap="round"
                                                                        stroke-width="1" />
                                                                    <line id="Line_13" data-name="Line 13" y1="4"
                                                                        transform="translate(950.5 179.5)" fill="none"
                                                                        stroke="#e95a6b" stroke-linecap="round"
                                                                        stroke-width="1" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div> PO
                                                    Attainment Tree
                                                </a>
                                                <div class="arrowiconsonhover three">
                                                  <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center" routerLink="../admin/obe/app-questions"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menuyellow">
                                                        <svg id="_226568_paper_icon" data-name="226568_paper_icon"
                                                            xmlns="http://www.w3.org/2000/svg" width="14.445"
                                                            height="17.335" viewBox="0 0 14.445 17.335">
                                                            <path id="Path_977" data-name="Path 977"
                                                                d="M56.862,0H46.75a2.166,2.166,0,0,0-2.167,2.167v13a2.166,2.166,0,0,0,2.167,2.167H56.862a2.166,2.166,0,0,0,2.167-2.167v-13A2.166,2.166,0,0,0,56.862,0Zm.722,15.168a.722.722,0,0,1-.722.722H46.75a.722.722,0,0,1-.722-.722v-13a.722.722,0,0,1,.722-.722H56.862a.722.722,0,0,1,.722.722Z"
                                                                transform="translate(-44.583)" fill="#b98269" />
                                                            <path id="Path_978" data-name="Path 978"
                                                                d="M163.264,379.681a.722.722,0,0,1-.722.722h-5.778a.722.722,0,0,1-.722-.722h0a.722.722,0,0,1,.722-.722h5.778a.722.722,0,0,1,.722.722Z"
                                                                transform="translate(-152.43 -366.68)" fill="#b98269" />
                                                            <path id="Path_979" data-name="Path 979"
                                                                d="M163.264,290.514a.722.722,0,0,1-.722.722h-5.778a.722.722,0,0,1-.722-.722h0a.722.722,0,0,1,.722-.722h5.778a.722.722,0,0,1,.722.722Z"
                                                                transform="translate(-152.43 -280.402)"
                                                                fill="#b98269" />
                                                            <path id="Path_980" data-name="Path 980"
                                                                d="M163.264,201.347a.722.722,0,0,1-.722.722h-5.778a.722.722,0,0,1-.722-.722h0a.722.722,0,0,1,.722-.722h5.778a.722.722,0,0,1,.722.722Z"
                                                                transform="translate(-152.43 -194.125)"
                                                                fill="#b98269" />
                                                            <path id="Path_981" data-name="Path 981"
                                                                d="M160.375,112.181a.722.722,0,0,1-.722.722h-2.889a.722.722,0,0,1-.722-.722h0a.722.722,0,0,1,.722-.722h2.889a.722.722,0,0,1,.722.722Z"
                                                                transform="translate(-152.43 -107.847)"
                                                                fill="#b98269" />
                                                        </svg>





                                                    </div> Question Papers
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center" routerLink="../admin/obe/report"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menupurple">



                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.703"
                                                            height="20.703" viewBox="0 0 20.703 20.703">
                                                            <g id="_3802014_account_manage_personal_profile_submit_icon"
                                                                data-name="3802014_account_manage_personal_profile_submit_icon"
                                                                transform="translate(-1 -1)">
                                                                <path id="Path_971" data-name="Path 971"
                                                                    d="M20.7,1.668H17.634A1,1,0,0,0,16.694,1H12.019a1,1,0,0,0-.94.668H8.012a1,1,0,0,0-1,1V7.011H5.873a2.34,2.34,0,0,0-1.16.308l-1.8,1.027H1v6.011H3.236l1.414.943a2.329,2.329,0,0,0,1.3.393H7.011V20.7a1,1,0,0,0,1,1H20.7a1,1,0,0,0,1-1V2.67A1,1,0,0,0,20.7,1.668ZM9.014,9.682h.668a1.336,1.336,0,1,0,0-2.671H9.014V3.671h2.065a1,1,0,0,0,.94.668h4.675a1,1,0,0,0,.94-.668H19.7V19.7H9.014ZM11.685,2a.334.334,0,0,1,.334-.334h4.675A.334.334,0,0,1,17.028,2V3.337a.334.334,0,0,1-.334.334H12.019a.334.334,0,0,1-.334-.334ZM7.678,2.67a.334.334,0,0,1,.334-.334h3.005V3H8.346V7.011H7.678ZM5.947,15.025a1.662,1.662,0,0,1-.926-.28L3.439,13.689H1.668V9.014H3.092L5.045,7.9a1.672,1.672,0,0,1,.828-.22H9.682a.668.668,0,1,1,0,1.336H5.675v.334a1.671,1.671,0,0,1-1.67,1.67v.668a2.341,2.341,0,0,0,2.314-2h.692v5.343ZM21.035,20.7a.334.334,0,0,1-.334.334H8.012a.334.334,0,0,1-.334-.334V9.682h.668V20.367H20.367V3H17.7V2.336H20.7a.334.334,0,0,1,.334.334Z"
                                                                    transform="translate(0 0)" fill="#8686e2" />
                                                                <rect id="Rectangle_203" data-name="Rectangle 203"
                                                                    transform="translate(12.351 2.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_204" data-name="Rectangle 204"
                                                                    transform="translate(15.351 2.351)"
                                                                    fill="#8686e2" />
                                                                <path id="Path_972" data-name="Path 972"
                                                                    d="M36.135,36.412l-1.127-.322v-.169a2.334,2.334,0,0,0,1-1.916v-.668a2.337,2.337,0,0,0-4.675,0v.668a2.335,2.335,0,0,0,1,1.915v.17l-1.127.322A1.677,1.677,0,0,0,30,38.018v1h7.346v-1A1.677,1.677,0,0,0,36.135,36.412ZM32,34.005v-.668a1.67,1.67,0,0,1,3.339,0v.668a1.67,1.67,0,0,1-3.339,0Zm2.326,2.242-.656.875-.656-.875a2.3,2.3,0,0,0,1.312,0Zm2.349,2.1H30.668v-.328a1.006,1.006,0,0,1,.727-.963l1.148-.328,1.131,1.508L34.8,36.727l1.148.328a1.006,1.006,0,0,1,.727.963Z"
                                                                    transform="translate(-19.316 -19.982)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_205" data-name="Rectangle 205"
                                                                    transform="translate(10.351 4.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_206" data-name="Rectangle 206"
                                                                    width="8" transform="translate(11.351 4.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_207" data-name="Rectangle 207"
                                                                    width="8" transform="translate(11.351 7.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_208" data-name="Rectangle 208"
                                                                    width="8" height="1"
                                                                    transform="translate(11.351 8.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_209" data-name="Rectangle 209"
                                                                    height="1" transform="translate(19.351 9.351)"
                                                                    fill="#8686e2" />
                                                                <rect id="Rectangle_210" data-name="Rectangle 210"
                                                                    width="7" height="1"
                                                                    transform="translate(11.351 9.351)"
                                                                    fill="#8686e2" />
                                                            </g>
                                                        </svg>





                                                    </div> PO/CO
                                                    Report
                                                </a>
                                                <div class="arrowiconsonhover five">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul> -->

                                    <!-- OBE Commeneted after client  -->
                                    <!-- </div> -->

                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Branchs')">
                                        <!-- <i class="icon"><svg width="16" height="16" class="icon">
                            <use xlink:href="#branchmm-icon"></use>
                        </svg></i> -->
                                        <h4 *ngIf="checkSubModulePermission('Branchs')">Branch</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Branch')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/manage-branch" routerLinkActive="active"
                                                    (click)="clickEvent1()">

                                                    <div class="site-nav-dropdown-icon-container menugreen">

                                                        <svg id="Group_440" data-name="Group 440"
                                                            xmlns="http://www.w3.org/2000/svg" width="14.871"
                                                            height="16.929" viewBox="0 0 14.871 16.929">
                                                            <path id="Path_969" data-name="Path 969"
                                                                d="M28.488,20.765h-11.8A1.539,1.539,0,0,0,15.153,22.3V37.694h5.4V34.315h4v3.379h5.47V22.3A1.537,1.537,0,0,0,28.488,20.765ZM19.813,32.432H17.638V23.176h2.175Zm3.864,0H21.5V23.176h2.177Zm3.862,0H25.363V23.176h2.175Z"
                                                                transform="translate(-15.153 -20.765)" fill="#74c4ba" />
                                                        </svg>


                                                    </div>


                                                    Manage Branches
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Add New Branch')"><a
                                                    class="d-flex align-center"
                                                    (click)="branchDialogPopup(OtpPopupBranch)">

                                                    <div class="site-nav-dropdown-icon-container menublue">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.871"
                                                            height="16.929" viewBox="0 0 14.871 16.929">
                                                            <g id="Production" transform="translate(-15.153 -20.765)">
                                                                <g id="Group_440" data-name="Group 440"
                                                                    transform="translate(15.153 20.765)">
                                                                    <path id="Path_969" data-name="Path 969"
                                                                        d="M28.488,20.765h-11.8A1.539,1.539,0,0,0,15.153,22.3V37.694h5.4V34.315h4v3.379h5.47V22.3A1.537,1.537,0,0,0,28.488,20.765ZM19.813,32.432H17.638V23.176h2.175Zm3.864,0H21.5V23.176h2.177Zm3.862,0H25.363V23.176h2.175Z"
                                                                        transform="translate(-15.153 -20.765)"
                                                                        fill="#7094db" />
                                                                </g>
                                                            </g>
                                                        </svg>



                                                    </div>
                                                    Add New Branch
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Roles & Permissions')">
                                        <!-- <i class="icon"><svg width="16.809" height="13.788" class="icon">
                            <use xlink:href="#admin-icon"></use>
                        </svg></i> -->
                                        <h4>Roles & Permissions</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Roles')"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-roles"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.814"
                                                            height="21.626" viewBox="0 0 21.814 21.626">
                                                            <g id="_1316635_beer_beverage_bottle_seal_seal_star_icon"
                                                                data-name="1316635_beer_beverage_bottle seal_seal_star_icon"
                                                                transform="translate(-15.636 -14.3)">
                                                                <g id="Group_444" data-name="Group 444">
                                                                    <path id="Path_967" data-name="Path 967"
                                                                        d="M36.6,25.185h0a1.658,1.658,0,0,1-.375-2.606h0a1.658,1.658,0,0,0-1.285-2.814h0a1.658,1.658,0,0,1-1.724-1.989h0a1.658,1.658,0,0,0-2.6-1.673h0a1.658,1.658,0,0,1-2.526-.742h0a1.658,1.658,0,0,0-3.094,0h0a1.658,1.658,0,0,1-2.526.742h0a1.658,1.658,0,0,0-2.6,1.673h0a1.658,1.658,0,0,1-1.724,1.989h0a1.658,1.658,0,0,0-1.285,2.814h0a1.658,1.658,0,0,1-.375,2.606h0a1.658,1.658,0,0,0,.44,3.062h0a1.658,1.658,0,0,1,1.094,2.394h0a1.658,1.658,0,0,0,2.026,2.338h0A1.658,1.658,0,0,1,22.258,34.4h0a1.658,1.658,0,0,0,2.969.872h0a1.658,1.658,0,0,1,2.632,0h0a1.658,1.658,0,0,0,2.969-.872h0a1.658,1.658,0,0,1,2.214-1.423h0a1.658,1.658,0,0,0,2.026-2.338h0a1.658,1.658,0,0,1,1.094-2.394h0A1.658,1.658,0,0,0,36.6,25.185ZM26.543,32.312a7.2,7.2,0,1,1,7.2-7.2A7.2,7.2,0,0,1,26.543,32.312Z"
                                                                        fill="#e95a6b" />
                                                                    <path id="Path_968" data-name="Path 968"
                                                                        d="M169.439,154.69h-3a.728.728,0,0,1-.679-.509l-.926-2.857a.714.714,0,0,0-1.357,0l-.926,2.863a.726.726,0,0,1-.679.507h-3a.7.7,0,0,0-.42,1.275l2.426,1.757a.708.708,0,0,1,.259.793l-.926,2.852a.713.713,0,0,0,1.1.8l2.424-1.762a.714.714,0,0,1,.839,0l2.424,1.76a.714.714,0,0,0,1.1-.8l-.926-2.852a.714.714,0,0,1,.259-.8l2.427-1.75A.7.7,0,0,0,169.439,154.69Z"
                                                                        transform="translate(-137.616 -131.822)"
                                                                        fill="#e95a6b" />
                                                                </g>
                                                            </g>
                                                        </svg>





                                                    </div>
                                                    Manage Roles
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="megaMenuCol">

                                        <h4>LMS</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli">
                                                <a class="d-flex align-center" href="javascript:void(0)">

                                                    <div class="site-nav-dropdown-icon-container menuyellow">

                                                        <svg id="Group_441" data-name="Group 441"
                                                            xmlns="http://www.w3.org/2000/svg" width="18.942"
                                                            height="19.591" viewBox="0 0 18.942 19.591">
                                                            <path id="Path_970" data-name="Path 970"
                                                                d="M18.612,14.949H12.222c-.009-2.4.365-4.686,2.4-5.934a4.276,4.276,0,0,1,4.43-.029l-.824.824a.362.362,0,0,0,.256.618h2.963a.362.362,0,0,0,.362-.362V7.1a.362.362,0,0,0-.618-.256l-.885.885a5.951,5.951,0,0,0-6.594-.2c-2.605,1.6-3.236,4.333-3.226,7.415H8.176a.7.7,0,0,0-.7.7v7.333a.7.7,0,0,0,.7.7h3.713v.956h-.842a1.4,1.4,0,0,0-1.4,1.4.178.178,0,0,0,.179.178h7.142a.179.179,0,0,0,.177-.2,1.4,1.4,0,0,0-1.4-1.382H14.9v-.956h3.713a.7.7,0,0,0,.7-.7V15.652A.7.7,0,0,0,18.612,14.949Zm-.863,7.172H9.039V16.5h8.709Zm8-10.736h-9.96a.671.671,0,0,0-.67.67v2.025h3.5a1.573,1.573,0,0,1,1.571,1.571v6.495h3.99a.17.17,0,0,0,.17-.17.164.164,0,0,0,0-.02A1.34,1.34,0,0,0,23,20.638h-.8v-.912h3.544a.671.671,0,0,0,.67-.67v-7A.671.671,0,0,0,25.745,11.385Z"
                                                                transform="translate(-7.474 -6.633)" fill="#b98269" />
                                                        </svg>






                                                    </div>

                                                    Learning Management System
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>

                                            </li>
                                        </ul>
                                    </div> -->
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Asessment & Gradings')">
                                        <!-- <i class="icon"><svg width="16" height="16" class="icon">
                            <use xlink:href="#gradingmm-icon"></use>
                        </svg></i> -->
                                        <h4>Assessment &amp; Gradings</h4>
                                        <ul class="subLinkMM">
                                            <!-- <li><a routerLink="academicslearning/manage-grade">Manage Grade</a></li>
                                        <li><a routerLink="academicslearning/report-cards">Report Cards of students</a></li>
                                        <li><a routerLink="academicslearning/manage-assessment">Manage Assessment</a></li>
                                        <li><a href="javascript:void(0)">Report Card Format</a></li>
                                        <li><a href="javascript:void(0)">Weighted Subjects &amp; Category Settings</a></li> -->
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="academicslearning/manage-grade"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menupurple">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.656"
                                                            height="19" viewBox="0 0 19.656 19">
                                                            <g id="_728614_a__exam_grade_mark_test_paper_icon"
                                                                data-name="728614_a+_exam_grade_mark_test paper_icon"
                                                                transform="translate(-16.001 -16.002)">
                                                                <path id="Path_965" data-name="Path 965"
                                                                    d="M22.016,20.773c-.627.36-.773.495-.773.942,0,.3.175,2.52.356,4.74l-.7.367a.59.59,0,0,0-.246.795l.183.353a.587.587,0,0,0,.792.246l.106-.056c.122,1.51.223,2.76.235,3.021.061.616.571.529.58.529a3.476,3.476,0,0,0,.736-.327c.436-.251.646-.385.543-1.088-.074-.5-.467-2.864-.467-2.864l1.852-.988a14.463,14.463,0,0,0,1.624,1.676,1.683,1.683,0,0,0,1.826.438,3.037,3.037,0,0,0,.692-.516,4,4,0,0,0,.651-.754.609.609,0,0,0-.7-.89c-.344.219-.791.536-1.3.058a56.629,56.629,0,0,1-4.854-5.673A.91.91,0,0,0,22.016,20.773Zm1.15,4.979s-.233-2.022-.248-2.227l1.318,1.665Zm12.089,1.79c-1.113,3.852-4.319,6.513-8.8,7.3A10.033,10.033,0,0,1,24.72,35c-4.549,0-7.45-3.383-8.371-7.086-1.147-4.612.484-10.173,5.82-11.874a.817.817,0,1,1,.5,1.556c-4.378,1.4-5.692,6.05-4.727,9.926.787,3.166,3.418,6.563,8.229,5.712,5.252-.926,6.957-4.248,7.505-6.146a7.834,7.834,0,0,0-1.856-7.8c-1.115-1.005-3.088-1.948-6.138-.736a.818.818,0,1,1-.609-1.518c3-1.189,5.783-.819,7.849,1.043A9.384,9.384,0,0,1,35.255,27.542Zm-8.47-5.666,1.03-.576-.578-1.031a.587.587,0,0,1,.225-.8l.209-.118a.589.589,0,0,1,.8.225l.577,1.03,1.03-.578a.587.587,0,0,1,.8.225l.119.209a.59.59,0,0,1-.225.8l-1.03.577.577,1.031a.586.586,0,0,1-.225.8l-.209.118a.59.59,0,0,1-.8-.225l-.576-1.03-1.031.578a.587.587,0,0,1-.8-.225l-.118-.209A.589.589,0,0,1,26.785,21.876Z"
                                                                    fill="#7094db" />
                                                            </g>
                                                        </svg>




                                                    </div>
                                                    Manage Assessment
                                                    &amp; Gradings
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </li>
                        <li class="dropdown-megamenu"><a (mouseleave)="hideOtherMenu()" routerLinkActive="active"
                                *ngIf="checkModulePermission('Communication')"><span>Communication</span></a>
                            <div class="megaMenu">
                                <div class="megaMenuRow">
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Messaging')">
                                        <!-- <i class="icon"><svg width="15" height="10.547" class="icon">
                                <use xlink:href="#message-icon"></use>
                            </svg></i> -->
                                        <h4>Messaging</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('SMS')"><a class="d-flex align-center"
                                                    routerLink="communication/sms//usage-statistics"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menugreen">



                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19"
                                                            height="16.625" viewBox="0 0 19 16.625">
                                                            <path id="_3994387_bubble_chat_message_sms_text_icon"
                                                                data-name="3994387_bubble_chat_message_sms_text_icon"
                                                                d="M9.5,6C4.262,6,0,9.2,0,13.125c0,2.594,1.8,4.9,4.75,6.16v2.152A1.187,1.187,0,0,0,6.477,22.5l4.655-2.372c4.572-.587,7.868-3.5,7.868-7C19,9.2,14.738,6,9.5,6Zm1.149,11.789a1.2,1.2,0,0,0-.408.123L7.125,19.5V18.469a1.187,1.187,0,0,0-.8-1.124c-2.4-.818-3.945-2.474-3.945-4.22,0-2.574,3.263-4.75,7.125-4.75s7.125,2.176,7.125,4.75C16.625,15.406,14.056,17.411,10.649,17.789Z"
                                                                transform="translate(0 -6)" fill="#74c4ba" />
                                                        </svg>






                                                    </div>
                                                    SMS
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Email')"><a class="d-flex align-center"
                                                    routerLink="communication/email/email-logs">
                                                    <div class="site-nav-dropdown-icon-container menublue">



                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.071"
                                                            height="16" viewBox="0 0 22.071 16">
                                                            <g id="_8-Email" data-name="8-Email"
                                                                transform="translate(0.5 -6.5)">
                                                                <path id="Path_982" data-name="Path 982"
                                                                    d="M19.754,7H1.317A1.32,1.32,0,0,0,0,8.324V20.676A1.32,1.32,0,0,0,1.317,22H19.754a1.32,1.32,0,0,0,1.317-1.324V8.324A1.32,1.32,0,0,0,19.754,7Zm-.281.882-8.938,6.944L1.6,7.882ZM.878,20.5V8.438l6.765,5.254Zm.619.622L8.341,14.24l1.923,1.5a.437.437,0,0,0,.536,0l1.931-1.5,6.844,6.878Zm18.7-.622-6.765-6.8,6.765-5.254Z"
                                                                    stroke="#7094db" stroke-width="1" />
                                                            </g>
                                                        </svg>






                                                    </div>
                                                    Email
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="megaMenuCol">
                                        <i class="icon"><svg width="15.981" height="15.981" class="icon">
                                                <use xlink:href="#shift-icon"></use>
                                            </svg></i>
                                        <h4>Shift Management</h4>
                                        <ul class="subLinkMM">
    
                                            <ul class="subLinkMM">
                                                <li><a routerLink="communication/manage-shift-category" routerLinkActive="active">Manage Shift Category</a></li>
                                                <li><a routerLink="communication/manage-shift"
                                                        routerLinkActive="active">Manage Shift</a></li>
    
                                            </ul>
                                        </ul>
                                    </div> -->
                                    <div class="megaMenuCol"
                                        *ngIf="checkSubModulePermission('Time Table Management and Log')">
                                        <!-- <i class="icon"><svg width="15" height="15.059" class="icon">
                                <use xlink:href="#timetable-icon"></use>
                            </svg></i> -->
                                        <h4>Time Table Management</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Time Table')"><a
                                                    class="d-flex align-center"
                                                    routerLink="communication/manage-timetable"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menured">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.892"
                                                            height="19.085" viewBox="0 0 17.892 19.085">
                                                            <g id="_728916_calendar_date_appointment_event_plan_icon"
                                                                data-name="728916_calendar_date_appointment_event_plan_icon"
                                                                transform="translate(-4)">
                                                                <g id="Group_456" data-name="Group 456"
                                                                    transform="translate(4)">
                                                                    <g id="Group_455" data-name="Group 455"
                                                                        transform="translate(0)">
                                                                        <path id="Path_983" data-name="Path 983"
                                                                            d="M19.507,1.193H18.314V.6a.6.6,0,1,0-1.193,0v.6H15.928V.6a.6.6,0,1,0-1.193,0v.6H13.543V.6A.6.6,0,1,0,12.35.6v.6H11.157V.6A.6.6,0,0,0,9.964.6v.6H8.771V.6A.6.6,0,1,0,7.578.6v.6H6.386A2.386,2.386,0,0,0,4,3.578V16.7a2.386,2.386,0,0,0,2.386,2.386H19.507A2.386,2.386,0,0,0,21.892,16.7V3.578A2.386,2.386,0,0,0,19.507,1.193ZM20.7,16.7a1.194,1.194,0,0,1-1.193,1.193H6.386A1.194,1.194,0,0,1,5.193,16.7V3.578A1.194,1.194,0,0,1,6.386,2.386H7.578v.6a.6.6,0,1,0,1.193,0v-.6H9.964v.6a.6.6,0,1,0,1.193,0v-.6H12.35v.6a.6.6,0,1,0,1.193,0v-.6h1.193v.6a.6.6,0,1,0,1.193,0v-.6h1.193v.6a.6.6,0,1,0,1.193,0v-.6h1.193A1.194,1.194,0,0,1,20.7,3.578Z"
                                                                            transform="translate(-4)" fill="#e95a6b" />
                                                                    </g>
                                                                </g>
                                                                <path id="Path_984" data-name="Path 984"
                                                                    d="M20,40V50.735H33.121V40Zm9.543,1.193v1.193H28.35V41.193Zm-4.771,3.578H23.578V43.578h1.193Zm1.193-1.193h1.193v1.193H25.964Zm-1.193,2.386v1.193H23.578V45.964Zm1.193,0h1.193v1.193H25.964Zm2.386,0h1.193v1.193H28.35Zm0-1.193V43.578h1.193v1.193Zm-1.193-3.578v1.193H25.964V41.193Zm-2.386,0v1.193H23.578V41.193Zm-3.578,0h1.193v1.193H21.193Zm0,2.386h1.193v1.193H21.193Zm0,2.386h1.193v1.193H21.193Zm0,3.578V48.35h1.193v1.193Zm2.386,0V48.35h1.193v1.193Zm2.386,0V48.35h1.193v1.193Zm2.386,0V48.35h1.193v1.193Zm3.578,0H30.735V48.35h1.193Zm0-2.386H30.735V45.964h1.193Zm0-2.386H30.735V43.578h1.193Zm0-2.386H30.735V41.193h1.193Z"
                                                                    transform="translate(-13.614 -34.036)"
                                                                    fill="#e95a6b" />
                                                            </g>
                                                        </svg>






                                                    </div>
                                                    Manage Time
                                                    Table
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <!-- <li><a routerLink="communication/view-timetable" routerLinkActive="active">View
                                                    Calender</a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="dropdown-megamenu"><a *ngIf="checkModulePermission('Administration')"
                                (mouseenter)="clickEvent2()" (mouseleave)="clickEvent1()"
                                routerLinkActive="active"><span>Administration</span></a>
                            <div class="megaMenu" [ngClass]="headermenu3 ? 'show' : 'none'">
                                <div class="megaMenuRow">
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Reports')">
                                        <!-- <i class="icon"><svg width="12.226" height="16.051" class="icon">
                                <use xlink:href="#reports-icon"></use>
                            </svg></i> -->
                                        <h4>Reports</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Academics Reports')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/academics-report">
                                                    <div class="site-nav-dropdown-icon-container menugreen">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.138"
                                                            height="19.412" viewBox="0 0 16.138 19.412">
                                                            <g id="g2129" transform="translate(1015.992 1216.326)">
                                                                <path id="path1455"
                                                                    d="M-957.652-1213.513h-3.478v-1.812h3.478Z"
                                                                    transform="translate(-48.531 0)" fill="none"
                                                                    stroke="#74c4ba" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-miterlimit="6.2"
                                                                    stroke-width="2" />
                                                                <g id="g1632"
                                                                    transform="translate(-1014.992 -1213.513)">
                                                                    <path id="path1451"
                                                                        d="M-208.866-164.139H-223v-15.6h14.138Z"
                                                                        transform="translate(223.004 179.738)"
                                                                        fill="none" stroke="#74c4ba"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        stroke-miterlimit="6.2" stroke-width="2" />
                                                                    <path id="path1457"
                                                                        d="M-187.675-173.344h-8.91v-.682h8.91Z"
                                                                        transform="translate(199.199 174.591)"
                                                                        fill="none" stroke="#74c4ba"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        stroke-miterlimit="6.2" stroke-width="2" />
                                                                </g>
                                                                <g id="g1488"
                                                                    transform="translate(-1011.896 -1209.488)">
                                                                    <g id="g1628" transform="translate(0 0)">
                                                                        <path id="path1343-2"
                                                                            d="M-4413.206-247.149h7.882"
                                                                            transform="translate(4413.271 249.982)"
                                                                            fill="none" stroke="#74c4ba"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2" />
                                                                        <path id="path1345-2"
                                                                            d="M-4413.206-275.78h7.882"
                                                                            transform="translate(4413.271 275.78)"
                                                                            fill="none" stroke="#74c4ba"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2" />
                                                                        <path id="path1347-0"
                                                                            d="M-4413.206-218.517h7.882"
                                                                            transform="translate(4413.271 224.183)"
                                                                            fill="none" stroke="#74c4ba"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2" />
                                                                        <path id="path1347-0-7"
                                                                            d="M-4413.87-189.884h7.883"
                                                                            transform="translate(4413.87 198.384)"
                                                                            fill="none" stroke="#74c4ba"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    Academics Reports
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Attendance Reports')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/attendancereport">
                                                    <div class="site-nav-dropdown-icon-container menublue">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.947"
                                                            height="19.774" viewBox="0 0 15.947 19.774">
                                                            <g id="_3709751_complaint_dissatisfaction_expression_feedback_report_icon"
                                                                data-name="3709751_complaint_dissatisfaction_expression_feedback_report_icon"
                                                                transform="translate(-7 -1)">
                                                                <path id="Path_985" data-name="Path 985"
                                                                    d="M21.784,17a4.784,4.784,0,1,0,4.784,4.784A4.79,4.79,0,0,0,21.784,17Zm0,8.93A4.132,4.132,0,0,1,18.8,24.655h5.97A4.132,4.132,0,0,1,21.784,25.93Zm-.477-4.6a.947.947,0,0,1-.479-.823V19.87a.959.959,0,0,1,.311-.705.947.947,0,0,1,.733-.247,1,1,0,0,1,.87,1.019v.572a.947.947,0,0,1-.479.823l-.159.092v.92l1.544.441a.954.954,0,0,1,.689.913v.319h-5.1V23.7a.954.954,0,0,1,.689-.913l1.544-.441v-.92Zm3.667,3.1V23.7a1.594,1.594,0,0,0-1.151-1.526l-1.081-.309v-.085a1.6,1.6,0,0,0,.638-1.269v-.572a1.641,1.641,0,0,0-1.453-1.655,1.595,1.595,0,0,0-1.736,1.588v.638a1.6,1.6,0,0,0,.638,1.269v.085l-1.081.309A1.594,1.594,0,0,0,18.595,23.7v.732a4.146,4.146,0,1,1,6.379,0Z"
                                                                    transform="translate(-6.811 -10.897)"
                                                                    fill="#7094db" />
                                                                <path id="Path_986" data-name="Path 986"
                                                                    d="M21.99,2.914H19.061a.956.956,0,0,0-.9-.638H17.084a2.383,2.383,0,0,0-4.221,0H11.784a.956.956,0,0,0-.9.638H7.957A.958.958,0,0,0,7,3.87V19.818a.958.958,0,0,0,.957.957H21.99a.958.958,0,0,0,.957-.957V3.87A.958.958,0,0,0,21.99,2.914Zm-.957,13.4H18.482v2.552H8.914V4.827h1.914v.638H19.12V4.827h1.914Zm-.451.638L19.12,18.41V16.947ZM11.465,3.233a.319.319,0,0,1,.319-.319h1.473l.155-.311a1.746,1.746,0,0,1,3.122,0l.155.311h1.473a.319.319,0,0,1,.319.319V4.827H11.465ZM22.309,19.818a.319.319,0,0,1-.319.319H7.957a.319.319,0,0,1-.319-.319V3.87a.319.319,0,0,1,.319-.319h2.87v.638H8.276V19.5H18.933l2.738-2.738V4.189H19.12V3.552h2.87a.319.319,0,0,1,.319.319Z"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_223" data-name="Rectangle 223"
                                                                    width="1.087" height="1.087"
                                                                    transform="translate(17.149 16.324)"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_224" data-name="Rectangle 224"
                                                                    width="7.611" height="1.087"
                                                                    transform="translate(9.537 16.324)"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_225" data-name="Rectangle 225"
                                                                    width="7.611" height="1.087"
                                                                    transform="translate(9.537 17.411)"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_226" data-name="Rectangle 226"
                                                                    width="1.087" height="1.087"
                                                                    transform="translate(17.149 17.411)"
                                                                    fill="#7094db" />
                                                                <path id="Path_987" data-name="Path 987"
                                                                    d="M29.957,5a.957.957,0,1,0,.957.957A.958.958,0,0,0,29.957,5Zm0,1.276a.319.319,0,1,1,.319-.319A.319.319,0,0,1,29.957,6.276Z"
                                                                    transform="translate(-14.983 -2.724)"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_227" data-name="Rectangle 227"
                                                                    transform="translate(12.799 4.363)"
                                                                    fill="#7094db" />
                                                                <rect id="Rectangle_228" data-name="Rectangle 228"
                                                                    transform="translate(17.149 4.363)"
                                                                    fill="#7094db" />
                                                            </g>
                                                        </svg>







                                                    </div>
                                                    Attendance Reports
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Fees Reports')"><a
                                                    class="d-flex align-center" routerLink="administration/fees-reports"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.834"
                                                            height="18.96" viewBox="0 0 10.834 18.96">
                                                            <path
                                                                id="_3994353_banking_currency_dollar_finance_money_icon"
                                                                data-name="3994353_banking_currency_dollar_finance_money_icon"
                                                                d="M30.772,14.126H28.063a1.354,1.354,0,0,1,0-2.709H33.48a1.354,1.354,0,1,0,0-2.709H30.772V7.354a1.354,1.354,0,0,0-2.709,0V8.709a4.063,4.063,0,0,0,0,8.126h2.709a1.354,1.354,0,0,1,0,2.709H25.354a1.354,1.354,0,1,0,0,2.709h2.709v1.354a1.354,1.354,0,0,0,2.709,0V22.252a4.063,4.063,0,0,0,0-8.126Z"
                                                                transform="translate(-24 -6)" fill="#e95a6b" />
                                                        </svg>








                                                    </div>
                                                    Fees Reports
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/assesmentreport"
                                                    routerLinkActive="active"
                                                    *ngIf="checkScreenPermission('Assessment Reports')"
                                                    (click)="clickEvent3()">
                                                    <div class="site-nav-dropdown-icon-container menuyellow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.805"
                                                            height="19.311" viewBox="0 0 18.805 19.311">
                                                            <g id="Production" transform="translate(-6.555 -5.87)">
                                                                <g id="Group_436" data-name="Group 436"
                                                                    transform="translate(6.555 5.87)">
                                                                    <path id="Path_954" data-name="Path 954"
                                                                        d="M11.284,16.993a1.037,1.037,0,0,0,1.466,0l4.722-4.723L16.006,10.8l-4.722,4.723A1.037,1.037,0,0,0,11.284,16.993Zm10.4-4.775a2.5,2.5,0,0,0,0-3.528L19.594,6.6a2.5,2.5,0,0,0-3.528,0L14.913,7.751l5.62,5.62ZM25.3,22.345a.693.693,0,0,0-.91-.364,25.653,25.653,0,0,1-4.835,1.477,22.993,22.993,0,0,0,3.325-5.206c.149-.334.272-.679,0-.991A.69.69,0,0,0,21.9,17.2l-.009.008c-2.951,2.716-6.142,4.407-11.8,4.583a.592.592,0,0,0-.57.551c-.033.505.429.61.822.638a16.5,16.5,0,0,0,9.443-2.236l.157-.1c-2.2,3.28-3.113,3.159-3.02,3.925a.69.69,0,0,0,.768.6,28.442,28.442,0,0,0,7.244-1.921A.693.693,0,0,0,25.3,22.345ZM14.082,19.822l5.471-5.471L18.22,13.019,13.5,17.741a1.037,1.037,0,0,0-.185,1.215l-3.654.961-1.3-1.3.969-3.646a1.037,1.037,0,0,0,1.216-.184l4.722-4.722L13.933,8.731,8.462,14.2a.554.554,0,0,0-.144.25L6.574,21.018a.554.554,0,0,0,.677.678l6.58-1.73A.554.554,0,0,0,14.082,19.822Z"
                                                                        transform="translate(-6.555 -5.87)"
                                                                        fill="#b98269" />
                                                                </g>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                    Assesment Reports
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Fees & Invoicing')">
                                        <!-- <i class="icon"><svg width="12.038" height="16.051" class="icon">
                                <use xlink:href="#invoice-icon"></use>
                            </svg></i> -->
                                        <h4>Fees & Invoicing</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Add Fees')"><a class="d-flex align-center"
                                                    routerLink="administration/add-fees" routerLinkActive="active"
                                                    (click)="clickEvent3()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">

                                                        <svg id="Layer_x0020_1" xmlns="http://www.w3.org/2000/svg"
                                                            width="20.678" height="20.678" viewBox="0 0 20.678 20.678">
                                                            <path id="Path_996" data-name="Path 996"
                                                                d="M10.339,8.136a.753.753,0,0,1,.294.06.763.763,0,0,1,.4.4.75.75,0,0,1,.06.294.689.689,0,1,0,1.378,0,2.1,2.1,0,0,0-.409-1.248,2.162,2.162,0,0,0-1.038-.763V6.491a.689.689,0,1,0-1.378,0v.391a2.158,2.158,0,0,0-1.037.763,2.122,2.122,0,0,0-.24,2.079,2.134,2.134,0,0,0,1.967,1.3.751.751,0,0,1,.294.06.764.764,0,0,1,.4.4.756.756,0,0,1,0,.589.763.763,0,0,1-.4.4.755.755,0,0,1-.829-.162.755.755,0,0,1-.222-.535.689.689,0,1,0-1.378,0,2.1,2.1,0,0,0,.408,1.248A2.158,2.158,0,0,0,9.65,13.8v.391a.689.689,0,1,0,1.378,0V13.8a2.163,2.163,0,0,0,1.038-.763,2.118,2.118,0,0,0,.241-2.078A2.136,2.136,0,0,0,10.339,9.65a.751.751,0,0,1-.294-.06.757.757,0,0,1-.462-.7.752.752,0,0,1,.06-.294.762.762,0,0,1,.4-.4A.752.752,0,0,1,10.339,8.136Zm5.775,6.03a.688.688,0,0,0,.931-.287,7.456,7.456,0,0,0,.379-.837A7.568,7.568,0,1,0,13,17.438a7.433,7.433,0,0,0,.826-.368.682.682,0,0,0,.213-.171.69.69,0,0,0-.85-1.051,6.163,6.163,0,0,1-.678.3,6.046,6.046,0,0,1-.7.217,6.107,6.107,0,0,1-.727.131,6.345,6.345,0,0,1-.747.044,6.2,6.2,0,1,1,6.158-5.45,6.239,6.239,0,0,1-.361,1.458,6.115,6.115,0,0,1-.309.683.688.688,0,0,0,.287.932ZM10.339,0A10.345,10.345,0,0,0,7.414.421a10.294,10.294,0,0,0-2.68,1.23.689.689,0,0,0,.493,1.257.7.7,0,0,0,.255-.1A8.914,8.914,0,0,1,9.054,1.471a9.014,9.014,0,0,1,1.286-.092,8.96,8.96,0,1,1-8.961,8.96,8.971,8.971,0,0,1,.358-2.512A8.895,8.895,0,0,1,2.784,5.521a.689.689,0,1,0-1.161-.743A10.34,10.34,0,1,0,10.339,0Z"
                                                                fill="#74c4ba" />
                                                        </svg>



                                                    </div> Add Fees
                                                    <!-- &amp; invoices -->
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <!-- <li><a routerLink="administration/recurring-subcriptions"
                                                    routerLinkActive="active">Recurring Subcriptions</a></li> -->
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('List of Fees & Invoicing')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/fees-and-invoicing"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menublue">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.298"
                                                            height="12.865" viewBox="0 0 17.298 12.865">
                                                            <g id="_3325153_list_icon" data-name="3325153_list_icon"
                                                                transform="translate(0 1)">
                                                                <line id="Line_14" data-name="Line 14" x2="10.865"
                                                                    transform="translate(5.433 0)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <line id="Line_15" data-name="Line 15" x2="10.865"
                                                                    transform="translate(5.433 5.433)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <line id="Line_16" data-name="Line 16" x2="10.865"
                                                                    transform="translate(5.433 10.865)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <line id="Line_17" data-name="Line 17"
                                                                    transform="translate(0 0)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <line id="Line_18" data-name="Line 18"
                                                                    transform="translate(0 5.433)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <line id="Line_19" data-name="Line 19"
                                                                    transform="translate(0 10.865)" fill="none"
                                                                    stroke="#7094db" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                            </g>
                                                        </svg>

                                                    </div> Listing of
                                                    Fees &amp; Invoicing
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Invoices')"><a class="d-flex align-center"
                                                    routerLink="administration/invoices" routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.793"
                                                            height="13.781" viewBox="0 0 18.793 13.781">
                                                            <g id="Layer_x0020_1" transform="translate(0 0)">
                                                                <path id="Path_995" data-name="Path 995"
                                                                    d="M32.377,48.17H19.213a.626.626,0,0,0,0,1.252H32.377a1.6,1.6,0,0,1,1.593,1.595v8.089A1.6,1.6,0,0,1,32.377,60.7h-13.1a1.6,1.6,0,0,1-1.593-1.593V50.954a.626.626,0,1,0-1.253,0v8.151a2.848,2.848,0,0,0,2.846,2.846h13.1a2.848,2.848,0,0,0,2.846-2.846V51.017a2.848,2.848,0,0,0-2.846-2.847Zm-2.1,8.893a.688.688,0,0,1-.688-.687.626.626,0,1,0-1.253,0A1.938,1.938,0,0,0,29.647,58.2v.354a.626.626,0,1,0,1.253,0V58.2a1.936,1.936,0,0,0-.626-3.77.688.688,0,1,1,.688-.688.626.626,0,1,0,1.253,0A1.937,1.937,0,0,0,30.9,51.918v-.356a.626.626,0,1,0-1.253,0v.356a1.935,1.935,0,0,0,.627,3.769.688.688,0,0,1,0,1.377ZM19.562,58.82h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Zm0-6.265h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Zm0,3.132h6.265a.626.626,0,0,0,0-1.253H19.562a.626.626,0,0,0,0,1.253Z"
                                                                    transform="translate(-16.43 -48.17)"
                                                                    fill="#e95a6b" />
                                                            </g>
                                                        </svg>

                                                    </div> Invoices
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Payment Transactions')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/payment-transaction"
                                                    routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menuyellow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.832"
                                                            height="16.027" viewBox="0 0 17.832 16.027">
                                                            <path id="_290143_cash_money_payment_wallet_icon"
                                                                data-name="290143_cash_money_payment_wallet_icon"
                                                                d="M17.832,17.177h0A1.829,1.829,0,0,1,16,19.007H2.83A1.829,1.829,0,0,1,1,17.177V6.565A1.464,1.464,0,0,1,2.464,5.1H9.845l2.964-1.074a.362.362,0,0,1,.464.216l.312.858h1.321a1.464,1.464,0,0,1,1.464,1.464V7.3h0a1.464,1.464,0,0,1,1.464,1.464ZM2.464,5.833h0a.732.732,0,1,0,0,1.464h1.33s.008-.006.012-.008L7.824,5.833ZM13.1,5.9l-.389-1.07h0l-.751.272h0l-2.018.732h0L5.9,7.3h7.707Zm2.533.666a.732.732,0,0,0-.732-.732H13.85L14.382,7.3h1.255Zm.732,1.464H2.464a1.447,1.447,0,0,1-.732-.2v9.351a1.1,1.1,0,0,0,1.1,1.1H16a1.1,1.1,0,0,0,1.1-1.1V14.615H15.637a1.464,1.464,0,1,1,0-2.927H17.1V8.761A.732.732,0,0,0,16.369,8.029Zm.732,5.855V12.42H15.637a.732.732,0,1,0,0,1.464Zm-1.464-1.1h.732v.732h-.732Z"
                                                                transform="translate(-0.5 -3.479)" fill="#b98269"
                                                                stroke="#b98269" stroke-width="1" fill-rule="evenodd" />
                                                        </svg>

                                                    </div>
                                                    Payment
                                                    Transaction
                                                </a>
                                                <div class="arrowiconsonhover four">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Settings')"><a class="d-flex align-center"
                                                    routerLink="administration/settings" routerLinkActive="active">
                                                    <div class="site-nav-dropdown-icon-container menupurple">
                                                        <!-- <svg id="Layer_x0020_1" xmlns="http://www.w3.org/2000/svg" width="20.678" height="20.678" viewBox="0 0 20.678 20.678">
                                        <path id="Path_996" data-name="Path 996" d="M10.339,8.136a.753.753,0,0,1,.294.06.763.763,0,0,1,.4.4.75.75,0,0,1,.06.294.689.689,0,1,0,1.378,0,2.1,2.1,0,0,0-.409-1.248,2.162,2.162,0,0,0-1.038-.763V6.491a.689.689,0,1,0-1.378,0v.391a2.158,2.158,0,0,0-1.037.763,2.122,2.122,0,0,0-.24,2.079,2.134,2.134,0,0,0,1.967,1.3.751.751,0,0,1,.294.06.764.764,0,0,1,.4.4.756.756,0,0,1,0,.589.763.763,0,0,1-.4.4.755.755,0,0,1-.829-.162.755.755,0,0,1-.222-.535.689.689,0,1,0-1.378,0,2.1,2.1,0,0,0,.408,1.248A2.158,2.158,0,0,0,9.65,13.8v.391a.689.689,0,1,0,1.378,0V13.8a2.163,2.163,0,0,0,1.038-.763,2.118,2.118,0,0,0,.241-2.078A2.136,2.136,0,0,0,10.339,9.65a.751.751,0,0,1-.294-.06.757.757,0,0,1-.462-.7.752.752,0,0,1,.06-.294.762.762,0,0,1,.4-.4A.752.752,0,0,1,10.339,8.136Zm5.775,6.03a.688.688,0,0,0,.931-.287,7.456,7.456,0,0,0,.379-.837A7.568,7.568,0,1,0,13,17.438a7.433,7.433,0,0,0,.826-.368.682.682,0,0,0,.213-.171.69.69,0,0,0-.85-1.051,6.163,6.163,0,0,1-.678.3,6.046,6.046,0,0,1-.7.217,6.107,6.107,0,0,1-.727.131,6.345,6.345,0,0,1-.747.044,6.2,6.2,0,1,1,6.158-5.45,6.239,6.239,0,0,1-.361,1.458,6.115,6.115,0,0,1-.309.683.688.688,0,0,0,.287.932ZM10.339,0A10.345,10.345,0,0,0,7.414.421a10.294,10.294,0,0,0-2.68,1.23.689.689,0,0,0,.493,1.257.7.7,0,0,0,.255-.1A8.914,8.914,0,0,1,9.054,1.471a9.014,9.014,0,0,1,1.286-.092,8.96,8.96,0,1,1-8.961,8.96,8.971,8.971,0,0,1,.358-2.512A8.895,8.895,0,0,1,2.784,5.521a.689.689,0,1,0-1.161-.743A10.34,10.34,0,1,0,10.339,0Z" fill="#b98269"/>
                                      </svg> -->


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.035"
                                                            height="18.037" viewBox="0 0 18.035 18.037">
                                                            <g id="_1" data-name="1" transform="translate(0 0)">
                                                                <path id="Path_993" data-name="Path 993"
                                                                    d="M73.083,66.037H70.374a.673.673,0,0,1-.669-.6l-.2-1.888a6.856,6.856,0,0,1-.819-.339L67.21,64.4a.673.673,0,0,1-.9-.048L64.4,62.438a.673.673,0,0,1-.048-.9l1.194-1.478a6.82,6.82,0,0,1-.34-.819l-1.892-.2a.673.673,0,0,1-.6-.669V55.664a.673.673,0,0,1,.6-.669l1.889-.2a6.826,6.826,0,0,1,.34-.819L64.346,52.5a.673.673,0,0,1,.048-.9l1.915-1.915a.673.673,0,0,1,.9-.048l1.478,1.193a6.855,6.855,0,0,1,.819-.339l.2-1.888a.673.673,0,0,1,.671-.6h2.709a.673.673,0,0,1,.669.6l.2,1.888a6.863,6.863,0,0,1,.819.339l1.477-1.193a.673.673,0,0,1,.9.048L79.062,51.6a.673.673,0,0,1,.048.9l-1.194,1.478a6.9,6.9,0,0,1,.34.819l1.889.2a.673.673,0,0,1,.6.669v2.709a.673.673,0,0,1-.6.67l-1.888.2a6.907,6.907,0,0,1-.34.819l1.194,1.478a.673.673,0,0,1-.048.9l-1.915,1.917a.673.673,0,0,1-.9.048l-1.477-1.193a6.863,6.863,0,0,1-.819.339l-.2,1.888A.673.673,0,0,1,73.083,66.037Zm-2.1-1.346h1.5l.184-1.73a.673.673,0,0,1,.5-.579,5.52,5.52,0,0,0,1.353-.562.673.673,0,0,1,.763.059l1.353,1.093,1.059-1.059-1.1-1.349a.673.673,0,0,1-.059-.763,5.5,5.5,0,0,0,.56-1.352.673.673,0,0,1,.58-.5l1.73-.184v-1.5l-1.73-.184a.673.673,0,0,1-.58-.5,5.5,5.5,0,0,0-.56-1.353.673.673,0,0,1,.059-.763l1.093-1.353-1.059-1.059-1.353,1.093a.673.673,0,0,1-.763.059,5.519,5.519,0,0,0-1.352-.56.673.673,0,0,1-.5-.579l-.182-1.728h-1.5l-.183,1.73a.673.673,0,0,1-.5.579,5.509,5.509,0,0,0-1.351.562.673.673,0,0,1-.763-.059l-1.352-1.1-1.059,1.059,1.094,1.353a.673.673,0,0,1,.058.763,5.537,5.537,0,0,0-.56,1.352.673.673,0,0,1-.579.5l-1.73.184v1.5l1.73.184a.673.673,0,0,1,.579.5,5.538,5.538,0,0,0,.56,1.351.673.673,0,0,1-.058.763l-1.093,1.351,1.059,1.059,1.354-1.093a.673.673,0,0,1,.763-.059,5.509,5.509,0,0,0,1.352.56.673.673,0,0,1,.5.579Zm9.1-6.318Z"
                                                                    transform="translate(-62.71 -48)" fill="#8686e2" />
                                                                <path id="Path_994" data-name="Path 994"
                                                                    d="M176.459,165.838a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,176.459,165.838Zm0-6.852a2.753,2.753,0,1,0,2.754,2.753A2.753,2.753,0,0,0,176.459,158.986Z"
                                                                    transform="translate(-167.44 -152.721)"
                                                                    fill="#8686e2" />
                                                            </g>
                                                        </svg>










                                                    </div>
                                                    Settings
                                                </a>
                                                <div class="arrowiconsonhover five">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="megaMenuCol" *ngIf="checkSubModulePermission('Finance & Accounting')">
                                        <!-- <i class="icon"><svg width="16.051" height="16.051" class="icon">
                                <use xlink:href="#finance-icon"></use>
                            </svg></i> -->
                                        <h4>Finance &amp; Accounting</h4>
                                        <ul class="subLinkMM">
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Create Account')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/create-account" (click)="clickEvent3()">
                                                    <div class="site-nav-dropdown-icon-container menugreen">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.737"
                                                            height="14.863" viewBox="0 0 16.737 14.863">
                                                            <g id="_8140814_create_user_profile_assign_reassign_icon"
                                                                data-name="8140814_create_user_profile_assign_reassign_icon"
                                                                transform="translate(-3.43 -4.04)">
                                                                <path id="Path_988" data-name="Path 988"
                                                                    d="M15.242,10.808h-.193a.44.44,0,0,0-.31-.311.464.464,0,0,0-.593.324,2.959,2.959,0,0,1-2.657,2.424c-1.819,0-2.954-2.1-2.954-4.044a3.08,3.08,0,0,1,2.954-3.5,2.827,2.827,0,0,1,1.29.3.476.476,0,0,0,.645-.22.494.494,0,0,0-.206-.648,3.855,3.855,0,0,0-1.728-.4,4.011,4.011,0,0,0-3.87,4.419,6.326,6.326,0,0,0,.271,1.905H7.8a7.991,7.991,0,0,0-3.87,6.869.478.478,0,0,0,.477.48H19.19a.478.478,0,0,0,.477-.48,7.9,7.9,0,0,0-4.424-7.115ZM4.833,17.457a7.015,7.015,0,0,1,3.405-5.521,3.676,3.676,0,0,0,3.251,2.216,3.665,3.665,0,0,0,3.341-2.475,6.934,6.934,0,0,1,3.87,5.767Z"
                                                                    transform="translate(0)" stroke="#74c4ba"
                                                                    stroke-width="1" />
                                                                <path id="Path_989" data-name="Path 989"
                                                                    d="M10.58,6.25a.38.38,0,0,0,0,.75h1.33V8.33a.38.38,0,0,0,.38.38.37.37,0,0,0,.37-.38V7H14a.38.38,0,0,0,0-.75H12.66V4.91a.37.37,0,0,0-.37-.37.38.38,0,0,0-.38.37V6.25Z"
                                                                    transform="translate(3.537)" stroke="#74c4ba"
                                                                    stroke-width="1" />
                                                            </g>
                                                        </svg>








                                                    </div> Create Account
                                                </a>
                                                <div class="arrowiconsonhover one">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Manage Account')"><a
                                                    class="d-flex align-center"
                                                    routerLink="administration/manage-account" (click)="clickEvent3()">
                                                    <div class="site-nav-dropdown-icon-container menublue">


                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.737"
                                                            height="19.924" viewBox="0 0 19.737 19.924">
                                                            <g id="_7853748_account_settings_kashifarif_configuration_profile_icon"
                                                                data-name="7853748_account_settings_kashifarif_configuration_profile_icon"
                                                                transform="translate(0.358 0.35)">
                                                                <g id="Group_459" data-name="Group 459"
                                                                    transform="translate(0.394 0.4)">
                                                                    <g id="Group_458" data-name="Group 458">
                                                                        <g id="Group_457" data-name="Group 457"
                                                                            transform="translate(0 9.417)">
                                                                            <path id="Path_990" data-name="Path 990"
                                                                                d="M12.681,13.292A8.323,8.323,0,0,0,7.932,11.9C3.838,11.9.726,14.275.4,18.942a1.857,1.857,0,0,0,1.8,1.965H12.6"
                                                                                transform="translate(-0.394 -11.9)"
                                                                                fill="none" stroke="#7094db"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                stroke-miterlimit="10"
                                                                                stroke-width="1.5" />
                                                                        </g>
                                                                        <path id="Path_991" data-name="Path 991"
                                                                            d="M11.7,3.1a2.7,2.7,0,0,0-5.4,0V4.658a2.7,2.7,0,1,0,5.4,0Z"
                                                                            transform="translate(-1.464 -0.4)"
                                                                            fill="none" stroke="#7094db"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" stroke-width="1.5" />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_463" data-name="Group 463"
                                                                    transform="translate(9.221 9.571)">
                                                                    <g id="Group_462" data-name="Group 462">
                                                                        <g id="Group_461" data-name="Group 461">
                                                                            <g id="Group_460" data-name="Group 460">
                                                                                <path id="Path_992" data-name="Path 992"
                                                                                    d="M21.021,16.349l-.819-.328a1.543,1.543,0,0,0-.164-.655l.573-.655a.744.744,0,0,0,0-.819l-.737-.9a.652.652,0,0,0-.819-.164l-.737.409a2.452,2.452,0,0,0-.655-.328l-.164-.819a.623.623,0,0,0-.655-.491H15.7a.7.7,0,0,0-.655.573l-.164.819a2.452,2.452,0,0,0-.655.328l-.737-.573a.635.635,0,0,0-.819.164l-.737.9a.744.744,0,0,0,0,.819l.573.655a2.531,2.531,0,0,0-.164.655l-.737.328A.623.623,0,0,0,11.2,17l.246,1.064a.7.7,0,0,0,.655.573h.819c.164.164.246.409.409.573l-.164.819a.778.778,0,0,0,.328.819l.983.491a.492.492,0,0,0,.328.082.743.743,0,0,0,.573-.246l.491-.655H16.6l.491.655a.635.635,0,0,0,.819.164l1.064-.491a.713.713,0,0,0,.328-.737l-.164-.819a1.469,1.469,0,0,0,.409-.573h.819a.713.713,0,0,0,.655-.491l.246-1.064A.609.609,0,0,0,21.021,16.349Zm-4.749,1.72a1.556,1.556,0,1,1,0-3.112,1.506,1.506,0,0,1,1.556,1.556A1.555,1.555,0,0,1,16.272,18.069Z"
                                                                                    transform="translate(-11.175 -11.6)"
                                                                                    fill="#7094db" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>








                                                    </div>
                                                    Manage Account
                                                </a>
                                                <div class="arrowiconsonhover two">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                            <li class="d-flex align-center justify-content-between linkli"
                                                *ngIf="checkScreenPermission('Select Account to Open')"><a
                                                    class="d-flex align-center" (click)="clickEvent3()"
                                                    routerLink="administration/select-account">
                                                    <div class="site-nav-dropdown-icon-container menured">

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.441"
                                                            height="18.44" viewBox="0 0 18.441 18.44">
                                                            <path id="_3994350_click_cursor_mouse_pointer_select_icon"
                                                                data-name="3994350_click_cursor_mouse_pointer_select_icon"
                                                                d="M26.1,20.227l-4.9-4.9,1.959-1.959a1.386,1.386,0,0,0-.668-2.329L9.763,8.1A1.386,1.386,0,0,0,8.1,9.765L11.04,22.5a1.386,1.386,0,0,0,2.329.668l1.959-1.959,4.9,4.9a1.385,1.385,0,0,0,1.959,0L26.1,22.186A1.385,1.385,0,0,0,26.1,20.227Zm-4.9,2.938-4.9-4.9a1.385,1.385,0,0,0-1.959,0L13.177,19.44,11.3,11.3l8.138,1.878-1.171,1.171a1.385,1.385,0,0,0,0,1.959l4.9,4.9Z"
                                                                transform="translate(-8.066 -8.068)" fill="#e95a6b" />
                                                        </svg>

                                                    </div>
                                                    Select Account to open
                                                </a>
                                                <div class="arrowiconsonhover three">

                                                    <i class="fas fa-arrow-right"></i>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="topHeaderRight">
                    <ul class="d-flex align-items-center topRightLinks">
                        <!-- <li><a href="#"><img src="../../../../assets/img/admin/us-flag.jpg" alt=""></a></li> -->
                        <li class="d-flex desktopview">

                            <!-- <div class="sessionDD desktopview"><a mat-button [matMenuTriggerFor]="sessionMenu"
                                    class="borderRadius8">Academic
                                    Session</a>
                                <mat-menu #sessionMenu="matMenu" xPosition="before" class="sessionDDMenu">
                                    <button mat-menu-item routerLink="academicslearning/new-academic-sessions">All
                                        academic
                                        session(New Module)</button>
                                    <button mat-menu-item routerLink="academicslearning/old-academic-sessions">All
                                        Closed
                                        Academic Data(Old Module)</button>
                                    <div class="mb-3 text-center">
                                        <button class="addbtn btn" matRipple
                                            (click)="loginDialogPopup(OtpPopupOptions)"> <i
                                                class="fas fa-plus-circle"></i> Create Academic session</button>
                                    </div>

                                </mat-menu>
                            </div> -->
                            <form class="top-pannel desktopview" *ngIf!="IsStudentOrPreant">
                                <form class="form-select-branch">
                                    <mat-select (selectionChange)="changeSession($event)" [(value)]="academicSessionID"
                                        class="form-control borderRadius8 diffArrowRT header headers"
                                        placeholder="Academic Session">
                                        <div *ngIf="isGlobal==true">
                                            <mat-option class="mt-4" *ngFor="let session of academicSessions"
                                                [value]="session.academicSessionID" class="text-center">
                                                {{session.academicSessionName}}
                                            </mat-option>
                                        </div>
                                        <div *ngIf="IsStudentOrPreant==false && isGlobal==false">
                                            <mat-option [value]="0" class="mt-4">Global Data</mat-option>
                                        </div>
                                        <div class="mb-4 text-center">
                                            <button mat-menu-item routerLink="academicslearning/new-academic-sessions">
                                                All academic session(New Module)</button>
                                            <button mat-menu-item routerLink="academicslearning/old-academic-sessions">
                                                All Closed Academic Data(Old Module)</button>
                                            <button class="addbtn btn" matRipple
                                                (click)="loginDialogPopup(OtpPopupOptions)"> <i
                                                    class="fas fa-plus-circle"></i> Create Academic session</button>
                                        </div>
                                    </mat-select>
                                </form>
                            </form>
                            <form class="top-pannel desktopview">

                                <!-- <div class="sessionDD"><a mat-button [matMenuTriggerFor]="BranchMenu" class="borderRadius8">Master
                        Branch</a>
                    <mat-menu #BranchMenu="matMenu" xPosition="before" class="sessionDDMenu">
                        <button mat-menu-item routerLink="academicslearning/new-academic-sessions"
                            *ngFor="let item of OrgBranches" [value]="item.organizationID">
                            {{item.organizationName}}</button>
                        <div class="pl-3 mb-3">
                            <button class="addbtn btn" matRipple  (click)="branchDialogPopup(OtpPopupBranch)"> <i class="fas fa-plus-circle"></i> Add New Branch</button>
                        </div>
                    </mat-menu>
                </div> -->
                                <form class="form-select-branch" *ngIf!="IsStudentOrPreant">
                                    <mat-select (selectionChange)="changeBranches($event)" [(value)]="selected"
                                        class="form-control borderRadius8 diffArrowRT header headers"
                                        placeholder="Master Branch" [disableOptionCentering]="true">
                                        <mat-option class="mt-4" *ngFor="let item of OrgBranches"
                                            [value]="item.organizationID" class="text-center"
                                            [attr.sub-domain]="item.subDomainName">
                                            {{item.organizationName}}
                                        </mat-option>

                                        <!-- <button mat-menu-item (click)="branchDialogPopup(OtpPopupBranch)">
                            <mat-icon>add</mat-icon>Add New Branch
                        </button> -->
                                        <div class="mb-4 text-center">
                                            <button class="addbtn btn " matRipple
                                                (click)="branchDialogPopup(OtpPopupBranch)"> <i
                                                    class="fas fa-plus-circle"></i> Add New Branch</button>
                                        </div>
                                    </mat-select>
                                </form>

                                <!-- <mat-select (selectionChange)="changeBranches($event.value)" [(value)]="selected"
                                class="form-control new borderRadius8 diffArrowRT" placeholder="Master Branch">
                                
                                <mat-option *ngFor="let item of OrgBranches" [value]="item.organizationID">
                                    {{item.organizationName}}
                                </mat-option>
                              
                                <button mat-menu-item (click)="branchDialogPopup(OtpPopupBranch)">
                                    <mat-icon>add</mat-icon>Add New Branch
                                </button>
                            </mat-select> -->
                            </form>




                        </li>
                        <!-- <li class="link-search"><a (click)="branchDialogPopup(search)"><img class="search"
                                    src="../../../assets/img/search.svg" alt=""></a></li> -->
                        <li class="link-notify"><a mat-button [matMenuTriggerFor]="notificationMenu"
                                *ngIf!="IsStudentOrPreant" (click)="updateNotification()">
                                <span>
                                    <img src="../../../../assets/img/admin/bell.svg" alt="">
                                </span>
                                <span *ngIf="isShown==true" class="badge-round">
                                </span>
                            </a>
                            <mat-menu style="width:439px" #notificationMenu="matMenu" xPosition="before"
                                class="notificationsDDMenu">
                                <button *ngIf="activityLogsForNotification" mat-menu-item>
                                    <!-- <b>New session has been created by Staff</b> -->
                                    <b style="white-space: normal;">{{activityLogsForNotification[0]?.description}}</b>
                                    <!-- <span class="date">5 hours ago</span> -->
                                </button>
                                <button *ngIf="activityLogsForNotification" mat-menu-item>
                                    <!-- <b>New session has been created by Staff</b> -->
                                    <b style="white-space: normal;">{{activityLogsForNotification[1]?.description}}</b>
                                    <!-- <span class="date">5 hours ago</span> -->
                                </button>
                                <button *ngIf="activityLogsForNotification" mat-menu-item>
                                    <!-- <b>New session has been created by Staff</b> -->
                                    <b style="white-space: normal;"> {{
                                        this.activityLogsForNotification[2]?.description}}</b>
                                    <!-- <span class="date">5 hours ago</span> -->
                                </button>
                            </mat-menu>
                        </li>
                        <li class="profileLinkTop"><a mat-button [matMenuTriggerFor]="beforeMenu">
                                <img [src]="userProfile" *ngIf="CheckUserStatus==1">
                                <img [src]="userProfile" alt="user-img" *ngIf="CheckUserStatus==0">
                            </a>
                            <mat-menu #beforeMenu="matMenu" xPosition="before" class="profileDDMenu">
                                <p class="userName text-left">
                                    <img class="mr-2" [src]="userProfile" *ngIf="CheckUserStatus==1"
                                        style="width: 30px;height:30px;border-radius: 50%;">
                                    <img class="mr-2" [src]="userProfile" alt="user-img" *ngIf="CheckUserStatus==0"
                                        style="width: 30px;height:30px;border-radius: 50%;">
                                    {{user.loggedInUserName}}
                                </p>
                                <!-- <button mat-menu-item> <i class="fas fa-cogs profilemenu"></i> Setting</button> -->
                                <button *ngIf="checkModulePermission('Organization Settings')" mat-menu-item
                                    routerLink="settings/organization-profile"><i class="fas fa-users profilemenu"></i>
                                    Organization
                                    Profile</button>
                                <!-- *ngIf="orgSetting" -->
                                <button mat-menu-item routerLink="settings/user-profile" *ngIf!="IsStudentOrPreant"> <i
                                        class="fas fa-user-alt profilemenu"></i> User Profile</button>
                                <!-- <button mat-menu-item (click)="login-user-profile()">Login User Profile</button> -->
                                <!-- <li><a routerLink="login-user-profile"
                                    routerLinkActive="active">Manage Login User Profile</a></li> -->
                                <button mat-menu-item (click)="resetPassword(resetPassword1)"><i
                                        class="fas fa-lock profilemenu"></i> Reset Password</button>
                                <button mat-menu-item (click)="logout()"><i class="fas fa-power-off profilemenu"></i>
                                    Log Out</button>

                            </mat-menu>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="showWarning" class="warning">{{expMsg}}<a
                    routerLink="/admin/settings/subscription"><b><u>Upgrade now</u></b></a></div>
        </div>

        <div class="container" style="position: relative;">
            <div class="container container1580 topForm">
                <div class="d-sm-flex justify-content-between align-items-center">
                    <!-- <div class="searchTop">
                        <form>
                            <input matInput type="text" class="form-control searchWithIcon"
                                placeholder="Enter your keyword">
                        </form>

                    </div> -->
                    <div class="DDLinksTop">
                        <div class="d-flex align-items-center topDDRight">
                            <!-- <div class="sessionDD"><a mat-button [matMenuTriggerFor]="sessionMenu"
                                    class="borderRadius8">Academic Session</a>
                                <mat-menu #sessionMenu="matMenu" xPosition="before" class="sessionDDMenu">
                                    <button mat-menu-item routerLink="academicslearning/new-academic-sessions">All
                                        academic
                                        session(New Module)</button>
                                    <button mat-menu-item routerLink="academicslearning/old-academic-sessions">All
                                        Closed
                                        Academic Data(Old Module)</button>
                                    <button mat-menu-item (click)="loginDialogPopup(OtpPopupOptions)">
                                        <mat-icon>add</mat-icon>Create Academic session
                                    </button>
                                </mat-menu>
                            </div>
                            <form>
                                <mat-select (selectionChange)="changeBranches($event.value)" [(value)]="selected"
                                    class="form-control borderRadius8 diffArrowRT" placeholder="Master Branch">
                                    
                                    <mat-option *ngFor="let item of OrgBranches" [value]="item.organizationID">
                                        {{item.organizationName}}
                                    </mat-option>
                                  
                                    <button mat-menu-item (click)="branchDialogPopup(OtpPopupBranch)">
                                        <mat-icon>add</mat-icon>Add New Branch
                                    </button>
                                </mat-select>
                            </form> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="headerMenu menuHide" [ngClass]="{'menuHide': toggle, 'menuShow': !toggle}">
            <button class="overlay-menu" (click)="change()">

            </button>
            <div class="container container1580">
                <ul class="admin-menu">


                    <div class="organizationdetails" style="background-color: white;">

                        <i class="fas fa-plus-circle" (click)="branchDialogPopup(OtpPopupBranch)"></i>
                        <button class="pr-0" mat-menu-item routerLink="academicslearning/new-academic-sessions"
                            *ngFor="let item of OrgBranches" [value]="item.organizationID">
                            {{item.organizationName}}</button>



                    </div>

                    <li *ngIf!="IsStudentOrPreant"><a routerLink="/admin" routerLinkActive="active">
                            <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#dashboard-icon"></use>
                                </svg></i>
                            <span>Dashboard</span></a></li>
                    <li class="dropdown-megamenu"><a routerLink="/academic" routerLinkActive="active">
                            <i class="icon"><svg width="14.857" height="16" class="icon">
                                    <use xlink:href="#academic-icon"></use>
                                </svg></i>
                            <span>Academic &amp; Learning</span></a>
                        <div class="megaMenu" [ngClass]="{'active': menuVisible}">
                            <div class="megaMenuRow">
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Courses & Subjects')">
                                    <!-- <i class="icon"><svg width="16" height="11.991" class="icon">
                                    <use xlink:href="#course-icon"></use>
                                </svg></i> -->
                                    <h4>Courses &amp; Subjects</h4>
                                    <ul class="subLinkMM">
                                        <li (click)="megaMenuHandler()"
                                            *ngIf="checkScreenPermission('Manage Class, Section and Subjects')"><a
                                                routerLink="academicslearning/manage-academic"
                                                routerLinkActive="active">Manage
                                                Class &amp;
                                                Subjects </a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Students')">
                                    <!-- <i class="icon"><svg width="17.671" height="14.798" class="icon">
                                    <use xlink:href="#studentmm-icon"></use>
                                </svg></i> -->
                                    <h4>Students</h4>
                                    <ul class="subLinkMM">
                                        <li *ngIf="checkScreenPermission('Manage Students')"><a
                                                routerLink="academicslearning/manage-students"
                                                routerLinkActive="active">Manage
                                                Students</a></li>
                                        <li *ngIf="checkScreenPermission('Manage Alumni')"><a
                                                routerLink="academicslearning/manage-alumni"
                                                routerLinkActive="active">Manage
                                                Alumni</a></li>
                                        <li *ngIf="checkScreenPermission('Manage Student Fields')"><a
                                                routerLink="administration/add-customeForms"
                                                routerLinkActive="active">Manage
                                                Students Field</a></li>
                                        <li *ngIf="checkScreenPermission('Manage Attendance')"><a
                                                routerLink="academicslearning/manage-student-attendance"
                                                routerLinkActive="active">Manage Attendance</a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Teachers & Staff/Admin')">
                                    <!-- <i class="icon"><svg width="15.998" height="15.868" class="icon">
                                    <use xlink:href="#teachermm-icon"></use>
                                </svg></i> -->
                                    <h4>Teachers &amp; Staffs/Admin</h4>
                                    <ul class="subLinkMM">
                                        <li *ngIf="checkScreenPermission('Manage Admin')"><a
                                                routerLink="academicslearning/manage-admin-staff"
                                                routerLinkActive="active">Manage Admin</a></li>
                                        <li *ngIf="checkScreenPermission('Manage Teachers')"><a
                                                routerLink="academicslearning/manage-teachers"
                                                routerLinkActive="active">Manage
                                                Teachers</a></li>

                                        <li *ngIf="checkScreenPermission('Manage Teacher Fields')"><a
                                                routerLink="academicslearning/manage-teachers/manage-teacher-field"
                                                routerLinkActive="active">Manage Teachers Field</a></li>
                                        <!-- <li><a href="javascript:void(0)">Manage Students Attendance</a></li> -->
                                        <li *ngIf="checkScreenPermission('Manage Teachers Attendance')"><a
                                                routerLink="academicslearning/manage-teacher-attendance"
                                                routerLinkActive="active">Manage Teachers Attendance</a></li>

                                        <!-- <li *ngIf="checkScreenPermission('Teachers Payment')"><a
                                                    routerLink="academicslearning/teacherspayment"
                                                    routerLinkActive="active">Teachers Payment</a></li> -->

                                        <li *ngIf="checkScreenPermission('Subject Allocations')"><a
                                                routerLink="academicslearning/subjects-allocation"
                                                routerLinkActive="active">Subjects Allocation</a></li>

                                    </ul>
                                </div>
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Asessment & Gradings')">
                                    <!-- <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#gradingmm-icon"></use>
                                </svg></i> -->
                                    <h4>Assessment &amp; Gradings</h4>
                                    <ul class="subLinkMM">
                                        <!-- <li><a routerLink="academicslearning/manage-grade">Manage Grade</a></li>
                                    <li><a routerLink="academicslearning/report-cards">Report Cards of students</a></li>
                                    <li><a routerLink="academicslearning/manage-assessment">Manage Assessment</a></li>
                                    <li><a href="javascript:void(0)">Report Card Format</a></li>
                                    <li><a href="javascript:void(0)">Weighted Subjects &amp; Category Settings</a></li> -->
                                        <li><a routerLink="academicslearning/manage-grade" routerLinkActive="active">

                                                Manage
                                                Assessment &amp; Gradings</a>



                                        </li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Branchs')">
                                    <!-- <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#branchmm-icon"></use>
                                </svg></i> -->
                                    <h4>Branch</h4>
                                    <ul class="subLinkMM">
                                        <li><a href="javascript:void(0)">Manage Branch</a></li>
                                        <li><a (click)="branchDialogPopup(OtpPopupBranch)">Add New Branch</a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol" *ngIf="checkSubModulePermission('Roles & Permissions')">
                                    <!-- <i class="icon"><svg width="16.809" height="13.788" class="icon">
                                    <use xlink:href="#admin-icon"></use>
                                </svg></i> -->
                                    <h4>Roles & Permissions</h4>
                                    <ul class="subLinkMM">
                                        <li *ngIf="checkScreenPermission('Manage Roles')"><a
                                                routerLink="academicslearning/manage-roles"
                                                routerLinkActive="active">Manage
                                                Roles</a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="16.809" height="13.788" class="icon">
                                    <use xlink:href="#lmsmm-icon"></use>
                                </svg></i> -->
                                    <!-- <h4>LMS</h4>
                                    <ul class="subLinkMM">
                                        <li><a href="javascript:void(0)">Learning Management System</a></li>
                                    </ul> -->
                                </div>

                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="16.809" height="13.788" class="icon">
                                    <use xlink:href="#lmsmm-icon"></use>
                                </svg></i> -->
                                    <!-- <h4>OBE</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="../admin/obe/app-program-outcome"
                                                routerLinkActive="active">Manage
                                                PO</a></li>
                                        <li><a routerLink="../admin/obe/app-course-outcome"
                                                routerLinkActive="active">Manage
                                                CO</a></li>
                                        <li><a routerLink="../admin/obe/app-po-attainment-tree-list"
                                                routerLinkActive="active">PO Attainment Tree</a></li>
                                        <li><a routerLink="../admin/obe/app-questions"
                                                routerLinkActive="active">Question
                                                Papers</a></li>
                                        <li><a routerLink="../admin/obe/report" routerLinkActive="active">PO/CO
                                                Report</a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="dropdown-megamenu" *ngIf!="IsStudentOrPreant"><a routerLink="/communication"
                            routerLinkActive="active">
                            <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#communication-icon"></use>
                                </svg></i>
                            <span>Communication</span></a>
                        <div class="megaMenu">
                            <div class="megaMenuRow">
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="15" height="10.547" class="icon">
                                    <use xlink:href="#message-icon"></use>
                                </svg></i> -->
                                    <h4>Messaging</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="communication/sms//usage-statistics"
                                                routerLinkActive="active">SMS</a></li>
                                        <li><a routerLink="communication/email/email-logs">Email</a></li>
                                    </ul>
                                </div>
                                <!-- <div class="megaMenuCol">
                                    <i class="icon"><svg width="15.981" height="15.981" class="icon">
                                            <use xlink:href="#shift-icon"></use>
                                        </svg></i>
                                    <h4>Shift Management</h4>
                                    <ul class="subLinkMM">

                                        <ul class="subLinkMM">
                                            <li><a routerLink="communication/manage-shift-category" routerLinkActive="active">Manage Shift Category</a></li>
                                            <li><a routerLink="communication/manage-shift"
                                                    routerLinkActive="active">Manage Shift</a></li>

                                        </ul>
                                    </ul>
                                </div> -->
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="15" height="15.059" class="icon">
                                    <use xlink:href="#timetable-icon"></use>
                                </svg></i> -->
                                    <h4>Time Table Management</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="communication/manage-timetable"
                                                routerLinkActive="active">Manage Time
                                                Table</a></li>
                                        <!-- <li><a routerLink="communication/view-timetable" routerLinkActive="active">View
                                                Calender</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="dropdown-megamenu" *ngIf!="IsStudentOrPreant"><a routerLink="/communication"
                            routerLinkActive="active">
                            <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#administration-icon"></use>
                                </svg></i>
                            <span>Administration</span></a>
                        <div class="megaMenu">
                            <div class="megaMenuRow">
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="12.226" height="16.051" class="icon">
                                    <use xlink:href="#reports-icon"></use>
                                </svg></i> -->
                                    <h4>Reports</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="administration/academics-report">Academics Reports</a></li>
                                        <li><a routerLink="administration/student-report">Attendence Reports</a></li>
                                        <li><a href="javascript:void(0)">Fees Reports</a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="12.038" height="16.051" class="icon">
                                    <use xlink:href="#invoice-icon"></use>
                                </svg></i> -->
                                    <h4>Fees & Invoicing</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="administration/fees-and-invoicing"
                                                routerLinkActive="active">Listing
                                                of Fees &amp; Invoicing</a></li>
                                        <!-- <li><a routerLink="administration/recurring-subcriptions"
                                                routerLinkActive="active">Recurring Subcriptions</a></li> -->
                                        <li><a routerLink="administration/invoices"
                                                routerLinkActive="active">Invoices</a></li>
                                        <li><a routerLink="administration/payment-transaction"
                                                routerLinkActive="active">Payment
                                                Transaction</a></li>
                                        <li><a routerLink="administration/add-fees" routerLinkActive="active">Add
                                                Fees</a></li>
                                        <li><a routerLink="administration/settings"
                                                routerLinkActive="active">Settings</a></li>
                                    </ul>
                                </div>
                                <div class="megaMenuCol">
                                    <!-- <i class="icon"><svg width="16.051" height="16.051" class="icon">
                                    <use xlink:href="#finance-icon"></use>
                                </svg></i> -->
                                    <h4>Finance &amp; Accounting</h4>
                                    <ul class="subLinkMM">
                                        <li><a routerLink="administration/create-account">Create Account</a></li>
                                        <li><a routerLink="administration/manage-account">Manage Account</a></li>
                                        <li><a routerLink="administration/select-account">Select Account to open</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="dropdown-megamenu" *ngIf!="IsStudentOrPreant">
                        <a routerLinkActive="active">
                            <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#administration-icon"></use>
                                </svg></i>
                            <span>Academic
                                Session</span></a>
                        <div class="megaMenu">
                            <div class="megaMenuRow">
                                <div class="megaMenuCol">
                                    <ul class="subLinkMM">
                                        <li><a routerLink="academicslearning/new-academic-sessions">All Academic
                                                Session(New Module)</a></li>
                                        <li><a routerLink="academicslearning/old-academic-sessions">All Closed Academic
                                                Data(Old Module)</a></li>
                                        <li><a (click)="loginDialogPopup(OtpPopupOptions)">Create Academic Session</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <!-- <form class="top-pannel desktopview">
                <div class="sessionDD"><a mat-button [matMenuTriggerFor]="BranchMenu" class="borderRadius8">Master
                        Branch</a>
                    <mat-menu #BranchMenu="matMenu" xPosition="before" class="sessionDDMenu">
                        <button mat-menu-item routerLink="academicslearning/new-academic-sessions"
                            *ngFor="let item of OrgBranches" [value]="item.organizationID">
                            {{item.organizationName}}</button>
                        <div class="pl-3 mb-3">
                            <button class="addbtn btn" matRipple  (click)="branchDialogPopup(OtpPopupBranch)"> <i class="fas fa-plus-circle"></i> Add New Branch</button>
                        </div>
                    </mat-menu>
                </div>
            </form> -->
                    </li>
                    <li class="dropdown-megamenu" *ngIf!="IsStudentOrPreant">
                        <a routerLinkActive="active">
                            <i class="icon"><svg width="16" height="16" class="icon">
                                    <use xlink:href="#administration-icon"></use>
                                </svg></i>
                            <span>Branch</span></a>
                        <div class="megaMenu">
                            <div class="megaMenuRow">
                                <div class="megaMenuCol">

                                    <ul class="subLinkMM">
                                        <li><a routerLink="academicslearning/new-academic-sessions">All Academic
                                                Session(New Module)</a></li>
                                        <li><a routerLink="academicslearning/old-academic-sessions">All Closed Academic
                                                Data(Old Module)</a></li>
                                        <li><a (click)="loginDialogPopup(OtpPopupOptions)">Create Academic Session</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </header>

    <ng-template #OtpPopupOptions>
        <div class="dialog-md">
            <i class="material-icons popupClose" mat-dialog-close>clear</i>
            <div class="dialogTitle center-align loginTtile">
                <h2 mat-dialog-title>Add Academic Session</h2>
            </div>
            <mat-dialog-content class="mat-typography">
                <form [formGroup]="addAcademiceForm" (ngSubmit)="onSubmit()">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Academic Session Name</mat-label>
                        <input type="text" maxlength="25" matInput placeholder="Academic Session Name"
                            formControlName="academicSessionName">
                        <mat-error
                            *ngIf="addAcademiceForm.get('academicSessionName')?.touched && addAcademiceForm.get('academicSessionName')?.errors?.required">
                            Academic Session is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error
                            *ngIf="addAcademiceForm.get('startDate')?.touched && addAcademiceForm.get('startDate')?.errors?.required">
                            Start Date is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepicker]="picker1" (click)="picker1.open()" formControlName="endDate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error
                            *ngIf="addAcademiceForm.get('endDate')?.touched && addAcademiceForm.get('endDate')?.errors?.required">
                            End Date is required
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Status</mat-label>
                        <mat-select placeholder="Select Status" (selectionChange)="selectStatus($event)"
                            formControlName="academicSessionStatusID">
                            <mat-option value="">Select Status</mat-option>
                            <mat-option *ngFor="let statusSelect of sessionStatus"
                                [value]="statusSelect.academicSessionStatusID">
                                {{statusSelect.academicSessionStatusName}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="addAcademiceForm.get('academicSessionStatusID')?.touched && addAcademiceForm.get('academicSessionStatusID')?.errors?.required">
                            Please Select a status </mat-error>
                    </mat-form-field>
                    <div *ngIf="statusID==1"><label class="mb-4 d-block">Setting the academic session status to In
                            Progress will unlock this
                            academic
                            session. However, you can lock it anytime to disable the academics data access for this
                            session.</label></div>
                    <div *ngIf="statusID==2">
                        <label class="mb-4 d-block">Setting the academic session status to Upcoming will lock this
                            academic session.
                            However, you can unlock it anytime to view/edit the academics data for this session.</label>
                    </div>
                    <div class="form-group17 text-right btnsWidget">
                        <button type="submit" class="btn btn-primary btn-sm" [disabled]="addAcademiceForm.invalid"
                            matRipple>Submit</button>
                        <button type="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close
                            matRipple>Cancel</button>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
    </ng-template>

    <ng-template #OtpPopupBranch>
        <div class="dialog-md">
            <i class="material-icons popupClose" mat-dialog-close>clear</i>
            <div class="dialogTitle center-align loginTtile">
                <h2 mat-dialog-title>Add Branch</h2>
            </div>
            <mat-dialog-content class="mat-typography">
                <form [formGroup]="signUpForm">
                    <div class="row-flex">
                        <div class="col s12">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Organization Name</mat-label>
                                <input matInput placeholder="Organization Name" required name="organizationName"
                                    formControlName="organizationName">
                                <mat-error
                                    *ngIf="signUpForm.get('organizationName')?.touched  && signUpForm.get('organizationName')?.errors?.required">
                                    Organization Name is required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Select Organization Type</mat-label>
                                <mat-select required placeholder="Select Organization Type" name="organizationType"
                                    formControlName="organizationType">
                                    <mat-option value="Select Organization Type">Select Organization Type</mat-option>
                                    <mat-option *ngFor="let orgType of organizationType"
                                        [value]="orgType.organizationTypeName">
                                        {{orgType.organizationTypeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="signUpForm.get('organizationType')?.touched  && signUpForm.get('organizationType')?.errors?.required">
                                    Organization Type is required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m6">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Organization Short Name</mat-label>
                                <input matInput placeholder="Organization Short Name" required
                                    name="organizationShortName" formControlName="organizationShortName">
                                <mat-error
                                    *ngIf="signUpForm.get('organizationShortName')?.touched && signUpForm.get('organizationShortName')?.errors?.required">
                                    Organization ShortName is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m6">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Approx organization student</mat-label>
                                <input matInput maxlength="5" (keypress)="allowOnlyNumber($event)"
                                    placeholder="Number of Students" required formControlName="maximumStudentAllowed">
                                <mat-error
                                    *ngIf="signUpForm.get('maximumStudentAllowed')?.touched && signUpForm.get('maximumStudentAllowed')?.errors?.required">
                                    Number of students is required
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <div class="col s12 m12">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Sub-domain</mat-label>
                                <div class="subDomainInput">
                                    <input matInput placeholder="Sub-domain" required name="SubDomainName"
                                        (blur)="onBlurMethodtxt($event)" formControlName="subDomainName"
                                        (keypress)="allowAlphaNumber($event)">
                                    <span class="spanDomain">.creocms.com.au </span>
                                </div>
                                <mat-error
                                    *ngIf="signUpForm.get('subDomainName')?.touched  && signUpForm.get('subDomainName')?.errors?.required">
                                    Sub-domain Name is required
                                </mat-error>
                                <mat-error *ngIf="confirm_subdomain.hasError('mismatchSubdomain')">
                                    Subdomain already exist!
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="subdomainAvailable">
                                Subdomain is available
                            </div>
                        </div>

                        <!-- <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Select Site Subscription Plan</mat-label>
                                <mat-select required placeholder="Select Site Subscription Plan"
                                    formControlName="subscriptionID" name="subscriptionID">
                                    <mat-option value="Select Site Subscription Plan">Select Site Subscription Plan
                                    </mat-option>
                                    <mat-option *ngFor="let plan of siteSubscriptionPlan" [value]="plan.subscriptionID">
                                        {{plan.planName}} ({{plan.numberOfUserAllowed}} Max. users allowed on
                                        Price:${{plan.price}})
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="signUpForm.get('subscriptionID')?.touched && signUpForm.get('subscriptionID')?.errors?.required">
                                    Site Subscription Plan is required
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <!-- <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Select Email Subscription Plan</mat-label>
                                <mat-select required placeholder="Select Email Subscription Plan"
                                    formControlName="emailSubscriptionPlanID" name="emailSubscriptionPlanID">
                                    <mat-option value="Select Email Subscription Plan">Select Email Subscription Plan
                                    </mat-option>
                                    <mat-option *ngFor="let plan of emailSubscriptionPlan"
                                        [value]="plan.emailSubscriptionPlanID">
                                        {{plan.emailPlanName}} (${{plan.emailPrice}})
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="signUpForm.get('emailSubscriptionPlanID')?.touched && signUpForm.get('emailSubscriptionPlanID')?.errors?.required">
                                    Select Email Subscription Plan is required
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <!-- <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Select SMS Subscription Plan</mat-label>
                                <mat-select required placeholder="Select SMS Subscription Plan"
                                    formControlName="smsSubscriptionPlanID" name="smsSubscriptionPlanID">
                                    <mat-option value="Select SMS Subscription Plan">Select SMS Subscription Plan
                                    </mat-option>
                                    <mat-option *ngFor="let plan of SMSSubscriptionPlan"
                                        [value]="plan.smsSubscriptionPlanID">
                                        {{plan.smsPlanName}} (${{plan.smsPrice}})
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="signUpForm.get('smsSubscriptionPlanID')?.touched && signUpForm.get('smsSubscriptionPlanID')?.errors?.required">
                                    Select SMS Subscription Plan is required
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Address</mat-label>
                                <div class="subDomainInput">
                                    <input matInput placeholder="address" name="Address" formControlName="address">
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Country</mat-label>
                                <!-- <mat-select placeholder="Country" (valueChange)="GetStateList($event)"
                                    formControlName="countryID">
                                    <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'country')"> -->


                                <input type="text" (input)="onKeyFilter($event, 'country')" matInput
                                    formControlName="countryID" [matAutocomplete]="countryAuto">


                                <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="GetStateList($event)"
                                    [displayWith]="dispalyFns.bind(this)">

                                    <mat-option value="" [disabled]="true">Select Country</mat-option>
                                    <mat-option *ngFor="let country of countries" [value]="country.countryID">
                                        {{country.countryName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- </mat-select> -->
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>State</mat-label>
                                <!-- <mat-select placeholder="State" formControlName="stateID">
                                    <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'state')"> -->



                                <input type="text" (input)="onKeyFilter($event, 'state')" matInput
                                    formControlName="stateID" [matAutocomplete]="stateAuto">


                                <mat-autocomplete #stateAuto="matAutocomplete"
                                    [displayWith]="dispalyStateAuto.bind(this)">



                                    <mat-option value="" [disabled]="true">Select State</mat-option>
                                    <mat-option *ngFor="let state of states" [value]="state.stateID">
                                        {{state.stateName}}
                                    </mat-option>
                                    <!-- </mat-select> -->
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>City</mat-label>
                                <input maxlength="20" matInput placeholder="city" formControlName="city"
                                    pattern="^[^\s]+(\s+[^\s]+)*$">
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 ">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Zip Code</mat-label>
                                <input maxlength="8" matInput placeholder="Zip Code" formControlName="postCode"
                                    pattern="^[^\s]+(\s+[^\s]+)*$">
                            </mat-form-field>
                        </div>
                        <div class="col s12 form-group17 center">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button type="submit" [disabled]="signUpForm.invalid" mat-dialog-close
                                        (click)="registerOrgApiCall()" class="btn btn-primary btn-sm mr-2" matRipple
                                        mat-dialog-close>Save</button>
                                    <button type="reset" (click)="reset()" class="btn btn-reset btn-sm" matRipple
                                        mat-dialog-close>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
    </ng-template>

</div>
<div *ngIf="showpopupflag" class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
        <h2 mat-dialog-title>Edit Academic Session</h2>
    </div>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="editacademicForm" (ngSubmit)="oneditSubmit()">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Academic Session Name</mat-label>
                <input type="text" maxlength="25" matInput placeholder="Academic Session Name"
                    formControlName="academicSessionName">
            </mat-form-field>
            <mat-error
                *ngIf="editacademicForm.get('academicSessionName')?.touched && editacademicForm.get('academicSessionName')?.errors?.required">
                Academic Session is required
            </mat-error>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" (keydown)="false"
                    formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error
                *ngIf="editacademicForm.get('startDate')?.touched && editacademicForm.get('startDate')?.errors?.required">
                Start Date is required
            </mat-error>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="picker1" (click)="picker1.open()" formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-error
                *ngIf="editacademicForm.get('endDate')?.touched && editacademicForm.get('endDate')?.errors?.required">
                End Date is required
            </mat-error>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Status</mat-label>
                <mat-select placeholder="Select Status" formControlName="academicSessionStatusID">
                    <mat-option value="Select Status">Select Status</mat-option>
                    <mat-option *ngFor="let statusSelect of sessionStatus"
                        [value]="statusSelect.academicSessionStatusID">
                        {{statusSelect.academicSessionStatusName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error
                *ngIf="editacademicForm.get('academicSessionStatusID')?.touched && editacademicForm.get('academicSessionStatusID')?.errors?.required">
                Please Select a status </mat-error>
            <!-- <label class="mb-4 d-block">Setting the academic session status to In Progress will unlock this academic
                session. However, you can lock it anytime to disable the academics data access for this
                session.</label> -->
            <div class="form-group17 text-right btnsWidget">
                <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
                    [disabled]="editacademicForm.invalid" matRipple>Submit</button>
                <button type="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close
                    matRipple>Cancel</button>
            </div>
        </form>
    </mat-dialog-content>
</div>

<ng-template #search>
    <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title>Search</h2>
        </div>
        <mat-dialog-content>
            <div class="searchbar">
                <mat-form-field appearance="outline">
                    <mat-label>Search Here</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="Number" matInput class="w-100"
                        [formControl]="myControl" [matAutocomplete]="auto">

                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            123
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon class="searchbtns">search</mat-icon>
                </mat-form-field>
            </div>
        </mat-dialog-content>
    </div>
</ng-template>

<ng-template #resetPassword1>
    <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title>Reset Password</h2>
        </div>
        <mat-dialog-content class="mat-typography">
            <form [formGroup]="resetForm" (ngSubmit)="savePassword()">
                <div class="form-group17">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Current Password</mat-label>
                        <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
                        <input matInput type="password" formControlName="currentPassword" required autocomplete="off"
                            required />
                        <mat-error
                            *ngIf="resetForm.get('currentPassword')?.touched  && resetForm.get('currentPassword')?.errors?.required">
                            Current password is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group17">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required
                            autocomplete="off"
                            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
                        <span mat-icon-button matSuffix style="background: transparent;border: none;"
                            (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </span>
                        <mat-error
                            *ngIf="resetForm.get('password')?.touched  && resetForm.get('password')?.errors?.required">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="resetForm.get('password')?.errors?.pattern">
                            Password must contain minimum eight characters,at least one uppercase and lowercase
                            letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group17">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput formControlName="confirmPassword" required autocomplete="off"
                            [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
                        <span mat-icon-button matSuffix style="background: transparent;border: none;"
                            (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                            <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </span>
                        <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group17 center">
                    <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid">Update</button>
                </div>
            </form>

        </mat-dialog-content>
    </div>
</ng-template>