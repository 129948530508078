import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild, TemplateRef, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog'
import { DynamicStudentMeta, FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { TeachersService } from 'src/app/services/teachers.service';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { MasterService } from 'src/app/services/master.service';
import { TeacherModel } from '../../teachers/teacher-model';
import { AbstractControl, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { StudentEnrollmentStatusService } from 'src/app/masterServices/enrollmentStatusServices/student-enrollment-status.service';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { SubjectEnrollmentStatusDropdownService } from 'src/app/masterServices/subjectenrollmentStatusIDropdown/subject-enrollment-status-dropdown.service';
import { DatePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-manage-alumni',
  templateUrl: './manage-alumni.component.html',
  styleUrls: ['./manage-alumni.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
})
export class ManageAlumniComponent implements AfterViewInit, OnInit {

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  usersData: any;
  classToggled = false;
  exportForm: FormGroup;
  columnForm: FormGroup;
  alumnistudentEmailForm: FormGroup;
  studentSmsForm: FormGroup;
  updateTextInPopUp: boolean;
  alumniId: any;
  classListDropdown: any;
  sectionListDropdown: any;
  academicSessions: any;
  studentEnrollmentStatus: any;
  studentEnrollObj: {};
  subjectEnrollObj: {};
  studentID: any;
  studentMultipleData: any;
  SubjectListDropdown: any;
  UpdateSubjectEnrollmentStatusForm: FormGroup;
  subjectEnrollmentStatusDropdown: any;
  subjectEnrollmentForm: FormGroup;
  selectedAluminiTooltip: string = 'Select atleast one student';

  users: any;
  userName: any;
  userEmail: any;
  orgID: any;
  exportField: any[] = [];
  getFilterStudentData: any = [];
  getNewFilterStudentData: any = [];
  getclassSectionModel: any;
  getColumnAndData: any;
  private dybamicstudentMeta: DynamicStudentMeta;
  private dynamicAlumniMeta: DynamicStudentMeta
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('textEditorValue')
  public tools: ToolbarModule = {
    type: 'Expand',
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  displayedColumnsList: string[] = [];
  columnsToDisplay: any;
  hideshowGridColumnList: any[] = [];
  dynamicFilterform: FormGroup;
  dynamicFilterboolean: boolean = false;
  changeColumnsToDisplay: any;
  checkedAll: boolean = false;
  user: any
  userId: any;
  selectedRow: any;
  selectedData: number = 0;
  printFlag: boolean;
  permissions: any = [];
  formbind: any;

  // displayedColumns: Array<any> = [
  //   { displayName: 'select', key: 'select', class: '', width: '5%' },
  //   { displayName: 'Profile Picture', key: 'profilePhoto', isSort: false, class: '', width: '5%' },
  //   { displayName: 'First Name', key: 'firstName', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Last Name', key: 'lastName', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Email', key: 'email', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Section', key: 'section', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Phone Number', key: 'contactNumber', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Admission Number', key: 'admissionNumber', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Student Skill', key: 'studentSkill', isSort: true, class: '', width: '5%' },
  //   { displayName: 'LinkedIn Link', key: 'linkedInLink', isSort: false, class: '', width: '5%' },
  //   { displayName: 'Facebook Link', key: 'facebookLink', isSort: false, class: '', width: '5%' },    
  //   { displayName: 'Parent Email', key: 'parentEmail', isSort: true, class: '', width: '5%' },
  //   { displayName: 'DOB', key: 'dateOfBirth', isSort: true, class: '', width: '10%' },
  //   { displayName: 'Profile Summary', key: 'profileSummary', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Zipcode', key: 'zipcode', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Country', key: 'country', class: '', width: '5%' },
  //   { displayName: 'Blood Group', key: 'bloodGroupName', class: '', width: '5%' },
  //   { displayName: 'Father Name', key: 'fatherName', isSort: true, class: '', width: '5%' },
  //   { displayName: 'Mother Name', key: 'motherName', class: '', width: '5%' },
  //   { displayName: 'Parent Contact', key: 'parentContact', class: '', width: '5%' },
  //   { displayName: 'Gender', key: 'genderName', class: '', width: '5%' },
  //   { displayName: 'Action', key: 'Actions', class: '', width: '10%' }
  // ];
  // actionButtons: Array<any> = [
  //   { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
  //   { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  //   { displayName: 'Revert', key: 'revert', class: 'fas fa-reply' },
  // ];


  constructor(
    private dialog: MatDialog,
    private teacherService: TeachersService,
    private studentService: StudentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private masterService: MasterService,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private studentEnrollmentStatusService: StudentEnrollmentStatusService,
    private activatedRoute: ActivatedRoute,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private subjectEnrollmentStatus: SubjectEnrollmentStatusDropdownService,
    public datepipe: DatePipe,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private cdr: ChangeDetectorRef
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.userName = currentUser;
    }
    this.filterModel = new FilterModel();
    this.usersData = new Array<TeacherModel>();
    this.dybamicstudentMeta = new DynamicStudentMeta();
    this.dynamicAlumniMeta = new DynamicStudentMeta()
    this.dynamicFilterform = new FormGroup({});
  }
  ngAfterViewInit(): void {
    this.selectedData = 0;

  }

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Manage Alumni');
    this.permissions = this.permissionService.getAllPermissions();
    console.log("this.permissions", this.permissions)
    if (this.permissions.length == 0) {
      console.log("this.permissions.length ", this.userName.userId)
      this.roleService.getPermissionByUserID(this.userName.userId).subscribe((res: any) => {
        if (res.status > 0) {
          console.log("roleService.getPermissionByUserID res", res)
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.ActivityLogTrack();
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      teacherName: [''],
      teacherNumber: [''],
      employeeTypeName: [''],
      contactNumber: [''],
      email: [''],

    });
    this.printFlag = false;
    this.InitialGridCall();
    this.getTableData();
    this.GetCustomTemplateList();
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    this.userId = currentUser.userId;
    this.userEmail = currentUser.email;
    this.orgID = currentUser.orgID;
    this.subjectEnrollmentForm = this.formBuilder.group({
      subjectID: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      academicSessionID: ['', Validators.required],
    }
    );
    this.exportForm = this.formBuilder.group({
      firstName: false,
      lastName: false,
      admissionNo: false,
      studentEmail: false,
      dob: false,
      studentContact: false,
      gender: false,
      bloodGroup: false,
      profileSummary: false,
      skill: false,
      facebookProfile: false,
      linkedInProfile: false,
      address: false,
      zipcode: false,
      state: false,
      country: false,
      fatherName: false,
      motherName: false,
      parentContact: false,
      parentEmail: false,
      applicationId: false,
      enrollment: false
    })
    this.getMasterDropdown();

  }

  // onPageOrSortChange(changeState?: any) {
  //   this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
  //   this.getUsersList(this.filterModel, '', '');
  // }

  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "teacherName": this.form.controls.teacherName.value,
      "contactNumber": this.form.controls.contactNumber.value,
      "employeeType": this.form.controls.employeeTypeName.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": ""
    }
    //this.AdminUnserList();
  }
  //metaData: any;
  // AdminUserList() {
  //   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
  //     if(res.status > 0){
  //       console.log(res);
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;

  //     }else{
  //       this.metaData = new Metadata();
  //     }

  //   });
  // }

  // onTableActionClick(actionObj?: any) {
  //   const id = actionObj.data && actionObj.data.studentID;
  //   switch ((actionObj.action || '').toUpperCase()) {
  //     case 'EDIT':
  //       //this.editCall(id);
  //       break;
  //     case 'DELETE':
  //       {

  //         this.dialogService
  //           .confirm(`Are you sure you want to delete this user?`)
  //           .subscribe((result: any) => {
  //             if (result == true) {
  //               this.alumniId = [];
  //               this.alumniId.push(JSON.stringify(id));
  //               this.deleteSinglealumni();
  //             }
  //           });
  //       }
  //       break;
  //     case 'DETAILS':
  //       this.infoCall(id);
  //       break;
  //     case 'REVERT':
  //       {

  //         this.dialogService
  //           .confirm(`Are you sure you want to revert this student?`)
  //           .subscribe((result: any) => {
  //             if (result == true) {
  //               this.RevertStudent(id);
  //             }
  //           });
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // applyFilter(searchText: string = '') {
  //   this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  //   if (searchText.trim() == '' || searchText.trim().length >= 3) {
  //     this.getUsersList(this.filterModel, '', '');
  //   }
  // }
  // getUsersList(filterModel: any, tags: any, roleIds: any) {
  //   this.studentService.GetStudentAlumniList(filterModel).subscribe((res: any) => {
  //     console.log("LISTDATA", res.responseData)
  //     if (res.status > 0) {
  //       console.log(res);
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;

  //     } else {
  //       this.metaData = new Metadata();
  //     }

  //   });
  // }

  checkActionPermission(actionName: any) {
    let screen = 'Manage Alumni';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  activityLog: any;
  ActivityLogTrack() {
    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Students',
        ActivityTypeId: 10,
        Description: 'Alumni list viewed by',
        ScreenName: 'Manage Alumni',
        RecordTypeId: 26,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  infoCall(teacherId: any) {
    //this.router.navigate(['/view-staff/']);
    console.log("INSIDE", teacherId)
    //this.router.navigate(["/admin/academicslearning/manage-students/view-student"], { queryParams: { id: teacherId } });
    this.router.navigate(["/admin/academicslearning/manage-students/view-profile"], { queryParams: { id: teacherId } });
  }


  deleteId: any;
  studentIdList: any;
  studentData: any;
  studentsid: any
  actionDialogPopup(templateRef: TemplateRef<any>, id: any) {
    this.dialog.open(templateRef, {
      data: id

    });
    this.deleteId = id;
    this.studentsid = [];
    if (this.selectedRow > 0) {
      for (var i = 0; i < this.selectedRow.length; i++) {
        //Legasov Made changes March-14-2022
        //this.studentsid.push(JSON.stringify(this.selectedRow[i]))
        this.studentsid.push(Number(this.selectedRow[i]))
      }
      this.studentIdList = {
        "studentID": this.studentsid
      }
    }
    console.log("INSIDEACTIONPOPUP", this.studentsid)
  }
  RevertStudent(id: any) {
    this.studentsid = [];
    this.studentIdList = [];
    this.studentsid.push(JSON.stringify(id))
    this.studentIdList = {
      "studentID": this.studentsid
    }
    this.studentService.RevertStudentAlumni(this.studentIdList).subscribe((res: any) => {

      console.log("INSIDEREVERT SINGLE", res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        window.location.reload();
        this.getTableData();

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  confirmRevert() {
    console.log("INSIDE SELECTED REVETRT STUDENT", this.studentIdList)
    this.studentService.RevertStudentAlumni(this.studentIdList).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getTableData();
        //this.dialog.closeAll();

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }


  reset() {
    this.form.reset();
    this.InitialGridCall();
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "teacherName": "",

      "employeeType": "",
      "email": "",
      "contactNumber": "",
      "columnName": "",
      "sortOrder": ""
    }
  }

  onselect(data: any) {
    debugger
    //   if(data == undefined){
    //     setTimeout(() => {
    //       this.printFlag = false;
    //     }, 1);
    //   }
    //   else{
    //   if(data.length != undefined){
    //     if(data.length == 0){
    //       setTimeout(() => {
    //         this.printFlag = false;
    //       }, 1);
    //       this.selectedData = Number(data.length);
    //       console.log("INSIDE IF",this.selectedData)
    //     }
    //     else{
    //       this.selectedRow = data;
    //       this.selectedData = Number(data.length);
    //       setTimeout(() => {
    //         this.printFlag = true;
    //       }, 1);
    //     }
    //   }
    // }
    if (data == undefined) {
      this.printFlag = false;
    } else {
      if (data.length != undefined) {
        if (data.length == 0) {
          this.printFlag = false;
          this.selectedData = data.length;
        } else {
          this.selectedRow = data;
          console.log("selected row", this.selectedRow)
          this.selectedData = data.length;
          this.printFlag = true;
        }
      }
    }
    if (this.selectedData > 0) {
      this.selectedAluminiTooltip = 'Alumini selected';
    } else {
      this.selectedAluminiTooltip = 'Please select atleast one alumini';
    }
  }
  OnlyTrueCheckBox(value: any) {
    return this.exportField.some(
      (i) => i.fieldName === value.fieldName && i.checkbox === true
    );
  }
  /** Whether the number of selected elements matches the total number of rows. */
  diplayList: any[] = [];
  coreList: any[] = [];
  coreFieldList: any[] = [];
  coreListNew: any[] = [];
  _returnList: any[] = [];
  dynamicFilterFields: any[] = [];
  filterArray: any = [];
  itemperPage: number[] = [];
  getTableData() {
    this.exportField = [];
    console.log("STUDENTMETA", this.dybamicstudentMeta)
    if (this.filterArray.length > 0) {
      this.dybamicstudentMeta.filterFieldIds = this.filterArray.map((a: any) => a.id).join(',');
      this.dybamicstudentMeta.filterFieldValue = this.filterArray.map((a: any) => a.val).join(',');
      this.dybamicstudentMeta.pageNumber = 1;
    }
    else {
      this.dybamicstudentMeta.filterFieldIds = "";
      this.dybamicstudentMeta.filterFieldValue = "";
    }
    this.studentService.getDynamicStudentAlumniList(this.dybamicstudentMeta).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("METATATA", res)
        this.metaData = res.meta;
        this.coreList = res.responseData.coreStudents;
        this.coreFieldList = res.responseData.fieldListItems;
        this.hideshowGridColumnList = res.responseData.customGridUserSettings;
        this.hideshowGridColumnList = this.hideshowGridColumnList.filter(
          (x) => x.fieldName != 'Academic Sessions'
        );
        this.coreList.map((e) => {
          let imagepath = (e.profilePhoto == null || e.profilePhoto == undefined || e.profilePhoto == '') ? '' : e.profilePhoto;
          let studentID = (e.studentID == null || e.studentID == undefined || e.studentID == '') ? '' : e.studentID;
          if (imagepath != '') {
            e.fields.push({
              "fieldName": "profilePhoto",
              "fieldID": 0,
              "fieldValue": imagepath,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          if (studentID != '') {
            e.fields.push({
              "fieldName": "studentID",
              "fieldID": 0,
              "fieldValue": studentID,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          return e;
        });

        if (this.dynamicFilterboolean === false) {
          this.dynamicFilterFields = this.coreFieldList.filter(e => (e.fieldName === 'Admission Number' || e.fieldName === 'Contact' || e.fieldName === 'First Name' || e.fieldName === 'Last Name' || e.fieldName === 'Email'));
          for (let i = 0; i < this.dynamicFilterFields.length; i++) {
            this.dynamicFilterform.addControl(this.dynamicFilterFields[i].fieldName, new FormControl(''));
          }
        }

        this.setListToDisplay();
        this.setColumnToDisplay();

      } else {
        this.dybamicstudentMeta = new DynamicStudentMeta();
      }
      for (
        var i = 0;
        i < res.responseData.customGridUserSettings.length;
        i++
      ) {
        if (
          res.responseData.customGridUserSettings[i].fieldName === 'Class'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName === 'Section'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName ===
          'Academic Sessions'
        ) {
        } else {
          this.exportField.push({
            fieldName: res.responseData.customGridUserSettings[i].fieldName,
            checkbox: false,
          });
        }
      }
      this.exportField.push({
        fieldName: 'Enrollment',
        checkbox: false,
      });
      var totalcount = res.meta.totalRecords / 500;
      if (totalcount > 1) {
        var value = Math.round(totalcount) + 1
        this.itemperPage = [10, 100, 200, 500, value * 500]
      }
      else {
        this.itemperPage = [10, 100, 200, 500]
      }
    })

  }
  SortChange(sortState: Sort) {
    this.dataSource.sort = this.sort;
  }
  displayColumnRow: any[] = [];
  displayListRow: any[] = [];
  setListToDisplay() {
    this.diplayList = [];
    for (var user = 0; user < this.coreList.length; user++) {
      let tempUserFields: any[] = [];
      for (var userField = 0; userField < this.coreList[user].fields.length; userField++) {
        tempUserFields.push(this.coreList[user].fields[userField]);
      }
      var _user = {
        fields: tempUserFields,
        transactionID: tempUserFields[0].studentID
      };
      this.diplayList.push(_user);
    }
    this.coreListNew = [];
    this.coreListNew = this.diplayList;
    this._returnList = this.setListForDrogandDrop(this.coreListNew);
    console.log("DISPlaylist", this._returnList);

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = this._returnList;
    this.dataSource.sort = this.sort;
  }

  setListForDrogandDrop(list: any[]): any[] {
    console.log("Testing", list)
    var tempList: any[] = list.map((s) => Object.assign(s));
    let returnList: any = [];
    tempList.forEach((element) => {
      let ReqModel: any = {};
      let studentID = 0;
      element.fields.forEach((ele: any) => {
        var timestamp = Date.parse(ele.fieldValue);

        if (isNaN(timestamp) == false && ele.isDate == true) {
          ReqModel[ele.fieldName] = this.datepipe.transform(
            ele.fieldValue,
            'MM/dd/yyyy'
          );
        }
        else {
          ReqModel[ele.fieldName] = ele.fieldValue ? ele.fieldValue : '—';
        }
      });
      returnList.push(ReqModel);
    });
    return returnList;
  }
  setColumnToDisplay() {
    this.displayedColumnsList = [];
    this.coreFieldList.sort(function (a, b) {
      return a.gridUserSettingOrder - b.gridUserSettingOrder;
    });
    for (var i = 0; i < this.coreFieldList.length; i++) {
      if (this.coreFieldList[i].isShowInGrid == true) {
        this.displayedColumnsList.push(this.coreFieldList[i].fieldName)
      }
    }
    console.log("AFTERDATA", this.coreFieldList)
    // this.coreFieldList.forEach((colunm, index) => {
    //   colunm.index = index;
    //   this.displayedColumnsList[index] = colunm.fieldName;
    // });
    let newArr = new Set(this.displayedColumnsList);
    this.displayedColumnsList = [];
    this.displayedColumnsList = [...newArr]
    this.displayedColumnsList.splice(0, 0, 'select');
    this.displayedColumnsList.splice(1, 0, 'profilePhoto');
    if (this.displayedColumnsList.length != 0) {
      for (var i = 0; i < this.displayedColumnsList.length; i++) {
        if (this.displayedColumnsList[i] == 'select') {
          this.displayedColumnsList.splice(i, 1);
        }
        if (this.displayedColumnsList[i] == 'Academic Sessions') {
          this.displayedColumnsList.splice(i, 1);
        }
      }
    }

    let changedColumns = (this.displayedColumnsList || []).map(obj => obj);
    changedColumns.splice(0, 0, "select");
    changedColumns.push('Actions')
    this.changeColumnsToDisplay = changedColumns;
    console.log("DISPLAYCOLUMNLIST", this.changeColumnsToDisplay)
  }
  selection = new SelectionModel<any>(true, []);
  selectedId: any[] = [];
  isAllSelected() {
    this.selectedId = [];
    if (this.selection.selected.length != 0) {
      for (var i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].studentID) {
          this.selectedId.push(JSON.stringify(this.selection.selected[i].studentID))
        }
      }
    }
    this.onselect(this.selectedId)
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.cdr.detectChanges();

    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) => this.selection.select(row));
    this.cdr.detectChanges();

    if (this.selection.selected.length == 0) {
      this.selectedId = [];
      this.onselect(this.selectedId)
    }
  }
  changee(event: any, data: any) {
    if (event.checked == false) {
      for (var i = 0; i < this.selectedId.length; i++) {
        if (this.selectedId == data.studentID) {
          this.selectedId.splice(i, 1);
        }
      }
      this.onselect(this.selectedId)
    }
  }
  checked: boolean = true;
  selectAllField() {
    this.hideshowGridColumnList.map(a => a.isShowInGrid = true);
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList()
  }
  unselectAllField() {
    this.hideshowGridColumnList.map(a => a.isShowInGrid = false);
    this.hideshowGridColumnList.find((g: any) => g.fieldName == "First Name").isShowInGrid = true;
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList()
  }
  drop(event: CdkDragDrop<string[]>) {
    this.hideshowList = {}
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    moveItemInArray(this.hideshowGridColumnList, event.previousIndex, event.currentIndex);
    this.addHideshowList()
  }
  hideshowList: any;
  onCheck() {
    this.hideshowList = {}
    this.hideshowGridColumnList.find((g: any) => g.fieldName == "First Name").isShowInGrid = true;
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList();
  }
  public handlePage(e: any) {

    this.dybamicstudentMeta.pageNumber = e.pageIndex + 1;
    this.dybamicstudentMeta.pageSize = e.pageSize;
    this.getTableData();
  }
  addHideshowList() {
    console.log("ONSAVEHIDESHOW", this.hideshowList)
    this.studentService.AddUpdateDynamicAlumniColumn(this.hideshowList).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getTableData();
        this.selectedId = [];
        this.selection.clear();
        this.onselect(this.selectedId);
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  dynamicFilter() {
    this.dynamicFilterboolean = true;
    Object.keys(this.dynamicFilterform.controls).forEach(key => {
      console.log('data ' + this.dynamicFilterform.controls[key].value);
      const value = this.dynamicFilterform.controls[key].value;
      const field = this.coreFieldList.find(e => e.fieldName === key).fieldID;
      this.setFilterValue(field, value, key);
    });
    this.getTableData();
  }

  resetFilter() {
    this.dynamicFilterform.reset();
    this.dybamicstudentMeta.filterFieldIds = "";
    this.dybamicstudentMeta.filterFieldValue = "";
    this.filterArray = [];
    this.getTableData();
  }
  setFilterValue(id: any, val: any, fieldName: any) {
    if (this.filterArray.length == 0) {
      if (val !== undefined && val !== null && val !== '') {
        this.filterArray.push({
          id: id,
          val: val,
          fieldName: fieldName
        });
      }
    }
    else {
      // field in array
      if (this.filterArray.find((m: any) => m.fieldName == fieldName) !== undefined) {
        if (val == undefined || val == null || val == '') {
          this.filterArray = this.filterArray.filter((m: any) => m.fieldName !== fieldName);
        }
        else {
          this.filterArray.find((m: any) => m.fieldName == fieldName).id = id;
          this.filterArray.find((m: any) => m.fieldName == fieldName).val = val;
          this.filterArray.find((m: any) => m.fieldName == fieldName).fieldName = fieldName;
        }
      }
      else // field not in array
      {
        if (val !== undefined && val !== null && val !== '') {
          this.filterArray.push({
            id: id,
            val: val,
            fieldName: fieldName
          });
        }
      }

    }
  }
  selectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = true;
    }
  }
  unselectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = false;
    }
  }
  export() {
    //this.dybamicstudentMeta.pageSize = 10000;
    this.dynamicAlumniMeta.pageSize = 10000;
    this.studentService.getDynamicStudentAlumniList(this.dynamicAlumniMeta).subscribe((res: any) => {
      if (res.status > 0) {
        //console.log("METATATA", res)
        this.metaData = res.meta;
        this.coreList = res.responseData.coreStudents;
        this.coreFieldList = res.responseData.fieldListItems;
        this.hideshowGridColumnList = res.responseData.customGridUserSettings;
        this.coreList.map((e) => {
          let imagepath =
            e.profilePhoto == null ||
              e.profilePhoto == undefined ||
              e.profilePhoto == ''
              ? ''
              : e.profilePhoto;
          let studentID =
            e.studentID == null ||
              e.studentID == undefined ||
              e.studentID == ''
              ? ''
              : e.studentID;
          if (imagepath != '') {
            e.fields.push({
              fieldName: 'profilePhoto',
              fieldID: 0,
              fieldValue: imagepath,
              label: 'Profile Photo',
              fieldTypeID: 0,
              fieldTypeName: '',
              fieldOrder: 0,
              isRequried: false,
              isEmail: false,
              isDate: false,
              isShowInGrid: false,
            });
          }
          if (studentID != '') {
            e.fields.push({
              fieldName: 'studentID',
              fieldID: 0,
              fieldValue: studentID,
              label: 'Profile Photo',
              fieldTypeID: 0,
              fieldTypeName: '',
              fieldOrder: 0,
              isRequried: false,
              isEmail: false,
              isDate: false,
              isShowInGrid: false,
            });
          }
          return e;
        });

        if (this.dynamicFilterboolean === false) {
          this.dynamicFilterFields = this.coreFieldList.filter(
            (e) =>
              e.fieldName === 'Admission Number' ||
              e.fieldName === 'Contact' ||
              e.fieldName === 'First Name' ||
              e.fieldName === 'Last Name' ||
              e.fieldName === 'Email'
          );
          for (let i = 0; i < this.dynamicFilterFields.length; i++) {
            this.dynamicFilterform.addControl(
              this.dynamicFilterFields[i].fieldName,
              new FormControl('')
            );
          }
        }

        this.setListToDisplay();
        this.setColumnToDisplay();
        //this.setHideandShowList();
        setTimeout(() => {
          this.getAllStudentData();
        }, 3500);
      } else {
        this.dybamicstudentMeta = new DynamicStudentMeta();
      }
      for (
        var i = 0;
        i < res.responseData.customGridUserSettings.length;
        i++
      ) {
        if (
          res.responseData.customGridUserSettings[i].fieldName === 'Class'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName === 'Section'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName ===
          'Academic Sessions'
        ) {
        } else {
          this.exportField.push({
            fieldName: res.responseData.customGridUserSettings[i].fieldName,
            checkbox: false,
          });
        }
      }
      this.exportField.push({
        fieldName: 'Enrollment',
        checkbox: false,
      });
    });
  }

  getAllStudentData() {
    this.getFilterStudentData = [];
    this.getNewFilterStudentData = [];
    this.getclassSectionModel = [];
    this.getColumnAndData = [];

    //console.log("exportField ", this.exportField);
    console.log("coreList ", this.coreList);

    var classSectionDataHighestCount = 0;
    this.coreList.forEach((coreStudentDataEle: any, index: number) => {
      if (classSectionDataHighestCount == 0) {
        classSectionDataHighestCount =
          coreStudentDataEle.classSectionModel.length;
      } else {
        if (
          classSectionDataHighestCount <
          coreStudentDataEle.classSectionModel.length
        ) {
          classSectionDataHighestCount =
            coreStudentDataEle.classSectionModel.length;
        }
      }
    });
    //console.log("classSectionDataHighestCount", classSectionDataHighestCount)

    this.coreList.forEach((element1) => {
      const myClonedObject = JSON.parse(JSON.stringify(element1));

      let count = 1;
      ////console.log("count b", count)
      element1.classSectionModel.forEach((element: any) => {
        let classSectionModelElement = element;

        // let academicSessionsObj = {
        //   fieldName: "Academic Sessions" ,
        //   fieldID: 0,
        //   fieldOrder: 0,
        //   fieldTypeID: 0,
        //   fieldTypeName: "",
        //   fieldValue: classSectionModelElement.studentEnrollmentStatus,
        //   isDate: false,
        //   isEmail: false,
        //   isRequried: false,
        //   isShowInGrid: false,
        //   label: "Academic Sessions",
        // }
        // myClonedObject.fields.push(academicSessionsObj);

        let classObj = {
          fieldName: 'ClassName',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.className,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Class',
        };
        myClonedObject.fields.push(classObj);

        let sectionObj = {
          fieldName: 'SectionName',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.sectionName,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Section',
        };
        myClonedObject.fields.push(sectionObj);

        let enrollmentObj = {
          fieldName: 'Enrollment',
          fieldID: 0,
          fieldOrder: 0,
          fieldTypeID: 0,
          fieldTypeName: '',
          fieldValue: classSectionModelElement.enrollment,
          isDate: false,
          isEmail: false,
          isRequried: false,
          isShowInGrid: false,
          label: 'Enrollment',
        };
        myClonedObject.fields.push(enrollmentObj);
      });
      count = count + 1;
      ////console.log("count a", count)
      this.getFilterStudentData.push(myClonedObject.fields);
    });

    console.log("this.exportField", this.exportField);

    // this.getColumnAndData = this.exportField.filter((x) =>
    //   this.OnlyTrueCheckBox(x)
    // );

    this.exportField.forEach(element => {
      if (element.checkbox === true) {
        this.getColumnAndData.push(element)
      }
    });
    console.log("getColumnAndData ", this.getColumnAndData)

    // var obj4: { [k: string]: any } = {};
    //   obj4['fieldName'] = 'studentID';
    //   obj4['checkbox'] = true;
    //   this.getColumnAndData.push(obj4)

    if (
      this.getColumnAndData.findIndex(
        (element: any) => element.fieldName === 'Enrollment'
      ) >= 0
    ) {
      var obj1: { [k: string]: any } = {};
      obj1['fieldName'] = 'Academic Sessions';
      obj1['checkbox'] = true;
      this.getColumnAndData.push(obj1);

      var obj2: { [k: string]: any } = {};
      obj2['fieldName'] = 'ClassName';
      obj2['checkbox'] = true;
      this.getColumnAndData.push(obj2);

      var obj3: { [k: string]: any } = {};
      obj3['fieldName'] = 'SectionName';
      obj3['checkbox'] = true;
      this.getColumnAndData.push(obj3);
    }

    this.getNewFilterStudentData = this.getFilterStudentData.map(
      (student: any) => {
        return student.filter((studentElement: any) => {
          if (
            this.getColumnAndData.findIndex(
              (element: any) => element.fieldName === studentElement.fieldName
            ) >= 0
          ) {
            //if (this.getColumnAndData.findIndex((element: any) => element.fieldName.includes(studentElement.fieldName)) >= 0) {
            return studentElement;
          }
        });
      }
    );

    //console.log("this.getFilterStudentData a", this.getFilterStudentData)
    //console.log("this.getNewFilterStudentData a", this.getNewFilterStudentData)

    var Heading: any = [];
    var HeadingTemp: any = [];

    //19-April-2022 Legasov Commented as header were getting duplicate 
    // for (let index = 0; index <= classSectionDataHighestCount; index++) {
    //   debugger
    //   this.getColumnAndData.forEach((element: any) => {
    //     if (index == 0) {
    //       HeadingTemp.push(element.fieldName);
    //     } else {
    //       if (
    //         element.fieldName != 'studentID' ||
    //         element.fieldName != 'Academic Sessions'
    //       ) {
    //         HeadingTemp.push(element.fieldName + index);
    //       }
    //     }
    //   });
    // }

    //19-April-2022 Legasov added new changes for header
    this.getColumnAndData.forEach((element: any) => {
      HeadingTemp.push(element.fieldName);
    });

    Heading.push(HeadingTemp);

    //console.log("Heading", Heading)

    var Data: any[] = [];

    var dynamicObjCount: { [n: string]: any } = {};

    this.getNewFilterStudentData.forEach((studentData: any, index: number) => {
      var obj: { [k: string]: any } = {};

      var currentcount = 0;
      studentData.forEach((item: any) => {
        if (obj.hasOwnProperty(item.fieldName)) {
          var tcount = dynamicObjCount[item.fieldName];

          obj[item.fieldName + tcount] = item.fieldValue;

          dynamicObjCount[item.fieldName] = dynamicObjCount[item.fieldName] + 1;
        } else {
          dynamicObjCount[item.fieldName] = 1;
          obj[item.fieldName] = item.fieldValue;
        }
      });
      Data.push(obj);
    });

    //console.log("Data", Data)
    //console.log("dynamicObjCount", dynamicObjCount)

    //Had to create a new workbook and then add the header
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, Data);

    // XLSX.utils.sheet_add_aoa(ws, [
    //   ["first row after data", 1],
    //   ["second row after data", 2]
    // ], { origin: -1 });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'StudentList');

    XLSX.writeFile(wb, 'ManageAlumniList.xlsx');
    this.getTableData();
  }


  studentEmailSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;

    this.dialog.open(templateRef, {});
    this.studentsid = [];
    console.log("this.selectedRow", this.selectedRow)
    for (var i = 0; i < this.selectedRow.length; i++) {
      //Legasov Made changes March-14-2022
      //this.studentsid.push(JSON.stringify(this.selectedRow[i]))
      this.studentsid.push(Number(this.selectedRow[i]))
      console.log("studentEmailSent for ", this.studentsid)
    }
    console.log("studentEmailSent ", this.studentsid)
    this.studentIdList = {
      "studentsID": this.studentsid
    }
    console.log("studentEmailSent studentIdList", this.studentIdList)
    console.log("INSIDEACTIONPOPUP", this.studentsid);

    this.GetStudentByIdData();

    this.alumnistudentEmailForm = this.formBuilder.group({
      fromEmail: [],
      subject: ['', Validators.required],
      parentEmail: [''],
      body: ['', Validators.required],
      parentCheck: false,
      senderEmail: true,
      template: []
    });
  }
  customtemplate: any[] = []
  GetCustomTemplateList() {
    this.studentService.GetCuastomEmailTemplate().subscribe((res: any) => {
      console.log("CustomeEmailTemplateList", res.responseData)
      this.customtemplate = res.responseData;
    })
  }
  selectChangeTemplate(event: any) {
    for (var i = 0; i < this.customtemplate.length; i++) {
      if (this.customtemplate[i].emailTemplateID == event.value) {
        this.alumnistudentEmailForm.patchValue({
          'body': this.customtemplate[i].emailBody
        });
      }
    }
  }
  attachmentData: any[] = [];
  uploadFile(event: any) {
    this.attachmentData = [];
    console.log("UPLOADD", event.target.files)
    for (var i = 0; i < event.target.files.length; i++) {
      this.attachmentData.push(event.target.files[i]);
    }
  }
  GetStudentByIdData() {
    console.log("GetStudentByIdData data :", this.studentIdList);

    this.studentService.GetStudentByIdData(this.studentIdList).subscribe((res: any) => {
      console.log('get student by id', res.responseData);
      this.studentData = res.responseData
    })
  }
  insMail: boolean = true;
  currentUserMail: boolean = false;
  mailFrom: any;
  instituteMail() {
    this.insMail = true;
    this.currentUserMail = false;
  }
  userMail() {
    this.insMail = false;
    this.currentUserMail = true;
  }
  submitted = false;
  EmailList: any;
  onSubmitstudentEmail() {
    this.submitted = true;
    let isFileAttached: boolean;
    if (this.alumnistudentEmailForm.invalid) {
      return;
    }
    this.EmailList = [];
    this.studentData.forEach((item: any) => {
      this.EmailList.push(item.email)

    });
    if (this.insMail) {
      this.mailFrom = "info@creocms.com.au"
    }
    else {
      this.mailFrom = this.userEmail;
    }
    if (this.boxhide == false) {
      this.studentData.forEach((item: any) => {
        this.EmailList.push(item.parentEmail)
      });
    }
    if (this.attachmentData.length > 0) {
      isFileAttached = true;
    }
    else {
      isFileAttached = false
    }
    let subject = this.alumnistudentEmailForm.controls.subject.value;
    let body = this.alumnistudentEmailForm.controls.body.value;
    let organizationID = this.orgID;
    let fromEmail = this.mailFrom;
    // this.formbind ={
    //   'toEmailMutiple':this.EmailList,
    //   'subject':this.alumnistudentEmailForm.controls.subject.value,
    //   'body':this.alumnistudentEmailForm.controls.body.value
    // }
    console.log("Formbind", fromEmail);
    this.studentService.EmailAttachment(this.EmailList, subject, body, organizationID, fromEmail, this.attachmentData, isFileAttached).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset()
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  boxhide = true;
  onparentChange() {
    if (this.alumnistudentEmailForm.controls.parentCheck.value) {
      this.boxhide = false;
    } else {
      this.boxhide = true;
      this.alumnistudentEmailForm.controls.parentEmail.setValue('');
      //this.studentEmailForm.controls.parentEmail.setErrors(null)
    }
  }

  /// for message send
  formbindmsms: any;
  studentSmsSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {
    });
    this.studentsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      //Legasov Made changes March-14-2022
      //this.studentsid.push(JSON.stringify(this.selectedRow[i]))
      this.studentsid.push(Number(this.selectedRow[i]))
    }
    this.studentIdList = {
      "studentsID": this.studentsid
    }
    console.log("INSIDEACTIONPOPUP", this.studentsid)
    this.GetStudentByIdData();
    this.studentSmsForm = this.formBuilder.group({

      parentContactNo: [''],
      message: ['', Validators.required],
      parentCheckSms: false
    }
    );

  }

  boxhidesms = true;
  onparentSmsChange() {
    if (this.studentSmsForm.controls.parentCheckSms.value) {
      this.boxhidesms = false;
    } else {
      this.boxhidesms = true;
      this.studentSmsForm.controls.parentContactNo.setValue('');
      //this.studentSmsForm.controls.parentContactNo.setErrors(null)
    }
  }
  parentContact: any
  onSubmitstudentSms() {
    this.submitted = true;

    if (this.studentSmsForm.invalid) {
      return;
    }
    this.parentContact = [];
    this.studentData.forEach((item: any) => {
      this.parentContact.push(item.contactNumber)

    });
    if (this.boxhidesms == false) {
      this.studentData.forEach((item: any) => {
        this.parentContact.push(item.parentContactNumber)

      });
    }
    this.formbind = {
      'multipleMobileNo': this.parentContact,
      'message': this.studentSmsForm.controls.message.value
    }
    console.log(this.formbind);
    this.studentService.StudentSentSms(this.formbind).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset()
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  Block() {
    //debugger;
    this.dialogService
      .confirm(`Are you sure you want block this student !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.BlockAlumniStudent();
        }
      });
  }
  AlumniStudentData: any;
  BlockAlumniStudent() {
    this.AlumniStudentData = []
    this.AlumniStudentData = {
      "alumniID": this.selectedRow,
      "status": true
    }
    console.log("BLOCKDATA", this.AlumniStudentData)
    this.studentService.ChangeAlumniStudentStatus(this.AlumniStudentData).subscribe((res: any) => {
      //debugger;
      if (res.status > 0) {

        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  Unblock() {
    this.dialogService
      .confirm(`Are you sure you want unblock this student !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.UnblockAlumniStudent();
        }
      });
  }
  UnblockAlumniStudent() {
    this.AlumniStudentData = []
    this.AlumniStudentData = {
      "alumniID": this.selectedRow,
      "status": false
    }
    console.log("UNBLOCKDATA", this.AlumniStudentData)
    this.studentService.ChangeAlumniStudentStatus(this.AlumniStudentData).subscribe((res: any) => {
      //debugger;
      if (res.status > 0) {
        this.toaster.success(res.message);
        console.log("AFTERSAVE", this.selectedData)
        //window.location.reload();
        this.getTableData();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  Delete() {
    this.dialogService
      .confirm(`Are you sure you want delete this Alumni students !`)
      .subscribe((result: any) => {
        if (result == true) {
          this.DeleteMultipleAlumniStudent();
        }
      });
  }
  DeleteMultipleAlumniStudent() {
    this.AlumniStudentData = []
    this.AlumniStudentData = {
      "alumniID": this.selectedRow
    }
    this.studentService.DeleteMultipleAlumniStudent(this.AlumniStudentData).subscribe((res: any) => {
      console.log("AFTERMOVE", res)
      if (res.status > 0) {
        this.toaster.success(res.message);
        window.location.reload();
        this.getTableData();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  deleteSinglealumni(id: any) {
    this.deleteId = {};
    this.deleteId = {
      "alumniID": [JSON.stringify(id)]
    }
    this.dialogService
      .confirm(`Are you sure you want to delete this user?`)
      .subscribe((result: any) => {
        if (result == true) {

          this.studentService.DeleteMultipleAlumniStudent(this.deleteId).subscribe((res: any) => {
            console.log("AFTERMOVE", res)
            if (res.status > 0) {
              this.toaster.success(res.message);
              window.location.reload();
              this.getTableData();
            }
            else {
              this.toaster.error(res.message);
            }
          });
        }
      });

  }

  getMasterDropdown() {
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
    });

    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData;

    });
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
    });
    this.studentEnrollmentStatusService.StudentEnrollmentStatusDropdown().subscribe((res: any) => {
      this.studentEnrollmentStatus = res.responseData;
    });
    this.subjectEnrollmentStatus.getSubjectEnrollmentStatusDropdown().subscribe((res: any) => {
      this.subjectEnrollmentStatusDropdown = res.responseData;
    });
    this.subjectService.AllSubject().subscribe((res: any) => {
      this.SubjectListDropdown = res.responseData
    })
  }


  filterSubject(event: any) {
    this.subjectService.GetSubjectBySectionId(event.value).subscribe((res: any) => {
      this.SubjectListDropdown = res.responseData
    });

    // this.studentMata.sectionID = event.value;
    // this.getUsersList(this.studentMata, '', '');
  }
  filterSection(event: any) {
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    });
    // this.studentMata.classID = event.value;
    // this.getUsersList(this.studentMata, '', '');
  }
}
export interface PeriodicElement {
  select: any
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  actions: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { select: 1, firstName: 'Virat Sharma', lastName: 'test001', email: 'test@gmail.com', contactNumber: '123456789', actions: ' ' },
];
