import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '.';
  orgDetails: any;
  orgName: any;
  resObj: any;
  constructor(private titleService: Title) {
     
    this.resObj = localStorage.getItem('instituteDetails');
    this.orgDetails = JSON.parse(this.resObj);
    if (this.orgDetails != null) {
      this.orgName = this.orgDetails.orgName;
      this.title = this.orgName;
      this.titleService.setTitle(this.title);
    }
    else {
      this.titleService.setTitle(this.title);;
    }
  }
}

