
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild, TemplateRef, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog'
import { FilterModel, Metadata, DynamicStudentMeta } from 'src/app/common/MasterModel/masterModel';
import { TeacherModel } from '../teacher-model';
import { TeachersService } from 'src/app/services/teachers.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, D, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-manage-teachers',
  templateUrl: './manage-teachers.component.html',
  styleUrls: ['./manage-teachers.component.css']
})
export class ManageTeachersComponent implements AfterViewInit {
  passMatch: boolean;
  hide = true;
  hide1 = true;
  updateCreditialForm: FormGroup;
  isTeacherUser: boolean = false;
  @ViewChild('loginPopup', { read: TemplateRef })
  updateCreditialTemplate: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("fileInput") fileInput: any;
  @ViewChild('textEditorValue')
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  requestpages: any;
  form: FormGroup;
  metaData: any;
  permissions: any;
  private filterModel: FilterModel;
  usersData: TeacherModel[];
  classToggled = false;
  columnForm: FormGroup;
  subjectAllocationForm: FormGroup;
  dynamicFilterform: FormGroup;
  dynamicFilterboolean: boolean = false;
  academicSessions: any;
  SubjectListDropdown: any[] = [];
  users: any;
  userName: any;
  orgID: any;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  columnsToDisplay: any;
  user: any
  userId: any;
  selectedRow: any = [];
  selectedData: number;
  printFlag: boolean;
  teacherEmailForm: FormGroup;
  subjectEnrollmentForm: FormGroup;
  teacherSmsForm: FormGroup;
  displayedColumns: Array<any> = [
    { displayName: 'Profile Picture', key: 'profilePicture', class: '', width: '10%' },
    { displayName: 'Teacher Name', key: 'teacherName', isSort: true, class: '', width: '20%' },
    { displayName: 'Teacher Id', key: 'teacherNumber', isSort: true, class: '', width: '20%' },
    { displayName: 'EMAIL', key: 'email', isSort: true, class: '', width: '10%' },
    { displayName: 'PHONE NUMBER', key: 'contactNumber', isSort: true, class: '', width: '10%' },
    { displayName: 'Employee Type', key: 'employeeTypeName', isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Attachment', key: 'Attachment', class: 'fas fa-paperclip' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  subjectPrimaryCtrl = new FormControl();
  subjectSecondaryCtrl = new FormControl();
  filteredSubjects: Observable<any[]>;
  filteredSecSubjects: Observable<any[]>;
  subjects: any[] = [];
  secSubjects: any[] = [];
  subjectList: any[] = [];
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  cities: any[] = [];
  userEmail: any;
  selectedItems: any[] = [];
  dropdownSettings: any = {};
  exportField: any = [];
  hideshowGridColumnList: any[] = [];
  displayedColumnsList: string[] = [];
  changeColumnsToDisplay: any;
  spinnerFlag: boolean;
  bloodGroups: any;
  genders: any;
  url: any;
  columnListForExport: any = [];
  getFilterStudentData: any = [];
  getNewFilterStudentData: any = [];
  getclassSectionModel: any;
  getColumnAndData: any;
  private dybamicTeacherMeta: DynamicStudentMeta;
  @ViewChild('subjectInput') subjectInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialog: MatDialog,
    private teacherService: TeachersService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private studentService: StudentService,
    private subjectService: SubjectService,
    public datepipe: DatePipe,
    private masterService: MasterService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService, private teachService: TeacherService, private datePipe: DatePipe
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.userName = currentUser;
    }
    this.filterModel = new FilterModel();
    this.usersData = new Array<TeacherModel>();
    this.dybamicTeacherMeta = new DynamicStudentMeta();
    this.dynamicFilterform = new FormGroup({});
    const parsedUrl = new URL(window.location.href);
    this.url = parsedUrl.origin;
    console.log("URLL", this.url);
  }

  addFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.teacherService
        .ImportTeachers(fileToUpload)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message, 'Success');
            this.getUsersList(this.filterModel, '', '');
            this.InitialGridCall()
          } else {
            this.toaster.error(res.message, 'Error');
          }
        });
    }
  }
  checkActionPermission(actionName: any) {
    let screen = 'Manage Teachers';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID');
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Manage Teachers');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.userName.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.spinnerFlag = true;
    this.selectedData = 0;
    this.printFlag = false
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      teacherName: [''],
      teacherNumber: [''],
      employeeTypeName: [''],
      contactNumber: [''],
      email: [''],
      TeacherID: ['']
    });
    this.ActivityLogTrack();
    this.getUsersList(this.filterModel, '', '');
    this.InitialGridCall();
    this.GetBloodGroupList();
    this.GetEmployeeTypeList();
    //this.GetGenderList();
    //this.GetEmployeeTypeList()
    // this.AdminUserList();


    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    this.userId = currentUser.userId;
    this.userEmail = currentUser.email;
    this.orgID = currentUser.orgID;
    this.columnForm = this.formBuilder.group({
      teacherName: true,
      profilepic: true,
      teacherID: true,
      employeeType: true,
      email: true,
      contactNumber: true,
      userId: ''
    })
    this.subjectAllocationForm = this.formBuilder.group({
      primarysubjectID: [this.selectedItems],
      secondarysubjectID: [this.selectedItems],
      academicSessionID: ['', Validators.required],
    }
    );
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'subjectID',
      textField: 'subjectClassSection',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    this.getMasterDropdown();
    this.getTableData();

    this.updateCreditialForm = this.formBuilder.group(
      {
        userName: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        logoutFromAll: [false],
        sendUpdateNotification: [false],
        teacherId: [''],
        userId: ['']
      },
      { validators: this.passwordMatcher }
    );


  }
  passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('password');
    const confirmPasswordControl = c.get('confirmPassword');
    if (passwordControl?.value === confirmPasswordControl?.value) {
      return null;
    }

    return { match: true };
  }

  resetCall(id: any) {
    var TeacherData = {
      "teachersID": id
    }
    debugger
    this.dialog.open(this.updateCreditialTemplate);
    debugger
    this.teacherService.GetTeacherDetailsById(id).subscribe((res: any) => {
      debugger
      console.log(res)
      this.updateCreditialForm.patchValue({

        userName: res.responseData[0].email,
        teacherId: res.responseData[0].teacherID,
        userId: res.responseData[0].userId,
        logoutFromAll: false,
        sendUpdateNotification: false,
      });
    });
  }
  onMatch() {
    this.passMatch = true;
    if (
      this.updateCreditialForm.value.password ===
      this.updateCreditialForm.value.confirmPassword
    ) {
      this.passMatch = false;
    } else {
      this.passMatch = true;
    }
  }
  updateCreditialObj: any;
  updateCreditial() {
    if (this.updateCreditialForm.value.password != this.updateCreditialForm.value.confirmPassword) {
      return;
    }
    //console.log("FINALDATA", this.updateCreditialForm.value)
    this.updateCreditialObj = {
      userID: this.updateCreditialForm.value.userId,
      password: this.updateCreditialForm.value.password,
      isDevicedLoggedOut: this.updateCreditialForm.value.logoutFromAll,
      isSendEmail: this.updateCreditialForm.value.sendUpdateNotification,
      teachersID: this.updateCreditialForm.value.teacherId,
    };
    console.log("UpdateData", this.updateCreditialObj)
    this.teacherService
      .updateTeacherCreditial(this.updateCreditialObj)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          this.dialog.closeAll();
          this.updateCreditialForm.reset();
        } else {
          this.toaster.error(res.message, 'Error');
          // this.updateCreditialForm.reset();
        }
      });
  }


  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    //console.log('onSelectAll', items);
  }
  getMasterDropdown() {
    this.teacherService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
      console.log("sessions", this.academicSessions);
    });
    this.teacherService.getSubjectwithClassandSection().subscribe((res: any) => {
      this.SubjectListDropdown = res.responseData;
      console.log("all subject", this.SubjectListDropdown)
    });
    // this.subjectService.AllSubject().subscribe((res: any) => {
    //   this.subjectList = res.responseData;
    //   
    // })
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getUsersList(this.filterModel, '', '')
    //this.GetEmployeeTypeList()
  }
  finalData: any[] = [];
  subjectAllocationObj: any;
  onSubmitSubjectAllocation() {
    this.finalData = [];
    var primary: any = this.subjectAllocationForm.value.primarysubjectID == null ? [] : this.subjectAllocationForm.value.primarysubjectID;
    var secondary: any = this.subjectAllocationForm.value.secondarysubjectID == null ? [] : this.subjectAllocationForm.value.secondarysubjectID;
    console.log("Testt", primary, secondary)
    if (primary.length == 0 && secondary.length == 0) {
      this.toaster.error('You can select a particular Subjects either as primary teacher or as secondary teacher.')
      return;
    }
    for (var i = 0; i < primary.length; i++) {
      for (var j = 0; j < secondary.length; j++) {
        if (primary[i].subjectID == secondary[j].subjectID) {
          console.log("INSIDEFIf", primary[i].subjectID, secondary[i].subjectID)
          this.toaster.error('You can select a particular Subjects either as primary teacher or as secondary teacher.')
          return;
        }
      }
    }
    if (primary.length > 0 || primary != null) {
      console.log("Primary", primary)
      for (var i = 0; i < primary.length; i++) {
        this.finalData.push({
          subjectID: primary[i].subjectID,
          teachersSubDetailList: this.getPrimaryTeacher()
        })
      }
    }
    if (secondary.length > 0 || secondary != null) {
      console.log("secondary", secondary)
      for (var j = 0; j < secondary.length; j++) {
        this.finalData.push({
          subjectID: secondary[j].subjectID,
          teachersSubDetailList: this.getSecondaryTeacher()
        })
      }
    }
    this.subjectAllocationObj = {
      "academicSessionID": this.subjectAllocationForm.value.academicSessionID,
      "subjectTeachersList": this.finalData
    }
    console.log("FINALEDATA", this.subjectAllocationObj)
    this.teacherService.subjectAllocationAPI(this.subjectAllocationObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        window.location.reload();
      } else {
        this.toaster.error(res.message, 'Error');
      }
    })
  }
  getPrimaryTeacher() {
    var PrimaryList: any[] = []
    for (var j = 0; j < this.selectedRow.length; j++) {
      PrimaryList.push({
        teacherID: this.selectedRow[j],
        isTeacherPrimary: true
      })
    }
    return PrimaryList
  }
  getSecondaryTeacher() {
    var SecondaryList: any[] = []
    for (var j = 0; j < this.selectedRow.length; j++) {
      SecondaryList.push({
        teacherID: this.selectedRow[j],
        isTeacherPrimary: false
      })
    }
    return SecondaryList
  }

  activityLog: any;
  ActivityLogTrack() {

    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Teachers & Staff/Admin',
        ActivityTypeId: 10,
        Description: 'Admin list record viewed by',
        ScreenName: 'Manage Teachers',
        RecordTypeId: 2,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }



  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "teacherName": this.form.controls.teacherName.value,
      "contactNumber": this.form.controls.contactNumber.value,
      "employeeType": this.form.controls.employeeTypeName.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": "",
      "TeacherID": this.form.controls.TeacherID.value
    }
    console.log(this.requestpages);
    this.getUsersList(this.requestpages, '', '')
    //this.GetEmployeeTypeList();
    //this.AdminUserList();
  }
  //metaData: any;
  // AdminUserList() {
  //   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
  //     if(res.status > 0){
  //       console.log(res);
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;

  //     }else{
  //       this.metaData = new Metadata();
  //     }

  //   });
  // }

  // onTableActionClick(actionObj?: any) {
  //   const id = actionObj.data && actionObj.data.teacherID;
  //   switch ((actionObj.action || '').toUpperCase()) {
  //     case 'EDIT':
  //       this.editCall(id);
  //       break;
  //     case 'DELETE':
  //       {

  //         this.dialogService
  //           .confirm(`Are you sure to delete this?`)
  //           .subscribe((result: any) => {
  //             if (result == true) {
  //               this.DeleteTeacher(id);
  //             }
  //           });
  //       }
  //       break;
  //     case 'ATTACHMENT':
  //       this.singleattachment(id);
  //       break;
  //     case 'DETAILS':
  //       this.infoCall(id);
  //       break;
  //     default:
  //       break;
  //   }
  // }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getUsersList(this.filterModel, '', '');
    }
  }
  getUsersList(filterModel: any, tags: any, roleIds: any) {
    this.teacherService.GetTeacherList(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("Teacher", res);
        this.usersData = res.responseData;
        this.metaData = res.meta;

      } else {
        this.metaData = new Metadata();
      }

    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  infoCall(teacherId: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/admin/academicslearning/manage-teachers/view-teacher"], { queryParams: { id: teacherId } });
  }
  editCall(teacherId: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/admin/academicslearning/manage-teachers/add-teachers"], { queryParams: { id: teacherId } });
    // "../manage-teachers/view-profile"
    // routerLink ="../manage-teachers/add-teacher"
  }
  singleattachment(teacherId: any) {
    this.router.navigate(["/admin/academicslearning/manage-teacher-attachment/add-teacher-attachment"], { queryParams: { id: teacherId } });
  }
  attachment() {
    this.router.navigate(["/admin/academicslearning/manage-teacher-attachment/add-teacher-attachment"], { queryParams: { data: this.selectedRow } });
  }
  employeeTypes: any
  // GetEmployeeTypeList() {
  //   this.masterService.GetEmployeeTypeDropdown().subscribe((res: any) => {
  //     this.employeeTypes = res.responseData;

  //   })
  // }

  deleteId: any;
  actionDialogPopup(templateRef: TemplateRef<any>, id: any) {
    this.subjectAllocationForm.reset();
    this.dialog.open(templateRef, {
      data: id

    });
    this.deleteId = id;
  }

  ////
  teachersIDList: any;
  teacherIdList1: any[] = []
  teacherid: any;
  actionDialogPopup1(templateRef: TemplateRef<any>) {
    //debugger;
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef, {});
    this.teacherid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.teacherid.push(this.selectedRow[i]);
    }
    this.teachersIDList =
    {
      teacherID: this.teacherid,
    };
    this.teacherIdList1 = [{
      teacherID1: this.teacherid
    }]
    this.GetTeacherByIdData();
  }

  /////




  importDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
    });

  }

  DeleteTeacher(id: any) {
    this.teacherData = [];
    this.teacherData = {
      "teacherIds": [id]
    }
    this.dialogService.confirm(`Are you sure to delete this teacher[s]?`).subscribe((result: any) => {
      if (result == true) {
        this.teacherService.deleteDynamicTeacher(this.teacherData).subscribe((res: any) => {

          console.log(res);
          if (res.status > 0) {
            this.toaster.success(res.message, 'Success');
            window.location.reload();
            this.getTableData();
          } else {
            this.toaster.error(res.message, 'Error');
          }
        });
      }
    });

  }

  onselect(data: any) {
    if (data == undefined) {
      this.printFlag = false;
    }
    else {
      if (data.length != undefined) {
        if (data.length == 0) {
          this.printFlag = false;
          this.selectedData = data.length;
        }
        else {
          this.selectedRow = data;
          this.selectedData = data.length;
          this.printFlag = true;
        }
      }
    }
  }
  GetBloodGroupList() {
    this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }
  GetEmployeeTypeList() {
    this.masterService.GetEmployeeTypeDropdown().subscribe((res: any) => {
      this.employeeTypes = res.responseData;
    })
  }
  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getUsersList(this.requestpages, '', '')
  }

  resetEmailD() {
    this.teacherEmailForm.reset();
    this.customboxhideEmail = false;
  }
  resetSMSForm() {
    this.teacherSmsForm.reset();
    this.customboxhideSMS = false;
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "teacherName": "",
      "TeacherID": "",
      "employeeType": "",
      "email": "",
      "contactNumber": "",
      "columnName": "",
      "sortOrder": ""
    }
  }




  /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  removeEmployeeType() {
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.columnForm.value.employeeType == false) {
        if (this.displayedColumns[i].key == 'employeeTypeName')
          this.displayedColumns.splice(i, 1);
      }
    }
  }
  formbind: any;
  updateTextInPopUp: boolean;
  teachersid: any;
  teacherIdList: any;
  teacherData: any;
  teacherEmailSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {
    });
    this.teachersid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.teachersid.push(this.selectedRow[i])
    }
    this.teacherIdList = {
      "teacherID": this.teachersid
    }
    console.log("INSIDEACTIONPOPUP", this.teachersid)
    this.GetTeacherByIdData();
    this.teacherEmailForm = this.formBuilder.group({
      fromEmail: [],
      subject: ['', Validators.required],
      parentEmail: [''],
      body: ['', Validators.required],
      customEmail: [''],
      template: [''],
      attachment: [''],
      customCheck: false,
      senderEmail: true
    }
    );
    this.GetCustomTemplateList();
  }



  customtemplate: any[] = []
  GetCustomTemplateList() {
    this.studentService.GetCuastomEmailTemplate().subscribe((res: any) => {
      console.log("CustomeEmailTemplateList", res.responseData)
      this.customtemplate = res.responseData;
    })
  }

  selectedTemplate: any
  EmailList: any;
  selectChangeTemplate(event: any) {
    //this.selectedTemplate = event.target.value;
    this.studentService.GetEmailTemplateById(this.teacherEmailForm.controls.template.value).subscribe((tempRes: any) => {
      this.teacherEmailForm.patchValue({
        'body': tempRes["responseData"].emailBody
      });
    });
  }

  GetTeacherByIdData() {
    //debugger;
    console.log("SELECTED TEACHER", this.teachersIDList)
    this.studentService.GetMultipleTeacherData(this.teachersIDList).subscribe((res: any) => {
      console.log('get student by id', res.responseData);
      this.teacherData = res.responseData
    })
  }



  insMail: boolean = true;
  currentUserMail: boolean = false;
  mailFrom: any;
  instituteMail() {
    this.insMail = true;
    this.currentUserMail = false;
  }
  userMail() {
    this.insMail = false;
    this.currentUserMail = true;
  }
  attachmentData: any[] = [];
  uploadFile(event: any) {
    this.attachmentData = [];
    console.log("UPLOADD", event.target.files)
    for (var i = 0; i < event.target.files.length; i++) {
      this.attachmentData.push(event.target.files[i]);
    }
  }
  submitted = false;
  onSubmitEmail() {
    this.submitted = true;

    if (this.teacherEmailForm.invalid) {
      return;
    }
    let arr = [];
    let customteacher: any[] = [];
    this.teacherData.forEach((item: any) => {
      arr.push(item.email)

    });

    if (this.teacherEmailForm.controls.parentEmail.value != '') {
      arr.push(this.teacherEmailForm.controls.parentEmail.value)
    }

    if (this.customboxhideEmail == true) {
      let customEmail = this.teacherEmailForm.controls.customEmail.value as string;
      if (customEmail) {
        let customlist = customEmail.split(',');
        if (customlist.length > 0) {
          customlist.forEach(element => {
            console.log(element);
            arr.push(element);
          });
        }
      }
    }


    if (this.insMail) {
      this.mailFrom = "info@creocms.com.au"
    }
    else {
      this.mailFrom = this.userEmail;
    }
    console.log("EMAILLLLL", arr)
    // this.formbind = {
    //   'toEmailMutiple': arr,
    //   'customteacher': customteacher,
    //   'subject': this.teacherEmailForm.controls.subject.value,
    //   'body': this.teacherEmailForm.controls.body.value,
    //   'FromEmail': this.mailFrom,
    // }
    let isFileAttached: boolean;
    if (this.attachmentData.length > 0) {
      isFileAttached = true;
    }
    else {
      isFileAttached = false
    }
    let subject = this.teacherEmailForm.controls.subject.value;
    let body = this.teacherEmailForm.controls.body.value;
    let organizationID = this.orgID;
    let fromEmail = this.mailFrom;
    this.studentService.EmailAttachment(arr, subject, body, organizationID, fromEmail, this.attachmentData, isFileAttached).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reloadCurrentRoute();
        this.resetEmailD();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  /// for message send
  formbindmsms: any;
  teacherSmsSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {
    });
    this.teachersid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.teachersid.push(this.selectedRow[i])
    }
    this.teacherIdList = {
      "teacherID": this.teachersid
    }
    console.log("INSIDEACTIONPOPUP", this.teachersid)
    this.GetTeacherByIdData();
    this.teacherSmsForm = this.formBuilder.group({

      parentContactNo: [''],
      message: ['', Validators.required],
      parentCheckSms: false,
      customSMS: []
    }
    );

  }

  boxhidesms = true;
  onparentSmsChange() {
    if (this.teacherSmsForm.controls.parentCheckSms.value) {
      this.boxhidesms = false;
    } else {
      this.boxhidesms = true;
      this.teacherSmsForm.controls.parentContactNo.setValue('');
      //this.studentSmsForm.controls.parentContactNo.setErrors(null)
    }
  }

  onSubmitSms() {
    this.submitted = true;

    if (this.teacherSmsForm.invalid) {
      return;
    }
    let arr = [];
    this.teacherData.forEach((item: any) => {
      arr.push(item.contactNumber)

    });
    if (this.teacherSmsForm.controls.parentContactNo.value != '') {
      arr.push(this.teacherSmsForm.controls.parentContactNo.value)
    }
    if (this.customboxhideSMS == true) {

      let cust = this.teacherSmsForm.controls.customSMS.value as string;
      if (cust) {
        let custlist = cust.split(',');
        if (custlist.length > 0) {
          custlist.forEach(element => {
            arr.push(element);

          });
        }

      }
    }


    this.formbind = {
      'multipleMobileNo': arr,
      'message': this.teacherSmsForm.controls.message.value
    }
    console.log(this.formbind);
    this.studentService.StudentSentSms(this.formbind).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  // email Invite to
  teacherEmailSentInvite() {
    this.teachersid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.teachersid.push(this.selectedRow[i])
    }
    this.teacherIdList = {
      "teacherID": this.teachersid
    }
    this.GetTeacherByIdData();
    this.dialogService
      .confirm(`Are you sure you want to send invite to login?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.emailInviteCall();
        }
      });
  }

  emailInviteCall() {

    let arrx: any = [];
    this.teacherData.forEach((item: any) => {
      arrx.push(item.email)
    });
    let msg = "<p><span>Hello,<br>Please click to login site <a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'>[orgUrl]</a></span></p><p><span><a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'><br></a></span><br></p><p><span>thank you</span></p><p><span>team support</span></p><p><span><br></span></p><p><span><br></span><br></p>";
    let data = {
      'toEmailMutiple': arrx,
      'subject': 'Invite to Login',
      'body': msg
    }
    console.log("EMAILINVITE", data);
    this.studentService.CommonEmailSenderNotification(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  /// end

  /// invite to SMS
  teacherSmsSentInvite() {
    this.teachersid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.teachersid.push(this.selectedRow[i])
    }
    this.teacherIdList = {
      "teacherID": this.teachersid
    }
    this.GetTeacherByIdData();
    this.dialogService
      .confirm(`Are you sure you want to send invite to login?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.smsInviteCall();
        }
      });

  }

  smsInviteCall() {
    let arrd: any = [];
    this.teacherData.forEach((item: any) => {
      arrd.push(item.contactNumber)
    });
    let data = {
      'multipleMobileNo': arrd,
      'message': "Hello,\n Please click to login site " + this.url + "/ \n thank you \n team support"
    }
    console.log(data);
    this.studentService.StudentSentSms(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  DeleteMultipleStudent() {
    this.teachersid = [];
    this.teacherData = [];
    this.teacherData = {
      "teacherIds": this.selectedRow
    }
    console.log("Deleteteacherid", this.teacherData)
    this.dialogService
      .confirm(`Are you sure you want delete this teachers!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.teacherService.deleteDynamicTeacher(this.teacherData).subscribe((res: any) => {
            console.log("AFTERMOVE", res)
            if (res.status > 0) {
              this.toaster.success(res.message);
              this.reset();
              this.reloadCurrentRoute();
            }
            else {
              this.toaster.error(res.message);
            }
          });
        }
      });
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    console.log("INSIDEADD", event)
    // Add our fruit
    if (value) {
      this.subjects.push(value);
    }

    // Clear the input value
    //event.chipInput!.clear();

    this.subjectPrimaryCtrl.setValue(null);
  }
  remove(subject: string, type: string): void {
    const index = this.subjects.indexOf(subject);
    const secIndex = this.secSubjects.indexOf(subject);

    if (index >= 0 || secIndex >= 0) {
      type == 'primary' ? this.subjects.splice(index, 1) : this.secSubjects.splice(secIndex, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, to: string): void {
    let sub = event.option.value;

    if (this.subjects.length > 0 && this.subjects.filter((x: any) => x.subjectID == sub.subjectID).length > 0 && to == 'primary') {
      this.toaster.error('already exists in primary');
    }
    else if (to == 'primary') {
      if (this.secSubjects.filter((x: any) => x.subjectID == sub.subjectID).length > 0) {
        this.toaster.error('already exists in secondary');
      }
      else {
        this.subjects.push(sub);
      }
    }

    if (this.secSubjects.length > 0 && this.secSubjects.filter((x: any) => x.subjectID == sub.subjectID).length > 0 && to == 'secondary') {
      this.toaster.error('already exists in secondary');
    }
    else if (to == 'secondary') {
      if (this.subjects.filter((x: any) => x.subjectID == sub.subjectID).length > 0) {
        this.toaster.error('already exists in primary');
      }
      else {
        this.secSubjects.push(sub);
      }
    }


    this.subjectInput.nativeElement.value = '';
    this.subjectPrimaryCtrl.setValue(null);
  }
  customboxhideSMS = false;
  oncustomChangeSMS() {

    this.customboxhideSMS = !this.customboxhideSMS;

  }
  customboxhideEmail = false;
  oncustomChangeEmail() {

    this.customboxhideEmail = !this.customboxhideEmail;

  }

  diplayList: any[] = [];
  coreList: any[] = [];
  coreList1: any[] = [];
  coreFieldList: any[] = [];
  coreListNew: any[] = [];
  _returnList: any[] = [];
  dynamicFilterFields: any[] = [];
  itemperPage: number[] = [];

  getTableData() {
    this.exportField = [];
    if (this.filterArray.length > 0) {
      this.dybamicTeacherMeta.filterFieldIds = this.filterArray.map((a: any) => a.id).join(',');
      this.dybamicTeacherMeta.filterFieldValue = this.filterArray.map((a: any) => a.val).join(',');
      this.dybamicTeacherMeta.pageNumber = 1;
    }
    else {
      this.dybamicTeacherMeta.filterFieldIds = "";
      this.dybamicTeacherMeta.filterFieldValue = "";
    }
    console.log("GETTABLEDATA", this.dybamicTeacherMeta)
    this.teacherService.getTeacherTableList(this.dybamicTeacherMeta).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("METATATA test data", res)
        this.selectedId = [];
        this.selection.clear();
        this.onselect(this.selectedId);
        this.spinnerFlag = false;
        this.metaData = res.meta;
        this.coreList = res.responseData.coreTeachers;
        this.coreFieldList = res.responseData.fieldListItems;
        this.hideshowGridColumnList = res.responseData.customGridUserSettings;
        this.coreList.map((e) => {
          let imagepath = (e.profilePhoto == null || e.profilePhoto == undefined || e.profilePhoto == '') ? '' : e.profilePhoto;
          let teacherID = (e.teacherID == null || e.teacherID == undefined || e.teacherID == '') ? '' : e.teacherID;
          if (imagepath != '') {
            e.fields.push({
              "fieldName": "profilePhoto",
              "fieldID": 0,
              "fieldValue": imagepath,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          if (teacherID != '') {
            e.fields.push({
              "fieldName": "teacherID",
              "fieldID": 0,
              "fieldValue": teacherID,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          return e;
        });

        if (this.dynamicFilterboolean === false) {
          this.dynamicFilterFields = this.coreFieldList.filter(e =>
          (e.fieldName === 'Employee Type' ||
            e.fieldName === 'Contact' ||
            e.fieldName === 'First Name' ||
            e.fieldName === 'Last Name' ||
            e.fieldName === 'Teacher Code' ||
            e.fieldName === 'Blood Group'));
          for (let i = 0; i < this.dynamicFilterFields.length; i++) {
            this.dynamicFilterform.addControl(this.dynamicFilterFields[i].fieldName, new FormControl(''));
          }
          console.log("FIELD", this.dynamicFilterFields)
        }
        this.setListToDisplay();
        this.setColumnToDisplay();
        this.setHideandShowList();
      } else {
        this.dybamicTeacherMeta = new DynamicStudentMeta();
      }

      for (
        var i = 0;
        i < res.responseData.customGridUserSettings.length;
        i++
      ) {
        if (
          res.responseData.customGridUserSettings[i].fieldName === 'Class'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName === 'Section'
        ) {
        } else if (
          res.responseData.customGridUserSettings[i].fieldName ===
          'Academic Sessions'
        ) {
        } else {
          this.exportField.push({
            fieldName: res.responseData.customGridUserSettings[i].fieldName,
            checkbox: false,
          });
        }
      }
      // this.exportField.push({
      //  // fieldName: 'Enrollment',
      //  // checkbox: false,
      // });

      var totalcount = res.meta.totalRecords;
      if (totalcount > 1) {
        var value = Math.round(totalcount) + 1
        this.itemperPage = [10, 100, 150]
      }
      else {
        this.itemperPage = [10, 100, 150]
      }
    });
  }



  export() {
    this.teacherService.getTeacherTableList(this.dybamicTeacherMeta).subscribe((res: any) => {
      if (res.status > 0) {
        debugger
        console.log("METATATA test data", res)
        //this.spinnerFlag = false;
        this.metaData = res.meta;
        this.coreList = res.responseData.coreTeachers;
        debugger
        if (this.teacherIdList1[0].teacherID1.length > 0) {
          this.coreList1 = res.responseData.coreTeachers.filter((x: any) => this.teacherIdList1[0].teacherID1.some((z: any) => z == x.teacherID));
        }
        else {
          this.coreList1 = this.coreList;
        }
        this.coreFieldList = res.responseData.fieldListItems;
        this.hideshowGridColumnList = res.responseData.customGridUserSettings;
        this.coreList1.map((e) => {
          let imagepath = (e.profilePhoto == null || e.profilePhoto == undefined || e.profilePhoto == '') ? '' : e.profilePhoto;
          let teacherID = (e.teacherID == null || e.teacherID == undefined || e.teacherID == '') ? '' : e.teacherID;
          if (imagepath != '') {
            e.fields.push({
              "fieldName": "profilePhoto",
              "fieldID": 0,
              "fieldValue": imagepath,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          if (teacherID != '') {
            e.fields.push({
              "fieldName": "teacherID",
              "fieldID": 0,
              "fieldValue": teacherID,
              "label": "Profile Photo",
              "fieldTypeID": 0,
              "fieldTypeName": "",
              "fieldOrder": 0,
              "isRequried": false,
              "isEmail": false,
              "isDate": false,
              "isShowInGrid": false
            })
          }
          return e;
        });

        if (this.dynamicFilterboolean === false) {
          this.dynamicFilterFields = this.coreFieldList.filter(e =>
          (e.fieldName === 'Employee Type' ||
            e.fieldName === 'Contact' ||
            e.fieldName === 'First Name' ||
            e.fieldName === 'Last Name' ||
            e.fieldName === 'Teacher Code' ||
            e.fieldName === 'Blood Group'));
          for (let i = 0; i < this.dynamicFilterFields.length; i++) {
            this.dynamicFilterform.addControl(this.dynamicFilterFields[i].fieldName, new FormControl(''));
          }
        }
        this.setListToDisplay();
        this.setColumnToDisplay();
        this.setHideandShowList();

        setTimeout(() => {
          this.getTeachersData();
        }, 3500);



      } else {
        this.dybamicTeacherMeta = new DynamicStudentMeta();
      }


      var totalcount = res.meta.totalRecords / 500;
      if (totalcount > 1) {
        var value = Math.round(totalcount) + 1
        this.itemperPage = [2, 10, 100, 200, 500, value * 500]
      }
      else {
        this.itemperPage = [2, 10, 100, 200, 500]
      }
    });
  }


  getTeachersData() {
    //debugger;

    this.getFilterStudentData = [];
    this.getNewFilterStudentData = [];
    this.getclassSectionModel = [];
    this.getColumnAndData = [];


    var classSectionDataHighestCount = 0;
    this.coreList1.forEach((coreStudentDataEle: any, index: number) => {

    });

    this.coreList1.forEach((element1) => {
      const myClonedObject = JSON.parse(JSON.stringify(element1));

      let count = 1;
      element1.fields.forEach((element: any) => {
        let classSectionModelElement = element;

      });
      count = count + 1;
      debugger
      this.getFilterStudentData.push(myClonedObject.fields);
    });



    this.exportField.forEach((element: any) => {
      if (element.checkbox === true) {
        this.getColumnAndData.push(element)
      }
    });

    if (
      this.getColumnAndData.findIndex(
        (element: any) => element.fieldName === 'Enrollment'
      ) >= 0
    ) {
      var obj1: { [k: string]: any } = {};
      obj1['fieldName'] = 'Employee Type';
      obj1['checkbox'] = true;
      this.getColumnAndData.push(obj1);

    }

    this.getNewFilterStudentData = this.getFilterStudentData.map(
      (student: any) => {
        return student.filter((studentElement: any) => {
          if (
            this.getColumnAndData.findIndex(
              (element: any) => element.fieldName === studentElement.fieldName
            ) >= 0
          ) {
            //if (this.getColumnAndData.findIndex((element: any) => element.fieldName.includes(studentElement.fieldName)) >= 0) {
            return studentElement;
          }
        });
      }
    );


    var Heading: any = [];
    var HeadingTemp: any = [];

    //19-April-2022 Legasov Commented as header were getting duplicate 
    // for (let index = 0; index <= classSectionDataHighestCount; index++) {
    //   debugger
    //   this.getColumnAndData.forEach((element: any) => {
    //     if (index == 0) {
    //       HeadingTemp.push(element.fieldName);
    //     } else {
    //       if (
    //         element.fieldName != 'studentID' ||
    //         element.fieldName != 'Academic Sessions'
    //       ) {
    //         HeadingTemp.push(element.fieldName + index);
    //       }
    //     }
    //   });
    // }

    //19-April-2022 Legasov added new changes for header
    this.getColumnAndData.forEach((element: any) => {
      HeadingTemp.push(element.fieldName);
    });

    Heading.push(HeadingTemp);

    //console.log("Heading", Heading)

    var Data: any[] = [];

    var dynamicObjCount: { [n: string]: any } = {};
    this.teacherIdList1[0].teacherID1 = [];
    this.teachersIDList = [];
    this.selectedId = [];
    this.selection.clear();
    this.selectedRow = [];
    this.onselect(this.selectedId);


    this.getNewFilterStudentData.forEach((teacherData: any, index: number) => {
      var obj: { [k: string]: any } = {};

      var currentcount = 0;
      teacherData.forEach((item: any) => {
        if (obj.hasOwnProperty(item.fieldName)) {
          var tcount = dynamicObjCount[item.fieldName];
          if (item.fieldName == 'Date of Birth') {
            item.fieldValue = this.datePipe.transform(String(item.fieldValue), 'MM-dd-yyyy');
          }
          obj[item.fieldName + tcount] = item.fieldValue;

          dynamicObjCount[item.fieldName] = dynamicObjCount[item.fieldName] + 1;
        } else {
          if (item.fieldName == 'Date of Birth') {
            item.fieldValue = this.datePipe.transform(String(item.fieldValue), 'MM-dd-yyyy');
          }
          dynamicObjCount[item.fieldName] = 1;
          obj[item.fieldName] = item.fieldValue;
        }
      });
      Data.push(obj);
    });

    //console.log("Data", Data)
    //console.log("dynamicObjCount", dynamicObjCount)

    //Had to create a new workbook and then add the header
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, Data);

    // XLSX.utils.sheet_add_aoa(ws, [
    //   ["first row after data", 1],
    //   ["second row after data", 2]
    // ], { origin: -1 });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'TeacherList');

    XLSX.writeFile(wb, 'ManageTeacherList.xlsx');
  }



  setHideandShowList() {
    this.hideshowGridColumnList.sort(function (a, b) {
      return a.gridUserSettingOrder - b.gridUserSettingOrder;
    });
  }


  displayColumnRow: any[] = [];
  displayListRow: any[] = [];
  setListToDisplay() {
    this.diplayList = [];
    for (var user = 0; user < this.coreList.length; user++) {
      let tempUserFields: any[] = [];
      for (var userField = 0; userField < this.coreList[user].fields.length; userField++) {
        tempUserFields.push(this.coreList[user].fields[userField]);
      }
      var _user = {
        fields: tempUserFields,
        transactionID: tempUserFields[0].teacherID
      };
      this.diplayList.push(_user);
    }
    this.coreListNew = [];
    this.coreListNew = this.diplayList;
    this._returnList = this.setListForDrogandDrop(this.coreListNew);
    console.log("DISPlaylist", this._returnList);

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = this._returnList;
    // this.dataSource.sort = this.sort;
  }

  setListForDrogandDrop(list: any[]): any[] {
    console.log("Testing", list)
    var tempList: any[] = list.map((s) => Object.assign(s));
    let returnList: any = [];
    tempList.forEach((element) => {
      let ReqModel: any = {};
      let teacherID = 0;
      element.fields.forEach((ele: any) => {
        var timestamp = Date.parse(ele.fieldValue);

        if (isNaN(timestamp) == false && ele.isDate == true) {
          ReqModel[ele.fieldName] = this.datepipe.transform(
            ele.fieldValue,
            'MM/dd/yyyy'
          );
        }
        else {
          ReqModel[ele.fieldName] = ele.fieldValue ? ele.fieldValue : '—';
        }
      });
      returnList.push(ReqModel);
    });
    console.log("return l", this.SubjectListDropdown)
    return returnList;
  }
  setColumnToDisplay() {
    this.displayedColumnsList = [];
    console.log("AFTERDATA", this.coreFieldList)
    this.coreFieldList.sort(function (a, b) {
      return a.gridUserSettingOrder - b.gridUserSettingOrder;
    });
    for (var i = 0; i < this.coreFieldList.length; i++) {
      if (this.coreFieldList[i].isShowInGrid == true) {
        this.displayedColumnsList.push(this.coreFieldList[i].fieldName)
      }
    }


    // this.coreFieldList.forEach((colunm, index) => {
    //   colunm.index = index;
    //   this.displayedColumnsList[index] = colunm.fieldName;
    // });
    let newArr = new Set(this.displayedColumnsList);
    this.displayedColumnsList = [];
    this.displayedColumnsList = [...newArr]
    this.displayedColumnsList.splice(0, 0, 'select');
    this.displayedColumnsList.splice(1, 0, 'profilePhoto');

    if (this.displayedColumnsList.length != 0) {
      for (var i = 0; i < this.displayedColumnsList.length; i++) {
        if (this.displayedColumnsList[i] == 'select') {
          this.displayedColumnsList.splice(i, 1);
        }
      }
    }

    let changedColumns = (this.displayedColumnsList || []).map(obj => obj);
    changedColumns.splice(0, 0, "select");
    changedColumns.push('Actions')
    this.changeColumnsToDisplay = changedColumns;
    console.log("DISPLAYCOLUMNLIST", this.changeColumnsToDisplay)
  }
  SortChange(sortState: Sort) {
    this.dataSource.sort = this.sort;
  }
  //selection = new SelectionModel<any>(true, []);
  selectedId: any[] = [];
  isAllSelected() {
    this.selectedId = [];
    if (this.selection.selected.length != 0) {
      for (var i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].teacherID) {
          this.selectedId.push(this.selection.selected[i].teacherID)
        }
      }
    }
    console.warn('FinalData>>>>', this.selectedId);
    this.onselect(this.selectedId)
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) => this.selection.select(row));
    if (this.selection.selected.length == 0) {
      this.selectedId = [];
      console.warn('FinalData>>>>', this.selectedId);
      this.onselect(this.selectedId)
    }
  }
  changee(event: any, data: any) {
    if (event.checked == false) {
      for (var i = 0; i < this.selectedId.length; i++) {
        if (this.selectedId == data.teacherID) {
          this.selectedId.splice(i, 1);
        }
      }
      this.onselect(this.selectedId)
    }
  }
  public handlePage(e: any) {

    console.log("PAGGG", e)
    this.dybamicTeacherMeta.pageNumber = e.pageIndex + 1;
    this.dybamicTeacherMeta.pageSize = e.pageSize;

    this.getTableData();
  }
  selectAllField() {
    this.hideshowGridColumnList.map(a => a.isShowInGrid = true);
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList()
  }
  checked: boolean = true;
  unselectAllField() {
    this.hideshowGridColumnList.map(a => a.isShowInGrid = false);
    this.hideshowGridColumnList.find((g: any) => g.fieldName == "First Name").isShowInGrid = true;
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList()
  }

  unselectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = false;
    }
  }
  selectAllExport() {
    for (var i = 0; i < this.exportField.length; i++) {
      this.exportField[i].checkbox = true;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.hideshowList = {}
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    moveItemInArray(this.hideshowGridColumnList, event.previousIndex, event.currentIndex);
    this.addHideshowList()
  }
  hideshowList: any;
  onCheck() {
    this.hideshowList = {}
    this.hideshowGridColumnList.find((g: any) => g.fieldName == "First Name").isShowInGrid = true;
    this.hideshowList = {
      "showHideFieldAndGroups": this.hideshowGridColumnList
    }
    this.addHideshowList();

  }
  addHideshowList() {
    console.log("ONSAVEHIDESHOW", this.hideshowList)
    this.teacherService.addTeacherShowHidecolumn(this.hideshowList).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getTableData();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  dynamicFilter() {
    this.dynamicFilterboolean = true;
    Object.keys(this.dynamicFilterform.controls).forEach(key => {
      console.log('data ' + this.dynamicFilterform.controls[key].value);
      //if (this.dynamicFilterform.controls[key].value !== '') {
      const value = this.dynamicFilterform.controls[key].value;
      const field = this.coreFieldList.find(e => e.fieldName === key).fieldID;
      this.setFilterValue(field, value, key);
    });
    this.getTableData();
  }
  setFilterValue(id: any, val: any, fieldName: any) {
    if (this.filterArray.length == 0) {
      if (val !== undefined && val !== null && val !== '') {
        this.filterArray.push({
          id: id,
          val: val,
          fieldName: fieldName
        });
      }
    }
    else {
      // field in array
      if (this.filterArray.find((m: any) => m.fieldName == fieldName) !== undefined) {
        if (val == undefined || val == null || val == '') {
          this.filterArray = this.filterArray.filter((m: any) => m.fieldName !== fieldName);
        }
        else {
          this.filterArray.find((m: any) => m.fieldName == fieldName).id = id;
          this.filterArray.find((m: any) => m.fieldName == fieldName).val = val;
          this.filterArray.find((m: any) => m.fieldName == fieldName).fieldName = fieldName;
        }
      }
      else // field not in array
      {
        if (val !== undefined && val !== null && val !== '') {
          this.filterArray.push({
            id: id,
            val: val,
            fieldName: fieldName
          });
        }
      }

    }
  }
  filterArray: any = [];
  resetFilter() {
    this.dynamicFilterform.reset();
    this.dybamicTeacherMeta.filterFieldIds = '';
    this.dybamicTeacherMeta.filterFieldValue = '';
    this.filterArray = [];
    this.getTableData();
  }
}
export interface PeriodicElement {
  id: number;
  profilepic: any;
  teacherName: string;
  teacherID: string;
  employeeType: string;
  email: string;
  contactNumber: string;
  actions: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, profilepic: ' ', teacherName: 'Virat Sharma', teacherID: 'test001', employeeType: 'Academic', email: 'test@gmail.com', contactNumber: '123456789', actions: ' ' },
];





