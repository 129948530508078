import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { StudentService } from 'src/app/services/student.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-manage-registrations',
  templateUrl: './manage-registrations.component.html',
  styleUrls: ['./manage-registrations.component.css']
})
export class ManageRegistrationsComponent implements OnInit, AfterViewInit {
  @ViewChild('testElement') testElement: ElementRef;
  isCollapsed = true;
  options = true;
  singlLineWithFields: any = [];
  multiLineWithFields: any = [];
  DateWithFields: any = [];
  SelectionWithFields: any = [];
  CheckBoxWithFields: any = [];
  groupsWIthFields: any = [];
  fieldTypeList: any = [];
  textBoxform: FormGroup;
  groupForm: FormGroup;
  formType: any;
  users: any;
  user: any;
  permissions: any;
  constructor(
    private formBuilder: FormBuilder,
    private studenService: StudentService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private masterService: MasterService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
    debugger
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.formType = params.formType;
        this.GetCustomFormById();
      }
      );
  }

  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Manage Student Fields');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    if (this.formType == '1') {
      this.ActivityLogTrackForStudent();
    }
    if (this.formType == '2') {
      this.ActivityLogTrackForTeacher();
    }
    this.textBoxform = this.formBuilder.group({
      FieldID: [''],
      FieldName: [''],
      DefaultValue: [''],
      Date: [false],
      FieldTypeID: [''],
      FieldTypeName: [''],
      GroupID: [''],
      IsRequried: [false],
      IsUnpublished: [''],
      IsNumber: [false],
      Email: [false],
      VisibleToStudent: [false],
      VisibleToParent: [false],
      VisibleToTeacher: [false],
      EditableToStudent: [false],
      EditableToParent: [false],
      IsDynamicField: [false],
    });
    this.groupForm = this.formBuilder.group({
      groupName: ['', Validators.required],
    });
    this.SingleLineControl();
    this.MultiLineControl();
    this.DateControl();
    this.SelectionControl();
    this.CheckBoxControl();

    console.log('DROPGROUP', this.groupsWIthFields);
  }
  ngAfterViewInit() {
    this.renderer.listen(this.testElement.nativeElement, 'click', () => {
      this.hoverOut();
      this.inOutChk = true;
    });
  }

  useClass: boolean = false;
  inOutChk: boolean = false;
  selectedID: number = 0;
  hoverIn(id: number) {
    //debugger;
    this.selectedID = id;
    this.inOutChk = false;
    this.useClass = true;
  }
  hoverOut() {
    //debugger;
    if (this.inOutChk) {
      this.useClass = false;
    }
  }

  checkActionPermission(actionName: any) {
    let screen = 'Manage Student Fields';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }

  activityLog: any;
  ActivityLogTrackForStudent() {
    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Students',
        ActivityTypeId: 10,
        Description: 'Student Fields record viewed by',
        ScreenName: 'Manage Student Fields',
        RecordTypeId: 1,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  ActivityLogTrackForTeacher() {
    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Teachers & Staff/Admin',
        ActivityTypeId: 10,
        Description: 'Teacher Fields record viewed by',
        ScreenName: 'Manage Teacher Fields',
        RecordTypeId: 2,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }


  GetCustomFormById(): void {
    this.studenService.GetCustomFormById(this.formType).subscribe((res: any) => {
      console.log(res);
      if (res.statusCode == 200) {
        this.groupsWIthFields = res.responseData.getFieldAndGroupDto;
        if (this.groupsWIthFields.length > 0) {
          this.groupsWIthFields = this.groupsWIthFields.filter(
            (g: any) => g.groupName !== 'ENROLLMENT'
          );
        }
        this.fieldTypeList = res.responseData.fieldTypeDto;
        this.SingleLineControl();
        this.MultiLineControl();
        this.DateControl();
        this.SelectionControl();
        this.CheckBoxControl();
      }
    });
  }

  AddUpdateDynamicFormGroupFields(): void {
    sessionStorage.setItem('Loader', '0');
    this.options = true;
    this.studenService.AddUpdateDynamicFormGroupFields({ FormType: this.formType, FieldAndGroups: this.groupsWIthFields }).subscribe((res: any) => {
      console.log(res);
      if (res.statusCode == 200) {
        this.toaster.success("Updated Successfully");
        this.GetCustomFormById();
      }
      if (res.statusCode == 500) {
        this.toaster.warning(res.message);
        //this.GetCustomFormById();
        this.GetCustomFormById();
        //this.isCollapsed = !this.isCollapsed
      }
    });
  }
  DeleteField(field: any, group: any) {
    console.log(this.groupsWIthFields
      .find((g: any) => g.groupID == group.groupID)
      .fields.find(
        (f: any) => f.fieldID == field.fieldID
      ).isRequried);
    if (this.groupsWIthFields
      .find((g: any) => g.groupID == group.groupID)
      .fields.find(
        (f: any) => f.fieldID == field.fieldID
      ).isRequried == false) {
      this.groupsWIthFields
        .find((g: any) => g.groupID == group.groupID)
        .fields.find(
          (f: any) => f.fieldID == field.fieldID
        ).isDeleted = true;
    }
    else
      this.toaster.warning("Required field can not be deleted.");
  }

  CreateGroup() {
    this.options = true;
    if (this.groupForm.value.groupName.trim() != '') {
      this.studenService.CreateEditDeleteGroup({ FormType: 3, GroupName: this.groupForm.value.groupName }).subscribe((res: any) => {
        console.log(res);
        if (res.statusCode == 200) {
          this.toaster.success("Group Created Successfully");
          this.GetCustomFormById();
          this.groupForm.reset();
          this.isCollapsed = !this.isCollapsed
        }
        if (res.statusCode == 500) {
          this.toaster.error(res.message);
          //this.GetCustomFormById();
          this.groupForm.reset();
          //this.isCollapsed = !this.isCollapsed
        }
      });
    }
    else {
      this.toaster.warning("Group Name is required.");
    }

  }
  cancle() {
    this.groupForm.reset();
  }

  EditGroup(group: any) {
    this.studenService.CreateEditDeleteGroup({ GroupID: group.groupID, FormType: group.formType, GroupName: group.groupName }).subscribe((res: any) => {
      console.log(res);
      if (res.statusCode == 200) {
        this.toaster.success("Group Updated Successfully");
        this.GetCustomFormById();
      }
    });
  }
  DisabledPointer1() {
    if (this.textBoxform.value.FieldName == "First Name" || this.textBoxform.value.FieldName == "Last Name" ||
      this.textBoxform.value.FieldName == "Admission Number" || this.textBoxform.value.FieldName == "Date of Birth" ||
      this.textBoxform.value.FieldName == "Contact" || this.textBoxform.value.FieldName == "Gender" ||
      this.textBoxform.value.FieldName == "Teacher Code" || this.textBoxform.value.FieldName == "Parents ID" ||
      this.textBoxform.value.FieldName == "FathersName" || this.textBoxform.value.FieldName == "MothersName" ||
      this.textBoxform.value.FieldName == "Parents Contact" || this.textBoxform.value.FieldName == "Parents Email" ||
      this.textBoxform.value.FieldName == "Student Email" || this.textBoxform.value.FieldName == "Teacher Email") {
      return true
    }
    else {
      return false
    }
  }


  DeleteGroup(group: any) {
    this.options = true;
    let hasAnyRequiredField = group.fields.filter(
      (f: any) => f.isRequried == true
    );

    let hasAnyStaticFieldsField = group.fields.filter(
      (f: any) => f.isDynamicField == false
    );
    if (hasAnyStaticFieldsField.length == 0 && hasAnyRequiredField.length == 0) {
      this.groupsWIthFields = this.groupsWIthFields
        .filter((g: any) => g.groupID == group.groupID);
      this.studenService.CreateEditDeleteGroup({ IsDeleted: true, GroupID: group.groupID, FormType: group.formType, GroupName: group.groupName }).subscribe((res: any) => {
        console.log(res);
        if (res.statusCode == 200) {
          this.toaster.success("Group Deleted Successfully");
          this.GetCustomFormById();
        }
      });
    }
    else
      this.toaster.warning("Can not delete group having required fields and predefined fields.");
  }
  drop(event: CdkDragDrop<string[]>, group: any) {
    moveItemInArray(group.fields, event.previousIndex, event.currentIndex);
    this.groupsWIthFields.find((g: any) => g.groupID == group.groupID).fields =
      group.fields;
  }
  dropGroup(event: CdkDragDrop<string[]>, group: any) {
    moveItemInArray(group, event.previousIndex, event.currentIndex);
    this.groupsWIthFields = group;
    console.log('DROPGROUP', this.groupsWIthFields);
  }
  getFieldList() {
    this.fieldTypeList = [
      { id: 1, name: 'Text' },
      { id: 2, name: 'Teaxtarea' },
      { id: 3, name: 'Date' },
      { id: 4, name: 'Selection' },
      { id: 5, name: 'Checkbox' },
    ];
  }
  onDrop(event: any, group: any) {
    //////debugger;
    console.log('EVENT', event.data);
    this.groupsWIthFields
      .find((g: any) => g.groupID == group.groupID)
      .fields.push({
        fieldID: this.randomIdGenerator(),
        fieldName: event.data.fieldTypeName,
        defaultValue: '',
        isDate: event.data.isDate,
        fieldTypeID: this.fieldTypeList.find(
          (g: any) => g.fieldTypeName == event.data.fieldTypeName
        ).fieldTypeID,
        groupID: group.groupID,
        fieldGroupMappingID: 0,
        formType: this.formType,
        isRequried: false,
        isNumber: false,
        isUnpublished: false,
        email: event.data.email,
        fieldOrder: group.fields.length + 1,
        visibleToStudent: false,
        visibleToParent: false,
        visibleToTeacher: false,
        editableToStudent: false,
        editableToParent: false,
        fieldTypeName: event.data.fieldTypeName,
        isDynamicField: true,
        isDeleted: false
      });
  }
  onDragStart(event: DragEvent) { }

  onDragEnd(event: DragEvent) { }

  onDraggableCopied(event: DragEvent) { }

  onDraggableLinked(event: DragEvent) { }

  onDragged(item: any, list: any[], effect: DropEffect) { }

  SingleLineControl() {
    this.singlLineWithFields = [];
    this.singlLineWithFields.push({
      FieldID: 0,
      fieldName: 'Textbox',
      FieldTypeID: 1,
      GroupID: 0,
      FormType: 0,
      IsRequried: false,
      email: false,
      isDate: false,
      DefaultValue: '',
      Order: 0,
      VisibleToStudent: false,
      VisibleToParent: false,
      VisibleToTeacher: false,
      EditableToStudent: false,
      EditableToParent: false,
      fieldTypeName: 'Textbox',
    });
  }

  MultiLineControl() {
    this.multiLineWithFields = [];
    this.multiLineWithFields.push({
      FieldID: 0,
      fieldName: 'Textarea',
      FieldTypeID: 2,
      GroupID: 0,
      FormType: 0,
      IsRequried: false,
      email: false,
      isDate: false,
      DefaultValue: '',
      Order: 0,
      VisibleToStudent: false,
      VisibleToParent: false,
      VisibleToTeacher: false,
      EditableToStudent: false,
      EditableToParent: false,
      fieldTypeName: 'Textarea',
    });
  }

  DateControl() {
    this.DateWithFields = [];
    this.DateWithFields.push({
      FieldID: 0,
      fieldName: 'Date',
      FieldTypeID: 3,
      GroupID: 0,
      FormType: 0,
      IsRequried: false,
      email: false,
      isDate: true,
      DefaultValue: '',
      Order: 0,
      VisibleToStudent: false,
      VisibleToParent: false,
      VisibleToTeacher: false,
      EditableToStudent: false,
      EditableToParent: false,
      fieldTypeName: 'Date',
    });
  }

  SelectionControl() {
    this.SelectionWithFields = [];
    this.SelectionWithFields.push({
      FieldID: 0,
      fieldName: 'Dropdown',
      FieldTypeID: 4,
      GroupID: 0,
      FormType: 0,
      IsRequried: false,
      email: false,
      isDate: false,
      DefaultValue: '',
      Order: 0,
      VisibleToStudent: false,
      VisibleToParent: false,
      VisibleToTeacher: false,
      EditableToStudent: false,
      EditableToParent: false,
      fieldTypeName: 'Dropdown',
    });
  }
  CheckBoxControl() {
    this.CheckBoxWithFields = [];
    this.CheckBoxWithFields.push({
      FieldID: 0,
      fieldName: 'Checkbox',
      FieldTypeID: 5,
      GroupID: 0,
      FormType: 0,
      IsRequried: false,
      email: false,
      isDate: false,
      DefaultValue: '',
      Order: 0,
      VisibleToStudent: false,
      VisibleToParent: false,
      VisibleToTeacher: false,
      EditableToStudent: false,
      EditableToParent: false,
      fieldTypeName: 'Checkbox',
    });
  }
  onDragCanceled(event: DragEvent) { }

  onDragover(event: DragEvent) { }
  onClickOption(event: any) {
    this.textBoxform.reset();
    this.options = false;
    this.textBoxform.patchValue({
      FieldID: event.fieldID,
      FieldName: event.fieldName,
      FieldTypeName: event.fieldTypeName,
      FieldTypeID: event.fieldTypeID,
      IsRequried: event.isRequried,
      Email: event.email,
      Date: event.isDate,
      IsNumber: event.isNumber,
      IsUnpublished: event.isUnpublished == true ? '2' : '1',
      DefaultValue: event.defaultValue,
      GroupID: event.groupID,
      VisibleToStudent: event.visibleToStudent,
      VisibleToParent: event.visibleToParent,
      VisibleToTeacher: event.visibleToTeacher,
      EditableToStudent: event.editableToStudent,
      EditableToParent: event.editableToParent,
      IsDynamicField: event.isDynamicField
    });
  }
  randomIdGenerator() {
    return Math.floor(10 + Math.random() * 9000);
  }
  onChangelabel(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).fieldName = event.target.value;
  }
  onChangeOption(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).defaultValue = event.target.value;
  }
  onRequried(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).isRequried = event.checked;
  }
  onIsNumber(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).isNumber = event.checked;
  }
  onIsUnpublished(event: any) {
    if (event.value == '2')
      this.groupsWIthFields
        .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
        .fields.find(
          (f: any) => f.fieldID == this.textBoxform.value.FieldID
        ).isUnpublished = true;
    else if (event.value == '1')
      this.groupsWIthFields
        .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
        .fields.find(
          (f: any) => f.fieldID == this.textBoxform.value.FieldID
        ).isUnpublished = false;
  }
  onEmail(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).email = event.checked;
  }
  onDate(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).isDate = event.checked;
  }
  onVisibleToStudent(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).visibleToStudent = event.checked;
  }
  onVisibleToParent(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).visibleToParent = event.checked;
  }
  onVisibleToTeacher(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).visibleToTeacher = event.checked;
  }
  onEditableToStudent(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).editableToStudent = event.checked;
  }
  onEditableToParent(event: any) {
    this.groupsWIthFields
      .find((g: any) => g.groupID == this.textBoxform.value.GroupID)
      .fields.find(
        (f: any) => f.fieldID == this.textBoxform.value.FieldID
      ).editableToParent = event.checked;
  }
  newGroupName: any
  Namecheck(event: any) {
    // console.log("GROUP",event)
    // this.newGroupName = document.getElementById("groupName")?.innerHTML;
    // console.log("GROUPName",this.newGroupName)
    // this.groupsWIthFields
    //   .find((g: any) => g.groupID == group.groupID).groupName = this.newGroupName;
    //   console.log("GROUP",this.groupsWIthFields)
  }
}
