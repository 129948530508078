import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import Calendar from 'js-year-calendar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { StudentService } from 'src/app/services/student.service';
import { SectionService } from '../../../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectService } from '../../../../courses-and-subjects/services/subjectServices/subject.service';
import { ExcelModel } from '../../UserModel';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  classListDropdown: any;
  subjectList: any = [];
  attendanceCountModel: any = [];
  studentAttendanceModel:any=[];
  a:any=[];
  stuAttendanceForm: FormGroup;
  sectionListDropdown: any;
  studentName: string;
  myControl = new FormControl();
  selectedClass: string = "";
  selectedSubject:string="";
  selectedSection: string = "";
  year: any = new Date().getFullYear();
  studentID: Number = 0;
  dataSource: any = [];
  dataSource1: any = [];
  dataSource2:any=[];
  test:any=[];
  test1:any=[];
  options: string[] = ['English', 'THindi', 'Maths'];
  filteredOptions: Observable<string[]>;
  IsStudentOrPreant: boolean = false;
  constructor(
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private fb: FormBuilder
  ) { 
    
  }

  ngOnInit(): void {
    new Calendar('#calendar');
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
    });
    this.stuAttendanceForm = this.fb.group({
      ClassID: ['', Validators.required],
      SectionID: ['', Validators.required],
      SubjetID: ['', Validators.required]
    });
    this.getMasterDropdown();
    //this.getData();
    this.GetStudentByIdList();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    var roleID  = localStorage.getItem('roleID')
    if(roleID== UserRole.Student || roleID== UserRole.Parent){
      this.IsStudentOrPreant = true;
    }
  }

  GetStudentByIdList() {
    this.studentService.GetStudentListById(this.studentID).subscribe((res: any) => {
      this.studentName = res.responseData.firstName + ' ' + res.responseData.lastName;
    });
  }
  ExcelName(){
    return this.studentName + ' (' + this.selectedClass + ' - ' + this.selectedSection + ')';

  }
  getMasterDropdown() {
    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    })
    this.studentService.GetStudentEnrollmentTabDetailsId(this.studentID).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.classListDropdown = res.responseData;
        console.log("classdata",this.classListDropdown)
        //this.studentName = res.responseData[0].studentName;
      }

    })
    // this.classDropdownService.ClassDropdown().subscribe((res: any) => {
    //   this.classListDropdown = res.responseData
    // });
  }
  GetSubjectBySectionId(event: any) {
    this.selectedSection = this.sectionListDropdown.find((x: any) => x.sectionID == event.value).sectionName;
    this.stuAttendanceForm.controls['SubjetID'].setValue('');
    this.subjectService.GetSubjectBySectionId(event.value).subscribe((res: any) => {
      this.subjectList = [];
      if (res.responseData) {
        this.subjectList = res.responseData
      }
    });
  }
  selectSectionByClassID(event: any) {
    console.log(event);
    this.selectedClass = this.classListDropdown.find((x: any) => x.classID == event.value).className;
    this.stuAttendanceForm.controls['SectionID'].setValue('');
    this.stuAttendanceForm.controls['SubjetID'].setValue('');
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = [];
      if (res.responseData) {
        this.sectionListDropdown = res.responseData
      }
    });
  }
  // getSubject(event:any){
  //   this.selectedSubject=this.classListDropdown.find((x:any) => x.classId == event.value).subjectName;
  // }


  noop(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  colData: any = [];
  colData1:any=[];
  excelData: any = [];
  excelDataFinal: any = [];
  getData() {
    this.excelData= [];
    this.excelDataFinal = [];
    this.colData = [];
    this.colData1=[];
    this.dataSource = [];
    this.dataSource1=[];
    this.colData = ['StudentName','Year','ClassName','SectionName','SubjectName'];
    this.colData1=['date','status'];
    if (this.stuAttendanceForm.controls['ClassID'].value == "" && this.stuAttendanceForm.controls['ClassID'].value == "" && this.stuAttendanceForm.controls['ClassID'].value == "") {
      return;
    }
    var data = {
      "Year": this.year,
      "StudentID": this.studentID,
      "ClassID": this.stuAttendanceForm.controls['ClassID'].value,
      "SectionID": this.stuAttendanceForm.controls['SectionID'].value,
      "SubjectID": this.stuAttendanceForm.controls['SubjetID'].value
    }
    let subName = this.subjectList.filter((x: any) => x.subjectID == data.SubjectID);
    this.excelData['StudentName']=this.studentName;
    this.excelData['Year']=this.year;
    this.excelData['ClassName']=this.selectedClass;
    this.excelData['SectionName']=this.selectedSection;
    this.excelData['SubjectName']=subName[0].subjectName;
    this.studentService.GetCalendarAttendanceByStudentID(data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        debugger
        this.attendanceCountModel = res.responseData.calendarAttendanceModel;
        this.studentAttendanceModel=res.responseData.studentAttendanceModel.filter((x: any) => x.statusName != 'Not Marked Yet');

        //this.dataSource1=this.studentAttendanceModel
        // this.dataSource = respose.Data;       
       // this.recordCount = this.dataSource.length > 0 ? this.dataSource[0].recordCount : 0;
      //  console.log("attendance Moddel",this.studentAttendanceModel)
        // this.attendanceCountModel.forEach((element: any) => {
        //   this.colData.push(element.statusName);
        //   this.excelData[element.statusName] = element.statusCount;
        // });

        //
        // this.studentAttendanceModel.forEach((element: any) => {
        //   this.colData.push(element.statusName);
        //   this.excelData[element.statusName] = element.statusCount;
        // });

        //
     //   this.excelDataFinal.push(this.excelData);
     

        // if (res.responseData.studentAttendanceModel.length > 0) {
        //   res.responseData.studentAttendanceModel.forEach((item: any) => {
        //     this.dataSource1.push({
        //       "startDate": new Date(item.startDate),
        //       "endDate": new Date(item.startDate),
        //         color: item.colorCode,
        //         status :item.statusName
        //     })
        //   });
        //   // const ids = this.dataSource1.map((obj:any) => obj.status);
        //   // console.log("idssssss",ids)
        //   // const id = this.dataSource1.map((obj:any) => obj.startDate);
        //   // console.log("data source",this.dataSource1)
        // //  =this.dataSource1[0]
          
        //   console.log("this.dataSource 1",this.dataSource1)


          
        
           
        
        //         // this.dataSource = respose.Data;
        
        //         // this.recordCount = this.dataSource.length > 0 ? this.dataSource[0].recordCount : 0;
        
             
        
           
        
        
        
       //   }


       // if (res.responseData.studentAttendanceModel.length > 0) {


          res.responseData.studentAttendanceModel.forEach((item: any) => {
            this.dataSource1.push({
              "startDate": new Date(item.startDate),
              "endDate": new Date(item.startDate),
              color: item.colorCode,
              date:item.startDate,
              status:item.statusName
              
            })
          });
        }
         else {
         this.dataSource = [];
          this.dataSource1=[];
          this.test1=[];
        // }

      }
      let calendar = new Calendar('#calendar');
      //calendar.setYear(this.year);
      // calendar.setDataSource(this.dataSource);
      // console.log("calender1 ",calendar)
      calendar.setDataSource(this.dataSource1);
      console.log("calender1 ",calendar)

    });
    
  }

  
  previousYear() {
    this.year = this.year - 1;
    this.getData();
  }
  nextYear() {
    this.year = this.year + 1;
    this.getData();
  }

}
