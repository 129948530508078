import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { ManageAssessmentAndGradingsService } from '../services/manage-assessment-and-gradings.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { Sort } from '@angular/material/sort';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { MasterService } from 'src/app/services/master.service';
import { UserRole } from 'src/environments/environment';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  type: string;
  count: string;
  status: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', type: 'A', count: '1235', status: 'abcd' },
];

@Component({
  selector: 'app-manage-assessment',
  templateUrl: './manage-assessment.component.html',
  styleUrls: ['./manage-assessment.component.css']
})
export class ManageAssessmentComponent implements OnInit {
  // positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  // position = new FormControl(this.positionOptions[0]);
  classToggled = false;
  dataSource: any;
  dataHover: any;
  metaData = new Metadata();
  addAssessmentForm: FormGroup;
  categoryDropdown: any;
  gradeScaleDropdown: any;
  rubicScaleList: any;
  gradeScaleTypeList: any
  AllSubjectsToApplied: any;
  classsectionDropdown: any;
  subjectDropdown: any;
  assessmentObj: any;
  updateTextInPopUp: boolean;
  AssessmentID: number;
  isappliedTO: any;
  deleteAssessmentForm: FormGroup;
  copyAssessmentData: boolean;
  deleteAssessmentID: number;
  deleteassessmentObj: any;
  userEmail: any;
  adminUserEmail: any;
  selectedId: any[] = [];
  delObj: any;
  publishObj: any;
  deleteObj: any;
  DeletedAssessmentID: any
  searchingAssessmentForm: FormGroup;
  assessmentSerchingObj: any;
  user: any;
  users: any;
  permissions: any;
  academicSessionID: any;
  dateFormat: any;
  previousClassSecVal: any = [];
  previousclassec: any = [];
  previousSubjectVal: any = [];
  previousAssessVal: any = [];
  isTeacherUser: boolean = false;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  private filterModel: FilterModel;
  constructor(
    private dialog: MatDialog,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private subjectService: SubjectService,
    private masterService: MasterService
  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
      this.dateFormat = currentUser.dateFormat;
    }
    this.filterModel = new FilterModel();
  }
  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID')
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Assessment');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.ActivityLogTrack();
    this.initForm();
    this.initSearchingForm();
    this.GetMasterDropdown();
    this.GetAllAssessment();

  }

  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'classSec') {
      if (serachTxt != '') {
        this.classsectionDropdown = this.previousClassSecVal.filter((x: any) => x.classSectionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.classsectionDropdown = this.previousClassSecVal;
      }
    } else if (type == 'subject') {
      if (serachTxt != '') {
        this.subjectDropdown = this.previousSubjectVal.filter((x: any) => x.subjectName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.subjectDropdown = this.previousSubjectVal;
      }
    }

    else if (type == 'classSect') {
      if (serachTxt != '') {
        this.classsectionDropdown = this.previousClassSecVal.filter((x: any) => x.classSectionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.classsectionDropdown = this.previousClassSecVal;
      }
    }
    else if (type == 'assesmentCategory') {
      debugger
      if (serachTxt != '') {
        this.categoryDropdown = this.previousCategory.filter((x: any) => x.assessmentCategoryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.categoryDropdown = this.previousCategory;
      }
    }

  }


  dispalyFns(classname: any) {
    return classname?.classSectionName;


  }
  dispalyFnsSections(classSectionName: any) {
    return classSectionName?.classSectionName;
  }

  dispalyFnsSubject(subjectNameID: any) {
    return this.subjectDropdownvalue.filter((a: any) => a.subjectID == subjectNameID)[0]?.subjectName;

  }
  dispalyFnsAssesmentCategory(assesmentCategoryName: any) {
    return assesmentCategoryName;

  }

  activityLog: any;
  ActivityLogTrack() {

    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Asessment & Gradings',
        ActivityTypeId: 10,
        Description: 'Assessment list record viewed by',
        ScreenName: 'Assessment',
        RecordTypeId: 42,
        RecordId: 0,
        Type: 0
      }
    }
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }


  checkActionPermission(actionName: any) {
    let screen = 'Assessment';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }

  initSearchingForm() {
    this.searchingAssessmentForm = this.fb.group({
      assessmentCategoryName: [''],
      assessmentName: [''],
      classSectionID: [0],
      classID: [0],
      sectionID: [0],
      subjectID: [0],
      assessmentStatusName: [''],
      isCountTowardsOverAllScore: [''],
      startFromDate: [null],
      startToDate: [null],
      endFromDate: [null],
      endToDate: [null],
    });
  }

  onSearchingSubmit() {
    this.assessmentSerchingObj = {
      "pageSize": this.filterModel.pageSize,
      "pageNumber": this.filterModel.searchPageNumber,
      "columnName": "",
      "sortOrder": "string",
      'assessmentCategoryName': this.searchingAssessmentForm.controls.assessmentCategoryName.value,
      'assessmentName': this.searchingAssessmentForm.controls.assessmentName.value,
      'AssessmentStatusID': this.searchingAssessmentForm.controls.assessmentStatusName.value,
      'isCountTowardsOverAllScore': this.searchingAssessmentForm.controls.isCountTowardsOverAllScore.value,
      'classID': this.searchingAssessmentForm.value.classSectionID.classID,
      'sectionID': this.searchingAssessmentForm.value.classSectionID.sectionID,
      'subjectID': this.searchingAssessmentForm.value.subjectID,
      'startFromDate': this.searchingAssessmentForm.controls.startFromDate.value,
      'startToDate': this.searchingAssessmentForm.controls.startToDate.value,
      'endFromDate': this.searchingAssessmentForm.controls.endFromDate.value,
      'endToDate': this.searchingAssessmentForm.controls.endToDate.value,
      'academicSessionID': this.academicSessionID
    }
    console.log("TABEL DATA", this.assessmentSerchingObj)
    this.manageAssessmentService.GetAllAssessmentList(this.assessmentSerchingObj).subscribe((res: any) => {
      this.dataSource = res.responseData;
      this.metaData = res.meta;
      this.dataHover = this.dataSource;
      console.log("Filtered Data ", this.dataSource);
    });



    console.log("this.assessmentSerchingObj", this.assessmentSerchingObj);

  }
  initForm() {
    this.addAssessmentForm = this.fb.group({
      assessmentID: [0],
      assessmentCategoryID: ['', Validators.required],
      assessmentName: ['', Validators.required],
      classSectionID: [0],
      classID: [0],
      sectionID: [0],
      subjectID: [0],
      points: ['', Validators.required],
      passingPoints: ['', Validators.required],
      assessmentStatusID: [1, Validators.required],
      isCountTowardsOverAllScore: [true, Validators.required],
      gradingScaleTypeID: ['', Validators.required],
      isAppliedTo: ['', Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      description: [''],
      onlineSubmissionID: [1, Validators.required],
      recordID: ['', Validators.required],
      isSendNotification: ['']
    });
  }

  classAndSection: any;
  previousCategory: any = [];
  subjectDropdownvalue: any = [];
  assessmentCategoryvalue: any = [];
  GetMasterDropdown() {
    this.manageAssessmentService.allAssessmentCategory(this.academicSessionID).subscribe((res: any) => {
      this.categoryDropdown = res.responseData;
      this.assessmentCategoryvalue = [...this.categoryDropdown];
      this.previousCategory = Object.assign([], this.categoryDropdown);

    });
    this.manageAssessmentService.GetClassAndSectionList().subscribe((res: any) => {
      this.classsectionDropdown = res.responseData;
      this.classAndSection = [...this.classsectionDropdown];
      this.previousClassSecVal = Object.assign([], this.classsectionDropdown);
      this.previousclassec = Object.assign([], this.classsectionDropdown);
    });

    this.manageAssessmentService.AllSubject().subscribe((res: any) => {
      this.subjectDropdown = res.responseData;
      this.subjectDropdownvalue = [...this.subjectDropdown];
      this.previousSubjectVal = Object.assign([], this.subjectDropdown);
    });
    this.manageAssessmentService.GetAllGradingscaleList(this.academicSessionID).subscribe((res: any) => {
      if (res.status > 0) {
        this.gradeScaleDropdown = res.responseData;
      }
    });
    this.manageAssessmentService.getAllRubicScaleList().subscribe((res: any) => {
      if (res.status > 0) {
        this.rubicScaleList = res.responseData;
        console.log("this.rubicScaleList", this.rubicScaleList);
      }
    });
    this.manageAssessmentService.GetAllGradingScaleTypesList().subscribe((res: any) => {
      if (res.status > 0) {
        this.gradeScaleTypeList = res.responseData;
        console.log("this.gradeScaleTypeId", this.gradeScaleTypeList);
      }
    })
  }
  checkWhiteSpace(event: any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }
  openDialog(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = false;
    this.copyAssessmentData = false;
    this.greaterThanValue = null;
    this.lessThanValue = null;
    this.startDateValue = new Date('');
    this.endDateValue = new Date('');
    console.log("OPEEEN", this.addAssessmentForm)
    this.initForm();
    this.dialog.open(templateRef);
    this.appliedSetting(1);
    this.selectGradingScale(0);
  }



  sortData(event: any) {
    if (event.direction === '') {
      return
    }
    console.log("sort", event);
    this.filterModel.sortColumn = event.active;
    this.filterModel.sortOrder = event.direction;
    this.GetAllAssessment();
  }
  onSubmitAssessment() {
    //debugger;
    if (this.addAssessmentForm.invalid) {
      return;
    }
    if (!this.updateTextInPopUp) {
      this.AssessmentID = 0;
    }
    let isFileAttached: boolean;
    if (this.attachmentData.length > 0) {
      isFileAttached = true;
    }
    else {
      isFileAttached = false
    }
    console.log("form", this.addAssessmentForm.value);
    if (this.addAssessmentForm.value.isAppliedTo == 1) {
      this.checkDates();
      this.assessmentObj = {
        'assessmentID': this.addAssessmentForm.controls.assessmentID.value,
        'assessmentCategoryID': this.addAssessmentForm.controls.assessmentCategoryID.value,
        'assessmentName': this.addAssessmentForm.controls.assessmentName.value,
        'points': this.addAssessmentForm.controls.points.value,
        'passingPoints': this.addAssessmentForm.controls.passingPoints.value,
        'assessmentStatusID': this.addAssessmentForm.controls.assessmentStatusID.value,
        'isCountTowardsOverAllScore': this.addAssessmentForm.controls.isCountTowardsOverAllScore.value,
        'gradingScaleTypeID': this.addAssessmentForm.controls.gradingScaleTypeID.value,
        'isAppliedTo': this.addAssessmentForm.controls.isAppliedTo.value,
        'subjectID': 0,
        'classID': 0,
        'sectionID': 0,
        //moment.utc(this.addAssessmentForm.controls.startDate.value).local().format();   
        'startDate': moment(this.addAssessmentForm.controls.startDate.value).format('YYYY-MM-DD'),
        'endDate': moment(this.addAssessmentForm.controls.endDate.value).format('YYYY-MM-DD'),
        'description': this.addAssessmentForm.controls.description.value,
        'onlineSubmissionID': this.addAssessmentForm.controls.onlineSubmissionID.value,
        'academicSessionID': this.academicSessionID,
        'recordID': this.addAssessmentForm.controls.recordID.value,
        'isSendNotification': this.addAssessmentForm.controls.isSendNotification.value,
        'isFileAttached': isFileAttached
      }
    } else if (this.addAssessmentForm.value.isAppliedTo == 3) {
      this.checkDates();
      this.assessmentObj = {
        'assessmentID': this.addAssessmentForm.controls.assessmentID.value,
        'assessmentCategoryID': this.addAssessmentForm.controls.assessmentCategoryID.value,
        'assessmentName': this.addAssessmentForm.controls.assessmentName.value,
        'points': this.addAssessmentForm.controls.points.value,
        'passingPoints': this.addAssessmentForm.controls.passingPoints.value,
        'assessmentStatusID': this.addAssessmentForm.controls.assessmentStatusID.value,
        'isCountTowardsOverAllScore': this.addAssessmentForm.controls.isCountTowardsOverAllScore.value,
        'gradingScaleTypeID': this.addAssessmentForm.controls.gradingScaleTypeID.value,
        'isAppliedTo': this.addAssessmentForm.controls.isAppliedTo.value,
        'subjectID': this.addAssessmentForm.value.subjectID,
        'classID': 0,
        'sectionID': 0,
        //moment.utc(this.addAssessmentForm.controls.startDate.value).local().format();   
        'startDate': moment(this.addAssessmentForm.controls.startDate.value).format('YYYY-MM-DD'),
        'endDate': moment(this.addAssessmentForm.controls.endDate.value).format('YYYY-MM-DD'),
        'description': this.addAssessmentForm.controls.description.value,
        'onlineSubmissionID': this.addAssessmentForm.controls.onlineSubmissionID.value,
        'academicSessionID': this.academicSessionID,
        'recordID': this.addAssessmentForm.controls.recordID.value,
        'isSendNotification': this.addAssessmentForm.controls.isSendNotification.value,
        'isFileAttached': isFileAttached
      }
    }
    else {
      this.checkDates();
      this.assessmentObj = {
        'assessmentID': this.addAssessmentForm.controls.assessmentID.value,
        'assessmentCategoryID': this.addAssessmentForm.controls.assessmentCategoryID.value,
        'assessmentName': this.addAssessmentForm.controls.assessmentName.value,
        'points': this.addAssessmentForm.controls.points.value,
        'passingPoints': this.addAssessmentForm.controls.passingPoints.value,
        'assessmentStatusID': this.addAssessmentForm.controls.assessmentStatusID.value,
        'isCountTowardsOverAllScore': this.addAssessmentForm.controls.isCountTowardsOverAllScore.value,
        'gradingScaleTypeID': this.addAssessmentForm.controls.gradingScaleTypeID.value,
        'isAppliedTo': this.addAssessmentForm.controls.isAppliedTo.value,
        'classID': this.addAssessmentForm.value.classSectionID.classID,
        'sectionID': this.addAssessmentForm.value.classSectionID.sectionID,
        'subjectID': 0,
        'startDate': moment(this.addAssessmentForm.controls.startDate.value).format('YYYY-MM-DD'),
        'endDate': moment(this.addAssessmentForm.controls.endDate.value).format('YYYY-MM-DD'),
        'description': this.addAssessmentForm.controls.description.value,
        'onlineSubmissionID': this.addAssessmentForm.controls.onlineSubmissionID.value,
        'academicSessionID': this.academicSessionID,
        'recordID': this.addAssessmentForm.controls.recordID.value,
        'isSendNotification': this.addAssessmentForm.controls.isSendNotification.value,
        'isFileAttached': isFileAttached
      }

    }
    if (this.copyAssessmentData) {
      this.CopyAssessments()
    }
    else {
      this.AddAssessments();
    }
  }
  editAssessment(templateRef: TemplateRef<any>, assessmentID: number) {

    this.copyAssessmentData = false;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.AssessmentID = assessmentID
    this.addAssessmentForm = this.fb.group({
      assessmentID: ['', Validators.required],
      assessmentCategoryID: [0, Validators.required],
      assessmentName: ['', Validators.required],
      classSectionID: [0],
      subjectID: [0],
      points: ['', Validators.required],
      passingPoints: ['', Validators.required],
      assessmentStatusID: ['', Validators.required],
      isCountTowardsOverAllScore: ['', Validators.required],
      gradingScaleTypeID: [0, Validators.required],
      isAppliedTo: [0, Validators.required],
      startDate: [null],
      endDate: [null],
      description: [''],
      onlineSubmissionID: [''],
      recordID: ['', Validators.required],
      isSendNotification: ['']
    });

    this.manageAssessmentService.getAssessmentById(assessmentID).subscribe((res: any) => {
      console.log("res data by id", res.responseData);
      this.isappliedTO = res.responseData[0]['isAppliedTo']
      for (let index = 0; index < this.classsectionDropdown.length; index++) {
        if (this.classsectionDropdown[index].classID == res.responseData[0]['classID'] && this.classsectionDropdown[index].sectionID == res.responseData[0]['sectionID']) {
          var classSectionID = this.classsectionDropdown[index]
        }
      }
      if (res.responseData[0]['isAppliedTo'] == 3) {
        for (let i = 0; i < this.subjectDropdown.length; i++) {
          if (this.subjectDropdown[i].subjectID == res.responseData[0]['subjectID']) {
            var subjectID = this.subjectDropdown[i].subjectID
          }
        }
      }

      console.log("this is value", res.responseData[0]['onlineSubmissionID'])

      this.addAssessmentForm.patchValue({
        assessmentID: res.responseData[0]['assessmentID'],
        assessmentCategoryID: res.responseData[0]['assessmentCategoryID'],
        assessmentName: res.responseData[0]['assessmentName'],
        classSectionID: classSectionID,
        subjectID: subjectID,//res.responseData[0]['subjectID'],
        points: res.responseData[0]['points'],
        passingPoints: res.responseData[0]['passingPoints'],
        assessmentStatusID: res.responseData[0]['assessmentStatusID'],
        isCountTowardsOverAllScore: res.responseData[0]['isCountTowardsOverAllScore'],
        gradingScaleTypeID: res.responseData[0]['gradingScaleTypeID'],//res.responseData[0]['gradingScaleID'],
        isAppliedTo: res.responseData[0]['isAppliedTo'],
        startDate: res.responseData[0]['startDate'],
        endDate: res.responseData[0]['endDate'],
        description: res.responseData[0]['description'],
        onlineSubmissionID: res.responseData[0]['onlineSubmissionID'],
        academicSessionID: this.academicSessionID,
        recordID: res.responseData[0]['recordID'],
        isSendNotification: false,
      });
      console.log("this is value", res.responseData[0])
      this.scaleValue = res.responseData[0]['gradingScaleTypeID'];
      if (res.responseData[0]['isAppliedTo'] == 2) {
        this.appliedSetting(2);
      }
      if (res.responseData[0]['isAppliedTo'] == 3) {
        this.appliedSetting(3);
      }
      if (res.responseData[0]['isAppliedTo'] == 1) {
        this.appliedSetting(1);
      }
      this.startDateValue = res.responseData[0]['startDate'];
      this.endDateValue = res.responseData[0]['endDate'];
    });


  }

  copyAssessment(templateRef: TemplateRef<any>, assessmentID: number) {
    this.updateTextInPopUp = false;
    this.copyAssessmentData = true;
    this.dialog.open(templateRef);
    this.AssessmentID = assessmentID
    this.addAssessmentForm = this.fb.group({
      assessmentID: [0],
      assessmentCategoryID: ['', Validators.required],
      assessmentName: ['', Validators.required],
      classSectionID: [0],
      subjectID: [0],
      points: ['', Validators.required],
      passingPoints: ['', Validators.required],
      assessmentStatusID: [0, Validators.required],
      isCountTowardsOverAllScore: ['', Validators.required],
      gradingScaleTypeID: [0, Validators.required],
      recordID: ['', Validators.required],
      isAppliedTo: [0, Validators.required],
      startDate: [''],
      endDate: [''],
      description: [''],
      onlineSubmissionID: [0],
      isSendNotification: ['']
    });

    this.manageAssessmentService.getAssessmentById(assessmentID).subscribe((res: any) => {
      console.log("res", res.responseData);

      this.isappliedTO = res.responseData[0]['isAppliedTo']
      for (let index = 0; index < this.classsectionDropdown.length; index++) {
        if (this.classsectionDropdown[index].classID == res.responseData[0]['classID'] && this.classsectionDropdown[index].sectionID == res.responseData[0]['sectionID']) {
          var classSectionID = this.classsectionDropdown[index]
        }
      }
      if (res.responseData[0]['isAppliedTo'] == 3) {
        for (let i = 0; i < this.subjectDropdown.length; i++) {
          if (this.subjectDropdown[i].subjectID == res.responseData[0]['subjectID']) {
            var subjectID = this.subjectDropdown[i].subjectID
          }
        }
      }
      for (let j = 0; j < this.gradeScaleTypeList.length; j++) {
        if (this.gradeScaleTypeList[j].gradeScaleTypeId == res.responseData[0]['gradeScaleTypeId']) {
          var gradeScaleTypeId = this.gradeScaleTypeList[j].gradeScaleTypeId
        }
      }
      this.addAssessmentForm.patchValue({
        //assessmentID: res.responseData[0]['assessmentID'],
        assessmentCategoryID: res.responseData[0]['assessmentCategoryID'],
        assessmentName: res.responseData[0]['assessmentName'],
        classSectionID: classSectionID,
        subjectID: subjectID,//res.responseData[0]['subjectID'],
        points: res.responseData[0]['points'],
        passingPoints: res.responseData[0]['passingPoints'],
        assessmentStatusID: res.responseData[0]['assessmentStatusID'],
        isCountTowardsOverAllScore: res.responseData[0]['isCountTowardsOverAllScore'],
        gradingScaleTypeID: gradeScaleTypeId,//res.responseData[0]['gradingScaleID'],
        isAppliedTo: res.responseData[0]['isAppliedTo'],
        startDate: res.responseData[0]['startDate'],
        endDate: res.responseData[0]['endDate'],
        description: res.responseData[0]['description'],
        onlineSubmissionID: res.responseData[0]['onlineSubmissionID'],
      });
      if (res.responseData[0]['isAppliedTo'] == 2) {
        this.appliedSetting(2);
      }
      if (res.responseData[0]['isAppliedTo'] == 3) {
        this.appliedSetting(3);
      }
      if (res.responseData[0]['isAppliedTo'] == 1) {
        this.appliedSetting(1);
      }
      this.selectGradingScale(1);
    });
  }
  attachmentData: any[] = [];
  uploadFile(event: any) {
    this.attachmentData = [];
    console.log("UPLOADD", event.target.files)
    for (var i = 0; i < event.target.files.length; i++) {
      this.attachmentData.push(event.target.files[i]);
    }
  }
  AddAssessments() {
    const formData: FormData = new FormData();
    for (var i = 0; i < this.attachmentData.length; i++) {
      formData.append("files", this.attachmentData[i]);
    }
    formData.append('assessmentID', this.assessmentObj.assessmentID);
    formData.append('assessmentCategoryID', this.assessmentObj.assessmentCategoryID);
    formData.append('assessmentName', this.assessmentObj.assessmentName);
    formData.append('points', this.assessmentObj.points);
    formData.append('passingPoints', this.assessmentObj.passingPoints);
    formData.append('assessmentStatusID', this.assessmentObj.assessmentStatusID);
    formData.append('isCountTowardsOverAllScore', this.assessmentObj.isCountTowardsOverAllScore);
    formData.append('gradingScaleTypeID', this.assessmentObj.gradingScaleTypeID);
    formData.append('isAppliedTo', this.assessmentObj.isAppliedTo);
    formData.append('classIDs', this.assessmentObj.classID);
    formData.append('sectionIDs', this.assessmentObj.sectionID);
    formData.append('subjectIDs', this.assessmentObj.subjectID);
    formData.append('startDate', this.assessmentObj.startDate);
    formData.append('endDate', this.assessmentObj.endDate);
    formData.append('description', this.assessmentObj.description);
    formData.append('onlineSubmissionID', this.assessmentObj.onlineSubmissionID);
    formData.append('academicSessionID', this.assessmentObj.academicSessionID);
    formData.append('recordID', this.assessmentObj.recordID);
    formData.append('isSendNotification', this.assessmentObj.isSendNotification);
    formData.append('isFileAttached', this.assessmentObj.isFileAttached);
    this.manageAssessmentService.AddAssessment(formData).subscribe((res: any) => {
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.GetAllAssessment();
        }
        else {
          this.toaster.error(res.message);
        }
      }
    });
  }
  CopyAssessments() {
    this.manageAssessmentService.CopyAssessment(this.assessmentObj).subscribe((res: any) => {
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.GetAllAssessment();
        }
        else {
          this.toaster.error(res.message);
        }
      }
    });
  }

  deleteAssessmentData(templateRef: TemplateRef<any>, assessmentID: number) {

    this.deleteAssessmentID = assessmentID
    this.dialog.open(templateRef);
    this.deleteAssessmentForm = this.fb.group({
      password: ['', Validators.required],
      keyword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  deleteAssessments(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.deleteAssessmentForm = this.fb.group({
      password: ['', Validators.required],
      keyword: ['', Validators.required],
    });
  }
  submitDeleteAssessment() {
    if (this.deleteAssessmentID) {

      this.DeletedAssessmentID = [JSON.stringify(this.deleteAssessmentID)]

    }
    else {

      this.DeletedAssessmentID = this.selectedId

    }

    this.userEmail = localStorage.getItem('currentUser');
    const currentUserEmail = JSON.parse(this.userEmail);
    this.adminUserEmail = currentUserEmail.username;
    this.deleteassessmentObj = {
      assessmentID: this.DeletedAssessmentID,
      password: this.deleteAssessmentForm.value.password,
      keyword: this.deleteAssessmentForm.value.keyword,
      email: this.adminUserEmail
    }
    console.log("this.deleteassessmentObj", this.deleteassessmentObj);
    this.deleteAssessment(this.deleteassessmentObj);
  }
  deleteAssessment(data: any) {
    this.manageAssessmentService.deleteAssessment(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.deleteassessmentObj = {};
        this.DeletedAssessmentID = null;
        this.selectedId = [];
        this.selection.clear();
        this.delObj = {};
      }
      else {
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.deleteassessmentObj = {};
        this.DeletedAssessmentID = null;
        this.selectedId = [];
        this.selection.clear();
        this.delObj = {};
      }
    });
  }

  PublishAndUnpublisht(id: any) {
    this.delObj = {
      "assessmentID": [JSON.stringify(id)]
    }
    console.log("this.delObj", this.delObj);

    this.manageAssessmentService.PublishAndUnpublish(this.delObj).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("res", res);
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.selectedId = [];
        this.selection.clear();
        this.delObj = {};
      }
      else {
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.selectedId = [];
        this.selection.clear();
        this.delObj = {};
      }
    });
  }

  multiPublish() {
    console.log("Ondelete", this.selectedId)
    this.publishObj = {
      "assessmentID": this.selectedId
    }
    this.manageAssessmentService.PublishAndUnpublish(this.publishObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.selectedId = [];
        this.selection.clear();
      }
      else {
        this.toaster.success(res.message);
        this.GetAllAssessment();
        this.selectedId = [];
        this.selection.clear();
      }
    });
  }


  displaySetting(event: any) {
    console.log(event.value);
  }

  appliedSetting(event: any) {
    this.AllSubjectsToApplied = event;
  }
  public handlePage(e: any) {

    console.log("Search Form Value", this.assessmentSerchingObj)
    if (this.assessmentSerchingObj === undefined) {
      this.filterModel.pageNumber = e.pageIndex + 1;
      this.filterModel.pageSize = e.pageSize;
      this.GetAllAssessment();

    }
    else {
      this.filterModel.searchPageNumber = e.pageIndex + 1;
      this.filterModel.pageSize = e.pageSize;
      this.onSearchingSubmit();
    }
  }

  GetAllAssessment() {
    this.filterModel.academicSessionID = this.academicSessionID
    this.manageAssessmentService.GetAllAssessmentList(this.filterModel).subscribe((res: any) => {
      this.dataSource = res.responseData;
      this.dataHover = this.dataSource;

      console.log(this.dataSource)
      this.metaData = res.meta;
      console.log("this.dataSourceList", this.dataSource);
    });
  }

  reset() {
    this.dateErro = false;
    this.err = false;
    this.addAssessmentForm.reset();
    this.initForm();
  }

  displayedColumns: string[] = ['select', 'assessmentID', 'assessmentCategoryName', 'assessmentName', 'isAppliedTo', 'gradingScaleName', 'isCountTowardsOverAllScore', 'assessmentStatusName', 'action'];

  //'select', 'assessmentID', 'assessmentCategoryName', 'assessmentName', 'assessmentStatusName', 'gradingScaleName', 'isAppliedTo', 'isCountTowardsOverAllScore', 'action'
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  selection = new SelectionModel<UserModel>(true, []);

  isAllSelected() {
    this.selectedId = [];
    if (this.selection.selected.length != 0) {
      for (var i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].assessmentID != undefined) {
          this.selectedId.push(JSON.stringify(this.selection.selected[i].assessmentID))
        }
      }
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  changee(event: any, data: any) {
    console.log("changeeata", data);

    if (event.checked == false) {
      for (var i = 0; i < this.selectedId.length; i++) {
        if (this.selectedId == data.assessmentID) {
          this.selectedId.splice(i, 1);
        }
      }
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row: any) => this.selection.select(row));
    if (this.selection.selected.length == 0) {
      this.selectedId = [];
    }
  }
  greaterThanValue: any

  // greaterThanValue(){
  // 
  //   var greaterThanValue=0;
  // }
  // lessThanValue(){
  //   
  //   var lessThanValue=0;
  // }
  err: any = false;
  lessThanValue: any;
  onChange(): void {
    console.log(this.greaterThanValue)
    console.log(this.lessThanValue)
    if (Number(this.greaterThanValue) < Number(this.lessThanValue)) {
      this.err = "Passing points is not greater than the points"
    }
    else {
      this.err = false;
    }
    // if (this.greaterThanValue > this.lessThanValue) {
    //   console.log("Wrong Value Enterred")
    // }
  }
  startDateValue: Date
  endDateValue: Date
  dateErro: any = false
  checkDates(): void {
    if (new Date(this.endDateValue) < new Date(this.startDateValue)) {
      console.log("end Date", this.endDateValue)
      console.log("start Date", this.startDateValue)
      this.dateErro = "End date must be greater than start date"
      //this.toaster.error('End date cannot befor start date', 'Error');

    }
    else {
      this.dateErro = false;
    }
  }
  scaleValue: any
  selectGradingScale(event: any) {
    this.scaleValue = event;
    console.log("SELECTGRADESCALE", this.scaleValue)
    if (this.scaleValue != 1 || this.scaleValue != 2) {
      this.addAssessmentForm.patchValue({
        recordID: 0
      })
    }
    else {
      this.addAssessmentForm.patchValue({
        recordID: ['']
      })
    }
  }

  sendNotification(assessmentID: any) {
    this.manageAssessmentService.sendNotificationById(assessmentID).subscribe((x: any) => {
      if (x.status > 0) {
        this.toaster.success(x.message);
      }
      else {
        this.toaster.error(x.message);
      }
    })
  }

  public hoverData: any;
  mouseEnter(event: any) {
    this.hoverData = this.dataHover.filter((member: any) => member.assessmentID === event)
    console.log(this.hoverData)
  }

  resetfilterform() {
    this.searchingAssessmentForm.reset();
    this.initSearchingForm()
    this.filterModel.pageNumber = 1;
    this.filterModel.searchPageNumber = 1;
    this.GetAllAssessment();
  }

}



export class UserModel {
  assessmentID: number;
  assessmentCategoryName: string = '';
  assessmentName: string = '';
  assessmentStatusName: string = '';
  gradingScaleName: string = '';
  isAppliedTo: string = '';
  isCountTowardsOverAllScore: boolean = true;
}
