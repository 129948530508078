import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PaymentDetailsService } from 'src/app/services/payment-details.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { UserRole } from 'src/environments/environment';
@Component({
  selector: 'app-viewpaymentdetails',
  templateUrl: './viewpaymentdetails.component.html',
  styleUrls: ['./viewpaymentdetails.component.css'],
})
export class ViewpaymentdetailsComponent implements OnInit {
  paymentDetailID: number = 0;
  paymentDetailData: any;
  dateFormat: string;
  monthNames: any;
  users: any;
  OrgName: any;
  currency: any;
  logo: any;
  salaryForYear: any;
  salaryForMonthName: any;
  teacherDetails: any;
  salaryField: any;
  deductionAmount: any;
  bonusAmount: any
  totalEarning: any;
  totalDeduction: any;
  isTeacherUser: boolean = false;
  
  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private paymentDetailService: PaymentDetailsService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.OrgName = currentUser.orgName;
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
      this.currency = currentUser.currency;
    }
  }

  ngOnInit(): void {
    var roleID = localStorage.getItem('roleID');
    if(roleID == UserRole.Teacher){
      this.isTeacherUser = true;
    }
    if (localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.logo = localStorage.getItem('Logo');
    } else {
      this.logo = '../../../../../../assets/img/admin/org_logo.png';
    }
    console.log('paymentDetailData', this.paymentDetailData);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paymentDetailID = params.id == undefined ? null : params.id;
    });
    this.dateFormat = 'yyyy/MM/dd';
    if (this.paymentDetailID > 0) {
      this.getPaymentDetailsByID();
    }
  }

  getPaymentDetailsByID() {
    this.paymentDetailService
      .GetPaymentDetailsByID(this.paymentDetailID)
      .subscribe((res: any) => {
        //debugger;
        console.log('GetPaymentDetailsByID', res);
        this.paymentDetailData = res.responseData;
        this.paymentDetailData.salaryForMonthName = moment().month(this.paymentDetailData.salaryForMonth - 1).format("MMMM");
        console.log("this.paymentDetailData", this.paymentDetailData);
        this.salaryslipopup(res.responseData);
      });

  }

  paidAmount: any;
  salaryslipopup(element: any) {
    //debugger;
    this.totalEarning = 0;
    this.totalDeduction = 0;
    console.log('ELEMENT', element);
    this.salaryForMonthName = element.salaryForMonthName;
    this.salaryForYear = element.salaryForYear;
    this.deductionAmount = element.adjustableAmount;
    this.bonusAmount = element.bonusAmount;
    this.paidAmount = element.paidAmount
    this.paymentDetailService
      .GetSalarySlipFormByTeacherID(element.userID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          console.log('this.collection', res.responseData);
          this.teacherDetails = res.responseData.coreTeacherDetails;
          this.salaryField = res.responseData.salaryFormDtos;
          for (let i = 0; i < this.salaryField.length; i++) {
            if (this.salaryField[i].deductionType == false) {
              this.totalEarning = this.salaryField[i].salaryFormFieldValue + this.totalEarning
            }
            if (this.salaryField[i].deductionType == true) {
              this.totalDeduction = this.salaryField[i].salaryFormFieldValue + this.totalDeduction
            }
          }
          this.totalEarning = this.bonusAmount + this.totalEarning;
          this.totalDeduction = this.totalDeduction + this.deductionAmount
          //this.dialog.open(templateRef);
        }
      });
  }
  back() {
    this.location.back();
  }
  generateArray(obj: any) {
    return Object.keys(obj).map((key) => {
      return { key: key, value: obj[key] };
    });
  }

  previousPage() {
    this.location.back();
  }
}
