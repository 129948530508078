import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRole } from 'src/environments/environment';

@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.css']
})
export class ProfileTabsComponent implements OnInit {
  IsStudentOrPreant: boolean = false;
  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
  ) { }
  studentID:any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id==undefined?null:params.id;
    });
    var roleID  = localStorage.getItem('roleID')
    if(roleID== UserRole.Student || roleID== UserRole.Parent){
      this.IsStudentOrPreant = true;
    }
  }
}
