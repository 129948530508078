import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AcademicRoutingModule } from './academic-routing.module';
import { DndModule } from 'ngx-drag-drop';
import { SharedModule } from 'src/app/common/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ManageClassSectionAndSubjectsComponent } from './courses-and-subjects/manage-class-section-and-subjects/manage-class-section-and-subjects.component';
import { ManageAlumniComponent } from './student/manage-alumni/manage-alumni.component';
import { ManageStudentsComponent } from './student/manage-students/manage-students.component';
import { NewAcademicSessionsComponent } from './academic-sessions/new-academic-sessions/new-academic-sessions.component';
import { OldAcademicSessionsComponent } from './academic-sessions/old-academic-sessions/old-academic-sessions.component';
import { FormioModule } from 'angular-formio';
import { ManageGradeComponent } from './assesment-and-gradings/manage-grade/manage-grade.component';
import { ReportCardsComponent } from './assesment-and-gradings/report-cards/report-cards.component';
import { ManageAssessmentComponent } from './assesment-and-gradings/manage-assessment/manage-assessment.component';
import { GradeSetupComponent } from './assesment-and-gradings/grade-setup/grade-setup.component';
import { AssessmentCategoriesComponent } from './assesment-and-gradings/grade-setup/assessment-categories/assessment-categories.component';
import { WightsSettingComponent } from './assesment-and-gradings/grade-setup/wights-setting/wights-setting.component';
import { GradingPeriodsComponent } from './assesment-and-gradings/grade-setup/grading-periods/grading-periods.component';
import { GradingScalesComponent } from './assesment-and-gradings/grade-setup/grading-scales/grading-scales.component';
import { ReportgradeDisplayComponent } from './assesment-and-gradings/grade-setup/reportgrade-display/reportgrade-display.component';
import { ReportcardFormatComponent } from './assesment-and-gradings/grade-setup/reportcard-format/reportcard-format.component';
import { RubicScaleComponent } from './assesment-and-gradings/grade-setup/grading-scales/rubic-scale/rubic-scale.component';
import { ManageTeachersComponent } from './teachers/manage-teachers/manage-teachers.component';
import { ViewTeacherComponent } from './teachers/manage-teachers/view-teacher/view-teacher.component';
import { EditTeacherComponent } from './teachers/manage-teachers/edit-teacher/edit-teacher.component';
import { SubjectsAllocationComponent } from './teachers/subjects-allocation/subjects-allocation.component';
import { ManageTeacherAttendanceComponent } from './teachers/manage-teacher-attendance/manage-teacher-attendance.component';
import { AttendanceSubmissionAnalyticsComponent } from './teachers/manage-teacher-attendance/attendance-submission-analytics/attendance-submission-analytics.component';
import { ViewProfileComponent } from './student/manage-students/view-profile/view-profile.component';
import { ProfileTabsComponent } from './student/manage-students/view-profile/profile-tabs/profile-tabs.component';
import { EnrollmentComponent } from './student/manage-students/view-profile/enrollment/enrollment.component';
import { ActivitiesComponent } from './student/manage-students/view-profile/activities/activities.component';
import { AttendanceComponent } from './student/manage-students/view-profile/attendance/attendance.component';
import { ClassScheduleComponent } from './student/manage-students/view-profile/class-schedule/class-schedule.component';
import { FeesAndInvoicingComponent } from './student/manage-students/view-profile/fees-and-invoicing/fees-and-invoicing.component';
import { AddStudentComponent } from './student/manage-students/add-student/add-student.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DataTableComponent } from 'src/app/app-layout/content/academics-and-learning/data-table/data-table.component';
import { CheckboxdataTableComponent } from './checkboxdata-table/checkboxdata-table.component';
import { EditStudentComponent } from './student/manage-students/edit-student/edit-student.component';
import { ViewStudentComponent } from './student/manage-students/view-student/view-student.component';
import { ActivityListComponent } from './student/manage-students/view-profile/activities/activity-list/activity-list.component';
import { ManageIdcardComponent } from './student/manage-students/manage-idcard/manage-idcard.component';
import { AddTeachersComponent } from './teachers/add-teachers/add-teachers.component';
import { NgxPrintModule } from 'ngx-print';
import { ProfileComponent } from './profile/profile.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AddAttchmentComponent } from './student/manage-attachment/add-attchment/add-attchment.component';
import { ViewAttachmentComponent } from './student/manage-attachment/view-attachment/view-attachment.component';
import { EditAttachmentComponent } from './student/manage-attachment/edit-attachment/edit-attachment.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
// tslint:disable-next-line:max-line-length
import { AddTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/add-teacher-attachment/add-teacher-attachment.component';
import { EditTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/edit-teacher-attachment/edit-teacher-attachment.component';
import { ViewTeacherAttachmentComponent } from './teachers/manage-teacher-attachment/view-teacher-attachment/view-teacher-attachment.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AddAdminStaffComponent } from './Admin-Staff/add-admin-staff/add-admin-staff.component';
import { EditAdminStaffComponent } from './Admin-Staff/edit-admin-staff/edit-admin-staff.component';
import { ManageAdminStaffComponent } from './Admin-Staff/manage-admin-staff/manage-admin-staff.component';
import { ViewAdminStaffComponent } from './Admin-Staff/view-admin-staff/view-admin-staff.component';
import { AttendanceSettingsComponent } from './teachers/manage-teacher-attendance/attendance-settings/attendance-settings.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ManageTeacherFieldComponent } from './teachers/manage-teacher-field/manage-teacher-field.component';
import { AddRolesComponent } from './manage-roles/add-roles/add-roles.component';
import { EditRolesComponent } from './manage-roles/edit-roles/edit-roles.component';
import { TreeviewModule } from 'ngx-treeview';
import { PreventButtonClickDirective } from 'src/app/common/directive/prevent-button-click.directive';
import { ManageStudentAttendanceComponent } from './student/manage-student-attendance/manage-student-attendance.component';
import { LoginUserProfileComponent } from './login-user-profile/login-user-profile.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TemplateOneComponent } from './assesment-and-gradings/grade-setup/template-one/template-one.component';
import { TemplateTwoComponent } from './assesment-and-gradings/grade-setup/template-two/template-two.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CustomformsComponent } from './customforms/customforms.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TeachersFormsComponent } from './teachers/teachers-forms/teachers-forms.component';
import { StudentSubmissionAnalyticsComponent } from './student/manage-student-attendance/student-submission-analytics/student-submission-analytics.component';
import { CustomSearchPipe } from './custom-search.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TeacherstabsComponent } from './teachers/manage-teachers/teacherstabs/teacherstabs.component';
import { TeachersclassScheduleComponent } from './teachers/manage-teachers/teachersclass-schedule/teachersclass-schedule.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { ReportcardformatsComponent } from './assesment-and-gradings/grade-setup/reportcardformats/reportcardformats.component';
import { TemplateThreeComponent } from './assesment-and-gradings/grade-setup/template-three/template-three.component';
import { TemplateFourComponent } from './assesment-and-gradings/grade-setup/template-four/template-four.component';
import { CustomRepotCardPipe } from './custom-repot-card.pipe';
import {MultiSelectModule} from 'primeng/multiselect';
import { ManageBranchGridComponent } from './manage-branch/manage-branch-grid/manage-branch-grid.component';
import { PaymentdetailsComponent } from './teachers/manage-teachers/paymentdetails/paymentdetails.component';
import { SalarydetailsComponent } from './teachers/manage-teachers/salarydetails/salarydetails.component';
import { ViewpaymentdetailsComponent } from './teachers/manage-teachers/viewpaymentdetails/viewpaymentdetails.component';
import { TeacherspaymentComponent } from './teachers/teacherspayment/teacherspayment.component';
import { ReportCardsTemplate1Component } from './assesment-and-gradings/report-cards-template1/report-cards-template1.component';
import { DefaultTemplateComponent } from './assesment-and-gradings/grade-setup/default-template/default-template.component';
import { GradeBookLogComponent } from './assesment-and-gradings/grade-book-log/grade-book-log.component';
import { ReportCardsTemplate2Component } from './assesment-and-gradings/report-cards-template2/report-cards-template2.component';
import { EditReportCardComponent } from './assesment-and-gradings/grade-setup/edit-report-card/edit-report-card.component';
import { EditTemplateOneComponent } from './assesment-and-gradings/grade-setup/edit-template-one/edit-template-one.component';
import { EditTemplateTwoComponent } from './assesment-and-gradings/grade-setup/edit-template-two/edit-template-two.component';
import { EditTemplateThreeComponent } from './assesment-and-gradings/grade-setup/edit-template-three/edit-template-three.component';
import { EditTemplateFourComponent } from './assesment-and-gradings/grade-setup/edit-template-four/edit-template-four.component';
import { RCardComponent } from './student/manage-students/view-profile/rcard/rcard.component';
import { SubscriptionpopUpComponent } from './student/manage-students/Common/subscriptionpop-up/subscriptionpop-up.component';
import {ManageRegistrationsComponent} from './manage-registrations/manage-registrations.component'

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    ManageClassSectionAndSubjectsComponent,
    ManageAlumniComponent,
    ManageBranchGridComponent,
    ManageStudentsComponent,
    NewAcademicSessionsComponent,
    OldAcademicSessionsComponent,
    ReportCardsComponent,
    ManageGradeComponent,
    ManageAssessmentComponent,
    GradeSetupComponent,
    AssessmentCategoriesComponent,
    WightsSettingComponent,
    GradingPeriodsComponent,
    GradingScalesComponent,
    ReportgradeDisplayComponent,
    ReportcardFormatComponent,
    RubicScaleComponent,
    ManageTeachersComponent,
    ViewTeacherComponent,
    EditTeacherComponent,
    SubjectsAllocationComponent,
    ManageTeacherAttendanceComponent,
    AttendanceSubmissionAnalyticsComponent,
    ViewProfileComponent,
    ProfileTabsComponent,
    EnrollmentComponent,
    ActivitiesComponent,
    AttendanceComponent,
    ClassScheduleComponent,
    FeesAndInvoicingComponent,
    AddStudentComponent,
    DataTableComponent,
    CheckboxdataTableComponent,
    EditStudentComponent,
    ViewStudentComponent,
    ProfileComponent,
    ActivityListComponent,
    ManageIdcardComponent,
    AddTeachersComponent,
    AddAttchmentComponent,
    ViewAttachmentComponent,
    EditAttachmentComponent,
    ViewTeacherAttachmentComponent,
    EditTeacherAttachmentComponent,
    AddTeacherAttachmentComponent,
    AddAdminStaffComponent,
    EditAdminStaffComponent,
    ManageAdminStaffComponent,
    ViewAdminStaffComponent,
    AttendanceSettingsComponent,
    ManageTeacherFieldComponent,
    ManageRolesComponent,
    AddRolesComponent,
    EditRolesComponent,
    PreventButtonClickDirective,
    ManageStudentAttendanceComponent,
    LoginUserProfileComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    CustomformsComponent,
    TeachersFormsComponent,
    StudentSubmissionAnalyticsComponent,
    CustomSearchPipe,
    TeacherstabsComponent,
    TeachersclassScheduleComponent,
    ReportcardformatsComponent,
    TemplateThreeComponent,
    TemplateFourComponent,
    CustomRepotCardPipe,
    PaymentdetailsComponent,
    SalarydetailsComponent,
    ViewpaymentdetailsComponent,
    TeacherspaymentComponent,
    ReportCardsTemplate1Component,
    DefaultTemplateComponent,
    GradeBookLogComponent,
    ReportCardsTemplate2Component,
    EditReportCardComponent,
    EditTemplateOneComponent,
    EditTemplateTwoComponent,
    EditTemplateThreeComponent,
    EditTemplateFourComponent,
    RCardComponent,
    SubscriptionpopUpComponent,
    ManageRegistrationsComponent
  ],
  imports: [
    DragDropModule,
    NgxMatSelectSearchModule ,
    MatFormFieldModule,
    CommonModule,
    MatSelectModule,
    AcademicRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule ,
    // BrowserAnimationsModule,
    ImageCropperModule,
    NgxPrintModule,
    MatChipsModule,
    RichTextEditorAllModule,
    NgxMatIntlTelInputModule,
    MatAutocompleteModule,
    Ng2SearchPipeModule,
    FormioModule,
    TreeviewModule.forRoot(),
    ColorPickerModule,PopoverModule,
    MatTableExporterModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatRippleModule,
    DndModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MultiSelectModule
  ],
  providers: []
})
export class AcademicModule { }
